import React from 'react';
import {connect} from 'react-redux';
import db from 'core/db';

import UploadView from './UploadView';
import EditView from './EditView';
import DoneNotifyView from './DoneNotifyView';
import Steps from 'components/Steps';
import JobUnitMatcher from '../helpers/JobUnitMatcher';
import { withLoading } from 'components';

import style from '../styles/import-switch.module.sass';


class ImportSwitch extends React.PureComponent
{
	state = {
		currentStep: 0,
		fileList: [],
	}

	onConfirmUpload = (fileList) => {
		this.setState({fileList, currentStep: 1});
	}

	onConfirmImport = () => {
		this.setState({currentStep: 2});
	}

	getGridItems = () => {
		const excelDateToJsDate = date => date && new Date((date - (25567 + 2)) * 86400 * 1000);

		const items = this.state.fileList
			.map(file => file.parsedRows)
			.reduce((res, rows) => {
				return res.concat(rows);
			}, [])
			.filter(row => !!row)
			.map(row => {
				const { estimatedStartDate, estimatedEndDate, unitTypeTitle } = row.data;

				const data = {
					...row.data,
					index: row.index,
					unitId: new JobUnitMatcher().match(unitTypeTitle),
					estimatedStartDate: excelDateToJsDate(estimatedStartDate),
					estimatedEndDate: excelDateToJsDate(estimatedEndDate),
				};

				delete data.unitTypeTitle;

				return data;
			})
		;

		return items;
	}

	renderContent() {
		if (this.state.currentStep === 0) {

			return <UploadView
				{...this.props}
				onConfirmUpload={this.onConfirmUpload}
			/>;
		} else if (this.state.currentStep === 1) {

			const items = this.getGridItems();

			return <EditView
				{...this.props}
				items={items}
				onConfirmImport={this.onConfirmImport}
			/>;
		} else if (this.state.currentStep === 2) {
			return <DoneNotifyView {...this.props}/>;
		}

		return null;
	}

	renderHeader = () => {
		const steps = [
			{ title: 'Загрузка файлов' },
			{ title: 'Редактирование' },
			{ title: 'Готово!' },
		];

		return (
			<div className={style.headerWrapper}>
				<Steps
					items={steps}
					currentStep={this.state.currentStep}
				/>
			</div>
		);
	}

	render()
	{
		return (
			<div className={style.mainWrapperFlex}>
				{this.renderHeader()}
				{this.renderContent()}
			</div>
		);
	}
}

export default connect((state, props) => {
	const jobUnits = db.jobUnits.list();
	const jobUnitsMap = db.jobUnits.list().hashById();

	const isLoading = jobUnits.isLoading;

	return {
		isLoading,
		jobUnits,
		jobUnitsMap,
		projectId: props.match.params.projectId,
	};
})(withLoading(ImportSwitch));
