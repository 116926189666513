import React from 'react';
import style from '../styles/action.module.sass';
import PropTypes from 'prop-types';


class MenuAction extends React.PureComponent
{
	static propTypes = {
		action: PropTypes.object,
	}

	static defaultProps = {
		action: {},
	}

	render()
	{
		const action = this.props.action;

		return (
			<div
				className={style.action}
				onClick={() => action.onClick()}
			>
				{action.title}
			</div>
		);
	}
}

export default MenuAction;
