
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import { getColumnDefs, getGridOptions } from './defs';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';


class Grid extends React.Component
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		detailItems: PropTypes.arrayOf(PropTypes.object),
		onDetailCellValueChanged: PropTypes.func,
		currenciesMap: PropTypes.any,
		currencies: PropTypes.arrayOf(PropTypes.object),
	};

	static defaultProps = {
		items: [],
		detailItems: [],
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
	}

	render()
	{
		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		const detailCellRendererParams = params => {
			const orderId = params.data.id;
			const items = this.props.detailItems.filter(i => (i.purchaseOrderId === orderId));

			return {
				items,
				onCellValueChanged: this.props.onDetailCellValueChanged,
			};
		};

		const getRowHeight = params => {
			if (params.node && params.node.detail) {
				const offset = 28 + 25;
				const orderId = params.data.id;
				const items = this.props.detailItems.filter(i => (i.purchaseOrderId === orderId));
				const allDetailRowHeight = items.length * 25;

				return allDetailRowHeight + offset;
			} else {
				return 38;
			}
		};

		return (
			<CustomGrid
				onGridReady={this.onGridReady}
				onSelectionChanged={this.onSelectionChanged}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				suppressRowClickSelection={true}
				masterDetail={true}
				detailCellRenderer={'detailCellRenderer'}
				detailCellRendererParams={detailCellRendererParams}
				keepDetailRows={true}
				getRowHeight={getRowHeight}
				groupDefaultExpanded={1}
				onCellValueChanged={this.props.onCellValueChanged}
			/>
		);
	}
}

export default connect()(Grid);

