import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import db from 'core/db';
import api from 'core/api';

import { withLoading } from 'components';
import AttachedFilesToolbar from 'components/AttachedFilesToolbar';
import ButtonToolbar from 'components/ButtonToolbar';
import TagToolbar from 'components/TagToolbar';
import Modal from 'components/Modal';

import Grid from './components/Grid';
import NewRequestDialog from './components/NewRequestDialog';
import CurrencyRatesToolbar from './components/CurrencyRatesToolbar';
import { requiredFields } from './components/Grid/defs';
import CurrencyRatesForm from './components/CurrencyRatesForm';

import Toolbar from 'domains/tbs-supplies/components/Toolbar';


class ConsumableSheetView extends React.Component
{
	static propTypes = {
		isLoading: PropTypes.bool,
		consumableSheetId: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.oneOf([null]).isRequired,
		]),
		consumableSheet: PropTypes.object
	}

	state = {
		selectedNodes: [],
		loading: false,
		requestModalVisible: false,
		ratesModalVisible: false,
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onSelectionChanged = selectedNodes => {
		this.setState({ selectedNodes });
	}

	clearSelection = () => {
		this.gridApi.redrawRows({
			rowNodes: this.state.selectedNodes,
		});
		this.gridApi.deselectAll();
	}

	lockSelected = (nodes) => {

		if (!nodes.length) {
			return;
		}

		const transactionBody = nodes
			.map(node => api.tbsConsumableSheetItems().lock({
				consumableSheetItemId: node.data.id
			}))
		;

		this.setState({loading: true});
		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(this.clearSelection)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось взять в работу", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	isAllValuesFilled()
	{
		const items = this.props.consumableSheetItems;
		const isValueFilled = (value) => {
			return !(value === null || value === undefined);
		};

		for (const item of items) {
			for (const dataIndex of requiredFields) {
				const value = item[dataIndex];
				if (dataIndex === 'planPrice' || dataIndex === 'retailPrice') {
					if (!(isValueFilled(item.planPrice) || isValueFilled(item.retailPrice))) {
						return false;
					}
				} else if (!isValueFilled(value)) {
					return false;
				}
			}
		}

		return true;
	}s

	releaseSelected = (nodes) => {
		if (!nodes.length) {
			return;
		}

		const transactionBody = nodes
			.map(node => api.tbsConsumableSheetItems().unlock({
				consumableSheetItemId: node.data.id
			}))
		;

		this.setState({loading: true});
		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(this.clearSelection)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось убрать из работы", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	approveSheet = () => {
		this.setState({loading: true});
		this.props.dispatch(api.tbsConsumableSheets()
			.approve({consumableSheetId: this.props.consumableSheetId}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось утвердить спецификацию", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	updateCurrencyRates = currencyRates => {
		const changes = {
			currencyRates,
		};

		this.props.dispatch(api.tbsConsumableSheets().update({
			consumableSheetId: this.props.consumableSheetId,
			changes: changes
		}))
			.then(this.closeCurrencyRatesModal)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось именить значения валют", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	openRequestModal = () => {
		this.setState({requestModalVisible: true});
	}

	closeRequestModal = () => {
		this.setState({requestModalVisible: false});
	}

	renderSheetBtnToolbar()
	{
		const { me } = this.props;

		const nodes = this.state.selectedNodes;
		const selectedReleased = nodes.filter(node => !node.data.lockedByUserId);
		const selectedLockedByUser = nodes.filter(node => node.data.lockedByUserId === me.id);
		const btnLockTitle = 'Взять в работу' + (selectedReleased.length ? ` ${selectedReleased.length}` : '');
		const btnReleaseTitle = 'Убрать из работы' + (selectedLockedByUser.length ? ` ${selectedLockedByUser.length}` : '');

		const btnLock = {
			title: btnLockTitle,
			props: {
				disabled: this.state.loading,
				onClick: () => this.lockSelected(selectedReleased),
			}
		};

		const btnRelease = {
			title: btnReleaseTitle,
			props: {
				disabled: this.state.loading,
				onClick: () => this.releaseSelected(selectedLockedByUser),
			}
		};

		const btnListLeft = [];

		if (selectedReleased.length) {
			btnListLeft.push(btnLock);
		}

		if (selectedLockedByUser.length) {
			btnListLeft.push(btnRelease);
		}

		const btnListRight = [
			{
				title: 'Утвердить',
				props: {
					disabled: !this.isAllValuesFilled(),
					onClick: this.approveSheet,
				}
			},
		];

		return (
			<div className="d-flex flex-row justify-content-between mt-2">
				<ButtonToolbar btnList={btnListLeft} align="left"/>
				<ButtonToolbar btnList={btnListRight}/>
			</div>
		);
	}

	renderOrdersBtnToolbar = () => {
		const nodes = this.state.selectedNodes;

		const btnList = [
			{
				title: 'Новая заявка',
				props: {
					disabled: !nodes.length,
					onClick: this.openRequestModal,
				}
			},
		];

		return <ButtonToolbar className="mt-2" btnList={btnList}/>;
	}

	renderTopToolbar = () => {
		const tagList = [
			{
				title: 'Утверждена',
				variant: 'success',
			},
		];

		const { currencyRates, isApproved, artifacts } = this.props.consumableSheet;

		const tagToolbar = isApproved && <TagToolbar
			tagList={tagList}
			className="p-2"
		/>;

		const currencyToolbar = !isApproved && <CurrencyRatesToolbar
			rates={currencyRates || {}}
			onEditClick={this.onRatesEditClick}
			currenciesMap={this.props.currenciesMap}
		/>;

		const consumableSheetAttachedXlsxList = artifacts.map(art => {
			return {
				file: {
					id: art.downloads.xlsx.url,
					originalName: art.title,
					url: art.downloads.xlsx.url,
				},
				icon: faFileExcel,
				iconStyle: { color: 'green' },
				withAuth: true,
			};
		});

		const attachedFilesToobar =
			<AttachedFilesToolbar
				iconsList={consumableSheetAttachedXlsxList}
				onRemoveClick={this.onRemoveSourceSpecClicked}
			/>
		;

		return (
			<Toolbar className="justify-content-between">
				{tagToolbar}
				{currencyToolbar}
				{attachedFilesToobar}
			</Toolbar>
		);
	}

	onRatesEditClick = () => {
		this.openCurrencyRatesModal();
	}

	openCurrencyRatesModal = () =>
	{
		this.setState({ ratesModalVisible: true });
	}

	closeCurrencyRatesModal = () =>
	{
		this.setState({ ratesModalVisible: false });
	}

	renderCurenncyRatesModal()
	{
		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.currencyRatesFormProps.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeCurrencyRatesModal
				}
			},
		];

		const onFormReady = props => {
			this.currencyRatesFormProps = props;
		};

		return (
			<Modal
				btnList={btnList}
				size="md"
				title="Курсы валют"
				show={this.state.ratesModalVisible}
				onHide={this.closeCurrencyRatesModal}
			>
				<CurrencyRatesForm
					onFormReady={onFormReady}
					onSubmit={this.updateCurrencyRates}
					onCancel={this.closeCurrencyRatesModal}
					currencies={this.props.currencies || []}
					currenciesMap={this.props.currenciesMap}
					ratesValues={this.props.consumableSheet?.currencyRates || {}}
				/>
			</Modal>
		);
	}

	renderNewRequestModal()
	{
		const btnList = [
			{
				title: 'Создать',
				props: {
					onClick: () => this.newRequestDialogProps.createRequest(),
					disabled: this.state.loading,
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeRequestModal,
					disabled: this.state.loading,
				}
			},
		];

		const onDialogReady = props => {
			this.newRequestDialogProps = props;
		};

		const onRequestCreated = () => {
			this.closeRequestModal();
			this.clearSelection();
		};

		const slectedItems = this.state.selectedNodes.map(node => node.data);
		const items = [...slectedItems];

		return (
			<Modal
				title={'Новая заявка'}
				btnList={btnList}
				show={this.state.requestModalVisible}
				onHide={this.closeRequestModal}
				size="xl"
			>
				<NewRequestDialog
					items={items}
					currenciesMap={this.props.currenciesMap}
					purchaseRequestItems={this.props.purchaseRequestItems}
					consumableSheetId={this.props.consumableSheetId}
					onRequestCreated={onRequestCreated}
					purchaseRequestsMap={this.props.purchaseRequestsMap}
					onDialogReady={onDialogReady}
				/>
			</Modal>
		);
	}

	render()
	{
		const { isApproved } = this.props.consumableSheet;

		const topToolBar = this.renderTopToolbar();
		const btnToolbar = isApproved ? this.renderOrdersBtnToolbar() : this.renderSheetBtnToolbar();
		const ratesModal = this.renderCurenncyRatesModal();
		const requestModal = this.renderNewRequestModal();

		return (
			<React.Fragment>
				<div className="flex-grow-1 d-flex flex-column">
					{topToolBar}
					<Grid
						consumableSheet={this.props.consumableSheet}
						items={this.props.gridItems}
						usersMap={this.props.usersMap}
						currencies={this.props.currencies}
						currenciesMap={this.props.currenciesMap}
						consumableUnits={this.props.consumableUnits}
						consumableUnitsMap={this.props.consumableUnitsMap}
						onSelectionChanged={this.onSelectionChanged}
						onGridReady={this.onGridReady}
						me={this.props.me}
					/>
					{btnToolbar}
					{requestModal}
				</div>
				{ratesModal}
			</React.Fragment>
		);
	}
}

const getGridItems = (props) => {
	const { consumableSheetItems, consumableSheet } = props;

	return consumableSheetItems.map(item => {
		return {
			...item,
			consumableSheet,
		};
	});
};

const mapToProps = (state, props) => {
	const consumableSheet = props.consumableSheet;
	const consumableSheetId = props.consumableSheetId;

	const filter = { filter: { consumableSheetId: props.consumableSheetId } };
	const consumableSheetItems = db.tbsConsumableSheetItems.list(filter);
	const purchaseRequestItems = db.tbsPurchaseRequestItems.list();
	const currencies = db.currencies.list();
	const users = db.users.list();
	const consumableUnits = db.consumableUnits.list();
	const purchaseRequests = db.tbsPurchaseRequests.list(filter);

	const currenciesMap = currencies.hashById();
	const consumableUnitsMap = consumableUnits.hashById();
	const usersMap = users.hashById();
	const purchaseRequestsMap = purchaseRequests.hashById();

	const isLoading = props.isLoading
		|| users.isLoading
		|| consumableSheetItems.isLoading
		|| currencies.isLoading
		|| consumableUnits.isLoading
		|| purchaseRequestItems.isLoading
		|| purchaseRequests.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const me = state.entities.me;
	const gridItems = getGridItems({consumableSheetItems, consumableSheet});

	return {
		consumableSheet,
		consumableSheetId,
		consumableSheetItems,
		currencies,
		currenciesMap,
		usersMap,
		isLoading,
		consumableUnits,
		consumableUnitsMap,
		me,
		gridItems,
		purchaseRequestItems,
		purchaseRequests,
		purchaseRequestsMap,
	};
};

export default connect(mapToProps)(withLoading(ConsumableSheetView));
