import React from 'react';
import PropTypes from 'prop-types';
import ActivityItem from './ActivityItem';
import styles from 'layouts/Default/components/ActivityDrawer/style/activity-drawer.module.sass';


class ActivityGroupItem extends React.PureComponent
{
	render()
	{
		const data = this.props.data;

		return (
			<div className={styles.activityGroupItem}>
				<span className={styles.date}>{data.day}</span>
				{data.items.map((item, index) => (
					<ActivityItem
						key={index}
						data={item}
					/>
				))}
			</div>
		);
	}
}

ActivityGroupItem.propTypes = {
	data: PropTypes.object
};

ActivityGroupItem.defaultProps = {
	data: {}
};


export default ActivityGroupItem;
