import React from 'react';
import PropTypes from 'prop-types';
import {ProgressBar} from 'react-bootstrap';


class JobProgressCard extends React.PureComponent
{
	static propTypes = {
		job: PropTypes.object,
	}

	static defaultProps = {
		job: {
			currentProgress: 384,
			maxProgress: 1422,
			unit: {
				suffix: 'м/п',
			}
		}
	}

	renderTitle(percents, title, suffix)
	{
		const style = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			fontWeight: 500,
			width: '100%',
		};

		return (
			<div style={style}>
				<div>{`${percents}%`}</div>
				<div>
					{`${title} `}
					<span style={{fontSize: '0.6em'}}>{suffix}</span>
				</div>
			</div>
		);
	}

	render()
	{
		const style = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '6px 15px',
			marginTop: '25px',
		};

		const mp = this.props.job.maxProgress || 0;
		const cp = this.props.job.currentProgress || 0;
		const pr = mp ? Number(cp / mp * 100).toFixed(0) : 0;

		const suf = this.props.job.unit.suffix;
		const numTitle = `${cp}/${mp}`;

		return (
			<div style={style}>
				{this.renderTitle(pr, numTitle, suf)}
				<ProgressBar
					variant={"success"}
					style={{
						width: '100%',
						height: '4px',
					}}
					now={pr}
				/>
			</div>
		);
	}
}

export default JobProgressCard;
