import DBResource from './DBResource';
import api from '../api';

class DB
{
	constructor(reduxStoreIntegration, store)
	{
		this.reduxStoreIntegration = reduxStoreIntegration;
		this.store = store;

		for (const resourceName in reduxStoreIntegration.resources) {
			const apiResource = reduxStoreIntegration.resources[resourceName];

			this[resourceName] = new DBResource(store, resourceName, apiResource);
		}
	}

	dispatchClearAndReloadCache()
	{
		this.store.dispatch({type: 'ENTITIES_STORE_CLEAR'});
		this.dispatchReloadCache();
	}

	dispatchReloadCache()
	{
		this.companies.list({refresh: true});
		this.projects.list({refresh: true});
		this.stages.list({refresh: true});
		this.jobs.list({refresh: true});
		this.store.dispatch(api.onboardings().list().replaceCache());
	}

	static reducer()
	{
		return (state = {}, action) => {
			if (action.type === 'DB_RESOURCE_CACHE') {
				return {
					...state,
					[action.key]: {
						ts: new Date,
						value: action.value,
					}
				};
			}

			if (action.type === 'LOGOUT') {
				return {};
			}

			return state;
		};
	}
}

export default DB;
