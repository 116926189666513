import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Diagramm from './components/Diagramm';
import AutoScheduler from './components/helpers/AutoScheduler';
import DepGraph from './components/helpers/DepGraph';
import api from 'core/api';
import db from 'core/db';
import moment from 'moment';
import { Row, Col, Button } from 'react-bootstrap';
import { Prompt } from 'react-router';

class GanttControl extends React.PureComponent
{
	diagramm = null;

	changes = {
		new: {},
		updated: {},
		deleted: {},
	};

	state = {
		unit: 'day',
		hasChanges: false,
		saving: false,
	};

	origOnbeforeunload;

	componentDidMount()
	{
		this.origOnbeforeunload = window.onbeforeunload;

		window.onbeforeunload = () => (this.state.hasChanges ? true : null);
	}

	componentWillUnmount()
	{
		window.onbeforeunload = this.origOnbeforeunload;
	}

	prepareDataForGantt(jobs, stages, links)
	{
		const toDate = date => {
			if (!date) {
				return date;
			}

			const ts = new Date(date);

			ts.setHours(0);
			ts.setMinutes(0);
			ts.setSeconds(0, 0);

			return ts;
		};

		const nextDate = date => {
			return toDate(new Date((new Date(date)).getTime() + 24 * 3600 * 1000));
		};

		const stagesMap = {};
		const jobsMap = {};

		for (const stage of stages) {
			stagesMap[stage.id] = {
				...stage,
				jobs: [],
			};
		}

		for (const job of jobs) {
			jobsMap[job.id] = job;

			const stage = stagesMap[job.stageId];
			if (!stage) {
				continue;
			}

			stage.jobs.push(job);
		}

		const taskExists = id => {
			return (id in stagesMap) || (id in jobsMap);
		};

		const dataObjects = [];
		const now = new Date;

		for (const stageId in stagesMap) {
			const stage = stagesMap[stageId];
			let completed = true;

			for (const job of stage.jobs) {
				const startDate = new Date(job.estimatedStartDate || job.creationTime);
				const endDate = job.estimatedEndDate
					? nextDate(job.estimatedEndDate)
					: nextDate(startDate)
				;

				dataObjects.push({
					id: job.id,
					taskType: 'job',
					task: job,
					parent: job.stageId,
					text: job.title,
					start_date: toDate(startDate),
					end_date: toDate(endDate),
					color: '#f5a623',
					progress: job.currentProgress / job.maxProgress,
					completed: job.currentProgress >= job.maxProgress,
					woDates: !job.estimatedStartDate && !job.estimatedEndDate,
				});

				if (job.currentProgress < job.maxProgress) {
					completed = false;
				}
			}

			const startDate = stage.estimatedStartDate ? new Date(stage.estimatedStartDate) : now;

			dataObjects.push({
				id: stage.id,
				taskType: 'stage',
				parent: stage.parentStageId,
				task: stage,
				text: stage.title,
				color: '#4a90e2',
				completed: completed,
				start_date: toDate(startDate),
				end_date: toDate(stage.estimatedEndDate ? new Date(stage.estimatedEndDate) : nextDate(startDate)),
				woDates: !stage.estimatedStartDate && !stage.estimatedEndDate,
			});
		}

		return {
			data: dataObjects.sort((a, b) => {
				return a.task.creationTime.localeCompare(b.task.creationTime);
			}),
			links: links
				.filter(link => taskExists(link.fromTaskId) && taskExists(link.toTaskId))
				.map(link => ({
					id: link.id,
					source: link.fromTaskId,
					target: link.toTaskId,
					type: '0',
				})),
		};
	}

	markTaskDirty(item)
	{
		this.changes.updated[item.id] = item;

		if (!this.state.hasChanges) {
			this.setState({hasChanges: this.hasChanges()});
		}
	}

	onTaskUpdate(item)
	{
		this.markTaskDirty(item);
		this.reschedule();
	}

	onLinkCreate(id, fromTaskId, toTaskId)
	{
		this.changes.new[id] = {fromTaskId, toTaskId};

		this.reschedule();

		this.setState({hasChanges: this.hasChanges()});
	}

	onLinkDelete(id)
	{
		if (!this.changes.new[id]) {
			this.changes.deleted[id] = {id: id};
		}

		delete this.changes.updated[id];
		delete this.changes.new[id];

		this.reschedule();

		this.setState({hasChanges: this.hasChanges()});
	}

	canCreateLink(link)
	{
		let node = gantt.getTask(link.source);

		while (node.parent) {
			if (node.parent === link.target) {
				return false;
			}

			node = gantt.getTask(node.parent);
		}

		const tasks = gantt.getTaskByTime().map(task => ({...task}));
		const dg = this.createDepGraph(tasks, gantt.getLinks());

		dg.addDep(
			tasks.find(task => task.id === link.source),
			tasks.find(task => task.id === link.target),
			'end-to-start'
		);

		try {
			this.rescheduleByGraph(dg);

			return true;
		} catch (e) {
			return false;
		}
	}

	createDepGraph(tasks, links)
	{
		const dg = new DepGraph();
		const tasksMap = {};

		for (const task of tasks) {
			tasksMap[task.id] = task;
		}

		for (const task of tasks) {
			if (!task.parent) {
				continue;
			}

			const parent = tasksMap[task.parent];

			dg.addDep(task, parent, 'child');
		}

		for (const link of links) {
			dg.addDep(tasksMap[link.source], tasksMap[link.target], 'end-to-start');
		}

		return dg;
	}

	reschedule()
	{
		const tasks = gantt.getTaskByTime().map(task => ({...task}));
		const dg = this.createDepGraph(tasks, gantt.getLinks());

		const updates = this.rescheduleByGraph(dg);

		for (const id in updates) {
			const task = gantt.getTask(id);

			for (const k in updates[id]) {
				task[k] = updates[id][k];
			}

			this.markTaskDirty(task);
		}

		gantt.render();
	}

	rescheduleByGraph(dg)
	{
		const scheduler = new AutoScheduler(dg);

		const updated = {};

		scheduler.schedule(update => {
			updated[update.task.id] = {
				...(updated[update.task.id] || {}),
				...update.changes
			};

			for (const field in update.changes) {
				update.task[field] = update.changes[field];
			}
		});

		return updated;
	}

	hasChanges()
	{
		return !!(
			Object.keys(this.changes.new).length
			+ Object.keys(this.changes.updated).length
			+ Object.keys(this.changes.deleted).length
		);
	}

	onSubmit()
	{
		const updates = [];

		this.setState({saving: true});

		for (const taskId in this.changes.updated) {
			const task = this.changes.updated[taskId];

			const changes = {
				estimatedStartDate: moment(task.start_date).format('YYYY-MM-DD'),
				estimatedEndDate: moment(task.end_date).format('YYYY-MM-DD'),
			};

			if (task.taskType === 'job') {
				updates.push(api.jobs().update({
					jobId: taskId,
					changes
				}));
			} else if (task.taskType === 'stage') {
				updates.push(api.stages().update({
					stageId: taskId,
					changes
				}));
			}
		}

		for (const linkId in this.changes.deleted) {
			updates.push(api.links().update({
				linkId: linkId,
				changes: {
					isDeleted: true,
				}
			}));
		}

		for (const linkId in this.changes.new) {
			const l = this.changes.new[linkId];

			updates.push(api.links().create({
				linkId: linkId,
				fromTaskId: l.fromTaskId,
				toTaskId: l.toTaskId,
				typeId: 'E6689A44-992C-49AE-8681-B837D72A9AC2',
				projectId: this.props.projectId,
			}));
		}

		this.changes = {
			new: {},
			updated: {},
			deleted: {},
		};

		this.props.dispatch(api.transaction().execute(updates)).then(() => {
			this.setState({
				saving: false,
				hasChanges: false,
			});
		});
	}

	render()
	{
		if (this.props.loading) {
			return null;
		}

		const data = this.prepareDataForGantt(this.props.data.jobs, this.props.data.stages, this.props.data.links);
		const hasChanges = this.state.hasChanges;
		const saveButtonLabel = this.state.saving ? 'Сохранение...' : 'Сохранить';

		return (
			<React.Fragment>
				<Prompt
					when={this.state.hasChanges}
					message={'Имеются несохранённые данные, действительно хотите покинуть План и отменить все изменения?'}
				/>

				<Row className="m-3" noGutters={true}>
					<Col md="auto" className="ml-1">
						<Button
							onClick={() => this.setState({unit: 'day'})}
							variant={'secondary'}
							size="sm"
							disabled={this.state.unit === 'day'}
						>
							День
						</Button>
					</Col>
					<Col md="auto" className="ml-1">
						<Button
							onClick={() => this.setState({unit: 'week'})}
							variant={'secondary'}
							size="sm"
							disabled={this.state.unit === 'week'}
						>
							Неделя
						</Button>
					</Col>
					<Col md="auto" className="ml-1">
						<Button
							onClick={() => this.setState({unit: 'month'})}
							size={'sm'}
							variant={'secondary'}
							disabled={this.state.unit === 'month'}
						>
							Месяц
						</Button>
					</Col>
					<Col md="auto" className="ml-auto">
						<Button
							onClick={() => this.onSubmit()}
							variant={'primary'}
							size="sm"
							disabled={!hasChanges || this.state.saving}
						>
							{saveButtonLabel}
						</Button>
					</Col>
				</Row>
				<Row className="basic-section m-3 p-2" noGutters={true}>
					<Col>
						<Diagramm
							{...this.props}
							data={data.data}
							links={data.links}
							onTaskUpdate={item => this.onTaskUpdate(item)}
							onLinkCreate={this.onLinkCreate.bind(this)}
							onLinkDelete={item => this.onLinkDelete(item)}
							canCreateLink={link => this.canCreateLink(link)}
							unit={this.state.unit}
							ref={e => (this.diagramm = e)}
						/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

GanttControl.propTypes = {
	projectId: PropTypes.string.isRequired,
	data: PropTypes.shape({
		jobs: PropTypes.arrayOf(PropTypes.object),
		stages: PropTypes.arrayOf(PropTypes.object),
		links: PropTypes.arrayOf(PropTypes.object),
	})
};

export default connect((state, props) => {
	const projectId = props.match.params.projectId;
	const jobs = db.jobs.listNotDeleted({filter: {projectId: projectId}});
	const stages = db.stages.listNotDeleted({filter: {projectId: projectId}});
	const links = db.links.listNotDeleted({filter: {projectId: projectId}});

	const loading = jobs.isLoading || stages.isLoading || links.isLoading;

	const data = {
		jobs: jobs,
		stages: stages,
		links: links,
	};

	return {
		projectId: projectId,
		data: data,
		loading: loading,
	};
})(GanttControl);


