import JobProgressCellRenderer from './components/JobProgressCellRenderer';
import JobDatesCellRenderer from './components/JobDatesCellRenderer';
import StageDatesCellRenderer from './components/StageDatesCellRenderer';

export const getGridOptions = params => {

	const frameworkComponents = {
		jobProgressCellRenderer: JobProgressCellRenderer,
		jobDatesCellRenderer: JobDatesCellRenderer,
		stageDatesCellRenderer: StageDatesCellRenderer,
	};

	const defaultColDef = {
		minWidth: 40,
		resizable: true,
		sortable: true,
		editable: false,
		suppressSizeToFit: true,
	};

	const gridOptions = {
		frameworkComponents,
		defaultColDef,
	};

	return gridOptions;
};

export const getColumnDefs = params => {

	const groupCellStyle = params => {
		const data = params.data;

		return data && data.itemType === 'stage' ? { fontWeight: '500' } : null;
	};

	const groupCellRendererParams = {
		suppressDoubleClickExpand: true,
		suppressSizeToFit: false,
		checkbox: params => params.data && params.data.itemType === 'job',
	};

	const groupColumn = {
		headerName: 'Наименование',
		field: 'title',
		suppressSizeToFit: false,
		showRowGroup: true,
		cellStyle: groupCellStyle,
		cellRenderer: "agGroupCellRenderer",
		cellRendererParams: groupCellRendererParams,
		rowDrag: params => params.data && params.data.itemType === 'job',
	};

	const columnDefs = [
		groupColumn,
		{
			headerName: 'Готовность',
			cellRendererSelector: params => {
				if (params.data && params.data.itemType === 'job') {
					return { component: 'jobProgressCellRenderer' };
				}

				return null;
			},
		},
		{
			headerName: 'Сроки',
			cellRendererSelector: params => {
				if (!params.data) {
					return null;
				}

				if (params.data.itemType === 'job') {
					return { component: 'jobDatesCellRenderer' };
				} else if (params.data.itemType === 'stage') {
					return { component: 'stageDatesCellRenderer' };
				}

				return null;
			},
		},
		{
			headerName: 'Отвественный',
			field: 'assignedUser',
			valueFormatter: params => params.value && (params.value.fullName || params.value.phoneNumber),
			cellStyle: params => ({ textAlign: 'center' }),
		},
	];

	return columnDefs;
};
