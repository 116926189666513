import React from 'react';
import style from './styles/image.module.sass';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');


class PhotoGalleryImage extends React.PureComponent
{

	static propTypes = {
		src: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
		onClick: PropTypes.func,
		assignedUser: PropTypes.object,
		tooltip: PropTypes.string,
		date: PropTypes.string,
	}

	static defaultProps = {
		width: 300,
		height: 150,
		date: new Date,
	}

	withOverlay = (component, tooltipText) => {

		const popperConfig = {
			modifiers: {
				preventOverflow: { enabled: false },
				hide: { enabled: false },
			}
		};

		const tooltip = (
			<Popover>
				<Popover.Content>
					<strong>
						{tooltipText}
					</strong>
				</Popover.Content>
			</Popover>
		);

		return (
			<OverlayTrigger
				trigger="hover"
				overlay={tooltip}
				popperConfig={popperConfig}
			>
				{component}
			</OverlayTrigger>
		);
	}

	render()
	{
		const { src, onClick, index, width, height, assignedUser, tooltip, date, jobTitle } = this.props;

		const icon = <FontAwesomeIcon
			className="mr-1"
			size="sm"
			icon={faUser}
		/>;

		const dateFormatted = moment(date).format('L');

		const title = (dateFormatted || assignedUser) && (
			<div
				className="w-100 d-flex flex-row  justify-content-between text-muted px-2"
				style={{ fontSize: '0.85em' }}
			>
				<span>
					{icon}
					{assignedUser?.fullName || assignedUser?.phoneNumber || `"Не назначен"`}
				</span>
				<div>
					{dateFormatted}
				</div>
			</div>
		);

		const jobText = jobTitle && (
			<div className="w-100 text-truncate px-2">
				{jobTitle}
			</div>
		);

		const component = (
			<div
				className="d-flex flex-column align-items-center justify-content-center m-1 img-thumbnail"
				onClick={event => onClick(event, index)}
				style={{ width }}
			>
				<img
					src={src}
					className={["w-100 px-1", style.image].join(' ')}
					style={{ height }}
				/>
				{jobText}
				{title}
			</div>
		);

		return tooltip ? this.withOverlay(component, tooltip) : component;
	}
}

export default PhotoGalleryImage;
