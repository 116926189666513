import React from 'react';
import PropTypes from 'prop-types';

import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import { getColumnDefs, getGridOptions } from './defs';


class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onRowDoubleClicked: PropTypes.func,
		onTitleCellClicked: PropTypes.func,
		onArchiveProjectClicked: PropTypes.func,
		onEditProjectClicked: PropTypes.func,
		onUnarchiveProjectClicked: PropTypes.func,
	};

	static defaultProps = {
		items: []
	};

	onFirstDataRendered = params => {
		const colIds = params.columnApi
			.getAllDisplayedColumns()
			.map(col => col.getColId());

		params.columnApi.autoSizeColumns(colIds);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
	}

	onRowDoubleClicked = params => {
		const isArchived = params.data.isArchived;

		if (isArchived) {
			return;
		}

		this.props.onRowDoubleClicked(params.data);
	}

	render()
	{
		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				suppressCellSelection={true}
				onRowDoubleClicked={this.onRowDoubleClicked}
				onGridReady={this.onGridReady}
				onFirstDataRendered={this.onFirstDataRendered}
			/>
		);
	}
}

export default Grid;
