import React from 'react';
import api from 'core/api';
import { connect } from 'react-redux';
import { withLoading } from "components";
import Loading from 'components/Loading';
import PropTypes from 'prop-types';

import ReportAggregator from '../../helpers/aggregator/ReportAggregator/ReportAggregator';
import RequestComponent from '../RequestComponent';

class EmploeeysCard extends RequestComponent
{
	state = {
		plannedCount: null,
		actualCount: null,
	}

	static propTypes = {
		project: PropTypes.object.isRequired,
		jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
		jobsMap: PropTypes.any.isRequired,
	}

	getRequestBody()
	{
		return api.jobsHistoryAggregation().aggregate({
			column: 'numberOfEmployedWorkers',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: this.props.projectId,
				isDeleted: false,
			}
		});
	}

	onRequestCallback = (result) => {
		const now = new Date();

		const aggregator = new ReportAggregator({
			startDate: now,
			endDate: now,
			groups: result.groups,
			jobsMap: this.props.jobsMap,
			jobs: this.props.jobs,
			project: this.props.project,
		});

		const emploeeys = aggregator.emploeeys();
		const actualCount = emploeeys.actualNumber()[0];
		const plannedCount = emploeeys.plannedNumber()[0];

		this.setState({ actualCount, plannedCount });
	}

	onUpdateStarted()
	{
		this.setState({ requestInProgress: true });
	}

	onUpdateFinished()
	{
		this.setState({ requestInProgress: false });
	}

	renderNumberTitle(title, num, numColor)
	{
		const style = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		};

		const numStyle = {
			fontSize: '1.3em',
			fontWeight: 500,
		};

		if (numColor) {
			numStyle.color = numColor;
		}

		return (
			<div style={style}>
				<div>
					{title}
				</div>
				<div style={numStyle}>
					{num}
				</div>
			</div>
		);
	}

	render()
	{
		const style = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			padding: '12px 35px',
		};

		const { plannedCount, actualCount } = this.state;
		const diff = actualCount - plannedCount;
		const numColor = diff < 0 ? '#a42520' : 'green';

		if (this.state.requestInProgress) {
			return <Loading />;
		}

		return (
			<div style={style}>
				{this.renderNumberTitle('План', Number.isNaN(plannedCount) ? '...' : plannedCount)}
				<div style={{width: '45px'}}/>
				{this.renderNumberTitle('Факт', Number.isNaN(actualCount) ? '...' : actualCount, numColor)}
			</div>
		);
	}

}

const mapToProps = (state, props) => {

	const { project, jobs } = props;

	const isLoading = !project || !jobs || jobs.isLoading;

	if (isLoading) {
		return { isLoading };
	}

	const now = new Date();
	const startDate = now;
	const endDate = now;

	return {
		startDate,
		endDate,
	};
};

export default connect(mapToProps)(withLoading(EmploeeysCard));
