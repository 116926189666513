import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';


class NativeFileInput extends React.PureComponent
{
	static propTypes = {
		onChanged: PropTypes.func,
	}

	state = {
		file: null,
	};

	onFileChanged = file => {
		this.setState({ file });
		const onChange = this.props.onChange;

		if (onChange) {
			onChange(file);
		}
	}

	render ()
	{
		const file = this.state.file;

		return (
			<div className="custom-file">
				<input
					ref={ref => (this.input = ref)}
					id="validatedCustomFile"
					type="file"
					className="custom-file-input"
					onChange={() => this.onFileChanged(this.input.files[0])}
				/>
				<label
					className="custom-file-label"
					htmlFor="validatedCustomFile"
					data-browse="Выбрать..."
				>
					{file && file.name || 'Выберите файл...'}
				</label>
			</div>
		);
	}
}

class FileInput extends React.PureComponent {

	renderField = ({field, form}) => {
		const { setFieldValue } = form;

		return (
			<Form.Control
				as={NativeFileInput}
				onChange={v => setFieldValue(field.name, v)}
			/>
		);
	}

	render() {
		return (
			<Field
				{...this.props}
				render={this.renderField}
			/>
		);
	}
}

export default FileInput;
