import api from 'core/api';

class Merger
{
	/**
	 *
	 * @param consolidatedSpecificationId
	 * @param sourceItems
	 * @param consolidatedItems
	 * @return {CreateQuery[]}
	 */
	static mergeAll(consolidatedSpecificationId, sourceItems, consolidatedItems)
	{
		const res = []
			.concat(Merger.mergeConsolidatedItems(consolidatedSpecificationId, sourceItems, consolidatedItems))
			.concat(Merger.mergeSourceItems(consolidatedSpecificationId, sourceItems, consolidatedItems))
		;

		return res;
	}

	static mergeSourceItems(consolidatedSpecificationId, sourceItems, consolidatedItems)
	{
		const mergesWithExistingCsi = {};
		const newMerges = {};

		for (const sourceItem of sourceItems) {
			if (sourceItem.consolidatedSpecificationItemId) {
				continue;
			}

			if (!sourceItem.consumableUnitId) {
				continue;
			}

			const sourceKey = Merger.key(sourceItem);

			// совпадения с позициями СС
			const consolidatedMatches = consolidatedItems.filter(ci => Merger.key(ci) === sourceKey);
			if (consolidatedMatches.length > 1) {
				// неоднозначность, не мерджим автоматом
				continue;
			} else if (consolidatedMatches.length === 1) {
				const cs = consolidatedMatches[0];

				if (!mergesWithExistingCsi[cs.id]) {
					mergesWithExistingCsi[cs.id] = [];
				}

				mergesWithExistingCsi[cs.id].push(sourceItem);

				continue;
			}

			// совпадения с позициями ИС, объединёнными в какие-то СС
			const sourceMergedMatches = sourceItems.filter(si =>
				si.consolidatedSpecificationItemId
				&& Merger.key(si) === sourceKey
			);

			if (sourceMergedMatches.length > 0) {
				const si = sourceMergedMatches[0];

				if (!mergesWithExistingCsi[si.consolidatedSpecificationItemId]) {
					mergesWithExistingCsi[si.consolidatedSpecificationItemId] = [];
				}

				mergesWithExistingCsi[si.consolidatedSpecificationItemId].push(sourceItem);

				continue;
			}

			if (sourceKey in newMerges) {
				// эту позицию уже учли в предыдущих итерациях

				continue;
			}

			// совпадения с необъединёнными позициями ИС
			const sourceNotMergedMatches = sourceItems.filter(si =>
				si !== sourceItem
				&& !si.consolidatedSpecificationItemId
				&& Merger.key(si) === sourceKey
			);

			if (sourceNotMergedMatches.length) {
				newMerges[sourceKey] = [
					sourceItem,
					...sourceNotMergedMatches
				];
			}
		}

		return []
			.concat(
				Object.entries(mergesWithExistingCsi)
					.map(([csId, sourceItems]) =>
						Merger.mergeToCsi(
							consolidatedItems.find(cs => cs.id === csId),
							sourceItems.map(si => si.id)
						)
					)
			)
			.concat(
				Object.entries(newMerges)
					.map(([key, sourceItems]) =>
						Merger.createNewCsi(
							consolidatedSpecificationId,
							sourceItems
						)
					)
			)
		;
	}

	static mergeConsolidatedItems(consolidatedSpecificationId, sourceItems, consolidatedItems)
	{
		const merges = {};
		const consolidatedItemsSorted = consolidatedItems.sort((a, b) => a.id.localeCompare(b.id));

		for (const consolidatedItem of consolidatedItemsSorted) {
			const key = Merger.key(consolidatedItem);

			if (key in merges) {
				continue;
			}

			const same = consolidatedItemsSorted.filter(ci =>
				ci !== consolidatedItem
				&& Merger.key(ci) === key
			);

			if (same.length) {
				merges[key] = [
					consolidatedItem,
					...same,
				];
			}
		}

		return Object.entries(merges).map(([key, consolidatedItems]) =>
			Merger.mergeToCsi(
				consolidatedItems[0],
				consolidatedItems.slice(1)
					.map(ci => ci.sourceSpecificationItemIds)
					.flat()
			)
		);
	}

	/**
	 * @public
	 * @param item
	 * @return {string}
	 */
	static key(item)
	{
		return JSON.stringify(
			['title', 'partNumber', 'manufacturerName'].map(p => item[p])
		);
	}

	/**
	 * @private
	 * @param item1
	 * @param item2
	 * @return {boolean}
	 */
	static hasSameKeys(item1, item2)
	{
		return Merger.key(item1) === Merger.key(item2);
	}

	/**
	 * @private
	 * @param consolidatedItem
	 * @param sourceItemIds
	 * @return {UpdateQuery}
	 */
	static mergeToCsi(consolidatedItem, sourceItemIds)
	{
		return api.tbsConsolidatedSpecificationItems().update({
			consolidatedSpecificationItemId: consolidatedItem.id,
			changes: {
				sourceSpecificationItemIds: [
					...consolidatedItem.sourceSpecificationItemIds,
					...sourceItemIds,
				],
			}
		});
	}

	/**
	 * @private
	 * @param consolidatedSpecificationId
	 * @param sourceItems
	 * @return {CreateQuery}
	 */
	static createNewCsi(consolidatedSpecificationId, sourceItems)
	{
		const si = sourceItems[0];

		return api.tbsConsolidatedSpecificationItems().create({
			consolidatedSpecificationId: consolidatedSpecificationId,
			title: si.title,
			partNumber: si.partNumber,
			manufacturerName: si.manufacturerName,
			consumableUnitId: si.consumableUnitId,
			sourceSpecificationItemIds: sourceItems.map(si => si.id),
		});
	}
}

export default Merger;
