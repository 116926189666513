import React from 'react';
import PropTypes from 'prop-types';

class OrderedItemsList extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		items: [],
	}

	render() {
		const content = this.props.items.map((item, index) => {

			const { title, consumableUnit} = item.consumableSheetItem;
			const { amount } = item;

			return (
				<div key={item.id} className="d-flex flex-row justify-content-between p-2 ml-1 border-bottom">
					<div className="text-truncate mr-3">
						{`${index + 1}. ${title}`}
					</div>
					<div className="text-nowrap">
						{`${amount} ${consumableUnit.suffix}`}
					</div>
				</div>
			);
		});

		return (
			<div className="mb-4">
				{content}
			</div>
		);
	}
}

export default OrderedItemsList;
