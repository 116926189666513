class HeaderRow
{
	/**
	 * @type {number}
	 */
	index;

	/**
	 * @type {object<number>}
	 */
	columnIndexes;

	/**
	 *
	 * @param {number} index
	 * @param {object<number>} columnIndexes
	 */
	constructor(index, columnIndexes)
	{
		this.index = index;
		this.columnIndexes = columnIndexes;
	}
}

export default HeaderRow;
