import React from 'react';
import PropTypes from 'prop-types';
import style from '../styles/custom-cell.module.sass';


class UnitsCellEditor extends React.PureComponent
{
	static propTypes = {
		units: PropTypes.arrayOf(PropTypes.object).isRequired,
		keyField: PropTypes.string,
		valueField: PropTypes.string,
	}

	static defaultProps = {
		units: [],
		keyField: 'id',
		valueField: 'value',
	}

	state = {}

	componentDidMount()
	{
		document.addEventListener('mousedown', this.handleClick, false);
		this.setState({value: this.props.value});
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}

		this.handleClickOutside();
	}

	handleClickOutside = () => {
		this.props.api.stopEditing();
	}

	getValue()
	{
		return this.state.value || this.props.units[0].id;
	}

	isPopup()
	{
		return false;
	}

	isCancelBeforeStart()
	{
		return !this.props.units.length;
	}

	isCancelAfterEnd()
	{
		const value = this.state.value || this.props.units[0];

		return !value;
	}

	render()
	{
		const onChange = (e) => {
			const value = e.target.value;
			this.setState({ value }, () => this.props.api.stopEditing());
		};

		const { units, keyField, valueField } = this.props;

		return (
			<div
				ref={(ref) => (this.node = ref)}
				className={style.wrapper}
			>
				<select
					type="select"
					autoFocus={true}
					onChange={onChange}
					style={{
						height: '90%',
						flex: 1,
						margin: '0 2px',
					}}
				>
					<option key={'default'} value={null}>
						{'-'}
					</option>
					{units.map((unit, index) =>
						<option key={index} value={unit[keyField]}>
							{unit[valueField]}
						</option>
					)}
				</select>
			</div>
		);
	}
}

export default UnitsCellEditor;
