import React from 'react';
import {connect} from 'react-redux';
import api from 'core/api';
import db from 'core/db';
import { Row, Col, Button } from 'react-bootstrap';

import Grid from './components/Grid';
import JobEditForm from './components/JobEditForm';
import StageEditForm from './components/StageEditForm';
import Modal from 'components/Modal';
import moment from 'moment';

import style from './styles/scope.module.sass';


class Scope extends React.PureComponent
{
	state = {
		editJobModalVisible: false,
		editStageModalVisible: false,
		editingJob: {},
		editingStage: {},
	}

	createStage = values => {
		this.props.dispatch(api.stages().create({
			...values,
			estimatedStartDate: moment(values.estimatedStartDate).format('YYYY-MM-DD'),
			estimatedEndDate: moment(values.estimatedEndDate).format('YYYY-MM-DD'),
			projectId: this.props.projectId,
		})).then(this.closeStageModal);
	}

	createJob = values => {
		this.props.dispatch(api.jobs().create({
			...values,
			estimatedStartDate: moment(values.estimatedStartDate).format('YYYY-MM-DD'),
			estimatedEndDate: moment(values.estimatedEndDate).format('YYYY-MM-DD'),
			projectId: this.props.projectId,
		})).then(this.closeJobModal);
	}

	updateStage = (stageId, changes) => {
		if (changes.userId) {
			changes.assignedUserId = changes.userId;
			delete changes.userId;
		}

		if (changes.estimatedStartDate) {
			changes.estimatedStartDate = moment(changes.estimatedStartDate).format('YYYY-MM-DD');
		}

		if (changes.estimatedEndDate) {
			changes.estimatedEndDate = moment(changes.estimatedEndDate).format('YYYY-MM-DD');
		}

		this.props.dispatch(api.stages().update({
			stageId,
			changes,
		})).then(this.closeStageModal);
	}

	updateJob = (jobId, changes) => {
		if (changes.estimatedStartDate) {
			changes.estimatedStartDate = moment(changes.estimatedStartDate).format('YYYY-MM-DD');
		}

		if (changes.estimatedEndDate) {
			changes.estimatedEndDate = moment(changes.estimatedEndDate).format('YYYY-MM-DD');
		}

		this.props.dispatch(api.jobs().update({
			jobId,
			changes,
		})).then(this.closeJobModal);
	}

	closeStageModal = () => {
		this.setState({ editStageModalVisible: false });
	}

	closeJobModal = () => {
		this.setState({ editJobModalVisible: false });
	}

	onGridReady = params => {
		this.gridApi = params.api;
	}

	onEditJob = editingJob => {
		this.setState({
			editingJob,
			editJobModalVisible: true,
		});
	}

	onEditStage = editingStage => {
		this.setState({
			editingStage,
			editStageModalVisible: true,
		});
	}

	onCreateNewStage = parentStageId => {
		const stage = parentStageId ? { parentStageId } : {};
		this.setState({
			editingStage: stage,
			editStageModalVisible: true,
		});
	}

	onCreateNewJob = stageId => {
		this.setState({
			editingJob: { stageId },
			editJobModalVisible: true,
		});
	}

	onMoveJobsToStage = (nodes, stageId) => {
		this.doTransaction(nodes, { stageId: stageId });
	}

	onRemove = (nodes) => {
		this.doTransaction(nodes, { isDeleted: true });
	}

	doTransaction = (nodes, changes) => {
		const transactionBody = nodes.map(node => {
			const item = node.data;

			if (item.itemType === 'job') {
				return api.jobs().update({
					jobId: item.id,
					changes: changes,
				});
			} else if (item.itemType === 'stage') {
				return api.stages().update({
					stageId: item.id,
					changes: changes,
				});
			}

			return null;
		});

		this.gridApi.showLoadingOverlay();
		this.props.dispatch(api.transaction().execute(transactionBody))
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				this.gridApi.hideOverlay();
			})
		;
	}

	hideModal = () => {
		this.setState({
			editJobModalVisible: false,
			editStageModalVisible: false,
			editingJob: {},
		});
	}

	onUpdateJob = (job, changes) => {
		this.props.dispatch(api.jobs().update({
			jobId: job.id,
			changes,
		}))
			.catch((err) => {
				//TODO добавить уведомление
				console.error(err);
			})
			.finally(() => {
				this.hideModal();
			})
		;
	}

	render()
	{
		if (this.props.isLoading) {
			return null;
			//@! TODO return <Loading/>
		}

		return (
			<React.Fragment>
				<Row className="m-3" noGutters={true}>
					<Col md="auto">
						<Button onClick={() => this.onCreateNewStage()}>
							Добавить участок
						</Button>
					</Col>
					<Col md="auto ml-2">
						<Button onClick={this.onEditJob}>
							Добавить работу
						</Button>
					</Col>
				</Row>
				<Row className="basic-section m-3 py-1" noGutters={true}>
					<Col className={style.scopeViewWrapper}>
						<Grid
							items={this.props.gridItems}
							stages={this.props.stages}
							stagesMap={this.props.stagesMap}
							onEditJob={this.onEditJob}
							onEditStage={this.onEditStage}
							onCreateNewStage={this.onCreateNewStage}
							onMoveJobsToStage={this.onMoveJobsToStage}
							onRemove={this.onRemove}
							onGridReady={this.onGridReady}
							onCreateNewJob={this.onCreateNewJob}
						/>
					</Col>
				</Row>
				<Modal
					title="Редактирование вида работ"
					show={this.state.editJobModalVisible}
					onHide={this.closeJobModal}
				>
					<JobEditForm
						jobUnits={this.props.jobUnits}
						stages={this.props.stages}
						projectId={this.props.projectId}
						job={this.state.editingJob}
						onCancel={this.closeJobModal}
						onUpdateJob={this.updateJob}
						onCreateJob={this.createJob}
					/>
				</Modal>
				<Modal
					title="Редактирование этапа"
					show={this.state.editStageModalVisible}
					onHide={this.closeStageModal}
				>
					<StageEditForm
						users={this.props.users}
						projectId={this.props.projectId}
						stage={this.state.editingStage}
						onCreateStage={this.createStage}
						onUpdateStage={this.updateStage}
						onCancel={this.closeStageModal}
					/>
				</Modal>
			</React.Fragment>
		);
	}
}

const getGridItems = params => {

	const { stages, jobs, stagesMap } = params;

	const getStagePath = stage => {
		const path = [stage.id];

		let parentStageId = stage.parentStageId;

		while (parentStageId) {
			path.push(parentStageId);
			const stage = stagesMap.get(parentStageId);
			parentStageId = stage.parentStageId;
		}

		return path.reverse();
	};

	const getJobPath = job => {

		const path = [];
		const stage = stagesMap.get(job.stageId);

		if (stage) {
			path.push(...getStagePath(stage));
		}

		path.push(job.id);

		return path;
	};

	const jobsItems = jobs.map(job => {
		return {
			...job,
			itemType: 'job',
			dataPath: getJobPath(job),
		};
	});

	const stagesItems = stages.map(stage => {
		return {
			...stage,
			itemType: 'stage',
			dataPath: getStagePath(stage),
		};
	});

	const items = [...stagesItems, ...jobsItems];

	return items;
};

export default connect((state, props) => {

	const projectId = props.match.params.projectId;
	const filter = { filter: { projectId } };

	const jobs = db.jobs.listNotDeleted(filter);
	const stages = db.stages.listNotDeleted(filter);
	const stagesMap = stages.hashById();
	const jobUnits = db.jobUnits.list();
	const users = db.users.list();

	const isLoading = stages.isLoading || jobs.isLoading || jobUnits.isLoading || users.isLoading;

	if (isLoading) {
		return { isLoading };
	}

	const gridItems = getGridItems({ stages, jobs, stagesMap });

	return {
		jobs,
		stages,
		stagesMap,
		gridItems,
		jobUnits,
		users,
		projectId,
		me: state.me,
	};
})(Scope);

