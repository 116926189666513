import moment from 'moment';

class StagesAggregator
{
	constructor(params)
	{
		this.params = params;
		this._createReportsMapByUserId();
		this._createStagesMapByAssignedUserId();
		this._createJobsMapByStageId();
		this._calculateChiesfGridData();
	}

	_createReportsMapByUserId()
	{
		const { periodicReports } = this.params;

		const reportsMapByUserId = new Map();

		for (const report of periodicReports) {
			const userId = report.createdByUserId;
			const reportsFromMap = reportsMapByUserId.get(userId);

			if (reportsFromMap) {
				reportsFromMap.push(report);
			} else {
				reportsMapByUserId.set(userId, [report]);
			}
		}

		this.reportsMapByUserId = reportsMapByUserId;
	}

	_createStagesMapByAssignedUserId()
	{
		const { stages } = this.params;

		const stagesMapByAssignedUserId = new Map();

		for (const stage of stages) {
			const userId = stage.assignedUserId;

			if (!userId) {
				continue;
			}

			const stagesFromMap = stagesMapByAssignedUserId.get(userId);

			if (stagesFromMap) {
				stagesFromMap.push(stage);
			} else {
				stagesMapByAssignedUserId.set(userId, [stage]);
			}
		}

		this.stagesMapByAssignedUserId = stagesMapByAssignedUserId;
	}

	_createJobsMapByStageId()
	{
		const { jobs } = this.params;

		const jobsMapByStageId = new Map();

		for (const job of jobs) {
			const stageId = job.stageId;
			const jobsFromMap = jobsMapByStageId.get(stageId);

			if (jobsFromMap) {
				jobsFromMap.push(job);
			} else {
				jobsMapByStageId.set(stageId, [job]);
			}
		}

		this.jobsMapByStageId = jobsMapByStageId;
	}

	_aggregateJobsEmployees(stageId)
	{
		const jobsMapByStageId = this.jobsMapByStageId;
		const stageJobs = jobsMapByStageId.get(stageId);

		if (!stageJobs) {
			return {
				emploeeysCount: 0,
				emploeeysPlannedCount: 0,
			};
		}

		let emploeeysCount = 0;
		let emploeeysPlannedCount = 0;

		const now = moment().format('YYYY-MM-DD');

		for (const job of stageJobs) {
			const isStarted = job.estimatedStartDate <= now;
			const isDone = job.estimatedEndDate >= now && job.currentProgress === job.maxProgress;

			if (!isStarted || isDone) {
				continue;
			}

			emploeeysCount += job.numberOfEmployedWorkers;
			emploeeysPlannedCount += job.planNumberOfWorkers;
		}

		return { emploeeysCount, emploeeysPlannedCount };
	}

	_calculateChiesfGridData()
	{
		const { employees } = this.params;
		const { stagesMapByAssignedUserId, reportsMapByUserId } = this;

		const items = [];

		for (const employee of employees) {
			const userId = employee.userId;
			const employeeId = employee.id;

			const reports = (reportsMapByUserId.get(userId) || [])
				.filter(r => r.isCommitted)
				.sort((a, b) => ((a.date < b.date) ? 1 : -1))
			;

			const lastReport = reports[0] || {};
			const assignedStages = stagesMapByAssignedUserId.get(userId) || [];

			for (const stage of assignedStages) {
				const stageId = stage.id;
				const {emploeeysCount, emploeeysPlannedCount} = this._aggregateJobsEmployees(stageId);

				items.push({
					employeeId,
					stage,
					emploeeysCount,
					emploeeysPlannedCount,
					lastReport,
					reports,
				});
			}
		}

		this._chiefsGridData = items;
	}

	chiefsGridData()
	{
		return this._chiefsGridData;
	}
}

export default StagesAggregator;
