import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import { withFormik } from 'formik';
import DateInput from 'components/Form/Date';
import FileInput from 'components/Form/FileInput';
import TextInput from 'components/Form/TextInput';
import SelectInput from 'components/Form/Select';

import { forIn, isNil, isArray, isObject, isEmpty } from 'lodash';
import moment from 'moment';


class OrderEditForm extends React.Component
{
	static propTypes = {
		onFormReady: PropTypes.func,
		onSubmit: PropTypes.func,
		onCancel: PropTypes.func,
		order: PropTypes.object.isRequired,
		currencies: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		currencies: [],
	}

	componentDidMount()
	{
		this.props.onFormReady(this.props);
	}

	render()
	{
		return (
			<Form>
				<Form.Group className="d-flex flex-column">
					<Form.Label>Поставщик</Form.Label>
					<TextInput name="supplierTitle"/>
				</Form.Group>
				<Form.Group className="d-flex flex-column">
					<Form.Label>Номер счёта</Form.Label>
					<TextInput name="invoiceNumber"/>
				</Form.Group>
				<Form.Group as={Row}>
					<Col>
						<Form.Label>Дата счёта</Form.Label>
						<DateInput name="invoiceDate"/>
					</Col>
					<Col>
						<Form.Label>Дата поставки</Form.Label>
						<DateInput name="deliveryDate"/>
					</Col>
					<Col>
						<Form.Label>Валюта</Form.Label>
						<SelectInput items={this.props.currencies} name="currencyId"/>
					</Col>
				</Form.Group>
				<Form.Group className="d-flex flex-column">
					<Form.Label>Условия оплаты</Form.Label>
					<TextInput as="textarea" style={{ minHeight: '100px' }} name="paymentTermsNotes"/>
				</Form.Group>
				<Form.Group className="d-flex flex-column">
					<Form.Label>Условия размещения</Form.Label>
					<TextInput as="textarea" style={{ minHeight: '100px' }} name="placingTermsNotes"/>
				</Form.Group>
				<Form.Group className="d-flex flex-column">
					<Form.Label>Файл счёта</Form.Label>
					<FileInput name="attachment"/>
				</Form.Group>
			</Form>
		);
	}
}

const mapPropsToValues = props => {
	return { ...props.order };
};

const handleSubmit = (values, { props, setSubmitting }) => {

	const intialValues = props.order;
	const { attachment, ...formValues } = values;
	formValues.invoiceDate = moment(formValues.invoiceDate).format('YYYY-MM-DD');
	formValues.deliveryDate = moment(formValues.deliveryDate).format('YYYY-MM-DD');

	const changes = {};

	//!! Функция проверят изменения сделанные в форме
	//!! Без вложенности, исключая массивы и объекты
	forIn(formValues, (value, key) => {
		const isPlain = !(isNil(value) || isArray(value) || isObject(value));

		if (isPlain) {
			if (intialValues[key] !== value) {
				changes[key] = value;
			}
		}
	});

	// console.log('changes', changes);

	if (isEmpty(changes) && !attachment) {
		props.onCancel();
	} else {
		props.onSubmit(changes, attachment);
	}

	setSubmitting(false);
};

export default withFormik({ handleSubmit, mapPropsToValues })(OrderEditForm);
