import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';


class Step extends React.PureComponent
{
	static propTypes = {
		number: PropTypes.number.isRequired,
		status: PropTypes.string.isRequired,
		title: PropTypes.string,
	}

	getIconWrapperClassName = () => {
		const st = this.props.status;
		if (st === 'done') {
			return style.stepIconWrapperDone;
		} else if (st === 'await') {
			return style.stepIconWrapperAwait;
		} else if (st === 'active') {
			return style.stepIconWrapperActive;
		}

		return style.stepIconWrapper;
	}

	getIconTitleClassName = () => {
		const st = this.props.status;
		if (st === 'await') {
			return style.stepIconTitleAwait;
		} else if (st === 'active') {
			return style.stepIconTitleActive;
		}

		return style.stepIconTitle;
	}

	getIcon = () => {
		const st = this.props.status;
		if (st === 'done') {
			return <FontAwesomeIcon
				fixedWidth
				icon={faCheck}
			/>;
		}

		return this.props.number + 1;
	}

	render()
	{
		return (
			<div className={style.stepWrapper}>
				<div className={this.getIconWrapperClassName()}>
					{this.getIcon()}
				</div>
				<div className={this.getIconTitleClassName()}>
					{this.props.title}
				</div>
			</div>
		);
	}
}

export default Step;
