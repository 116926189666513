import React from 'react';
import PropTypes from 'prop-types';


class ColumnsResizer extends React.Component
{
	state = {
		moving: false,
	}

	componentWillMount()
	{
		document.addEventListener('mousemove', this.handleMouseMove, false);
		document.addEventListener('mouseup', this.handleMouseUp, false);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousemove', this.handleMouseMove, false);
		document.removeEventListener('mouseup', this.handleMouseUp, false);
	}

	stopMoving = () => {
		this.props.onMovingChanged(false);
		this.setState({moving: false});
	}

	handleMouseUp = (e) => {
		e.stopPropagation();
		if (this.state.moving) {
			this.stopMoving();
		}
	}

	handleMouseMove = (e) => {
		if (this.state.moving) {
			const offsetX = e.pageX - this.state.x;
			const width = this.state.width || this.props.initialWidth;
			let newWidth = width + offsetX;
			newWidth = newWidth < this.props.minWidth ? this.props.minWidth : newWidth;
			this.setState({
				width: newWidth,
				x: e.pageX,
			}, () => {
				this.props.onResize(this.state.width);
			});
			if (newWidth === this.props.minWidth) {
				this.stopMoving();
			}
		}
	}

	handleMouseDown = (e) => {
		e.stopPropagation();
		this.setState({
			moving: true,
			x: e.pageX,
		});
		this.props.onMovingChanged(true);
	}

	render()
	{
		return (
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: this.state.width || this.props.initialWidth,
					height: '100%',
					width: '10px',
					marginLeft: '-5px',
					zIndex: 1,
					cursor: this.state.moving ? 'move' : 'col-resize',
				}}
				onMouseDown={this.handleMouseDown}
			/>
		);
	}
}

ColumnsResizer.propTypes = {
	onResize: PropTypes.func,
	onMovingChanged: PropTypes.func,
	initialWidth: PropTypes.number.isRequired,
	minWidth: PropTypes.number.isRequired,
};


export default ColumnsResizer;

