export default {
	tbs: {
		priceCurrency: {
			default: {
				code: "RUB",
				id: "1BAAFB81-13C0-4329-A131-B2C1ED7BA2A2",
				symbol: "₽",
				title: "Рубль",
			}
		}
	}
};
