import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import UserCellRenderer from 'components/ag-grid-components/renderers/UserCellRenderer';
import AttachedFilesCellRenderer from 'components/ag-grid-components/renderers/AttachedFilesCellRenderer';
import { numberFormatter } from 'components/ag-grid-components/formatters';
import NumberCellEditor from 'components/ag-grid-components/editors/NumberCellEditor';
import UnitsCellEditor from 'components/ag-grid-components/editors/UnitsCellEditor';
import DateCellEditor from 'components/ag-grid-components/editors/DateCellEditor';
import SubtitleCellRenderer from 'components/ag-grid-components/renderers/SubtitleCellRenderer';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

import { faFileExcel } from '@fortawesome/free-solid-svg-icons';


export const getGridOptions = params => {

	const { cellStyle, currenciesMap } = params;

	const priceRenderer = params => `${params.valueFormatted} ${params.data.consumableSheetItem.priceCurrency.symbol}`;
	const serialRenderer = params => `№ ${params.value}`;
	const amountRenderer = params => `${params.valueFormatted} ${params.data.consumableSheetItem.consumableUnit.suffix}`;
	const statusRenderer = params => {
		const v = params.value;

		if (v === 'inTransit') {
			return 'Отгружен частично';
		} else if (v === 'delivered') {
			return 'Отгружен полностью';
		}

		return '';
	};

	const currencyRenderer = params => {
		const cur = currenciesMap.get(params.value);

		return `${cur.title} (${cur.symbol})`;
	};

	const dateRenderer = params => params.value && moment(params.value).format('L');

	const frameworkComponents = {
		unitsCellEditor: UnitsCellEditor,
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: UserCellRenderer,
		numberCellEditor: NumberCellEditor,
		dateCellEditor: DateCellEditor,
		attachedFilesCellRenderer: AttachedFilesCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
		serialRenderer,
		priceRenderer,
		statusRenderer,
		amountRenderer,
		dateRenderer,
		currencyRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			width: 120,
			minWidth: 40,
			resizable: true,
			sortable: true,
			editable: false,
			cellStyle,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {

	const { isDetail, itemsEditable } = params;

	const deliveryStatuses = [
		{
			id: 'inTransit',
			value: 'Отгружен частично',
		},
		{
			id: 'delivered',
			value: 'отгружен полностью',
		},
	];

	const invoiceFileRendererParams = params => {
		const attachments = params.value || [];

		return {
			itemsList: attachments.map(attach => {
				return {
					file: attach,
					icon: faFileExcel,
					iconStyle: { color: 'green' },
					noText: true,
				};
			})
		};
	};

	const titleRendererParams = params => {
		const item = params.data.consumableSheetItem;

		return {
			subtitle: `${item.partNumber} / ${item.manufacturerName}`
		};
	};

	let colDefs = [
		{
			headerName: 'Статус',
			field: 'deliveryStatus',
			editable: true,
			cellEditor: 'unitsCellEditor',
			cellEditorParams: { units: deliveryStatuses },
		},
		{
			headerName: 'Номер счёта',
			field: 'purchaseOrder.invoiceNumber',
		},
		{
			headerName: 'Дата счёта',
			field: 'purchaseOrder.invoiceDate',
		},
		{
			headerName: 'Файл счёта',
			field: 'purchaseOrder.attachments',
			cellRendererParams: invoiceFileRendererParams,
		},
		{
			headerName: 'Заявка',
			field: 'purchaseRequest.sn',
		},
		{
			headerName: 'Номер заказа',
			field: 'purchaseOrder.sn',
		},
		{
			headerName: 'Поставщик',
			field: 'purchaseOrder.supplierTitle'
		},
		{
			headerName: 'Валюта',
			field: 'purchaseOrder.currencyId',
		},
		{
			headerName: 'Фактическая цена',
			field: 'priceActual',
			valueFormatter: numberFormatter,
			editable: true,
			cellEditor: 'numberCellEditor',
			cellEditorParams: {inputProps: {step: '0.01'}},
		},
		{
			headerName: 'Фактическая стоимость',
			field: 'costActual',
			valueFormatter: numberFormatter,
			valueGetter: 'data.priceActual * (data.amountProposed || data.amount)',
		},
		{
			headerName: 'Фактический срок поставки',
			field: 'deliveryDateActual',
			editable: true,
			cellEditor: 'dateCellEditor',
		},
		{
			headerName: 'Корректировка количества',
			field: 'amountProposed',
			valueFormatter: numberFormatter,
			editable: true,
			cellEditor: 'numberCellEditor',
			cellEditorParams: {inputProps: {step: '0.01'}},
		},
		{
			headerName: 'Наименование',
			field: 'consumableSheetItem.title',
			sort: 'asc',
			pinned: isDetail ? undefined : 'left',
			cellRendererParams: titleRendererParams,
			width: 400,
		},
		{
			headerName: 'Создатель заявки',
			field: 'purchaseRequest.assignedUser',
		},
		{
			headerName: 'Количество',
			field: 'amount',
			valueFormatter: numberFormatter,
		},
	];

	if (!itemsEditable) {
		colDefs = colDefs.map(col => {
			return {
				...col,
				editable: false,
			};
		});
	}

	const renderersMap = {
		'priceActual': { component: 'priceRenderer' },
		'costActual': { component: 'priceRenderer' },
		'purchaseRequest.assignedUser': { component: 'userCellRenderer' },
		'purchaseOrder.sn': { component: 'serialRenderer' },
		'purchaseRequest.sn': { component: 'serialRenderer' },
		'deliveryStatus': { component: 'statusRenderer' },
		'amount': { component: 'amountRenderer' },
		'amountProposed': { component: 'amountRenderer' },
		'deliveryDateActual': { component: 'dateRenderer' },
		'purchaseOrder.attachments': { component: 'attachedFilesCellRenderer' },
		'consumableSheetItem.title': isDetail ? null : { component: 'subtitleCellRenderer' },
		'purchaseOrder.invoiceDate': { component: 'dateRenderer' },
		'purchaseOrder.currencyId': { component: 'currencyRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
