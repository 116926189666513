import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import api from 'core/api';

import { getColumnDefs, getGridOptions } from './defs';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';


class Grid extends React.Component
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		me: PropTypes.object,
		onSelectedRowsChanged: PropTypes.func,
		detailGridProps: PropTypes.object,
		detailItems: PropTypes.arrayOf(PropTypes.object),
	};

	static defaultProps = {
		items: [],
		detailItems: [],
	};

	constructor(params)
	{
		super(params);
		this.nodesApiMap = new Map();
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
	}

	onNodeGridReady = (params, node) => {
		this.nodesApiMap.set(node.id, params.api);
	}

	onNodeSelectionChanged = (params, node) => {
		let subRowsSelected = [];

		for (const api of this.nodesApiMap.values()) {
			subRowsSelected = subRowsSelected.concat(api.getSelectedRows() || []);
		}

		this.props.onSelectedRowsChanged(subRowsSelected);
	}

	assignToMe = ({ purchaseRequestId }) => {
		this.props.dispatch(api.tbsPurchaseRequests().assignToMe({
			purchaseRequestId,
		}));
	}

	unassignFromMe = ({ purchaseRequestId }) => {
		console.log("unassignFromMe", purchaseRequestId);
	}

	render()
	{
		const columnDefs = getColumnDefs({
			...this.props,
			assignToMe: this.assignToMe,
			unassignFromMe: this.unassignFromMe,
		});

		const gridOptions = getGridOptions(this.props);

		const detailCellRendererParams = params => {

			const purchaseRequestId = params.data.id;
			const items = this.props.detailItems.filter(i => i.purchaseRequestId === purchaseRequestId);

			return {
				detailGridProps: this.props.detailGridProps,
				items,
				onGridReady: this.onNodeGridReady,
				onSelectionChanged: this.onNodeSelectionChanged,
			};
		};

		const getRowHeight = (params) => {
			if (params.node && params.node.detail) {
				const purchaseRequestId = params.data.id;
				const items = this.props.detailItems.filter(i => i.purchaseRequestId === purchaseRequestId);
				const allDetailRowHeight = items.length * 25;
				const offset = 28 + 25;

				return allDetailRowHeight + offset;
			} else {
				return 38;
			}
		};

		return (
			<CustomGrid
				onGridReady={this.onGridReady}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				suppressRowClickSelection={true}
				masterDetail={true}
				detailCellRenderer={'detailCellRenderer'}
				detailCellRendererParams={detailCellRendererParams}
				keepDetailRows={true}
				getRowHeight={getRowHeight}
				groupDefaultExpanded={1}
			/>
		);
	}
}

export default connect()(Grid);

