class ParsedRowGroup
{
	/**
	 * @type {ParsedRowGroup}
	 */
	root;

	startRowIndex;

	/**
	 * @type {HeaderRow[]}
	 */
	rows;

	/**
	 * @type {ParsedRowGroup[]}
	 */
	childs;

	/**
	 * @type {string}
	 */
	title = null;

	/**
	 * @param {ParsedRowGroup} root
	 * @param {number} startRowIndex
	 * @param {HeaderRow[]} rows
	 * @param {ParsedRowGroup[]} childs
	 */
	constructor(root, startRowIndex, rows, childs)
	{
		this.root = root;
		this.startRowIndex = startRowIndex;
		this.rows = rows;
		this.childs = childs;
	}
}

export default ParsedRowGroup;
