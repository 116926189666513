import React from 'react';
import { connect } from 'react-redux';
import style from 'styles/page.module.sass';
import { Tab } from 'react-bootstrap';

import db from 'core/db';
import RequestsView from './components/RequestsView';
import OrdersView from './components/OrdersView';


class RequestsPage extends React.PureComponent
{
	state = {
		activeKey: 'orders',
	}

	switchOrders = () => {
		this.setState({ activeKey: 'orders' });
	}

	switchRequests = () => {
		this.setState({ activeKey: 'requests' });
	}

	render()
	{
		return (
			<div className={[style.wrapper, 'justify-content-center'].join(' ')}>
				<Tab.Container activeKey={this.state.activeKey} >
					<Tab.Content className="w-100 h-100">
						<Tab.Pane
							eventKey="requests"
							className="w-100 h-100"
						>
							<RequestsView
								{...this.props}
								onSwitchOrders={this.switchOrders}
							/>
						</Tab.Pane>
						<Tab.Pane
							eventKey="orders"
							className="w-100 h-100"
						>
							<OrdersView
								{...this.props}
								onSwitchRequests={this.switchRequests}
							/>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId: projectId }};

	const consumableSheets = db.tbsConsumableSheets.list(projectFilter);
	const consumableSheet = consumableSheets[0];
	const isLoading = consumableSheets.isLoading;

	return {
		consumableSheetId: consumableSheet?.id,
		consumableSheet,
		projectId,
		isLoading,
	};
};

export default connect(mapToProps)(RequestsPage);

