import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from './components/Grid';
import { toast } from 'react-toastify';

import api from 'core/api';

class NewRequestDialog extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		purchaseRequestItems: PropTypes.arrayOf(PropTypes.object),
		purchaseRequestsMap: PropTypes.any,
		onRequestCreated: PropTypes.func,
		onDialogReady: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	state = {
		loading: false,
	}

	componentDidMount()
	{
		this.props.onDialogReady({ createRequest: this.createNewRequest });
	}

	createNewRequest = (params) => {
		const itemsToUpdate = [];
		const itemsToCreate = [];

		const { gridItems, purchaseRequestItems } = this.props;

		for (const item of gridItems) {
			const index = purchaseRequestItems.map(pr => pr.consumableSheetItemId).indexOf(item.id);
			const existingItem = purchaseRequestItems[index];

			if (index !== -1) {
				itemsToUpdate.push({
					purchaseRequestItemId: existingItem.id,
					changes: {
						amount: Number(existingItem.amount) + Number(item.requestAmount),
						notes: item.notes,
					}
				});
			} else {
				itemsToCreate.push({
					consumableSheetItemId: item.id,
					amount: item.requestAmount,
					notes: item.notes,
				});
			}
		}

		const updateRequests = itemsToUpdate.length ? itemsToUpdate.map(item => {
			return api.tbsPurchaseRequestItems().update(item);
		}) : null;

		const requestToCreate = itemsToCreate.length ? api.tbsPurchaseRequests().create({
			consumableSheetId: this.props.consumableSheetId,
			items: itemsToCreate,
		}) : null;

		let transactionBody = [];

		if (requestToCreate) {
			transactionBody.push(requestToCreate);
		}

		if (updateRequests) {
			transactionBody = transactionBody.concat(updateRequests);
		}

		if (!transactionBody.length) {
			return;
		}

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => {
				toast.success('Новая заявка создана!', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				this.props.onRequestCreated();
			})
			.catch((err) => {
				console.error(err);
				toast.error('Не удалось создать заявку', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	render()
	{

		const gridHeight = this.props.items.length * 38 + 42;

		return (
			<div
				className="d-flex"
				style={{
					height: gridHeight
				}}
			>
				<Grid
					classname="w-100"
					style={{
						height: '50vh',
						minHeight: '400px',
					}}
					items={this.props.gridItems}
					purchaseRequestItems={this.props.purchaseRequestItems}
					purchaseRequestsMap={this.props.purchaseRequestsMap}
				/>
			</div>
		);
	}
}

const getGridItems = params => {
	const { items, purchaseItemsMapByConsumableItemId } = params;

	return items.map(item => {
		const purchaseItems = purchaseItemsMapByConsumableItemId.get(item.id) || [];

		return {
			...item,
			orderedAmount: purchaseItems.reduce((acc, item) => {
				return acc + Number(item.amount);
			}, 0),
			requestAmount: 0,
			notes: null,
		};
	});
};

const getPurchaseItemsMapByConsumableItemId = purchaseRequestItems => {
	const map = new Map();

	for (const item of purchaseRequestItems) {
		const id = item.consumableSheetItemId;

		const mapItems = map.get(id);

		if (mapItems) {
			mapItems.push(item);
		} else {
			map.set(id, [item]);
		}
	}

	return map;
};

const mapToProps = (state, props) => {

	const { items } = props;

	const purchaseRequestItems = props.purchaseRequestItems.filter(i => !i.isCanceled);

	const purchaseItemsMapByConsumableItemId = getPurchaseItemsMapByConsumableItemId(purchaseRequestItems);
	const gridItems = getGridItems({ items, purchaseItemsMapByConsumableItemId });

	return {
		gridItems,
		purchaseRequestItems,
	};

};

export default connect(mapToProps)(NewRequestDialog);


