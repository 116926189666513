import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

import style from '../styles/cell-style.module.sass';


class JobProgressCellRenderer extends React.PureComponent
{

	getGroupCurrentProgress = () => {
		return this.props.node.allLeafChildren.reduce((acc, node) => {
			const cp = node.data.currentProgress;

			return cp ? Number(acc) + Number(cp) : acc;
		}, 0);
	}

	getGroupMaxProgress = () => {
		return this.props.node.allLeafChildren.reduce((acc, node) => {
			const mp = node.data.maxProgress;

			return mp ? Number(acc) + Number(mp) : acc;
		}, 0);
	}

	render()
	{
		const data = this.props.node.data;
		const cp = data.currentProgress;
		const mp = data.maxProgress;

		if (!mp) {
			return null;
		}

		const isDone = cp === mp;
		const prPercent = Number(cp * 100 / mp).toFixed(0);
		const currencySuffix = data.unit && data.unit.suffix || '';

		const iconDone = <FontAwesomeIcon
			fixedWidth
			icon={faCheck}
			style={{color: 'green', marginRight: '5px'}}
		/>;

		const title = <span>
			{isDone && iconDone}
			{`${cp || 0} из ${mp} ${currencySuffix}`}
		</span>;

		const progressBar = !isDone && <ProgressBar
			variant="success"
			style={{
				width: '100%',
				height: '4px',
			}}
			now={prPercent}
		/>;

		return (
			<div
				className={isDone ? style.fakeCellWrapperLeft : style.fakeCellWrapperCenter}
				style={{flexDirection: 'column'}}
			>
				{title}
				{progressBar}
			</div>
		);
	}
}

export default JobProgressCellRenderer;


