import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SettingsProvider } from 'core/local-settings';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/global/app.sass';
import 'styles/global/bootstrap-custom.sass';

import Layout from './layouts/Default';
import AuthPage from 'domains/authorization';
import InviteSwitch from 'domains/user/components/Invite/InviteSwitch';


class App extends React.PureComponent {
	constructor(props)
	{
		super(props);

		const token = localStorage.getItem('token');

		if (!token) {
			return;
		}

		this.props.dispatch({type: 'LOGIN_WITH_TOKEN', token: token});
	}

	render() {

		const meId = this.props.me.id;

		const app = <Provider store={this.props.store}>
			<AuthPage>
				<React.Fragment>
					<InviteSwitch>
						<HashRouter>
							<Layout/>
						</HashRouter>
					</InviteSwitch>
					<ToastContainer closeButton={false}/>
				</React.Fragment>
			</AuthPage>
		</Provider>;

		return meId ? <SettingsProvider meId={meId}>{app}</SettingsProvider> : app;
	}
}

App.propTypes = {
	store: PropTypes.object.isRequired,
};

export default connect((state, props) => {
	return {
		me: state.entities.me || {},
	};
})(App);
