import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from './components/Header';
import Content from './components/Content';

import styles from 'layouts/Default/components/ActivityDrawer/style/activity-drawer.module.sass';


class ActivityDrawer extends React.PureComponent
{
	state = {
		activeIndex: 0,
		isOpen: false,
	}

	setActiveIndex = (activeIndex) => {
		this.setState({activeIndex, isOpen: true});
	}

	open = () => {
		this.setState({isOpen: true});
	}

	close = () => {
		this.setState({isOpen: false});
	}

	componentWillMount()
	{
		document.addEventListener('mousedown', this.handleClick, false);
		document.addEventListener('keydown', this.handleKey, false);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClick, false);
		document.removeEventListener('keydown', this.handleKey, false);
	}

	handleClick = (e) => {
		if (!this.node.contains(e.target)) {
			this.close();
		}
	}

	handleKey = (e) => {
		if (e.key === "Escape") {
			this.close();
		}
	}

	render() {
		return (
			<div
				ref={(ref) => (this.node = ref)}
				className={this.state.isOpen ? styles.activityDrawer : styles.activityDrawerHidden}
			>
				<Row>
					<Col>
						<Row className="pt-3 pb-3" noGutters={true} style={{borderBottom: "1px solid #ebebeb"}}>
							<Header
								activeIndex={this.state.activeIndex}
								onIndexChanged={this.setActiveIndex}
								onClose={this.close}
							/>
						</Row>
						<Row>
							<Content
								activeIndex={this.state.activeIndex}
							/>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ActivityDrawer;
