import React from 'react';
import propTypes from 'prop-types';
import { makeCancelable } from 'core/promise';

class RequestComponent extends React.PureComponent
{
	static propTypes = {
		startDate: propTypes.any.isRequired,
		endDate: propTypes.any.isRequired,
		isLoading: propTypes.bool,
	}

	componentDidMount()
	{
		if (!this.props.isLoading) {
			this.updateData();
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
		const loadingFinished = !this.props.isLoading && prevProps.isLoading;
		const dateChanged = this.props.startDate !== prevProps.startDate ||
			this.props.endDate !== prevProps.endDate;

		if (loadingFinished) {
			this.updateData();

			return;
		}

		if (dateChanged) {
			this.updateData();
		}
	}

	componentWillUnmount()
	{
		this.cancelRequest();
	}

	updateData = () => {
		this.cancelRequest();
		const request = makeCancelable(this.props.dispatch(this.getRequestBody()));

		this.onUpdateStarted();
		this.request = request;

		request.promise
			.then(this.onRequestCallback)
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				delete this.request;
				this.onUpdateFinished();
			})
		;
	}

	cancelRequest()
	{
		if (this.request) {
			this.request.cancel();
			delete this.request;
		}
	}
}

export default RequestComponent;
