class ParsedRow
{
	/**
	 * @type {number}
	 */
	index;

	/**
	 * @type {object}
	 */
	data;

	/**
	 *
	 * @param {number} index
	 * @param {object} data
	 */
	constructor(index, data)
	{
		this.index = index;
		this.data = data;
	}
}

export default ParsedRow;
