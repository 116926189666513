import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';


export default class InputModal extends React.Component
{
	static propTypes = {
		onConfirm: PropTypes.func,
		onHide: PropTypes.func,
		title: PropTypes.string,
		text: PropTypes.string,
	}

	state = {
		show: true,
	}

	onHide = () => {
		this.setState({show: false});
		this.props.onHide();
	}

	onConfirm = () => {
		this.setState({show: false});
		this.props.onConfirm();
	}

	render = () => {
		return (
			<Modal
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.state.show}
				onHide={this.onHide}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.text}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.onConfirm}>Ок</Button>
					<Button onClick={this.onHide}>Отмена</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
