
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Profile from './components/Profile';

const routes = [
	{
		path: "/profile",
		title: 'Профиль',
		faIcon: faUser,
		component: Profile,
	},
];

export default routes;
