import React from 'react';
import api from 'core/api';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withLoading } from "components";
import Empty from 'components/Empty';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';

import ReportAggregator from '../../helpers/aggregator/ReportAggregator/ReportAggregator';
import RequestComponent from '../RequestComponent';
import moment from 'moment';


class PerfomanceChart extends RequestComponent
{
	static propTypes = {
		project: PropTypes.object.isRequired,
		jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
		jobsMap: PropTypes.any.isRequired,
	}

	state = {
		data: [],
		labels: [],
	}

	getRequestBody()
	{
		const progress = api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: this.props.projectId,
				isDeleted: false,
			}
		});

		const emploeeys = api.jobsHistoryAggregation().aggregate({
			column: 'numberOfEmployedWorkers',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: this.props.project.id,
				isDeleted: false,
			}
		});

		return api.transaction().execute([progress, emploeeys]);
	}

	onRequestCallback = (result) => {
		const aggregatorParams = {
			startDate: this.props.startDate,
			endDate: this.props.endDate,
			jobsMap: this.props.jobsMap,
			jobs: this.props.jobs,
			project: this.props.project,
		};

		const progressAggregator = new ReportAggregator({
			...aggregatorParams,
			groups: result[0].groups,
		});

		const emploeeysAggregator = new ReportAggregator({
			...aggregatorParams,
			groups: result[1].groups,
		});

		const progressActual = progressAggregator.progress().delta().actual();
		const progressPlanned = progressAggregator.progress().delta().plan();

		const emploeeysActual = emploeeysAggregator.emploeeys().actualNumber();
		const emploeeysPlanned = emploeeysAggregator.emploeeys().plannedNumber();

		const perfomancePlanned = progressPlanned.map((progress, index) => {
			const empCount = emploeeysPlanned[index];

			if (!empCount) {
				return 0;
			}

			return progress / empCount;
		});

		const perfomanceActual = progressActual.map((progress, index) => {
			const empCount = emploeeysActual[index];

			if (!empCount) {
				return 0;
			}

			return progress / empCount;
		});

		const perfomancePercents = perfomanceActual.map((pActual, index) => {
			const pPlanned = perfomancePlanned[index];

			if (!pPlanned) {
				return 0;
			}

			return parseFloat(Number(pActual * 100 / pPlanned).toFixed(2));
		});


		const labels = emploeeysAggregator.emploeeys().labels()
			.map(date => moment(date).format('DD.MM'));

		this.setState({ data: perfomancePercents, labels });
	}

	onUpdateStarted()
	{
		this.setState({ requestInProgress: true });
	}

	onUpdateFinished()
	{
		this.setState({ requestInProgress: false });
	}

	render()
	{
		const mainProps = {
			fill: true,
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			pointBackgroundColor: '#fff',
			pointBorderWidth: 1,
			pointHoverRadius: 5,
			pointHoverBorderWidth: 2,
			pointRadius: 1,
			pointHitRadius: 10,
			lineTension: 0.1,
			borderCapStyle: 'butt',
			pointHoverBorderColor: 'rgba(220,220,220,1)',
		};

		const data = {
			labels: this.state.labels,
			datasets: [
				{
					...mainProps,
					label: 'Факт %',
					backgroundColor: 'rgba(75,192,192,0.4)',
					borderColor: 'rgba(75,192,192,1)',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					data: this.state.data,
				},
			]
		};

		const options = {
			scales: {
				yAxes: [{
					ticks: {
						suggestedMin: 0,
						suggestedMax: 100,
					}
				}]
			}
		};

		if (this.state.requestInProgress) {
			return <Loading />;
		}

		if (this.state.data.length === 0 || this.state.data.every(value => value === 0)) {
			return <Empty title="Нет данных"/>;
		}

		return (
			<Line
				data={data}
				options={options}
			/>
		);
	}
}

const mapToProps = (state, props) => {
	const { project, jobs } = props;
	const isLoading = !project || !jobs || jobs.isLoading;

	return { isLoading };
};


export default connect(mapToProps)(withLoading(PerfomanceChart));
