import React from 'react';
import { connect } from 'react-redux';
import AuthPage from './AuthPage';

class AuthSwitch extends React.Component
{
	render()
	{
		if (this.props.me && this.props.me.id) {
			return this.props.children;
		}

		if (this.props.token) {
			return null;
		}

		return <AuthPage {...this.props}/>;
	}
}

export default connect((state, props) => {
	return {
		token: state.token,
		me: state.entities.me,
	};
})(AuthSwitch);
