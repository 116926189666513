import moment from 'moment';

class MiniChart
{
	days = {};
	isAccumulating = false;

	constructor(isAccumulating)
	{
		this.isAccumulating = isAccumulating;
	}

	/**
	 * @private
	 */
	fillDaysWithZero(acc, startDate, endDate, zeroValue)
	{
		let date = moment(startDate).startOf('day');
		const now = moment();

		const accNew = {...acc};
		while (date.isBefore(now)) {
			const ts = date.format('YYYY-MM-DD');

			if (!accNew[ts]) {
				accNew[ts] = zeroValue;
			}

			date = date.add(1, 'days');
		}

		return accNew;
	}

	fillDaysWithLast(acc, startDate, endDate)
	{
		const tss = Object.keys(acc).sort((a, b) => (a.localeCompare(b)));

		const prod = {};
		let d = moment.min(moment(tss[0]), moment(startDate));

		let last = 0;

		while (d.isSameOrBefore(endDate)) {
			const ts = d.format('YYYY-MM-DD');
			last += (acc[ts] || 0);

			prod[ts] = last;

			d = d.add(1, 'days');
		}

		return prod;
	}

	fillEmptyDays(acc, startDate, endDate)
	{
		if (this.isAccumulating) {
			return this.fillDaysWithLast(acc, startDate, endDate);
		}

		return this.fillDaysWithZero(acc, startDate, endDate, 0);
	}

	/**
	 * @private
	 */
	dateGroupsToSortedArray(acc)
	{
		return Object.keys(acc)
			.sort((a, b) => (a.localeCompare(b)))
			.map(ts => ({value: acc[ts], date: new Date(ts)}))
		;
	}

	/**
	 * @private
	 */
	createPrevCurrentMiniChart(prev, cur, key)
	{
		return {
			currentPeriod: {
				sum: cur.reduce((acc, d) => acc + d.value, 0),
				byDays: cur.map(d => ({date: moment(d.date).format('YYYY-MM-DD'), value: d.value}))
			},
			prevPeriod: {
				sum: prev.reduce((acc, d) => acc + d.value, 0),
			},
		};
	}

	currentPeriod(periodDays)
	{
		return this.daysWithEmpty(periodDays).slice(-periodDays);
	}

	prevPeriod(periodDays)
	{
		return this.daysWithEmpty(periodDays).slice(-periodDays * 2, -periodDays);
	}

	/**
	 * @private
	 */
	daysWithEmpty(periodDays)
	{
		return this.dateGroupsToSortedArray(
			this.fillEmptyDays(
				this.days,
				moment().subtract(periodDays * 2, 'days').startOf('day'),
				moment(),
				0
			)
		);
	}

	export(periodDays)
	{
		const curPeriod = this.currentPeriod(periodDays);
		const prevPeriod = this.prevPeriod(periodDays);

		return this.createPrevCurrentMiniChart(prevPeriod, curPeriod);
	}

	put(date, delta)
	{
		const ts = moment(date).format('YYYY-MM-DD');

		this.days[ts] = delta + (this.days[ts] || 0);
	}
}

export default MiniChart;
