import React from 'react';
import api from 'core/api';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withLoading } from "components";
import Empty from 'components/Empty';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReportAggregator from '../../helpers/aggregator/ReportAggregator/ReportAggregator';
import RequestComponent from '../RequestComponent';


class GapChart extends RequestComponent
{
	static propTypes = {
		project: PropTypes.object.isRequired,
		jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
		jobsMap: PropTypes.any.isRequired,
	}

	state = {
		data: [],
		labels: [],
	}

	getRequestBody()
	{
		return api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: this.props.projectId,
				isDeleted: false,
			}
		});
	}

	onRequestCallback = (result) => {
		const aggregator = new ReportAggregator({
			startDate: this.props.startDate,
			endDate: this.props.endDate,
			groups: result.groups,
			jobsMap: this.props.jobsMap,
			jobs: this.props.jobs,
			project: this.props.project,
		});

		const progress = aggregator.progress().last();
		const actual = progress.actualPercents();
		const planned = progress.planPercents();

		const data = planned.map((value, index) => {
			const gap = value - (actual[index] || 0);

			return gap < 0 ? 0 : parseFloat(Number(gap).toFixed(2));
		});

		const labels = progress.labels().map(date => moment(date).format('DD.MM'));

		this.setState({ data, labels });
	}

	onUpdateStarted()
	{
		this.setState({ requestInProgress: true });
	}

	onUpdateFinished()
	{
		this.setState({ requestInProgress: false });
	}

	render()
	{
		const mainProps = {
			fill: true,
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			pointBackgroundColor: '#fff',
			pointBorderWidth: 6,
			pointHoverRadius: 8,
			pointHoverBorderWidth: 2,
			pointRadius: 1,
			pointHitRadius: 10,
			lineTension: 0.1,
			borderCapStyle: 'butt',
			pointHoverBorderColor: 'rgba(220,220,220,1)',
		};

		const data = {
			labels: this.state.labels,
			datasets: [
				{
					...mainProps,
					label: 'Отставание %',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					pointHoverBackgroundColor: 'rgba(255,99,132,1)',
					pointBorderColor: 'rgba(255,99,132,1)',
					data: this.state.data,
				}
			]
		};

		const options = {
			scales: {
				yAxes: [{
					ticks: {
						suggestedMin: 0,
						suggestedMax: 50,
					}
				}]
			}
		};

		if (this.state.requestInProgress) {
			return <Loading />;
		}

		if (this.state.data.length === 0 || this.state.data.every(value => value === 0)) {
			return <Empty title="Нет данных"/>;
		}

		return (
			<Line
				data={data}
				options={options}
			/>
		);
	}
}


const mapToProps = (state, props) => {
	const { project, jobs } = props;
	const isLoading = !project || !jobs || jobs.isLoading;

	return { isLoading };
};


export default connect(mapToProps)(withLoading(GapChart));
