import React from 'react';
import { connect } from 'react-redux';
import { Navbar, Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faBell, faUser } from '@fortawesome/free-solid-svg-icons';
import CustomToggle from './components/CustomToggle';
import Breadcrumbs from './components/Breadcrumbs';
import PropTypes from 'prop-types';

import styles from 'layouts/Default/style/statusbar.module.sass';

class StatusBar extends React.Component {

	onLogoutPress = () => {
		this.props.dispatch({type: 'LOGOUT'});
	}

	render() {
		const icons = [
			<FontAwesomeIcon icon={faHeartbeat} fixedWidth size="lg" />,
			<FontAwesomeIcon icon={faBell} fixedWidth size="lg" />,
		];

		return (
			<Navbar expand="lg" sticky="top" className={styles.statusBar}>
				<Breadcrumbs />
				<div className={styles.controls}>
					<div className={styles.services}>
						{icons.map((icon, index) => (
							<div
								key={index}
								onClick={() => this.props.onActivityDrawerOpen(index)}
							>
								{icon}
							</div>
						))}
					</div>
				</div>
				<Dropdown>
					<Dropdown.Toggle as={CustomToggle} id="user-menu" userName={this.props.me.fullName} />

					<Dropdown.Menu className={styles.userMenu}>
						<Dropdown.Header className={styles.header}>
							<div className={styles.title}>
								<div className={styles.position}>{this.props.me.position || 'Сотрудник'}</div>
								<div className={styles.companyTitle}>{this.props.me.defaultEmployee.company.title}</div>
							</div>
						</Dropdown.Header>

						{/* userRoutes.map((route, key) => {
							return (
								<LinkContainer to={route.path} key={key}>
									<Dropdown.Item className={styles.item} eventKey="1">
										{route.icon}
										<span>{route.title}</span>
									</Dropdown.Item>
								</LinkContainer>
							);
						}) */}
						<LinkContainer to="/user/profile">
							<Dropdown.Item className={styles.item} eventKey="1">
								<FontAwesomeIcon icon={faUser} fixedWidth size="lg" />
								<span>Профиль</span>
							</Dropdown.Item>
						</LinkContainer>

						<Button
							onClick={this.onLogoutPress}
							className={styles.logoutButton}
						>
							Выход
						</Button>
					</Dropdown.Menu>
				</Dropdown>
			</Navbar>
		);
	}
}

StatusBar.propTypes = {
	onActivityDrawerOpen: PropTypes.func.isRequired,
};

export default connect((state) => {
	return {
		me: state.me
	};
})(StatusBar);
