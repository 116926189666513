import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import db from 'core/db';
import api from 'core/api';

import { withLoading } from 'components';
import Grid from './components/Grid';
import Aggregator from 'domains/tbs-supplies/pages/RequestsPage/components/OrdersView/helpers/Aggregator';


class InvoicesView extends React.PureComponent
{
	static propTypes = {
		consumableSheet: PropTypes.object,
		consumableSheetId: PropTypes.string,
		isLoading: PropTypes.bool,
		projectId: PropTypes.string,
	}

	updateOrder = (purchaseOrderId, changes, attach) => {
		if (!purchaseOrderId) {
			return;
		}

		const finallyFunc = () => this.setState({ loading: false });

		const catchFunc = error => {
			console.error(error);
			toast.error("Не удалось сохранить изменения...", {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
				draggable: false,
			});
		};

		this.setState({ loading: true });

		if (attach) {
			const fileCreate = api.attachments().create({
				files: [attach]
			});

			this.props.dispatch(fileCreate)
				.then(res => {
					this.props.dispatch(api.tbsPurchaseOrders().update({
						purchaseOrderId,
						changes: {
							...changes,
							attachments: {
								new: [{ id: res.id }],
							}
						}
					}));
				})
				.then(this.closeEditModal)
				.catch(catchFunc)
				.finally(finallyFunc)
			;
		} else {
			this.props.dispatch(api.tbsPurchaseOrders().update({
				purchaseOrderId,
				changes,
			}))
				.then(this.closeEditModal)
				.catch(catchFunc)
				.finally(finallyFunc)
			;
		}
	}

	onPayOrder = order => {
		const changes = {
			isPayed: true,
		};

		this.updateOrder(order.id, changes);
	}

	onPlaceOrder = order => {
		this.props.dispatch(api.tbsPurchaseOrders().place({
			purchaseOrderId: order.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	render()
	{
		return (
			<div className="flex-grow-1 d-flex flex-column">
				<Grid
					items={this.props.groupGridItems.filter(i => i.isWaitingForApprove)}
					currenciesMap={this.props.currenciesMap}
					onPayOrder={this.onPayOrder}
					onPlaceOrder={this.onPlaceOrder}
				/>
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const { consumableSheetId, projectId } = props;

	if (!consumableSheetId) {
		return { isLoading: true };
	}

	const filter = { filter: { projectId } };
	const filterConsumbaleSheet = { filter: { consumableSheetId } };

	const purchaseOrders = db.tbsPurchaseOrders.list(filter);
	const purchaseOrderItems = db.tbsPurchaseOrderItems.list();
	const purchaseRequests = db.tbsPurchaseRequests.list();
	const purchaseRequestItems = db.tbsPurchaseRequestItems.list();
	const consumableSheetItems = db.tbsConsumableSheetItems.list(filterConsumbaleSheet);
	const currencies = db.currencies.list();

	const purchaseOrdersMap = purchaseOrders.hashById();
	const purchaseRequestItemsMap = purchaseRequestItems.hashById();
	const consumableSheetItemsMap = consumableSheetItems.hashById();
	const purchaseRequestsMap = purchaseRequests.hashById();
	const currenciesMap = currencies.hashById();

	const isLoading = props.isLoading
		|| purchaseOrders.isLoading
		|| purchaseOrderItems.isLoading
		|| purchaseRequests.isLoading
		|| purchaseRequestItems.isLoading
		|| consumableSheetItems.isLoading
		|| currencies.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new Aggregator({
		purchaseOrderItems,
		purchaseRequestItemsMap,
		consumableSheetItemsMap,
		purchaseOrdersMap,
		purchaseRequestsMap,
		purchaseOrders,
	});

	const gridItems = aggregator.getGridItems();
	const groupGridItems = aggregator.getGroupGridItems();

	return {
		gridItems,
		groupGridItems,
		currenciesMap,
		currencies,
	};
};

export default connect(mapToProps)(withLoading(InvoicesView));
