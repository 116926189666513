import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AuthDownloadLink from 'components/AuthDownloadLink';


class Icon extends React.PureComponent
{

	static propTypes = {
		file: PropTypes.shape({
			originalName: PropTypes.string,
			url: PropTypes.string,
		}).isRequired,
		icon: PropTypes.object.isRequired,
		iconStyle: PropTypes.object,
		onRemoveClick: PropTypes.func,
		withAuth: PropTypes.bool,
		noText: PropTypes.bool,
	}

	static defaultProps = {
		iconStyle: {},
		withAuth: false,
		noText: false,
	}

	state = {
		hovered: false
	}

	onMouseEnterHandler = (e) => {
		this.setState({hovered: true});
	}

	onMouseLeaveHandler = (e) => {
		this.setState({hovered: false});
	}

	render()
	{
		const { iconStyle, icon, file, onRemoveClick } = this.props;

		const popperConfig = {
			modifiers: {
				preventOverflow: { enabled: false },
				hide: { enabled: false },
			}
		};

		const removeIconStyle = {
			fontSize: '0.8em',
			color: 'red',
			cursor: 'pointer',
			top: 0,
			right: 0,
		};

		if (!this.state.hovered) {
			removeIconStyle.display = 'none';
		}

		const linkIcon = <FontAwesomeIcon
			style={{
				...iconStyle,
				cursor: 'pointer',
			}}
			fixedWidth
			size="lg"
			icon={icon}
		/>;

		const link = this.props.withAuth
			? <AuthDownloadLink href={file.url}>{linkIcon}</AuthDownloadLink>
			: <a href={file.url} target="_blank">{linkIcon}</a>
		;

		const text = !this.props.noText && (
			<span
				className="d-inline-block text-truncate"
				style={{maxWidth: '35px', fontSize: '0.7em'}}
			>
				{file.originalName}
			</span>
		);

		const removeIcon = !!onRemoveClick && <FontAwesomeIcon
			className="position-absolute"
			style={removeIconStyle}
			icon={faTimes}
			fixedWidth
			onClick={ () => onRemoveClick(file) }
		/>;

		return (
			<OverlayTrigger
				trigger="hover"
				popperConfig={popperConfig}
				overlay={
					<Tooltip id={`tooltip`}>
						<strong>{file.originalName}</strong>.
					</Tooltip>
				}
			>
				<div
					onMouseEnter={this.onMouseEnterHandler}
					onMouseLeave={this.onMouseLeaveHandler}
					className="d-flex flex-column position-relative align-items-center px-1"
					style={{width: '40px'}}
				>
					{link}
					{text}
					{onRemoveClick && removeIcon}
				</div>
			</OverlayTrigger>
		);
	}
}

export default Icon;
