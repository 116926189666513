import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {
	Client as ApiClient,
	createReduxMiddleware as createApi2ReduxMiddleware,
	HttpTransport,
	RetryableFetcher,
	QueryExecutor
} from '@brigadier/js-api2';
import api from 'core/api';

import AppReducer from './reducers';
import config from '../../config';
import deviceId from './deviceId';

const fetcher = new RetryableFetcher(
	(...args) => fetch(...args),
	1,
	2,
	(error, retry) => {
		setTimeout(retry, 5000);
	}
);

const apiHttpTransport = new HttpTransport(config.apiUrl, fetcher.fetch.bind(fetcher));
const queryExecutor = new QueryExecutor;

const apiClient = new ApiClient(
	apiHttpTransport,
	deviceId,
);

const tarifLimitsRefhresher = store => next => action => {
	const actions = [
		'PROJECTS_CREATE_RESPONSE',
		'PROJECTS_UPDATE_RESPONSE',
	];

	const result = next(action);

	if (actions.includes(action.type)) {
		store.dispatch(api.me().getSelf());
	}

	return result;
};

const store = createStore(
	(state, action) => {
		return AppReducer(
			queryExecutor.reducer(state, action),
			action
		);
	},
	applyMiddleware(
		tarifLimitsRefhresher,
		createApi2ReduxMiddleware(apiClient, queryExecutor), // эта миддлварь обязательно должна быть до thunk
		thunkMiddleware
	)
);

export default store;
