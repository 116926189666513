import ParsedSpreadsheet from './ParsedSpreadsheet';
import WorksheetParser from './WorksheetParser';
import RowParser from './RowParser';

class Parser
{
	/**
	 *
	 * @type {Object<ColumnSpec>}
	 */
	columns = {};

	/**
	 * @public
	 * @param {Object<ColumnSpec>} columns
	 */
	constructor(columns)
	{
		this.columns = columns;
	}

	/**
	 * @public
	 * @param {object} plainSpreadsheet
	 *
	 * @return {ParsedSpreadsheet}
	 */
	parse(plainSpreadsheet)
	{
		const worksheetParser = new WorksheetParser(this.columns, new RowParser(this.columns));
		const worksheets = [];

		for (const worksheet of plainSpreadsheet.worksheets) {
			worksheets.push(worksheetParser.parse(worksheet));
		}

		return new ParsedSpreadsheet(worksheets);
	}
}

export default Parser;
