import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Form, Col} from 'react-bootstrap';


export default class SelectModal extends React.Component
{
	static propTypes = {
		onConfirm: PropTypes.func,
		onHide: PropTypes.func,
		title: PropTypes.string,
		inputTitle: PropTypes.string,
		values: PropTypes.arrayOf(PropTypes.any),
		labels: PropTypes.arrayOf(PropTypes.string),
	}

	static defaultProps = {
		title: 'Выберите значение',
		values: [],
		labels: [],
	}

	state = {
		show: true,
	}

	constructor()
	{
		super();
		this.selectRef = React.createRef();
	}

	onHide = () => {
		this.setState({show: false});
		this.props.onHide();
	}

	componentDidMount()
	{
		this.setState({value: this.props.values[0]});
	}

	onConfirm = () => {
		if (!this.state.value) {
			this.props.onHide();

			return;
		}

		this.props.onConfirm(this.state.value);
	}

	onChange = e => {
		this.setState({value: e.target.value});
	}

	render = () => {
		const values = this.props.values;
		const labels = this.props.labels;
		const options = [];
		for (let i = 0; i < values.length; ++i) {
			options.push({value: values[i], label: labels[i]});
		}

		const formLabel = this.props.inputTitle &&
			<Form.Label>
				{this.props.inputTitle}
			</Form.Label>
		;

		return (
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.state.show}
				onHide={this.onHide}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Row>
							<Col>
								<Form.Group>
									{formLabel}
									<Form.Control
										as="select"
										value={this.state.value}
										onChange={this.onChange}
									>
										{options.map((option, index) => (
											<option key={index} value={option.value}>{option.label}</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
						</Form.Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.onConfirm}>Ок</Button>
					<Button onClick={this.onHide}>Отмена</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
