import React from 'react';
import PropTypes from 'prop-types';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';


class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onEditRole: PropTypes.func,
		onRemoveRole: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	render()
	{
		const actionsServiceRole = [
			{
				icon: faEdit,
				onClick: this.props.onEditRole,
			},
		];

		const actionsCustomRole = [
			{
				icon: faEdit,
				onClick: this.props.onEditRole,
			},
			{
				icon: faTimes,
				hoverStyle: {color: 'rgb(225, 50, 0)'},
				onClick: this.props.onRemoveRole,
			},
		];

		const columnDefs = [
			{
				headerName: 'Название роли',
				field: 'title',
				suppressSizeToFit: false,
			},
			{
				headerName: 'Количество сотрудников',
				field: 'usersCount',
				cellStyle: () => ({textAlign: 'center'}),
			},
			{
				headerName: 'По умолчанию',
				field: 'isDefaultRole',
				cellRenderer: params => (params.value ? 'Да' : ''),
				cellStyle: () => ({textAlign: 'center'}),
			},
			{
				headerName: 'Редактирование',
				cellRenderer: 'actionsCellRenderer',
				cellRendererParams: (params) => (params.data.isServiceRole ? {actions: actionsServiceRole} : {actions: actionsCustomRole}),
			},
		];

		const frameworkComponents = {
			actionsCellRenderer: ActionsCellRenderer,
		};

		const gridOptions = {
			frameworkComponents: frameworkComponents,
			defaultColDef: {
				minWidth: 40,
				width: 120,
				resizable: true,
				sortable: true,
				editable: false,
				suppressSizeToFit: true,
			}
		};

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				onFirstDataRendered={this.onFirstDataRendered}
				onGridReady={this.onGridReady}
				rowData={this.props.items}
				suppressCellSelection={true}
			/>
		);
	}
}

export default Grid;
