import moment from 'moment';

export default class ProjectAggregator
{
	constructor(params)
	{
		this.params = params;
		this._calculatePerfomance();
		this._calculateEstimatedEndDate();
	}

	_calculatePerfomance()
	{
		const {jobs} = this.params;

		let perfomanceCapacity = 0;
		let perfomanceDone = 0;

		for (const job of jobs) {

			if (!job.productionRatePerShift) {
				continue;
			}

			perfomanceCapacity += job.maxProgress * job.productionRatePerShift;
			perfomanceDone += job.currentProgress * job.productionRatePerShift;
		}

		this.perfomanceCapacity = perfomanceCapacity;
		this.perfomanceDone = perfomanceDone;
	}

	_calculateEstimatedEndDate()
	{
		const {estimatedStartDate} = this.params.project;
		const momentStart = moment(estimatedStartDate);
		const now = moment();

		const diff = now.diff(momentStart, 'days');

		if (diff <= 0) {
			return;
		}

		const dailyPerfomance = this.perfomanceDone / diff;

		if (dailyPerfomance <= 0) {
			return;
		}

		const perfomnceRemain = this.perfomanceCapacity - this.perfomanceDone;
		const estidatedDaysToEnd = Math.floor(perfomnceRemain / dailyPerfomance);

		this._estimatedEndDate = now.add(estidatedDaysToEnd, 'days');
	}

	perfomancePercents()
	{
		return this.perfomanceCapacity ? (this.perfomanceDone * 100 / this.perfomanceCapacity) : 0;
	}

	estimatedEndDate()
	{
		return this._estimatedEndDate && this._estimatedEndDate.toDate() || this.params.project.estimatedEndDate;
	}
}
