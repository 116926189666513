import config from 'config';

const getValue = params => params.valueFormatted || params.value;

export const percentCellRenderer = params => `${getValue(params)} %`;

export const defaultCurrencyCellRenderer = params => `${getValue(params)} ${config.tbs.priceCurrency.default.symbol}`;

export const daysCellRenderer = params => `${getValue(params)} дн.`;

export const priceCellRenderer = props => {

	const { params, currenciesMap } = props;
	const currId = params.data.priceCurrencyId;
	const c = currId && currenciesMap.get(currId);

	const symbol = c && c.symbol;

	return `${getValue(params)} ${symbol}`;
};

export const userCellRenderer = props => {

	const { params, usersMap } = props;

	const user = usersMap.get(params.value);

	if (!user) {
		return null;
	}

	return user.fullName || user.phoneNumber;
};

export const amountCellRenderer = props => {
	const { params, consumableUnitsMap } = props;
	const consumableUnit = consumableUnitsMap.get(params.data.consumableUnitId);
	const suffix = consumableUnit && consumableUnit.suffix;
	const value = getValue(params);

	return `${value} ${suffix}`;
};
