import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from 'core/api';


import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import { getColumnDefs, getGridOptions } from './defs';


class Grid extends React.Component
{
	static propTypes = {
		consumableSheet: PropTypes.object.isRequired,
		items: PropTypes.arrayOf(PropTypes.object),
		usersMap: PropTypes.any,
		currenciesMap: PropTypes.any,
		consumableUnitsMap: PropTypes.any,
		consumableUnits: PropTypes.arrayOf(PropTypes.object),
		currencies: PropTypes.arrayOf(PropTypes.object),
		me: PropTypes.object,
		onSelectionChanged: PropTypes.func,
		onGridReady: PropTypes.func,
	};

	static defaultProps = {
		items: [],
	};

	state = {
		selectedNodes: [],
	}

	onSelectionChanged = (params) => {
		const filteredAndSelectedNodes = [];
		params.api.rowModel.forEachNodeAfterFilter(node => {
			if (node.selected) {
				filteredAndSelectedNodes.push(node);
			}
		});

		this.setState({selectedNodes: filteredAndSelectedNodes});
		this.props.onSelectionChanged(filteredAndSelectedNodes);
	}

	updateRows = (nodes) => {
		this.gridApi.redrawRows({
			rowNodes: nodes
		});
	};

	handleChanges = (node, changes) => {
		const id = node.data.id;

		if (changes.hasOwnProperty('retailPrice')) {
			changes['planPrice'] = null;
		} else if (changes.hasOwnProperty('planPrice')) {
			changes['retailPrice'] = null;
		}

		this.props.dispatch(
			api.tbsConsumableSheetItems().update({
				consumableSheetItemId: id,
				changes: changes
			}))
			.then(() => this.updateRows([node]))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось внести изменения", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	};

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}


		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.handleChanges(params.node, changes);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.props.onGridReady(params);
	}

	onCurrencyCellValuesChanged = (params) => {
		const { node, changes } = params;
		this.handleChanges(node, changes);
	}

	render()
	{
		const columnDefs = getColumnDefs({
			...this.props,
			onCurrencyCellValuesChanged: this.onCurrencyCellValuesChanged,
		});

		const gridOptions = getGridOptions(this.props);

		const getRowNodeId = params => params.id;

		return (
			<CustomGrid
				getRowNodeId={getRowNodeId}
				deltaRowDataMode={true}
				onGridReady={this.onGridReady}
				onSelectionChanged={this.onSelectionChanged}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				onSelectionChanged={this.onSelectionChanged}
				rowSelection={'multiple'}
				stopEditingWhenGridLosesFocus={true}
				onCellValueChanged={this.onCellValueChanged}
				suppressRowClickSelection={true}
			/>
		);
	}
}

export default connect()(Grid);

