import NumberCellEditor from 'components/ag-grid-components/editors/NumberCellEditor';
import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';
import { numberFormatter } from 'components/ag-grid-components/formatters';
import { defaultCurrencyCellRenderer } from 'components/ag-grid-components/renderers';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


export const getGridOptions = params => {

	const amountCellRenderer = params => `${params.valueFormatted} ${params.data.consumableUnit.suffix}`;

	const frameworkComponents = {
		actionsCellRenderer: ActionsCellRenderer,
		numberCellEditor: NumberCellEditor,
		amountCellRenderer: amountCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		defaultCurrencyCellRenderer: defaultCurrencyCellRenderer,
	};

	const gridOptions = {
		enableCellChangeFlash: true,
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			width: 100,
			resizable: true,
			sortable: true,
			editable: false,
			suppressSizeToFit: true,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params =>
{
	const editableCellStyle = { backgroundColor: '#00dcff21' };
	const { purchaseRequestItems, purchaseRequestsMap } = params;

	const getActionParams = params => {
		const data = params.data;

		const index = purchaseRequestItems.map(pr => pr.consumableSheetItemId).indexOf(data.id);

		if (index !== -1) {

			const item = purchaseRequestItems[index];
			const request = purchaseRequestsMap.get(item?.purchaseRequestId);
			const itemAmount = `${item.amount} ${data?.consumableUnit?.suffix}`;

			return {
				actions: [
					{
						icon: faExclamationCircle,
						popover: {
							title: 'Дублироввание позиции',
							content: `Количество будет добавлено к позиции в заявке: "Заявка №${request.sn}" - ${itemAmount}`,
						}
					}
				]
			};
		}

		return null;
	};

	const colDefs = [
		{
			headerName: '#',
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: getActionParams,
			width: 40,
		},
		{
			headerName: 'Наименование',
			field: 'title',
			suppressSizeToFit: false,
		},
		{
			headerName: 'Заказано',
			field: 'orderedAmount',
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Остаток',
			field: 'remainAmount',
			valueFormatter: numberFormatter,
			valueGetter: 'data.amount - data.orderedAmount >= 0 ? data.amount - data.orderedAmount : 0',
		},
		{
			headerName: 'В заказ',
			field: 'requestAmount',
			editable: true,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", step: '0.01' } },
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Примечание',
			field: 'notes',
			editable: true,
			cellEditor: 'agLargeTextCellEditor',
		},
	];

	const renderersMap = {
		'amount': { component: 'amountCellRenderer' },
		'orderedAmount': { component: 'amountCellRenderer' },
		'remainAmount': { component: 'amountCellRenderer' },
		'requestAmount': { component: 'amountCellRenderer' },
		'price': { component: 'defaultCurrencyCellRenderer' },
		'cost': { component: 'defaultCurrencyCellRenderer' },
	};


	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {

		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		if (column.editable) {
			column.cellStyle = editableCellStyle;
		}

		return column;
	});
};
