import React from 'react';
import PropTypes from 'prop-types';
import { Toast, Button } from 'react-bootstrap';
import style from './styles/order-toast.module.sass';

import OrderedItemsList from '../OrderedItemsList';


class OrderToast extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onCreateOrderClicked: PropTypes.func,
		onClose: PropTypes.func,
	}

	static defaultProps = {
		items: [],
		onCreateOrderClicked: () => {},
		onClose: () => {},
	}

	render()
	{
		const items = this.props.items;

		return (
			<Toast
				className={style.toast}
				onClose={this.props.onClose}
			>
				<Toast.Header>
					<strong className="mr-auto">В заказ</strong>
				</Toast.Header>
				<Toast.Body>
					<OrderedItemsList items={items}/>
					<div className="d-flex flex-row justify-content-end">
						<Button
							className="py-1 px-2 mt-3"
							onClick={this.props.onCreateOrderClicked}
						>
							Создать заказ
						</Button>
					</div>
				</Toast.Body>
			</Toast>
		);
	}
}

export default OrderToast;
