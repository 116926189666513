import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from 'core/api';

import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import { getColumnDefs, getGridOptions } from './defs';


class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		sourceSpecsMap: PropTypes.any.isRequired,
		sourceSpecItemsMap: PropTypes.any.isRequired,
		sourceSpecsMapByItemId: PropTypes.any.isRequired,
		onSelectionChanged: PropTypes.func,
		consSpecId: PropTypes.string.isRequired,
		isApproved: PropTypes.bool.isRequired,
		onGridReady: PropTypes.func,
	};

	static defaultProps = {
		items: []
	};

	unmerge(item)
	{
		const ids = item.sourceSpecificationItemIds;

		const transactionBody = ids.map(id => {
			return api.tbsSourceSpecificationItems().update({
				sourceSpecificationItemId: id,
				changes: {
					consolidatedSpecificationItemId: null,
				}
			});
		});

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => this.props.dispatch(api.tbsConsolidatedSpecificationItems().list().withoutCache()))
		;
	}

	createConsolidatedItem = (record) => {
		return this.props.dispatch(
			api.tbsConsolidatedSpecificationItems().create({
				consolidatedSpecificationId: this.props.consSpecId,
				title: record.title,
				partNumber: record.partNumber,
				manufacturerName: record.manufacturerName,
				consumableUnitId: record.consumableUnitId,
				sourceSpecificationItemIds: [record.id],
			})
		);
	}

	updateConsolidatedItem = (id, changes) => {
		return this.props.dispatch(
			api.tbsConsolidatedSpecificationItems().update({
				consolidatedSpecificationItemId: id,
				changes: changes
			}));
	}

	handleChanges = (record, changes) => {
		let p = null;

		if (record.isSource) {
			p = this.createConsolidatedItem({
				...record,
				...changes
			});
		} else {
			p = this.updateConsolidatedItem(record.id, changes);
		}

		return p.catch((err => {
			console.error(err);
			toast.error("Не удалось внести изменения", {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
			});
		}));
	};

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	onUnmergeClick = data => {
		if (data.isSource) {
			return;
		}

		this.unmerge(data);
	}

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}
		const fieldName = params.colDef.field;
		const changes = {};
		const record = params.data;
		changes[fieldName] = params.newValue;
		this.handleChanges(record, changes);
	}

	onGridReady = (params) => {
		this.props.onGridReady(params);
		this.gridApi = params.api;
	}

	render()
	{
		const { sourceSpecItemsMap, sourceSpecsMapByItemId } = this.props;
		const isApproved = this.props.isApproved;

		const columnDefs = getColumnDefs({
			...this.props,
			isApproved,
			onUnmergeClick: this.onUnmergeClick,
		});

		const gridOptions = getGridOptions();

		const detailCellRendererParams = {
			sourceSpecItemsMap,
			sourceSpecsMapByItemId
		};

		const isRowMaster = (dataItem) => !dataItem.isSource;

		const getRowHeight = (params) => {
			if (params.node && params.node.detail) {
				const offset = 28 + 25;
				const allDetailRowHeight = params.data.sourceSpecificationItemIds.length * 25;

				return allDetailRowHeight + offset;
			} else {
				return 38;
			}
		};

		return (
			<CustomGrid
				onGridReady={this.onGridReady}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				masterDetail={true}
				isRowMaster={isRowMaster}
				detailCellRenderer={'detailCellRenderer'}
				detailCellRendererParams={detailCellRendererParams}
				getRowHeight={getRowHeight}
				rowData={this.props.items}
				onFirstDataRendered={this.onFirstDataRendered}
				onSelectionChanged={this.props.onSelectionChanged}
				rowSelection={'multiple'}
				stopEditingWhenGridLosesFocus={true}
				onCellValueChanged={this.onCellValueChanged}
				suppressRowClickSelection={true}
			/>
		);
	}
}

export default connect()(Grid);

