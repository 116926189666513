import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import moment from 'moment';
import numerize from 'core/numerize';

import style from '../styles/cell-style.module.sass';
import 'moment/locale/ru';
moment.locale('ru');


class JobDatesCellRenderer extends React.PureComponent
{
	render()
	{
		const data = this.props.node.data;
		const stDate = moment(data.estimatedStartDate);
		const endDate = moment(data.estimatedEndDate);

		const isValid = stDate && endDate && stDate.isValid() && endDate.isValid();

		if (!isValid) {
			return <span>{`Не установленны`}</span>;
		}

		const now = moment();
		const isOutdated = endDate < now;
		const isNotStarted = now < stDate;

		const title = `${stDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`;
		const diffLate = Math.round(moment.duration(now.diff(endDate)).asDays());
		const diffSoon = Math.round(moment.duration(stDate.diff(now)).asDays());

		const outdateTitle =
			<span style={{color: 'IndianRed', fontSize: '0.85em'}}>
				Отставание {diffLate} {numerize(diffLate, 'день', 'дня', 'дней')}
			</span>
		;

		const notStartedTitle =
			<span style={{color: 'green', fontSize: '0.85em'}}>
				Начинается через {diffSoon} {numerize(diffSoon, 'день', 'дня', 'дней')}
			</span>
		;

		const getProgressComponent = () => {
			if (data.currentProgress >= data.maxProgress) {
				return null;
			}

			if (isOutdated) {
				return outdateTitle;
			}

			if (isNotStarted) {
				return notStartedTitle;
			}

			const daysToNow = stDate.diff(now, 'days');
			const allDays = stDate.diff(endDate, 'days');
			const prPercent = daysToNow * 100 / allDays;

			return <ProgressBar
				variant={isOutdated ? "danger" : "success"}
				style={{
					width: '100%',
					height: '4px',
				}}
				now={prPercent}
			/>;
		};

		return (
			<div
				className={style.fakeCellWrapperCenter}
				style={{flexDirection: 'column'}}
			>
				{title}
				{getProgressComponent()}
			</div>
		);
	}
}

export default JobDatesCellRenderer;


