import React from 'react';
import style from 'styles/page.module.sass';
import { connect } from 'react-redux';
import db from 'core/db' ;

import UploadView from './components/UploadView';
import CreationView from './components/CreationView';
import ConsolidatedSpecView from './components/ConsolidatedSpecView';
import Loading from 'components/Loading';


class ImportPage extends React.PureComponent
{
	state = {
		allowedSourceSpecIds: [],
	}

	onSourceSpecFilterChange = allowedSourceSpecIds => {
		this.setState({ allowedSourceSpecIds });
	}

	renderComponent()
	{
		const consSpec = this.props.consSpec;
		const consSpecId = this.props.consSpecId;
		const hasSource = this.props.sourceSpecs && this.props.sourceSpecs.length;

		if (this.props.isLoading) {
			return <Loading/>;
		}

		if (!consSpecId) {
			return <CreationView projectId={this.props.projectId}/>;
		}

		if (!hasSource) {
			return <UploadView consSpecId={consSpecId}/>;
		}

		return <ConsolidatedSpecView
			consSpecId={consSpecId}
			consSpec={consSpec}
			onSourceSpecFilterChange={this.onSourceSpecFilterChange}
			allowedSourceSpecIds={this.state.allowedSourceSpecIds}
		/>;
	}

	render()
	{
		const component = this.renderComponent();

		return (
			<div
				className={style.wrapper}>
				<div className="flex-grow-1 d-flex flex-column justify-content-center" style={{backgroundColor: '#fff'}}>
					{component}
				</div>
			</div>
		);
	}
}

const mapToProps = (state, props) => {

	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId }};

	const consSpecs = db.tbsConsolidatedSpecifications.list(projectFilter);
	const consSpec = consSpecs[0] || null;
	const consSpecId = consSpec && consSpec.id;

	const consSpecFilter = { filter: { consolidatedSpecificationId: consSpecId } };
	const sourceSpecs = consSpecId && db.tbsSourceSpecifications.list(consSpecFilter) || [];

	const isLoading = consSpecs.isLoading || sourceSpecs.isLoading;

	if (isLoading) {
		return { isLoading };
	}

	return {
		projectId,
		consSpecId,
		consSpec,
		sourceSpecs,
	};
};

export default connect(mapToProps)(ImportPage);
