import React from 'react';
import PropTypes from 'prop-types';
import ButtonToolbar from 'components/ButtonToolbar';


class Layout extends React.PureComponent
{

	static propTypes = {
		btnList: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		btnList: [],
	}

	render()
	{
		return (
			<div className="w-100 h-100 d-flex flex-row" style={{backgroundColor: '#fff'}}>
				<div className="h-100 col-3 border-right d-flex flex-column px-0 align-items-center">
					<ButtonToolbar align="center" className="m-2" btnList={this.props.btnList}/>
					{this.props?.children[0] || this.props?.children}
				</div>
				<div className="h-100 col-9 d-flex flex-column">
					{this.props?.children[1]}
				</div>
			</div>
		);
	}
}

export default Layout;
