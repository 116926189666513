import React from 'react';
import PropTypes from 'prop-types';
import Action from './components/Action';
import { Tooltip, OverlayTrigger, Popover } from 'react-bootstrap';


class ActionsCellRenderer extends React.PureComponent
{
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.object),
		className: PropTypes.string,
	}

	static defaultProps = {
		actions: []
	}

	renderWithOverlay = (action, overlay) => {
		const content = <span>
			<Action action={action}/>
		</span>;

		const popperConfig = {
			modifiers: {
				preventOverflow: { enabled: false },
				hide: { enabled: false },
			}
		};

		return (
			<OverlayTrigger
				overlay={overlay}
				trigger={ action.tooltipTrigger || "hover"}
				popperConfig={popperConfig}
			>
				{content}
			</OverlayTrigger>
		);
	}

	renderPopoverAction = (action) => {
		const popover = (
			<Popover id="popover-basic">
				<Popover.Title as="h4">
					{action?.popover.title}
				</Popover.Title>
				<Popover.Content>
					{action?.popover.content}
				</Popover.Content>
			</Popover>
		);

		return this.renderWithOverlay(action, popover);
	}

	renderTooltipAction = (action) => {
		const tooltip = <Tooltip>
			{action.tooltip}
		</Tooltip>;

		return this.renderWithOverlay(action, tooltip);
	}

	renderSeparator = (index, size) => {
		if (size < 2 || (index + 1) === size) {
			return null;
		}

		return <span className="p-1"/>;
	}

	render()
	{
		const { actions } = this.props;
		const size = actions.length;

		return (
			<div className={this.props.className}>
				{actions.map((action, index) => {
					let actionComponent = null;

					if (action.tooltip) {
						actionComponent = this.renderTooltipAction(action);
					} else if (action.popover) {
						actionComponent = this.renderPopoverAction(action);
					} else {
						actionComponent = <Action action={action}/>;
					}

					const separator = this.renderSeparator(index, size);

					return (
						<span key={index}>
							{actionComponent}
							{separator}
						</span>
					);
				})}
			</div>
		);
	}
}

export default ActionsCellRenderer;
