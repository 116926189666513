import moment from 'moment';

class ProgressAggregator {
	constructor(generator)
	{
		this.generator = generator;
		const { jobs } = generator.params;

		this.projectLaborCosts = this._calculateProjectLaborCosts(jobs);
		this.actualProgress = this._calculateActualProgress();
		this.plannedProgress = this._calculatePlannedProgress();
	}

	_calculateProjectLaborCosts(jobs)
	{
		let costs = 0;

		for (const job of jobs) {
			if (!job.productionRatePerShift) {
				continue;
			}

			const jobCost = job.maxProgress * job.productionRatePerShift;
			costs += jobCost;
		}

		return costs;
	}

	_getJobPerfomance = (value, jobId) => {
		const { jobsMap } = this.generator.params;
		const job = jobsMap.get(jobId);

		if (!job) {
			return 0;
		}

		const { productionRatePerShift } = job;

		if (!productionRatePerShift) {
			return 0;
		}

		return value * productionRatePerShift;
	}

	_getDatesDiff(starDate, endDate, gap = 'days')
	{
		if (starDate > endDate) {
			return 0;
		}

		const momentStart = moment(starDate);
		const momentEnd = moment(endDate);

		return momentEnd.diff(momentStart, gap) + 1;
	}

	_getJobCurrentProgressOnDate(starDate, endDate, date, gap = 'days')
	{
		if (endDate < date) {
			return this._getDatesDiff(starDate, endDate, gap);
		} else {
			return this._getDatesDiff(starDate, date, gap);
		}
	}

	_calculateActualProgress()
	{
		const generator = this.generator;
		const result = [];

		for (const values of generator.getJobSetsIterator(this._getJobPerfomance)) {
			values.forEach((value, index) => {
				result[index] = (result[index] || 0) + value;
			});
		}

		return result;
	}

	_calculatePlannedProgress()
	{
		const { generator } = this;
		const { jobs } = generator.params;

		const result = [];

		for (const date of generator.getDatesIterator()) {

			let plannedLaborCostOnDate = 0;

			for (const job of jobs) {
				const {
					estimatedStartDate,
					estimatedEndDate,
					productionRatePerShift,
					maxProgress,
				} = job;

				if (estimatedStartDate > date) {
					continue;
				}

				const jobMaxProgress = this._getDatesDiff(estimatedStartDate, estimatedEndDate);

				if (jobMaxProgress === 0) {
					continue;
				}

				const jobCurrentProgress = this._getJobCurrentProgressOnDate(estimatedStartDate, estimatedEndDate, date);
				const jobLaborCost = maxProgress * productionRatePerShift;
				const jobPlannedLaborCostOnDate = jobLaborCost * (jobCurrentProgress / jobMaxProgress);

				plannedLaborCostOnDate += jobPlannedLaborCostOnDate;
			}

			result.push(plannedLaborCostOnDate);
		}

		return result;
	}

	actualPercents()
	{
		const { actualProgress, projectLaborCosts } = this;

		if (!projectLaborCosts) {
			return actualProgress;
		}

		return actualProgress.map(value => parseFloat(Number(100 * value / projectLaborCosts).toFixed(2)));
	}

	planPercents()
	{
		const { plannedProgress, projectLaborCosts } = this;

		if (!projectLaborCosts) {
			return plannedProgress;
		}

		return plannedProgress.map(value => parseFloat(Number(value * 100 / projectLaborCosts).toFixed(2)));
	}

	actual()
	{
		return this.actualProgress.map(v => parseFloat(Number(v).toFixed(2)));
	}

	plan()
	{
		return this.plannedProgress.map(v => parseFloat(Number(v).toFixed(2)));
	}

	labels() {
		const { generator } = this;
		const labels = [];

		for (const date of generator.getDatesIterator()) {
			labels.push(date);
		}

		return labels;
	}
}

export default ProgressAggregator;
