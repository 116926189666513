export const MAX_TITLE_LENGTH = 256;

export const isValid = (value, field) => {
	if (field === 'title') {
		return !!value && value.length > 0 && value.length <= MAX_TITLE_LENGTH;
	} else if (field === 'unitId') {
		return !!value;
	}

	return true;
};
