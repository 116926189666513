import UnitsCellEditor from 'components/ag-grid-components/editors/UnitsCellEditor';
import NumberCellEditor from 'components/ag-grid-components/editors/NumberCellEditor';
import RequiredCellRenderer from 'components/ag-grid-components/renderers/RequiredCellRenderer';
import ValidationCellRenderer from 'components/ag-grid-components/renderers/ValidationCellRenderer';
import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import PerfomanceCellRenderer from './components/PerfomanceCellRenderer';
import DateCellEditor from 'components/ag-grid-components/editors/DateCellEditor';
import { isValid, MAX_TITLE_LENGTH } from '../../helpers/validators';
import { dateFormatter } from 'components/ag-grid-components/formatters';


export const getGridOptions = params => {

	const frameworkComponents = {
		unitsEditor: UnitsCellEditor,
		numberCellEditor: NumberCellEditor,
		requiredCellRenderer: RequiredCellRenderer,
		validationCellRenderer: ValidationCellRenderer,
		perfomanceCellRenderer: PerfomanceCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		dateCellEditor: DateCellEditor,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			width: 120,
			resizable: true,
			sortable: true,
			editable: true,
			suppressSizeToFit: true,
		}
	};

	return gridOptions;
};

export const validationFields = ['title', 'unitId'];

export const getColumnDefs = params => {

	const { jobUnits, jobUnitsMap } = params;

	const warningTitle = `Строка не должна быть пустой и длиной не более ${MAX_TITLE_LENGTH} символов!`;
	const warningUnits = 'Необходимо заполнить единицы измерения!';

	const getSuffix = params => {
		const v = jobUnitsMap.get(params.value);

		return v && v.suffix;
	};

	const numberFormatter = params => Number(params.value).toFixed(2);

	const columnDefs = [
		{
			headerName: '№',
			field: 'index',
			maxWidth: 60,
			width: 60,
			minWidth: 60,
			pinned: 'left',
			cellStyle: { textAlign: 'center' },
		},
		{
			headerName: 'Наименование',
			field: 'title',
			suppressSizeToFit: false,
			cellRendererParams: params => {
				return {
					validationTitle: warningTitle,
					isValid: isValid(params.value, 'title'),
				};
			},
		},
		{
			headerName: 'Объем',
			field: 'maxProgress',
			cellStyle: { textAlign: 'center' },
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { step: '0.01' } },
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Ед. изм.',
			field: 'unitId',
			cellStyle: { textAlign: 'center' },
			cellEditor: 'unitsEditor',
			cellEditorParams: { units: jobUnits, valueField: 'suffix' },
			valueFormatter: getSuffix,
			singleClickEdit: true,
			cellRendererParams: params => {
				return {
					validationTitle: warningUnits,
					isValid: isValid(params.value, 'unitId'),
				};
			},
		},
		{
			headerName: 'Трудозатраты',
			field: 'productionRatePerShift',
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { step: '0.01' } },
			valueFormatter: numberFormatter,
			cellRendererParams: { jobUnitsMap }
		},
		{
			headerName: 'Дата начала',
			field: 'estimatedStartDate',
			cellEditor: 'dateCellEditor',
			valueFormatter: dateFormatter,
		},
		{
			headerName: 'Дата окончания',
			field: 'estimatedEndDate',
			cellEditor: 'dateCellEditor',
			valueFormatter: dateFormatter,
		},
		{
			headerName: 'Плановое количество сотрудников',
			field: 'planNumberOfWorkers',
			valueFormatter: params => params.value && `${params.value} чел.`,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: 1 } },
		},
	];

	const renderersMap = {
		'title': { component: 'validationCellRenderer' },
		'unitId': { component: 'validationCellRenderer' },
		'productionRatePerShift': { component: 'perfomanceCellRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (validationFields.includes(column.field)) {
			return renderersMap[column.field];
		}

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer' };
		}

		return renderersMap[column.field] || null;
	};

	return columnDefs.map(column => {
		if (!column.field) {
			return column;
		}

		column.cellRendererSelector = params => getRendererSelector(params, column);

		return column;
	});
};
