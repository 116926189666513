import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import api from 'core/api';
import {Modal} from 'react-bootstrap';
import ButtonToolbar from 'components/ButtonToolbar';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import UnitsCellEditor from 'components/ag-grid-components/editors/UnitsCellEditor';
import {unitsFormatter} from 'components/ag-grid-components/formatters';

import style from './styles/modal.module.sass';


class MergeDialog extends React.Component
{
	static propTypes = {
		consSpecId: PropTypes.string.isRequired,
		consumableUnitsMap: PropTypes.any.isRequired,
		consumableUnits: PropTypes.any.isRequired,
		isOpen: PropTypes.bool.isRequired,
		items: PropTypes.arrayOf(PropTypes.object),
		onClose: PropTypes.func,
		onSuccess: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	state = {
		selectedNodes: [],
		loading: false,
	}

	onSelectionChanged = (params) => {
		const selectedNodes = params.api.getSelectedNodes();
		this.setState({selectedNodes});
	}

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	confirm = () => {
		const selectedItem = this.state.selectedNodes[0].data;

		const sourceSpecificationItemIds = this.props.items
			.reduce((acc, item) => {
				return acc.concat(item.isSource ? [item.id] : item.sourceSpecificationItemIds);
			}, [])
		;

		const consolidatedItemBody = (({title, partNumber, manufacturerName, consumableUnitId}) =>
			({title, partNumber, manufacturerName, consumableUnitId}))(selectedItem);

		const obj = {
			consolidatedSpecificationId: this.props.consSpecId,
			sourceSpecificationItemIds,
			...consolidatedItemBody,
		};

		this.setState({loading: true});
		this.props.dispatch(api.tbsConsolidatedSpecificationItems().create(obj))
			.then(() => {
				this.props.onSuccess();
				this.props.onClose();
			})
			.catch((err) => {
				console.error(err);
				// message.error('Ошибка...');
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	render()
	{
		const { consumableUnitsMap, consumableUnits } = this.props;

		const colDef = [
			{
				checkboxSelection: true,
				maxWidth: 30,
				width: 30,
				editable: false,
			},
			{
				headerName: 'Наименование',
				field: 'title',
				suppressSizeToFit: true,
			},
			{
				headerName: 'Тип',
				field: 'partNumber',
			},
			{
				headerName: 'Производитель',
				field: 'manufacturerName',
			},
			{
				headerName: 'Исходные спецификации',
				field: 'sourceSpecifications',
				editable: false,
			},
			{
				headerName: 'Количество',
				field: 'amount',
				editable: false,
				valueFormatter: (params) => Number(params.value).toFixed(2)
			},
			{
				headerName: 'Ед. изи.',
				field: 'consumableUnitId',
				cellEditor: 'unitsCellEditor',
				cellEditorParams: {units: consumableUnits || []},
				valueFormatter: params => unitsFormatter(params, consumableUnitsMap),
			},
		];

		const frameworkComponents = {
			unitsCellEditor: UnitsCellEditor,
		};

		const gridOptions = {
			frameworkComponents: frameworkComponents,
			onFirstDataRendered: this.onFirstDataRendered,
			defaultColDef: {
				minWidth: 40,
				width: 450,
				resizable: true,
				sortable: true,
				editable: true,
				suppressSizeToFit: false,
			}
		};

		const btnList = [
			{
				title: 'Объеденить',
				props: {
					disabled: !this.state.selectedNodes.length || this.state.loading,
					onClick: this.confirm,
				}
			},
			{
				title: 'Отмена',
				props: {
					disabled: this.state.loading,
					onClick: this.props.onClose,
				}
			},
		];

		return (
			<Modal
				show={this.props.isOpen}
				onHide={this.props.onClose}
				dialogClassName={style.mergeModal}
			>
				<Modal.Header>
					<Modal.Title>
						Объединение позиций
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{height: '300px'}}
					className="d-flex flex-column"
				>
					<CustomGrid
						className="flex-grow-1"
						rowSelection={'single'}
						columnDefs={colDef}
						rowData={this.props.items}
						onSelectionChanged={this.onSelectionChanged}
						gridOptions={gridOptions}
					/>
				</Modal.Body>
				<Modal.Footer>
					<ButtonToolbar btnList={btnList}/>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default connect()(MergeDialog);


