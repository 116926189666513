import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button } from 'react-bootstrap';

import { withFormik } from 'formik';
import TextInput from 'components/Form/TextInput';
import Select from 'components/Form/Select';
import Date from 'components/Form/Date';
import moment from 'moment';

class StageEditForm extends React.Component
{
	state = {}

	static propTypes = {
		users: PropTypes.arrayOf(PropTypes.object).isRequired,
		stage: PropTypes.object,
		onCancel: PropTypes.func,
		onCreateStage: PropTypes.func,
		onUpdateStage: PropTypes.func,
	}

	static defaultProps = {
		stage: {},
		onCancel: () => {},
		onCreateStage: () => {},
		onUpdateStage: () => {},
	}

	render()
	{
		const isEdit = this.props.stage && this.props.stage.id;

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label>Название участка или этапа</Form.Label>
							<TextInput name="title" />
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row>
					<Col sm={6}>
						<Form.Group>
							<Form.Label>Отвественный</Form.Label>
							<Select name="userId" items={this.props.users} optionField="fullName" emptyValue="Не назначен" />
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row>
					<Col sm={3}>
						<Form.Group>
							<Form.Label>Дата начала</Form.Label>
							<Date
								name="estimatedStartDate"
								locale="ru"
							/>
						</Form.Group>
					</Col>

					<Col sm={3}>
						<Form.Group>
							<Form.Label>Дата окончания</Form.Label>
							<Date
								name="estimatedEndDate"
								locale="ru"
							/>
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row className="justify-content-end">
					<Col md="auto">
						<Button type="submit">{isEdit ? 'Сохранить' : 'Создать'}</Button>
					</Col>
					<Col md="auto">
						<Button onClick={this.props.onCancel}>Отмена</Button>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

const handleSubmit = (values, { props, setSubmitting }) => {
	const stage = props.stage;

	if (stage.id) {
		const changes = {};

		for (const [key, value] of Object.entries(values)) {
			if (value && (!stage[key] || stage[key] !== value)) {
				changes[key] = value;
			}
		}

		if (Object.entries(changes).length) {
			props.onUpdateStage(stage.id, changes);
		} else {
			props.onCancel();
		}
	} else {
		props.onCreateStage(values);
	}

	setSubmitting(false);
};

const mapPropsToValues = props => {
	return { ...props.stage };
};

export default withFormik({ handleSubmit, mapPropsToValues })(StageEditForm);
