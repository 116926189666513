import React from 'react';
import PropTypes from 'prop-types';


class RequiredCellRenderer extends React.PureComponent
{
	static propTypes = {
		requiredTitle: PropTypes.string,
	}

	static defaultProps = {
		requiredTitle: '--Введите значение--',
	}

	render()
	{
		const style = {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontWeight: 600,
			textAlign: 'center',
			width: '100%',
			backgroundColor: 'IndianRed',
			fontSize: '0.7em',
		};

		return (
			<div style={style}>
				{this.props.requiredTitle}
			</div>
		);
	}
}

export default RequiredCellRenderer;
