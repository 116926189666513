import React from 'react';
import PropTypes from 'prop-types';

import style from 'components/ag-grid-components/styles/custom-cell.module.sass';
import AttachedFilesToolbar from 'components/AttachedFilesToolbar';


class AttachedFilesCellRenderer extends React.PureComponent
{

	static propTypes = {
		itemsList: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		itemsList: [],
	}

	render()
	{
		return (
			<div className={[style.wrapper, 'justify-content-center'].join(' ')}>
				<AttachedFilesToolbar iconsList={this.props.itemsList}/>
			</div>
		);
	}
}

export default AttachedFilesCellRenderer;
