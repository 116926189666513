import React from 'react';
import NotificationItem from './components/NotificationItem';


class Notifications extends React.PureComponent
{
	render()
	{
		const items = [
		];

		return (
			<div>
				{items.map((item, index) => (
					<NotificationItem
						key={index}
						data={item}
					/>
				))}
			</div>
		);
	}
}

export default Notifications;
