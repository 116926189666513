import React from 'react';
import {connect} from 'react-redux';
import db from 'core/db';
import api from 'core/api';
import { toast } from 'react-toastify';


import ButtonToolbar from 'components/ButtonToolbar';
import pageStyle from 'styles/page.module.sass';
import {Modal} from 'components/Modals';
import Grid from './components/Grid';


class List extends React.PureComponent
{

	onRemoveRole = (employee, role) => {
		this.props.dispatch(api.employees().update({
			employeeId: employee.id,
			changes: {
				roles: {
					deleted: [{id: role.id}]
				}
			}
		}))
			.catch((err) => {
				toast.error("Не удалось удалить роль", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
		;
	}

	onRemoveRoleModal = (employee, role) => {
		Modal.confirm({
			title: 'Удаление роли',
			text: `Удалить роль '${role.title}' у пользовотеля ${employee.user.fullName}?`,
			onConfirm: () => this.onRemoveRole(employee, role),
		});
	}

	onAddEmploeeyRoleModal = (employee) => {
		Modal.select({
			title: 'Добавление роли',
			inputTitle: `Новая роль для ${employee.user.fullName}`,
			values: this.props.roles.map(role => role.id),
			labels: this.props.roles.map(role => role.title),
			onConfirm: (roleId) => this.onAddEmploeeyRole(employee, roleId),
		});
	}

	onAddEmploeeyRole = (employee, roleId) => {
		this.props.dispatch(api.employees().update({
			employeeId: employee.id,
			changes: {
				roles: {
					new: [{id: roleId}]
				}
			}
		}))
			.catch((err) => {
				toast.error("Не удалось добавить роль", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
		;
	}

	onRemoveEmploeeyModal = (employee) => {
		Modal.confirm({
			title: 'Удаление сотрудника',
			text: `Удалить сотрудника ${employee.user.fullName || employee.user.phoneNumber}?`,
			onConfirm: () => this.onRemoveEmploeey(employee),
		});
	}


	onRemoveEmploeey = (employee) => {
		this.props.dispatch(api.employees().deactivate({
			employeeId: employee.id
		}))
			.catch((error) => {
				toast.error("Не удалось удалить сотрудника", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onInviteEmploeeyModal = () => {
		Modal.input({
			title: 'Добавление сотрудника',
			placeholder: '+7 999 8001122',
			text: 'Номер телефона',
			onConfirm: (phoneNumber) => this.onInviteEmploeey(phoneNumber.replace(/\s/g, '').replace('+', '')),
		});
	}

	onInviteEmploeey = phoneNumber => {
		this.props.dispatch(api.employees().invite({
			companyId: this.props.companyId,
			phoneNumber: phoneNumber,
		}))
			.then(() => {
				toast.success(`Приглашение ${phoneNumber} отправлено!`, {
					hideProgressBar: true,
				});
			})
			.catch((error) => {
				toast.error("Не удалось отправить приглашение", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	render()
	{
		const btnList = [
			{
				title: 'Пригласить сотрудника',
				props: { onClick: this.onInviteEmploeeyModal },
			}
		];

		return (
			<div className={pageStyle.wrapper}>
				<ButtonToolbar
					btnList={btnList}
					style={{marginBottom: '10px'}}
					align={'left'}
				/>
				<Grid
					items={this.props.visibleEmploeeys}
					onRemoveEmploeey={this.onRemoveEmploeeyModal}
					onRemoveEmploeeyRole={this.onRemoveRoleModal}
					onAddEmploeeyRole={this.onAddEmploeeyRoleModal}
					onInviteEmploeey={emploeey => this.onInviteEmploeey(emploeey.user.phoneNumber.replace(/\s/g, '').replace('+', ''))}
				/>
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const employees = db.employees.list();
	const roles = db.roles.list();
	const companyId = state.company.id;
	const visibleEmploeeys = employees.filter(e => !(e.inviteState === 'decliened' && !e.deactivationTime));

	return {
		employees: employees,
		visibleEmploeeys: visibleEmploeeys,
		roles: roles,
		companyId: companyId,
	};
};

export default connect(mapToProps)(List);
