import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';
import UnitsCellEditor from 'components/ag-grid-components/editors/UnitsCellEditor';
import RequiredCellRenderer from 'components/ag-grid-components/renderers/RequiredCellRenderer';
import { unitsFormatter } from 'components/ag-grid-components/formatters';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';

import SourceSpecInfoCellRenderer from './components/SourceSpecInfoCellRenderer';
import DetailCellRenderer from './components/DetailCellRenderer';

export const getColumnDefs = params => {

	const {
		consumableUnits,
		consumableUnitsMap,
		onUnmergeClick,
		isApproved
	} = params;

	const actionsUnmerge = [
		{
			icon: faMinusSquare,
			onClick: onUnmergeClick,
			hoverStyle: { color: "rgb(225, 50, 0)" },
		},
	];

	const getActions = params => {
		if (params.data.isSource || isApproved) {
			return null;
		}

		return { actions: actionsUnmerge };
	};

	const colDefs = [
		{
			headerName: '#',
			checkboxSelection: true,
			maxWidth: 30,
			width: 30,
			hide: isApproved,
		},
		{
			headerName: 'Наименование',
			field: 'title',
			editable: true,
			width: 400,
			sort: 'asc',
			cellRenderer: "agGroupCellRenderer",
		},
		{
			headerName: 'Тип',
			field: 'partNumber',
			editable: true,
		},
		{
			headerName: 'Производитель',
			field: 'manufacturerName',
			editable: true,
		},
		{
			headerName: 'Исходные спецификации',
			width: 80,
			field: 'sourceSpecs',
			cellRenderer: 'sourceSpecInfoCellRenderer',
		},
		{
			headerName: 'Количество',
			field: 'amount',
			width: 80,
			valueFormatter: (params) => Number(params.value).toFixed(2)
		},
		{
			headerName: 'Ед. изи.',
			field: 'consumableUnitId',
			width: 100,
			editable: true,
			cellEditor: 'unitsCellEditor',
			cellEditorParams: { units: consumableUnits, valueField: 'suffix'},
			valueFormatter: params => unitsFormatter(params, consumableUnitsMap, 'suffix'),
			cellRendererSelector: params => (params.value ? null : { component: 'requiredCellRenderer' }),
		},
		{
			headerName: '#',
			width: 40,
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: getActions,
			hide: isApproved,
		}
	];


	return colDefs.map(def => {

		if (!def.editable) {
			return def;
		}

		return {
			...def,
			editable: !isApproved,
		};
	});
};

export const getGridOptions = params => {

	const defaultColDef = {
		minWidth: 40,
		resizable: true,
		sortable: true,
		editable: false,
	};

	const frameworkComponents = {
		actionsCellRenderer: ActionsCellRenderer,
		sourceSpecInfoCellRenderer: SourceSpecInfoCellRenderer,
		unitsCellEditor: UnitsCellEditor,
		requiredCellRenderer: RequiredCellRenderer,
		detailCellRenderer: DetailCellRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: defaultColDef,
	};

	return gridOptions;
};
