import ColumnSpec from 'core/xlsx-parser/src/ColumnSpec';
import Parser from 'core/xlsx-parser/src/Parser';

class JobSheetParser
{
	columnsDeclaration = {
		title: new ColumnSpec(
			[
				/^\s*виды?\s+работ/i,
				/\s*название\s*/i,
				/\s*наименование\s*/i,
			],
			true
		),
		unitTypeTitle: new ColumnSpec(
			[
				/^\s*ед\.\s*изм\s*/i,
				/^\s*единица\s+изм/i,
				/\s*обозначение\s*/i,
			],
			true
		),
		priceClient: new ColumnSpec(
			[
				/^\s*цена/i,
			],
			false
		),
		maxProgress: new ColumnSpec(
			[
				/^объ(е|ё)м$/i,
				/^кол(-во|.|ичество)?$/i,
			],
			false
		),
		productionRatePerShift: new ColumnSpec(
			[
				/^ед.*\/см/i,
			],
			false
		),
		estimatedStartDate: new ColumnSpec(
			[
				/^дата.* нач/i,
			],
			false
		),
		estimatedEndDate: new ColumnSpec(
			[
				/^дата.* зав/i,
				/^дата.* окон/i,
			],
			false
		),
		planNumberOfWorkers: new ColumnSpec(
			[
				/^люд.*/i,
			],
			false
		),
	};

	/**
	 * @public
	 *
	 * @param {object} spreadsheet
	 *
	 * @return {ParsedSpreadsheet}
	 */
	parse(spreadsheet)
	{
		const parser = new Parser(this.columnsDeclaration);

		return parser.parse(spreadsheet);
	}
}

export default JobSheetParser;
