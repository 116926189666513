
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Action extends React.PureComponent
{
	static propTypes = {
		action: PropTypes.object.isRequired,
	}

	static defaultProps = {
		action: {}
	}

	state = {
		hovered: false
	}

	onMouseEnterHandler = () => {
		this.setState({ hovered: true });
	}

	onMouseLeaveHandler = () => {
		this.setState({ hovered: false });
	}

	render()
	{
		const { icon, onClick } = this.props.action;

		const style = {
			color: 'rgba(0, 0, 0, 0.6)',
			cursor: 'pointer',
			...(this.props.action.style || {})
		};

		const hoverStyle = {
			color: 'rgba(0, 0, 0, 0.7)',
			cursor: 'pointer',
			...(this.props.action.hoverStyle || {})
		};

		const hovered = this.state.hovered;
		const currentStyle = hovered ? hoverStyle : style;

		return (
			<FontAwesomeIcon
				onMouseEnter={this.onMouseEnterHandler}
				onMouseLeave={this.onMouseLeaveHandler}
				icon={icon}
				fixedWidth
				style={currentStyle}
				onClick={onClick}
			/>
		);
	}
}

export default Action;
