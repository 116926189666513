import React from 'react';
import PropTypes from 'prop-types';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import RadioGroupCellRenderer from 'components/ag-grid-components/renderers/RadioGroupCellRenderer';


class PermissionsGrid extends React.PureComponent
{
	static propTypes = {
		entityTypes: PropTypes.arrayOf(PropTypes.object),
		onCellValueChanged: PropTypes.func,
	}

	static defaultProps = {
		entityTypes: [],
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	onCellValueChanged = (params) => {
		this.props.onCellValueChanged(params);
	}

	render()
	{
		const radioActions = [
			{
				title: 'Чтение',
				key: 'read',
			},
			{
				title: 'Создание',
				key: 'create',
			},
			{
				title: 'Изменение',
				key: 'update',
			},
		];

		const columnDefs = [
			{
				headerName: 'Сущность',
				field: 'title',
				suppressSizeToFit: false,
			},
			{
				headerName: 'Разрешения',
				field: 'permissions',
				cellRenderer: 'radioGroupCellRenderer',
				cellRendererParams: {actions: radioActions},
			},
		];

		const frameworkComponents = {
			radioGroupCellRenderer: RadioGroupCellRenderer,
		};

		const gridOptions = {
			frameworkComponents: frameworkComponents,
			defaultColDef: {
				minWidth: 40,
				width: 280,
				resizable: false,
				sortable: true,
				editable: false,
				suppressSizeToFit: true,
			}
		};

		return (
			<CustomGrid
				wrapperStyle={{height: '400px'}}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				onFirstDataRendered={this.onFirstDataRendered}
				onGridReady={this.onGridReady}
				rowData={this.props.entityTypes}
				suppressCellSelection={true}
				onCellValueChanged={this.onCellValueChanged}
			/>
		);
	}
}

export default PermissionsGrid;
