import React from 'react';
import { Redirect, Route, Switch, } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from 'routes';

import SideBar from './components/SideBar';
import StatusBar from './components/StatusBar';
import ActivityDrawer from './components/ActivityDrawer';

import styles from './style/layout.module.sass';


class DefaultLayout extends React.PureComponent {

	state = {
		sidebarCollapsed: true,
	}

	onActivityDrawerOpen = (index) => {
		this.activityDrawer.setActiveIndex(index);
	}

	onToggleCollapse = () => {

		const sidebarCollapsed = !this.state.sidebarCollapsed;

		this.setState({ sidebarCollapsed });
	}

	render() {

		const collapsed = this.state.sidebarCollapsed;

		return (
			<React.Fragment>
				<div className={styles.layout}>
					<div className={collapsed ? styles.sidebarCollapsed : styles.sidebar}>
						<SideBar
							onToggleCollapse={this.onToggleCollapse}
							collapsed={collapsed}
						/>
					</div>
					<div className={styles.mainActivity}>
						<StatusBar onActivityDrawerOpen={this.onActivityDrawerOpen}/>
						<Switch>
							{routes.map(((route, key) => {
								if (route.redirect) {
									return <Redirect
										from={route.path}
										to={route.to}
										key={key}
										exact={route.exact}
									/>;
								}

								return <Route
									path={route.path}
									key={key}
									exact={route.exact}
									component={route.component}
								/>;
							}))}
						</Switch>
					</div>
				</div>
				<ActivityDrawer ref={(ref) => (this.activityDrawer = ref)}/>
			</React.Fragment>
		);
	}
}

export default connect()(DefaultLayout);
