class Balance
{
	deltas = [];

	/**
	 *
	 * @param {Date} date
	 * @param {number} delta
	 */
	push(date, delta)
	{
		this.deltas.push({
			ts: date.getTime(),
			delta: delta
		});
	}

	getByTsRange(tsFrom, tsTo)
	{
		let delta = 0;

		/*
			Привет оптимизаторам: сортируйте и обрящете
		 */

		for (const di of this.deltas) {
			if (di.ts >= tsFrom && di.ts < tsTo) {
				delta += di.delta;
			}
		}

		return delta;
	}

	/**
	 *
	 * @param {number} ts
	 * @return {number}
	 */
	getAccumulatedAtTsExclusive(ts)
	{
		return this.getByTsRange(0, ts);
	}

	/**
	 *
	 * @param {number} ts
	 * @return {number}
	 */
	getAccumulatedAtTsInclusive(ts)
	{
		return this.getByTsRange(0, ts + 1);
	}

	/**
	 *
	 * @param {Date} date
	 * @return {number}
	 */
	getByDay(date)
	{
		const tsFrom = date.getTime();
		const dateTo = new Date(date);

		dateTo.setDate(dateTo.getDate() + 1);

		return this.getByTsRange(tsFrom, dateTo.getTime());
	}

	/**
	 *
	 * @param {Date} date
	 * @return {number}
	 */
	getAccumulatedAtDateExclusive(date)
	{
		return this.getAccumulatedAtTsExclusive(date.getTime());
	}

	/**
	 *
	 * @param {Date} date
	 * @return {number}
	 */
	getAccumulatedAtDateInclusive(date)
	{
		return this.getAccumulatedAtTsInclusive(date.getTime());
	}
}

export default Balance;
