import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button } from 'react-bootstrap';
import { withFormik } from 'formik';
import TextInput from 'components/Form/TextInput';
import Select from 'components/Form/Select';
import Date from 'components/Form/Date';
import NumberInput from 'components/Form/NumberInput';

import 'react-datepicker/dist/react-datepicker.css';

class JobEditForm extends React.Component
{
	static propTypes = {
		jobUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
		stages: PropTypes.arrayOf(PropTypes.object).isRequired,
		job: PropTypes.object,
		onClose: PropTypes.func,
		onUpdateJob: PropTypes.func,
		onCreateJob: PropTypes.func,
	}

	static defaultProps = {
		job: {},
		onClose: () => {},
		onUpdateJob: () => {},
		onCreateJob: () => {},
	}

	render()
	{
		const isEdit = this.props.job && this.props.job.id;

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label>Наименование</Form.Label>
							<TextInput name="title" />
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row>
					<Col sm={6}>
						<Form.Group>
							<Form.Label>Участок</Form.Label>
							<Select name="stageId" items={this.props.stages} />
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row>
					<Col sm={3}>
						<Form.Group>
							<Form.Label>Дата начала</Form.Label>
							<Date name="estimatedStartDate"/>
						</Form.Group>
					</Col>

					<Col sm={3}>
						<Form.Group>
							<Form.Label>Дата окончания</Form.Label>
							<Date name="estimatedEndDate"/>
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row>
					<Col sm={3}>
						<Form.Group>
							<Form.Label>Выполнено</Form.Label>
							<NumberInput
								name="currentProgress"
							/>
						</Form.Group>
					</Col>

					<Col sm={3}>
						<Form.Group>
							<Form.Label>Всего</Form.Label>
							<NumberInput name="maxProgress" />
						</Form.Group>
					</Col>

					<Col sm={2}>
						<Form.Group>
							<Form.Label>Единицы</Form.Label>
							<Select name="unitId" items={this.props.jobUnits} optionField="suffix" />
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row>
					<Col sm={3}>
						<Form.Group>
							<Form.Label>Трудозатраты</Form.Label>
							<NumberInput name="productionRatePerShift" step="0.01" />
						</Form.Group>
					</Col>

					<Col sm={4}>
						<Form.Group>
							<Form.Label>Плановое количество людей</Form.Label>
							<NumberInput name="planNumberOfWorkers" />
						</Form.Group>
					</Col>
				</Form.Row>

				<Form.Row className="justify-content-end">
					<Col md="auto">
						<Button type="submit">{isEdit ? 'Сохранить' : 'Создать'}</Button>
					</Col>
					<Col md="auto">
						<Button onClick={this.props.onCancel}>Отмена</Button>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

const handleSubmit = (values, { props, setSubmitting }) => {

	const job = props.job;

	if (job.id) {
		const changes = {};

		for (const [key, value] of Object.entries(values)) {
			if (value && (!job[key] || job[key] !== value)) {
				changes[key] = value;
			}
		}

		if (Object.entries(changes).length) {
			props.onUpdateJob(job.id, changes);
		} else {
			props.onCancel();
		}
	} else {
		props.onCreateJob(values);
	}

	setSubmitting(false);
};

const mapPropsToValues = (props) => {
	return { ...props.job };
};

export default withFormik({ handleSubmit, mapPropsToValues })(JobEditForm);
