import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AuthForm from './AuthForm';

import 'styles/global/bootstrap-custom.sass';
import styles from '../styles/signin.module.sass';
import logo from 'assets/img/logo-white-2.png';


class AuthPage extends React.PureComponent
{
	render()
	{
		return (
			<div>
				<Row className={[styles.wave]} noGutters={true}>
					<Col md={5} className="mt-5 ml-5">
						<img src={logo} style={{width: 250}} />
						<p className={styles.teaser}>Отчёт для руководителей проектов, который позволяет за пару минут понять ситуацию на стройке и принять необходимые решения.</p>
					</Col>
					<Col md={{span: 3, offset: 2}}>
						<Row className="align-items-center" style={{height: "100vh"}}>
							<Col>
								<h1>Вход в систему</h1>
								<p className="text-thin">Если у вас ещё нет учётной записи, просто введите ваш e-mail или номер телефона и она будет создана автоматически.
После этого вы сразу попадёте в личный кабинет.</p>
								<AuthForm {...this.props}/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className={styles.dark}></Row>
				<Row className={styles.main}></Row>
				<Row className={[styles.footer, "fixed-bottom justify-content-between px-5 pb-3"]}>
					<Col md="6" className={styles.footerLinks}>
						<a href="#">О продукте</a>
						<a href="#">Политика конфиденциальности</a>
						<a href="#">Пользовательское соглашение</a>
					</Col>
					<Col md="3" style={{ textAlign: "right" }}>ООО “Дабл Зед Дев” © 2019</Col>
				</Row>
			</div>
		);
	}
}

export default AuthPage;
