import ReportGenerator from './ReportGenerator';

export default class ReportGeneratorLast extends ReportGenerator
{
	mapper = values => {
		let last = 0;

		return values.map((value, index) => {
			if (index !== 0 && value === 0) {
				const prevValue = values[index - 1];

				if (prevValue !== 0) {
					last = prevValue;

					return prevValue;
				} else {
					return last;
				}
			}

			return value;
		});
	}
}
