import React from 'react';
import {connect} from 'react-redux';
import db from 'core/db';
import ActivityGroupItem from './components/ActivityGroupItem';
import moment from 'moment';
import DashboardCalculator from './helpers/DashboardCalculator';


class Activity extends React.PureComponent
{
	calculateData()
	{
		const calc = new DashboardCalculator(14);

		return calc.calculate(
			this.props.projects,
			this.props.stages,
			this.props.jobs,
			this.props.jobsHistory,
			this.props.acts,
			this.props.employees,
			this.props.expenses
		);
	}

	groupByDay(items)
	{
		return Object
			.values(items.reduce((groups, item) => {
				const day = moment(item.date).format('YYYY-MM-DD');

				if (!(day in groups)) {
					groups[day] = {
						day: day,
						items: [],
					};
				}

				groups[day].items.push(item);

				return groups;
			}, {}))
			.sort((a, b) => {
				return b.day.localeCompare(a.day);
			})
		;
	}

	render()
	{
		const data = this.calculateData();
		const groups = this.groupByDay(data.activityFeed);

		return (
			<div>
				{groups.map((group, index) => (
					<ActivityGroupItem
						key={index}
						data={group}
					/>
				))}
			</div>
		);
	}
}

export default connect((state, props) => {

	const companyFilter = {
		filter: {
			companyId: state.company.id,
		}
	};

	return {
		projects: db.projects.list(companyFilter),
		acts: [], //db.acts.list(),
		expenses: [], //db.expenses.list(),
		stages: db.stages.list(),
		jobs: db.jobs.list(),
		jobsHistory: db.jobsHistory.list(),
		employees: db.employees.list(companyFilter),
		me: state.me,
	};
})(Activity);
