import React from 'react';

import style from 'styles/page.module.sass';
import List from './components/List';


class ListPage extends React.PureComponent
{
	render()
	{
		return (
			<div className={[style.wrapper, "justify-content-center"].join(' ')}>
				<List/>
			</div>
		);
	}
}

export default ListPage;
