class DepGraph
{
	deps = [];
	tasks = {};
	depsFrom = {};
	depsTo = {};

	addDep(fromTask, toTask, type)
	{
		const dep = {
			id: this.deps.length + 1,
			from: fromTask,
			to: toTask,
			type: type,
		};

		this.deps.push(dep);

		if (!this.depsFrom[fromTask.id]) {
			this.depsFrom[fromTask.id] = [];
		}

		this.depsFrom[fromTask.id].push(dep);

		if (!this.depsTo[toTask.id]) {
			this.depsTo[toTask.id] = [];
		}

		this.depsTo[toTask.id].push(dep);

		this.tasks[fromTask.id] = fromTask;
		this.tasks[toTask.id] = toTask;
	}
}

export default DepGraph;
