import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import style from 'components/ag-grid-components/styles/custom-cell.module.sass';


class NameCellRenderer extends React.PureComponent
{
	render()
	{
		const icon = <FontAwesomeIcon
			fixedWidth
			icon={faUser}
		/>;

		const iconWrapperStyle = {
			height: '28px',
			width: '28px',
			backgroundColor: 'rgba(0,0,0,0.1)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '15px',
			marginRight: '5px',
		};

		const value = this.props.value;

		return (
			<div className={style.wrapper}>
				<div style={iconWrapperStyle}>
					{icon}
				</div>
				{value}
			</div>
		);
	}
}

export default NameCellRenderer;
