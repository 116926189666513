import React from 'react';
import moment from 'moment';

import style from '../styles/cell-style.module.sass';
import 'moment/locale/ru';
moment.locale('ru');


class StageDatesCellRenderer extends React.PureComponent
{
	render()
	{
		const data = this.props.node.data;
		const stDate = moment(data.estimatedStartDate);
		const endDate = moment(data.estimatedEndDate);

		const isValid = stDate && endDate && stDate.isValid() && endDate.isValid();

		if (!isValid) {
			return null;
		}

		const dates = `${stDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`;

		return (
			<div
				className={style.fakeCellWrapperCenter}
				style={{ flexDirection: 'column', fontSize: '14px' }}
			>
				{dates}
			</div>
		);
	}
}

export default StageDatesCellRenderer;


