class ParsedSpreadsheet
{
	/**
	 * @type {ParsedWorksheet[]}
	 */
	worksheets;

	/**
	 *
	 * @param {ParsedWorksheet[]} worksheets
	 */
	constructor(worksheets)
	{
		this.worksheets = worksheets;
	}
}

export default ParsedSpreadsheet;
