import React, { useState } from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);

/**
 * Form date input with Formik Field wrapper
 */

const DateForm = (props) => {

	const [startDate, setStartDate] = useState(new Date());

	const renderField = ({field, form: { setFieldValue, values }}) => {

		const initialDate = values[field.name] && moment(values[field.name]).toDate();

		return (
			<Form.Control
				locale="ru"
				dateFormat="dd.MM.yyyy"
				as={DatePicker}
				selected={initialDate || startDate}
				onChange={date => {
					setFieldValue(field.name, date);
					setStartDate(date);
				}}
			/>
		);
	};

	return (
		<Field
			{...props}
			render={renderField}
		/>
	);
};

export default DateForm;
