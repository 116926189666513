import React from 'react';
import Upload from 'rc-upload';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import UploadList from './components/UploadList';

import style from './styles/uploader.module.sass';


class Uploader extends React.Component
{
	state = {
		fileMap: {}
	}

	onRemove = (file) => {
		const fileMap = this.state.fileMap;
		delete fileMap[file.uid];
		this.setState({fileMap});
		this.props.onFileListChanged(Object.values(fileMap));
	}

	onError = (file, error) => {
		file.status = "error";
		file.error = error;
		file.errorText = error.message;
		const fileMap = this.state.fileMap;
		fileMap[file.uid] = file;
		this.setState({fileMap});
		this.props.onFileListChanged(Object.values(fileMap));
	}

	onAbort = (file) => {

	}

	onSuccess = (file) => {
		file.status = "done";
		const fileMap = this.state.fileMap;
		fileMap[file.uid] = file;
		this.setState({fileMap});
		this.props.onFileListChanged(Object.values(fileMap));
	};

	render()
	{
		const uploadProps = {
			...this.props.uploadProps,
			customRequest: obj => {
				const {file} = obj;
				file.status = "loading";
				file.abort = this.onAbort(file);

				const fileMap = this.state.fileMap;
				fileMap[file.uid] = file;
				this.setState({fileMap});
				this.props.onFileListChanged(Object.values(fileMap));

				const onError = (err) => this.onError(file, err);
				const onSuccess = () => this.onSuccess(file);
				const api = {...obj, onError, onSuccess};

				this.props.uploadProps.customRequest(api);

				return {
					abort() {}
				};
			},
		};

		const items = Object.values(this.state.fileMap);

		return (
			<div className={style.uploaderWrapper}>
				<Upload
					{...uploadProps}
					className={style.uploader}
					component="div"
					ref={(ref) => (this.upload = ref)}
				>
					<FontAwesomeIcon
						icon={faUpload}
						className={style.icon}
					/>
					<div className={style.label}>
						{this.props.label}
					</div>
					<div className={style.underLabel}>
						{this.props.underLabel}
					</div>
				</Upload>
				<UploadList
					style={this.props.listStyle}
					onRemove={this.onRemove}
					items={items}
				/>
			</div>
		);
	}
}

Uploader.propTypes = {
	label: PropTypes.string,
	underLabel: PropTypes.string,
	onFileListChanged: PropTypes.func,
	uploadProps: PropTypes.object,
	listStyle: PropTypes.object,
};

Uploader.defaultProps = {
	label: 'Нажмите или перетащите файлы в область для загрузки',
	underLabel: 'Можно загрузить один или несколько файлов',
	uploadProps: {},
};

export default Uploader;
