import React from 'react';
import {ButtonGroup, Button, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from 'layouts/Default/components/ActivityDrawer/style/activity-drawer.module.sass';


class Header extends React.PureComponent
{
	setActive = (activeIndex) => {
		this.props.onIndexChanged(activeIndex);
	}

	render()
	{
		const btns = ['Активность', 'Уведомления'];
		const activeIndex = this.props.activeIndex || 0;

		return (
			<React.Fragment>
				<Col lg="10" md="10" style={{textAlign: 'center'}}>
					<ButtonGroup toggle size="sm">
						{btns.map((btn, index) => (
							<Button
								key={index}
								variant={index === activeIndex ? 'primary' : 'outline-primary'}
								onClick={() => this.setActive(index)}
							>
								{btn}
							</Button>
						))}
					</ButtonGroup>
				</Col>
				<Col lg="2" md="2" className="pr-2" style={{textAlign: "right"}}>
					<FontAwesomeIcon
						className={styles.closeIcon}
						icon={faTimes}
						fixedWidth
						onClick={this.props.onClose}
						style={{marginLeft: 24}}
					/>
				</Col>
			</React.Fragment>
		);
	}
}

Header.propTypes = {
	onIndexChanged: PropTypes.func,
	activeIndex: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default Header;
