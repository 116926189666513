import { faUsersCog} from '@fortawesome/free-solid-svg-icons';
import RolesSettings from './components/RolesSettings';

const routes = [
	{
		path: "/roles",
		title: 'Настройки ролей',
		faIcon: faUsersCog,
		component: RolesSettings,
	},
];

export default routes;
