import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import db from 'core/db';
import api from 'core/api';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { withLoading } from 'components';
import GroupGrid from './components/GroupGrid';
import Grid from './components/Grid';
import AttachInvoiceForm from './components/AttachInvoiceForm';
import Toolbar from 'domains/tbs-supplies/components/Toolbar';
import Modal from 'components/Modal';
import ButtonToolbar from 'components/ButtonToolbar';
import moment from 'moment';

import RequestItemsAggregator from './helpers/RequestItemsAggregator';


class RequestsView extends React.PureComponent
{
	static propTypes = {
		consumableSheet: PropTypes.object,
		consumableSheetId: PropTypes.string,
		projectId: PropTypes.string.isRequired,
		showTopToolbar: PropTypes.bool,
		itemsCheckable: PropTypes.bool,
	}

	static defaultProps = {
		showTopToolbar: true,
		itemsCheckable: true,
	}

	state = {
		loading: false,
		selectedRows: [],
		groupByRequest: false,
		billModalVisible: false,
		activePurchaseRequestItem: null,
	}

	onSelectedRowsChanged = selectedRows => {
		this.setState({ selectedRows });
	}

	onNewOrderSuccess = () => {
		this.closeOrderDialog();
	}

	openBillModal = () => {
		this.setState({ billModalVisible: true });
	}

	closeBillModal = () => {
		this.setState({ billModalVisible: false });
	}

	createBill = data => {
		const { date, file, supplierTitle } = data;
		const { projectId } = this.props;
		const deliveryDate = moment(date).format('YYYY-MM-DD');

		const items = this.state.selectedRows.map(item => {
			return {
				purchaseRequestItemId: item.id,
				amount: item.amount,
			};
		});

		const orderCreate = api.tbsPurchaseOrders().create({
			projectId,
			deliveryDate,
			supplierTitle,
			items,
		});

		const fileCreate = api.attachments().create({
			files: [file]
		});

		this.setState({ loading: true });
		this.props.dispatch(fileCreate)
			.then(res => {

				const { purchaseOrderId } = orderCreate.props;

				const attachUpdate = api.tbsPurchaseOrders().update({
					purchaseOrderId: purchaseOrderId,
					changes: {
						attachments: {
							new: [{id: res.id}],
						}
					}
				});

				const transactionBody = [orderCreate, attachUpdate];
				const transaction = api.transaction().execute(transactionBody);

				this.props.dispatch(transaction);
			})
			.then(this.closeBillModal)
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			})
		;
	}

	updateItem = (id, changes) => {
		this.props.dispatch(api.tbsPurchaseRequestItems().update({
			purchaseRequestItemId: id,
			changes,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.updateItem(params.node.data.id, changes);
	}

	onApprovePurchaseRequestItem = params => {
		this.props.dispatch(api.tbsPurchaseRequestItems().approve({
			purchaseRequestItemId: params.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onRejectPurchaseRequestItem = params => {
		this.props.dispatch(api.tbsPurchaseRequestItems().cancel({
			purchaseRequestItemId: params.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onRejectAmount = params => {
		this.props.dispatch(api.tbsPurchaseRequestItems().approveAmount({
			purchaseRequestItemId: params.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	renderGrid()
	{
		const gridProps = {
			onCellValueChanged: this.onCellValueChanged,
			consumableSheet: this.props.consumableSheet,
			onApprovePurchaseRequestItem: this.onApprovePurchaseRequestItem,
			onRejectPurchaseRequestItem: this.onRejectPurchaseRequestItem,
			onRejectAmount: this.onRejectAmount,
		};

		if (this.state.groupByRequest) {
			return (
				<GroupGrid
					items={this.props.groupGridItems}
					me={this.props.me}
					onSelectedRowsChanged={this.onSelectedRowsChanged}
					detailGridProps={gridProps}
					detailItems={this.props.gridItems}
				/>
			);
		}

		return (
			<Grid
				{...gridProps}
				onSelectedRowsChanged={this.onSelectedRowsChanged}
				items={this.props.gridItems}
				itemsCheckable={this.props.itemsCheckable}
			/>
		);
	}

	renderTopToolbar()
	{
		const onChange = e => this.setState({ groupByRequest: e.target.checked });

		return (
			<Toolbar>
				<Form.Check
					label={'Группировка по номеру заявки'}
					onChange={onChange}
				/>
			</Toolbar>
		);
	}

	renderBillModal = () => {
		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.billFormProps.handleSubmit(),
					disabled: this.state.loading,
				},
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeBillModal
				},
			}
		];

		const onFormReady = props => { this.billFormProps = props; };

		const items = this.state.selectedRows.map((item, index) => {

			const { title, consumableUnit} = item.consumableSheetItem;
			const { amount } = item;

			return (
				<div key={item.id} className="d-flex flex-row justify-content-between p-2 ml-1 border-bottom">
					<div className="text-truncate mr-3">
						{`${index + 1}. ${title}`}
					</div>
					<div className="text-nowrap">
						{`${amount} ${consumableUnit.suffix}`}
					</div>
				</div>
			);
		});

		return (
			<Modal
				show={this.state.billModalVisible}
				onHide={this.closeBillModal}
				btnList={btnList}
				dialogClassName="w-50"
				title="Прикрепить счёт"
			>
				<div className="mb-4">
					{items}
				</div>
				<AttachInvoiceForm
					onFormReady={onFormReady}
					onConfirm={this.createBill}
				/>
			</Modal>
		);
	}

	renderBtnToolbar = () => {
		const btnList = [];

		if (this.state.selectedRows.length) {
			btnList.push({
				title: 'Добавить счет',
				props: {
					onClick: () => this.openBillModal(),
				},
			});
		}

		return <ButtonToolbar btnList={btnList}/>;
	}

	render()
	{
		const topToolbar = this.props.showTopToolbar && this.renderTopToolbar();
		const grid = this.renderGrid();
		const billModal = this.renderBillModal();
		const btnToolbar = this.renderBtnToolbar();

		return (
			<React.Fragment>
				<div className="flex-grow-1 d-flex flex-column">
					{topToolbar}
					{grid}
					{btnToolbar}
				</div>
				{billModal}
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {
	const consumableSheetId = props.consumableSheetId;
	const filter = { filter: { consumableSheetId } };

	const consumableSheetItems = db.tbsConsumableSheetItems.list(filter);
	const consumableSheetItemsMap = consumableSheetItems.hashById();
	const purchaseRequests = db.tbsPurchaseRequests.list(filter);
	const purchaseRequestsMap = purchaseRequests.hashById();
	const purchaseRequestItems = db.tbsPurchaseRequestItems.list();
	const purchaseOrderItems = db.tbsPurchaseOrderItems.list();
	const purchaseOrders = db.tbsPurchaseOrders.list();
	const purchaseOrdersMap = purchaseOrders.hashById();

	const isLoading = props.isLoading
		|| purchaseRequests.isLoading
		|| purchaseRequestItems.isLoading
		|| consumableSheetItems.isLoading
		|| purchaseOrderItems.isLoading
		|| purchaseOrders.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new RequestItemsAggregator({
		purchaseRequestItems,
		purchaseRequests,
		purchaseRequestsMap,
		consumableSheetItemsMap,
		purchaseOrdersMap,
		purchaseOrderItems,
	});

	const gridItems = aggregator.getGridItems();
	const groupGridItems = aggregator.getGroupGridItems();

	return {
		purchaseRequests,
		consumableSheetItemsMap,
		gridItems,
		groupGridItems,
		me: state.me,
	};
};

export default connect(mapToProps)(withLoading(RequestsView));
