class TasksGraph
{
	tasks = [];
	links = [];

	tasksMap = {};

	taskOutgoingMap = {};
	taskIncomingMap = {};
	stageChildsMap = {};

	constructor(tasks, links)
	{
		this.tasks = tasks;
		this.links = links;

		this.tasksMap = tasks.reduce((acc, task) => {
			acc[task.id] = task;

			return acc;
		}, {});

		const srcToDst = links.reduce((acc, link) => {
			if (!acc[link.source]) {
				acc[link.source] = [];
			}

			acc[link.source].push(this.tasksMap[link.target]);

			return acc;
		}, {});

		const dstToSrc = links.reduce((acc, link) => {
			if (!acc[link.target]) {
				acc[link.target] = [];
			}

			acc[link.target].push(this.tasksMap[link.source]);

			return acc;
		}, {});

		for (const task of tasks) {
			this.taskIncomingMap[task.id] = dstToSrc[task.id] || [];
			this.taskOutgoingMap[task.id] = srcToDst[task.id] || [];

			if (!this.stageChildsMap[task.parent]) {
				this.stageChildsMap[task.parent] = [];
			}

			if (task.parent) {
				this.stageChildsMap[task.parent].push(task);
			}
		}
	}
}

export default TasksGraph;
