import ParsedRowGroup from './ParsedRowGroup';
import ParsedWorksheet from './ParsedWorksheet';
import NotParsedRow from './NotParsedRow';
import HeaderRow from './HeaderRow';

class WorksheetParser
{
	/**
	 * @type {object<ColumnSpec>}
	 */
	columns;

	/**
	 * @type {RowParser}
	 */
	rowParser;

	/**
	 *
	 * @param {object<ColumnSpec>} columns
	 * @param {RowParser} rowParser
	 */
	constructor(columns, rowParser)
	{
		this.columns = columns;
		this.rowParser = rowParser;
	}

	/**
	 *
	 * @param {Array[]} rowGroups
	 * @param {NotParsedRow[]} rows
	 * @return {ParsedRowGroup}
	 */
	makeTree(rowGroups, rows)
	{
		if (!rows.length) {
			return new ParsedRowGroup(null, 0, [], []);
		}

		const mappedRows = {};

		for (const row of rows) {
			mappedRows[row.index] = row;
		}

		const deep = (root, group) => {
			const groupRows = [];
			const groupStartIndex = group[0];
			const groupSize = group[1];
			const newGroup = new ParsedRowGroup(root, groupStartIndex, [], []);

			const titleRow = mappedRows[groupStartIndex - 1];

			if (titleRow instanceof NotParsedRow) {
				const title = this.tryParseSectionTitle(titleRow);

				if (title !== null) {
					newGroup.title = title;
				}
			}

			const childs = [];

			for (const child of group[2]) {
				const c = deep(newGroup, child);

				if (!c) {
					continue;
				}

				childs.push(c);
			}

			/*
				Если будет тормозить - переделать на работу по отсортированному массиву строк.
				Тогда будет O(n) на весб обход
			 */
			for (let index = groupStartIndex; index < groupStartIndex + groupSize; index++) {
				if (!(index in mappedRows)) {
					continue;
				}

				groupRows.push(mappedRows[index]);
				delete mappedRows[index];
			}

			if (!childs.length && !groupRows.length) {
				return null;
			}

			newGroup.childs = childs;
			newGroup.rows = groupRows;

			return newGroup;
		};

		return deep(
			null,
			[
				0,
				rows[rows.length - 1].index + 1,
				rowGroups
			]
		);
	}

	/**
	 * @public
	 * @param worksheet
	 * @return {ParsedWorksheet}
	 */
	parse(worksheet)
	{
		const rows = [];
		let actualHeader = null;

		for (let i = 0; i < worksheet.rows.length; i++) {
			const row = worksheet.rows[i];
			const header = this.tryParseHeader(i, row);

			if (header) {
				actualHeader = header;
				rows.push(header);

				continue;
			}

			if (actualHeader) {
				const parsedRow = this.rowParser.parse(actualHeader, i, row);

				if (parsedRow) {
					rows.push(parsedRow);

					continue;
				}
			}

			rows.push(new NotParsedRow(i, row));
		}

		return new ParsedWorksheet(
			worksheet.title,
			this.makeTree(worksheet.rowGroups, rows)
		);
	}


	/**
	 * @private
	 * @param {number} index
	 * @param {Array} row
	 * @return {?HeaderRow}
	 */
	tryParseHeader(index, row)
	{
		const matches = {};

		for (let i = 0; i < row.length; i++) {
			const value = row[i];

			if (typeof(value) !== 'string') {
				continue;
			}

			const column = this.tryMatchColumnName(value);
			if (column) {
				matches[column] = i;
			}
		}

		for (const name in this.columns) {
			const column = this.columns[name];

			if (column.isRequired && !(name in matches)) {
				return null;
			}
		}

		return new HeaderRow(index, matches);
	}

	/**
	 *
	 * @param {NotParsedRow} row
	 * @return {?string}
	 */
	tryParseSectionTitle(row)
	{
		if (typeof(row.data[0]) === 'string') {
			return row.data[0];
		}

		return null;
	}


	/**
	 * @private
	 * @param {string} string
	 * @return {?string}
	 */
	tryMatchColumnName(string)
	{
		for (const name in this.columns) {
			const column = this.columns[name];

			for (const alias of column.aliases) {
				if (alias.test(string)) {
					return name;
				}
			}
		}

		return null;
	}
}

export default WorksheetParser;
