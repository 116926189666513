import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {Form, InputGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';

class Panel extends React.PureComponent
{
	static propTypes = {
		onDateChanged: PropTypes.func
	}

	state = {
		date: new Date()
	}

	onDateChanged = date => {
		this.setState({date});
		this.refs.picker.setOpen(false);
		this.props.onDateChanged(date);
	}

	render()
	{
		const icon = <FontAwesomeIcon
			icon={faCalendarAlt}
		/>;

		return (
			<InputGroup>
				<Form.Control
					as={DatePicker}
					size="sm"
					ref="picker"
					locale={ru}
					dateFormat="P"
					selected={this.state.date}
					onChange={this.onDateChanged}
				/>
				<InputGroup.Append
					onClick={() => this.refs.picker.setOpen(true)}
					style={{ cursor: 'pointer'}}
				>
					<InputGroup.Text id="inputGroupPrepend">
						{icon}
					</InputGroup.Text>
				</InputGroup.Append>
			</InputGroup>
		);
	}
}

export default Panel;
