import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';


const withItemEditModal = Form => {

	class EditModal extends React.PureComponent
	{
		constructor(props) {
			super(props);
			this.state = {};
		}

		static propTypes = {
			editingItem: PropTypes.object.isRequired,
			title: PropTypes.string,
			onClose: PropTypes.func,
			onUpdate: PropTypes.func,
			modalProps: PropTypes.object,
			formProps: PropTypes.object,
		}

		static defaultProps = {
			modalProps: {},
			formProps: {},
			title: 'Редактирование',
		}

		onUpdate = () => {

			if (!this.state.changes) {
				this.props.onClose();
				this.setState({changes: null});

				return;
			}

			this.props.onUpdate(this.props.editingItem, this.state.changes);
			this.setState({changes: null});
		}

		onChanged = (changes) => {
			this.setState({changes});
		}

		render()
		{
			return (
				<Modal
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					{...this.props.modalProps}
					onHide={() => this.props.onClose()}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{this.props.title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form
							onChanged={this.onChanged}
							editingItem={this.props.editingItem}
							{...this.props.formProps}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => this.onUpdate()}>Ок</Button>
						<Button onClick={() => this.props.onClose()}>Отмена</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	}

	return EditModal;
};

export default withItemEditModal;
