class TaskInfo
{
	hasExpirationWarning = false;
	expired = false;

	constructor(fields)
	{
		Object.assign(this, fields);
	}

	/**
	 * @param task
	 * @return {TaskInfo}
	 */
	static calculate(task)
	{
		const now = new Date();

		return new TaskInfo({
			expired: TaskInfo.expired(now, task),
			hasExpirationWarning: TaskInfo.hasExpirationWarning(now, task),
			timeOverflow: TaskInfo.timeOverflow(now, task),
		});
	}

	static hasExpirationWarning(now, task)
	{
		return TaskInfo.timeOverflow(now, task) > 0;
	}

	static expired(now, task)
	{
		if (task.completed) {
			return false;
		}

		return now.getTime() > task.end_date.getTime();
	}

	static timeOverflow(now, task)
	{
		if (task.completed) {
			return 0;
		}

		const duration = task.end_date.getTime() - task.start_date.getTime();
		const estimateDuration = task.estimatedEndMs - task.start_date.getTime();

		return Math.max(0, estimateDuration / duration - 1);
	}
}

export default TaskInfo;
