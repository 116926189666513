class EventEmitter
{
	listeners = {};

	addListener(event, listener)
	{
		this.on(event, listener);
	}

	on(event, listener)
	{
		if (!this.listeners[event]) {
			this.listeners[event] = [];
		}

		this.listeners[event].push(listener);
	}

	removeListener(event, listener)
	{
		if (!this.listeners[event]) {
			return;
		}

		this.listeners[event] = this.listeners[event].filter(l => l !== listener);
	}

	removeAllListeners(event)
	{
		delete this.listeners[event];
	}

	emit(event, ...data)
	{
		if (!this.listeners[event]) {
			return false;
		}

		this.listeners[event].forEach(listener => listener(...data));

		return true;
	}
}

export default EventEmitter;
