import React from 'react';
import PropTypes from 'prop-types';
import style from '../styles/upload-list.module.sass';
import UploadListItem from './UploadListItem';


class UploadList extends React.Component
{
	render()
	{
		return (
			<div
				className={style.uploadList}
				style={{
					...this.props.style,
					visibility: this.props.items.length ? 'visible' : 'hidden',
				}}
			>
				{this.props.items.map((item, index) => <UploadListItem
					key={index}
					data={item}
					onRemove={this.props.onRemove}
				/>)}
			</div>
		);
	}
}

UploadList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	onRemove: PropTypes.func,
};

UploadList.defaultProps = {
	items: []
};

export default UploadList;
