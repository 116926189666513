import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

class EmployeeGroupCellRenderer extends React.PureComponent
{
	render()
	{
		const id = this.props.value;
		const employee = this.props.employeesMap.get(id);

		const icon = <FontAwesomeIcon
			style={{marginRight: '5px'}}
			fixedWidth
			icon={faUser}
		/>;

		const name = employee && (employee.user.fullName || employee.user.phoneNumber);

		return (
			<div>
				{icon}
				{name}
			</div>
		);
	}
}

export default EmployeeGroupCellRenderer;
