class AutoScheduler
{
	depGraph = null;

	constructor(depGraph)
	{
		this.depGraph = depGraph;
	}

	taskIsCompleted(task)
	{
		return task.completed;
	}

	schedule(updateCb)
	{
		let iter = 0;

		while (true) {
			iter++;

			if (iter > this.depGraph.deps.length * 3) {
				throw new Error('Possible infinity loop at reschedule');
			}

			const update = this.resolveFirst();

			if (!update) {
				break;
			}

			updateCb(update);
		}
	}

	resolveFirst()
	{
		for (const dep of this.depGraph.deps) {
			const resolved = this.depResolve(dep);

			if (resolved) {
				return resolved;
			}
		}

		return null;
	}

	depResolve(dep)
	{
		switch (dep.type) {
			case 'child':
				return this.depChildResolve(dep);
			case 'end-to-start':
				return this.depEndToStartResolve(dep);
			default:
				throw new Error('Unknown dep type: ' + dep.type);
		}
	}

	depChildResolve(dep)
	{
		if (this.taskIsCompleted(dep.from)) {
			return null;
		}

		if (this.compareDatesLt(dep.from.start_date, dep.to.start_date)) {
			return {
				task: dep.from,
				changes: {
					start_date: dep.to.start_date,
					end_date: this.moveToDateDiff(dep.to.start_date, dep.from.start_date, dep.from.end_date),
				}
			};
		}

		if (this.compareDatesLt(dep.to.end_date, dep.from.end_date)) {
			return {
				task: dep.to,
				changes: {
					end_date: dep.from.end_date,
				}
			};
		}

		return null;
	}

	depEndToStartResolve(dep)
	{
		if (this.compareDatesLt(dep.to.start_date, dep.from.end_date)) {
			return {
				task: dep.to,
				t: 'hello',
				changes: {
					start_date: dep.from.end_date,
					end_date: this.moveToDateDiff(dep.from.end_date, dep.to.start_date, dep.to.end_date),
				}
			};
		}

		return null;
	}

	compareDatesLt(a, b)
	{
		return a.getTime() < b.getTime();
	}

	moveToDateDiff(a, b, movingDate)
	{
		const diff = a.getTime() - b.getTime();
		const moving = movingDate.getTime();

		return new Date(moving + diff);
	}
}

export default AutoScheduler;
