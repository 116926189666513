import AttachedFilesCellRenderer from 'components/ag-grid-components/renderers/AttachedFilesCellRenderer';
import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import ContextActionsCellRenderer from './components/ContextActionsCellRenderer';
import { numberFormatter } from 'components/ag-grid-components/formatters';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import config from 'config';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');


export const getGridOptions = params => {

	const { currenciesMap } = params;

	const dateRenderer = params => params.value && moment(params.value).format('L');
	const costRenderer = params => `${params.valueFormatted} ${config.tbs.priceCurrency.default.symbol}`;
	const currencyRenderer = params => {
		const cur = currenciesMap.get(params.value);

		return `${cur.title} (${cur.symbol})`;
	};

	const frameworkComponents = {
		attachedFilesCellRenderer: AttachedFilesCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		contextActionsCellRenderer: ContextActionsCellRenderer,
		dateRenderer,
		costRenderer,
		currencyRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			width: 120,
			minWidth: 40,
			resizable: true,
			sortable: true,
			editable: false,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {

	const { onPlaceOrder, onPayOrder, onCancelOrder } = params;

	const invoiceFileRendererParams = params => {
		const attachments = params.value || [];

		return {
			itemsList: attachments.map(attach => {
				return {
					file: attach,
					icon: faFileExcel,
					iconStyle: {color: 'green'},
					noText: true,
				};
			})
		};
	};

	const statusGetter = params => {
		const { purchaseRequestItems, isPayed, isPlaced, isWatingForApprove } = params.data;

		const isApproved = purchaseRequestItems.every(i => i.isApproved && !i.isCanceled);

		if (isPayed) {
			return 'Оплачен';
		}

		if (isPlaced) {
			return 'Размещён';
		}

		if (isWatingForApprove && isApproved) {
			return 'На оплате';
		}

		if (isApproved) {
			return "Утверждён РП";
		}

		return 'Новый';
	};

	const costGetter = params => {
		const { purchaseOrderItems } = params.data;

		return purchaseOrderItems.reduce((acc, item) => {
			const { amount, amountProposed, priceActual } = item;
			const am = amountProposed || amount;

			if (!am || !priceActual) {
				return acc;
			}

			return acc + am * priceActual;
		}, 0);
	};

	const getActions = params =>
	{
		const { isPayed, isPlaced } = params.data;

		const actions = [];

		if (!isPlaced) {
			actions.push({
				title: 'Разместить',
				onClick: () => onPlaceOrder(params.data),
			});
		}

		if (!isPayed) {
			actions.push({
				title: 'Оплатить',
				onClick: () => onPayOrder(params.data),
			});
		}

		actions.push({
			title: 'Отменить',
			onClick: () => onCancelOrder(params.data),
		});

		return { actions };
	};

	const colDefs = [
		{
			headerName: 'Номер счёта',
			field: 'invoiceNumber',
		},
		{
			headerName: 'Дата счёта',
			field: 'invoiceDate',
		},
		{
			headerName: 'Поставщик',
			field: 'supplierTitle',
		},
		{
			headerName: 'Валюта',
			field: 'currencyId',
		},
		{
			headerName: 'Сумма',
			field: 'cost',
			valueGetter: costGetter,
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Условия',
			field: 'paymentTermsNotes',
		},
		{
			headerName: 'Статус',
			valueGetter: statusGetter,
		},
		{
			headerName: 'Файл счёта',
			field: 'attachments',
			cellRendererParams: invoiceFileRendererParams,
			width: 50,
		},
		{
			headerName: 'Действия',
			cellRenderer: 'contextActionsCellRenderer',
			cellRendererParams: getActions,
			width: 50,
		}
	];

	const renderersMap = {
		'invoiceDate': { component: 'dateRenderer' },
		'attachments': { component: 'attachedFilesCellRenderer' },
		'currencyId': { component: 'currencyRenderer' },
		'cost': { component: 'costRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
