import React from 'react';
import PropTypes from 'prop-types';
import {ProgressBar} from 'react-bootstrap';


class MainProgressCard extends React.PureComponent
{
	static propTypes = {
		progressPercent: PropTypes.number
	}

	render()
	{
		const style = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '6px 15px',
		};

		const titleStyle = {
			fontSize: '1.5em',
			fontWeight: 500,
		};

		const pr = this.props.progressPercent;

		const bar = <ProgressBar
			variant={"success"}
			style={{
				width: '100%',
				height: '6px',
				marginTop: '12px',
			}}
			now={pr}
		/>;

		const isValid = pr !== null && pr !== undefined;

		return (
			<div style={style}>
				{isValid ? <div style={titleStyle}>{`${Number(pr).toFixed(2)}%`}</div> : '...'}
				{isValid && bar}
			</div>
		);
	}
}

export default MainProgressCard;
