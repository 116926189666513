const getMainMenuItems = params => {
	const column = params.column;
	const colId = column.getId();
	const api = params.api;
	const columnApi = params.columnApi;

	const autoSizeAll = () => {
		const allColumnIds = [];
		columnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		columnApi.autoSizeColumns(allColumnIds);
	};

	return [
		{
			name: 'Прикрепить',
			subMenu: [
				{
					name: 'Слева',
					checked: column.pinned === 'left',
					action: () => columnApi.setColumnPinned(colId, 'left'),
				},
				{
					name: 'Справа',
					checked: column.pinned === 'right',
					action: () => columnApi.setColumnPinned(colId, 'right'),
				},
				{
					name: 'Открепить',
					action: () => columnApi.setColumnPinned(colId, null),
				}
			]
		},
		{
			name: 'Выровнять таблицу по ширине экрана',
			action: () => api.sizeColumnsToFit(),
		},
		{
			name: 'Выровнять таблицу по содержимому',
			action: autoSizeAll,
		},
		{
			name: 'Выровнять колонку по содержимому',
			action: () => columnApi.autoSizeColumns([colId]),
		}
	];
};

export default getMainMenuItems;
