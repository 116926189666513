import React from "react";
import PropTypes from 'prop-types';
import SettingsContext from './SettingsContext';

class SettingsProvider extends React.PureComponent
{
	static propTypes = {
		meId: PropTypes.string.isRequired,
	}

	constructor(props)
	{
		super(props);
		this.state = JSON.parse(localStorage.getItem(this.props.meId)) || {};
	}

	setSettings(settings)
	{
		this.setState(settings, () => {
			localStorage.setItem(this.props.meId, JSON.stringify(this.state));
		});
	}

	render()
	{
		return <SettingsContext.Provider
			value={{
				settings: this.state,
				setSettings: s => this.setSettings(s)
			}}
		>
			{this.props.children}
		</SettingsContext.Provider>;
	}
}

export default SettingsProvider;
