import { combineReducers } from 'redux';

import apiReduxIntegration from './api-redux-integration';
import DB from './db/DB';
import store from './store';
import api from './api';
import notifications from './notifications';

const appReducer = combineReducers({
	entities: apiReduxIntegration.reducer(),
	dbResourceCache: DB.reducer(),
	token: (token = null, action) => {
		if (action.type === 'LOGOUT') {
			localStorage.removeItem('token');
			notifications.refreshWebsocketToken();

			return null;
		}

		if (action.type !== 'LOGIN_WITH_TOKEN') {
			return token;
		}

		localStorage.setItem('token', action.token);
		setTimeout(() => {
			store.dispatch(api.me().getSelf());
		}, 1);

		return action.token;
	},
	company: (state = {}, action) => {
		if (action.type !== 'API_CACHE_ENGINE_PUT_SELF' || action.resourceName !== 'me') {
			return state;
		}

		return action.payload.defaultEmployee.company;
	},
	me: (state = {}, action) => {
		if (action.type !== 'API_CACHE_ENGINE_PUT_SELF' || action.resourceName !== 'me') {
			return state;
		}

		return action.payload;
	},
});

export default (state, action) => {
	if (action.type === 'LOGOUT') {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};
