class DashboardData
{
	period = {
		from: null,
		to: null,
	};

	customerBudget = {
		payed: 0,
		notPayed: 0,
		inFuture: 0,
	};

	workerBudget = {
		payed: 0,
		notPayed: 0,
		inFuture: 0,
	};

	objectsSummary = {
		inactive: 0,
		expiredProjects: 0,
		expiredJobs: 0,
		expiredStages: 0,
	};

	activityFeed = [
		/*
		{
			type: 'jobProgress',
			date: 'Сегодня 10:24',
			user: {
				fullName: 'Анатолий Кабанов'
			},
			project: {
				title: 'ул. Завокзальная 1-я, дом 37, квартира 105',
			},
		}
		*/
	];

	projectsCountChart = {
		byMonth: [
			/*
			{
				month: 'Январь',
				values: {
					created: 10,
					completed: 5
				}
			}
			*/
		]
	};

	worksChart = {
		byDays: [
			/*
			{
				date: '2018-08-01',
				values: {
					completed: 53344
				}
			}
			*/
		]
	};

	values = {
		projectsInWork: {
			currentPeriod: {
				sum: 0,
				byDays: [
					/*
					{
						date: '2018-08-07',
						value: 15
					}
					*/
				],
			},
			prevPeriod: {
				sum: 0,
			},
		},
		projectsCompleted: {
			currentPeriod: {
				sum: 0,
				byDays: [
					/*
					{
						date: '2018-08-07',
						value: 15
					}
					*/
				],
			},
			prevPeriod: {
				sum: 0,
			},
		},
		projectsStarted: {
			currentPeriod: {
				sum: 0,
				byDays: [
					/*
					{
						date: '2018-08-07',
						value: 15
					}
					*/
				],
			},
			prevPeriod: {
				sum: 0,
			},
		},
		completedPriceClient: {
			currentPeriod: {
				sum: 0,
				byDays: [
					/*
					{
						date: '2018-08-07',
						value: 12213
					}
					*/
				],
			},
			prevPeriod: {
				sum: 0,
			},
		},
		actedPriceClient: {
			currentPeriod: {
				sum: 0,
				byDays: [
					/*
					{
						date: '2018-08-07',
						value: 34343
					}
					*/
				],
			},
			prevPeriod: {
				sum: 0,
			},
		},
		actsCreated: {
			currentPeriod: {
				sum: 0,
				byDays: [
					/*
					{
						date: '2018-08-07',
						value: 1
					}
					*/
				],
			},
			prevPeriod: {
				sum: 0,
			},
		}
	};

	managersInfo = [
		/*
		{
			user: {
				fullName: 'Кирилл Зорин',
				avatar: avatarZarin,
			},
			projectsCount: 8,
			projectsExpired: [
				{
					days: 24,
					project: {
						title: 'ул. Батюнинская, дом 89, квартира 295',
					},
				}
			],
			projectsInactive: [
				{
					days: 3,
					project: {
						title: 'ул. Беговая 4-я, дом 97, квартира 55',
					}
				},
				{
					days: 2,
					project: {
						title: 'ул. Адмирала Макарова, дом 87, квартира 252',
					}
				}
			]
		}
		*/
	];

	nearestCompletions = [
		/*
		{
			title: 'ул. Батюнинская, дом 89, квартира 295',
			date: '2018-07-20',
		},
		*/
	];

	constructor(data = {})
	{
		Object.assign(this, data);
	}
}

export default DashboardData;
