import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

import style from '../styles/done-notify-view.module.sass';


class DoneNotifyView extends React.PureComponent
{
	static propTypes = {
		projectId: PropTypes.string.isRequired
	}

	render()
	{
		const projectId = this.props.projectId;
		const path = `/projects/${projectId}/scope`;

		return (
			<div className={style.doneNotifyView}>
				<div className={style.doneNotifyViewContent}>
					<FontAwesomeIcon
						className={style.doneNotifyViewIcon}
						fixedWidth
						icon={faCheck}
					/>
					<div>
						Данные успешно импортированы!
					</div>
					<Link
						to={path}
					>
						<Button
							variant="dark"
							className={style.doneNotifyViewButton}
						>
							Перейти к плану работ
						</Button>
					</Link>
				</div>
			</div>
		);
	}
}

export default DoneNotifyView;
