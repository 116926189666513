import avatar1 from '../assets/img/avatar-random-1.jpg';
import avatar2 from '../assets/img/avatar-random-2.jpg';
import avatar3 from '../assets/img/avatar-random-3.jpg';
import avatar4 from '../assets/img/avatar-random-4.jpg';
import avatar5 from '../assets/img/avatar-random-5.jpg';
import avatarZarin from '../assets/img/zarin-avatar.jpg';
import DashboardData from './DashboardData';
import moment from 'moment';

export default new DashboardData({
	period: 'С 2018-08-07 по 2018-08-13',
	customerBudget: {
		payed: 1142486 * 2,
		notPayed: 360967 * 2,
		inFuture: 3730702,
	},
	workerBudget: {
		payed: 256577 * 2,
		notPayed: 345708 * 2,
		inFuture: 1865351,
	},
	objectsSummary: {
		inactive: 2,
		expiredProjects: 1,
		expiredJobs: 8,
		expiredStages: 2,
	},
	activityFeed: [
		{
			type: 'jobProgress',
			date: moment().subtract(1, 'hour'),
			user: {
				id: 'Анатолий Кабанов',
				fullName: 'Анатолий Кабанов'
			},
			project: {
				title: 'ул. Завокзальная 1-я, дом 37, квартира 105',
			},
		},
		{
			type: 'expense',
			date: moment().subtract(2, 'hour').subtract(34, 'minute'),
			user: {
				id: 'Анатолий Кабанов',
				fullName: 'Анатолий Кабанов'
			},
			project: {
				title: 'ул. Завокзальная 1-я, дом 37, квартира 105',
			},
			sum: 2300,
		},
		{
			type: 'act',
			date: moment().subtract(1, 'day').add(473, 'minute'),
			user: {
				id: 'Олег Николаев',
				fullName: 'Олег Николаев'
			},
			project: {
				title: 'ул. Ленина 24, кв 211',
			},
			sum: 180550,
		},
		{
			type: 'jobProgress',
			date: moment().subtract(1, 'day').add(379, 'minute'),
			user: {
				id: 'Никита Ларионов',
				fullName: 'Никита Ларионов',
			},
			project: {
				title: 'ул. Проспект Октября 70, кв 104',
			},
		},
		{
			type: 'jobProgress',
			date: moment().subtract(1, 'day').add(272, 'minute'),
			user: {
				id: 'Роман Лаврентьев',
				fullName: 'Роман Лаврентьев',
			},
			project: {
				title: 'ул. Багрицкого, дом 34, квартира 228',
			},
		},
		{
			type: 'jobProgress',
			date: moment().subtract(1, 'day').add(177, 'minute'),
			user: {
				id: 'Олег Николаев',
				fullName: 'Олег Николаев'
			},
			project: {
				title: 'ул. Декабристов, дом 75',
			},
		},
	],
	projectsCountChart: {
		byMonth: [
			{
				month: 'Январь',
				values: {
					created: 10,
					completed: 5
				}
			},
			{
				month: 'Февраль',
				values: {
					created: 4,
					completed: 8
				}
			},
			{
				month: 'Март',
				values: {
					created: 15,
					completed: 6
				}
			},
			{
				month: 'Апрель',
				values: {
					created: 11,
					completed: 8
				}
			},
			{
				month: 'Май',
				values: {
					created: 5,
					completed: 14
				}
			},
			{
				month: 'Июнь',
				values: {
					created: 7,
					completed: 12
				}
			},
			{
				month: 'Июль',
				values: {
					created: 8,
					completed: 8
				}
			},
			{
				month: 'Август',
				values: {
					created: 3,
					completed: 0
				}
			},
		]
	},
	worksChart: {
		byDays: [
			{
				date: '2018-08-01',
				values: {
					completed: 53344
				}
			},
			{
				date: '2018-08-02',
				values: {
					completed: 12323
				}
			},
			{
				date: '2018-08-03',
				values: {
					completed: 54828
				}
			},
			{
				date: '2018-08-04',
				values: {
					completed: 9822
				}
			},
			{
				date: '2018-08-05',
				values: {
					completed: 54872
				}
			},
			{
				date: '2018-08-06',
				values: {
					completed: 44033
				}
			},
			{
				date: '2018-08-07',
				values: {
					completed: 54888
				}
			},
			{
				date: '2018-08-08',
				values: {
					completed: 19272
				}
			},
			{
				date: '2018-08-09',
				values: {
					completed: 38292
				}
			},
			{
				date: '2018-08-10',
				values: {
					completed: 77199
				}
			},
			{
				date: '2018-08-11',
				values: {
					completed: 43892
				}
			},
			{
				date: '2018-08-12',
				values: {
					completed: 87783
				}
			},
			{
				date: '2018-08-13',
				values: {
					completed: 54872
				}
			},
		]
	},
	values: {
		projectsInWork: {
			currentPeriod: {
				sum: 12,
				byDays: [
					{
						date: '2018-08-07',
						value: 15
					},
					{
						date: '2018-08-08',
						value: 16
					},
					{
						date: '2018-08-09',
						value: 14
					},
					{
						date: '2018-08-10',
						value: 17
					},
					{
						date: '2018-08-11',
						value: 13
					},
					{
						date: '2018-08-12',
						value: 18
					},
					{
						date: '2018-08-13',
						value: 12
					},
				],
			},
			prevPeriod: {
				sum: 10,
			},
		},
		projectsCompleted: {
			currentPeriod: {
				sum: 12,
				byDays: [
					{
						date: '2018-08-07',
						value: 15
					},
					{
						date: '2018-08-08',
						value: 16
					},
					{
						date: '2018-08-09',
						value: 14
					},
					{
						date: '2018-08-10',
						value: 17
					},
					{
						date: '2018-08-11',
						value: 13
					},
					{
						date: '2018-08-12',
						value: 18
					},
					{
						date: '2018-08-13',
						value: 12
					},
				],
			},
			prevPeriod: {
				sum: 10,
			},
		},
		projectsStarted: {
			currentPeriod: {
				sum: 12,
				byDays: [
					{
						date: '2018-08-07',
						value: 15
					},
					{
						date: '2018-08-08',
						value: 16
					},
					{
						date: '2018-08-09',
						value: 14
					},
					{
						date: '2018-08-10',
						value: 17
					},
					{
						date: '2018-08-11',
						value: 13
					},
					{
						date: '2018-08-12',
						value: 18
					},
					{
						date: '2018-08-13',
						value: 12
					},
				],
			},
			prevPeriod: {
				sum: 10,
			},
		},
		completedPriceClient: {
			currentPeriod: {
				sum: 12435850,
				byDays: [
					{
						date: '2018-08-07',
						value: 12213
					},
					{
						date: '2018-08-08',
						value: 22332
					},
					{
						date: '2018-08-09',
						value: 12345
					},
					{
						date: '2018-08-10',
						value: 54432
					},
					{
						date: '2018-08-11',
						value: 8754
					},
					{
						date: '2018-08-12',
						value: 11134
					},
					{
						date: '2018-08-13',
						value: 19932
					},
				],
			},
			prevPeriod: {
				sum: 1223334,
			},
		},
		actedPriceClient: {
			currentPeriod: {
				sum: 4343566,
				byDays: [
					{
						date: '2018-08-07',
						value: 34343
					},
					{
						date: '2018-08-08',
						value: 3445
					},
					{
						date: '2018-08-09',
						value: 65565
					},
					{
						date: '2018-08-10',
						value: 34745
					},
					{
						date: '2018-08-11',
						value: 34544
					},
					{
						date: '2018-08-12',
						value: 12213
					},
					{
						date: '2018-08-13',
						value: 14334
					},
				],
			},
			prevPeriod: {
				sum: 3324440,
			},
		},
		actsCreated: {
			currentPeriod: {
				sum: 17,
				byDays: [
					{
						date: '2018-08-07',
						value: 1
					},
					{
						date: '2018-08-08',
						value: 2
					},
					{
						date: '2018-08-09',
						value: 0
					},
					{
						date: '2018-08-10',
						value: 4
					},
					{
						date: '2018-08-11',
						value: 3
					},
					{
						date: '2018-08-12',
						value: 4
					},
					{
						date: '2018-08-13',
						value: 2
					},
				],
			},
			prevPeriod: {
				sum: 20,
			},
		},
	},
	managersInfo: [
		{
			user: {
				fullName: 'Вячеслав Жданов',
				avatar: avatar1,
			},
			projectsCount: 9,
		},
		{
			user: {
				fullName: 'Олег Николаев',
				avatar: avatar2,
			},
			projectsCount: 5,
		},
		{
			user: {
				fullName: 'Анатолий Кабанов',
				avatar: avatar3,
			},
			projectsCount: 7,
		},
		{
			user: {
				fullName: 'Кирилл Зорин',
				avatar: avatarZarin,
			},
			projectsCount: 8,
			projectsExpired: [
				{
					days: 24,
					project: {
						title: 'ул. Батюнинская, дом 89, квартира 295',
					},
				}
			],
			projectsInactive: [
				{
					days: 3,
					project: {
						title: 'ул. Беговая 4-я, дом 97, квартира 55',
					}
				},
				{
					days: 2,
					project: {
						title: 'ул. Адмирала Макарова, дом 87, квартира 252',
					}
				}
			]
		},
		{
			user: {
				fullName: 'Роман Лаврентьев',
				avatar: avatar4,
			},
			projectsCount: 5,
		},
		{
			user: {
				fullName: 'Никита Ларионов',
				avatar: avatar5,
			},
			projectsCount: 6,
		},
	],
	nearestCompletions: [
		{
			id: '1',
			title: 'ул. Батюнинская, дом 89, квартира 295',
			date: moment().subtract(24, 'day'),
		},
		{
			id: '2',
			title: 'ул. Багрицкого, дом 34, квартира 228',
			date: moment().add(1, 'hour'),
		},
		{
			id: '3',
			title: 'ул. Завокзальная 1-я, дом 37, квартира 105',
			date: moment().add(1, 'week'),
		},
		{
			id: '4',
			title: 'ул. 15 лет октября, дом 34, квартира 291',
			date: moment().add(2, 'week'),
		},
		{
			id: '5',
			title: 'ул. Декабристов, дом 75',
			date: moment().add(3, 'week'),
		}
	]
});
