import React from 'react';
import { connect } from 'react-redux';
import InviteReceived from './InviteRceived';
import db from 'core/db';
import style from './styles/invite.module.sass';

class InviteSwitch extends React.PureComponent
{
	render()
	{
		if (!this.props.invites.length) {
			return this.props.children;
		}

		const invite = this.props.invites[0];

		return (
			<div
				className={["d-flex flex-column vh-100 w-100 align-items-center justify-content-center", style.inviteBackground].join(' ')}
			>
				<InviteReceived
					{...this.props}
					invite={invite}
				/>
			</div>
		);
	}
}

const mapToProps = (state, props) => {

	const invites = db.meInvites.list().filter(invite => invite.inviteState === null);
	const isLoading = invites.isLoading;
	const loadingTitle = 'Секундочку...';

	return {
		isLoading,
		invites,
		loadingTitle,
	};
};

export default connect(mapToProps)(InviteSwitch);
