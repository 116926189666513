class EmploeeysAggregator {

	constructor(generator)
	{
		this.generator = generator;
	}

	_filterEmploeeyByJobDate = (value, jobId, index) => {
		const generator = this.generator;
		const { jobsMap } = generator.params;

		const job = jobsMap.get(jobId);

		if (!job) {
			return 0;
		}

		const date = generator.getDateByIndex(index);
		const { estimatedStartDate, estimatedEndDate } = job;

		const isOutdated = date < estimatedStartDate || date > estimatedEndDate;

		if (isOutdated) {
			return 0;
		}

		return value;
	}

	actualNumber()
	{
		const generator = this.generator;
		const numbers = [];

		for (const values of generator.getJobSetsIterator(this._filterEmploeeyByJobDate)) {
			values.forEach((value, index) => {
				numbers[index] = (numbers[index] || 0) + value;
			});
		}

		return numbers;
	}


	plannedNumber()
	{
		const generator = this.generator;
		const { jobs } = generator.params;
		const numbers = [];

		for (const date of generator.getDatesIterator()) {

			let number = 0;

			for (const job of jobs) {
				const { estimatedStartDate, estimatedEndDate, planNumberOfWorkers } = job;

				if (!estimatedStartDate || !estimatedEndDate) {
					continue;
				}

				const isOutdated = date < estimatedStartDate || date > estimatedEndDate;

				if (!isOutdated) {
					number += planNumberOfWorkers;
				}
			}

			numbers.push(number);
		}

		return numbers;
	}

	labels() {
		const { generator } = this;
		const labels = [];

		for (const date of generator.getDatesIterator()) {
			labels.push(date);
		}

		return labels;
	}
}

export default EmploeeysAggregator;
