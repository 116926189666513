import { faRubleSign, faFileAlt, faTasks } from '@fortawesome/free-solid-svg-icons';

export const types = {
	'expense': {
		title: 'Расход',
		icon: faRubleSign,
		color: 'rgba(255,0,0,0.5)',
	},
	'act': {
		title: 'Акт',
		icon: faFileAlt,
		color: 'rgba(0, 191, 255, 0.5)'
	},
	'jobProgress': {
		title: 'Прогресс по работе',
		icon: faTasks,
		color: 'rgba(255,199,40, 0.5)',
	}
};
