
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');


export const numberFormatter = (params) => {
	const v = params.value;
	if (v === null || v === undefined) {
		return v;
	}

	return Number(v).toFixed(2);
};

export const unitsFormatter = (params, unitsMap, key) => {
	if (!unitsMap || !key) {
		return params.value;
	}

	const v = params.value;

	if (v === null || v === undefined) {
		return v;
	}

	const u = unitsMap.get(v);

	return u && u[key];
};

export const dateFormatter = params => params.value && moment(params.value).format('L');
export const longDateFormatter = params => params.value && moment(params.value).format('LL');
