import React from 'react';


class Toolbar extends React.PureComponent
{
	render()
	{
		return (
			<div className={["d-flex flex-row px-1 py-2 border-bottom", this.props.className].join(' ')}>
				{this.props.children}
			</div>
		);
	}
}

export default Toolbar;
