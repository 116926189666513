import React from 'react';
import PropTypes from 'prop-types';
import {Col} from 'react-bootstrap';
import Activity from './components/Activity';
import Notifications from './components/Notifications';

import styles from 'layouts/Default/components/ActivityDrawer/style/activity-drawer.module.sass';


class Content extends React.PureComponent
{
	render()
	{
		const tabs = {
			0: <Activity/>,
			1: <Notifications/>,
		};

		const activeIndex = this.props.activeIndex || 0;

		return (
			<Col>
				<div className={styles.activityDrawerContent}>
					{tabs[activeIndex]}
				</div>
			</Col>
		);
	}
}

Content.propTypes = {
	activeIndex: PropTypes.number.isRequired,
};


export default Content;
