import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudSun} from '@fortawesome/free-solid-svg-icons';


class WeatherCard extends React.PureComponent
{
	static propTypes = {
		tempCelsius: PropTypes.number
	}

	static defaultProps = {
		tempCelsius: 20,
	}

	render()
	{
		const style = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '12px 35px',
		};

		const tempStyle = {
			fontSize: '1.3em',
			fontWeight: 500,
		};

		const temp = `${this.props.tempCelsius}°`;

		return (
			<div style={style}>
				<FontAwesomeIcon
					style={{fontSize: '1.6em', }}
					icon={faCloudSun}
					fixedWidth
				/>
				<div style={tempStyle}>{temp}</div>
			</div>
		);
	}
}

export default WeatherCard;

