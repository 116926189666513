import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import UserCellRenderer from 'components/ag-grid-components/renderers/UserCellRenderer';
import SubtitleCellRenderer from 'components/ag-grid-components/renderers/SubtitleCellRenderer';
import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';

import { numberFormatter } from 'components/ag-grid-components/formatters';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';


const getSuffix = params => params.data.consumableSheetItem.consumableUnit.suffix;
const getSymbol = params => params.data.consumableSheetItem.priceCurrency.symbol;

export const getGridOptions = params => {

	const { cellStyle } = params;
	const priceRenderer = params => `${params.valueFormatted} ${getSymbol(params)}`;
	const amountRenderer = params => `${params.valueFormatted} ${getSuffix(params)}`;

	const statusCellRenderer = params => {
		const {
			purchaseOrderItems,
			isCanceled,
			isApproved,
			isAmountApproved,
			purchaseRequest,
		} = params.data;

		if (isCanceled) {
			return 'Отменена';
		}

		if (isApproved) {
			return 'Обработана';
		}

		if (!isAmountApproved) {
			return 'Утверждение количества';
		}

		if (purchaseOrderItems.length) {
			return 'Утверждение счёта';
		}

		if (purchaseRequest.assignedUser) {
			return 'Взята в работу';
		}

		return 'Оформлена';
	};

	const frameworkComponents = {
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: UserCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
		priceRenderer,
		amountRenderer,
		statusCellRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			width: 100,
			minWidth: 40,
			resizable: true,
			sortable: true,
			editable: false,
			suppressSizeToFit: true,
			cellStyle,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {
	const {
		isDetail,
		consumableSheet,
		onApprovePurchaseRequestItem,
		onRejectPurchaseRequestItem,
		onRejectAmount,
		itemsCheckable,
	} = params;

	const orderedRendererParams = params => {
		const { consumableSheetItem, orderedAmount } = params.data;
		const remainAmount = consumableSheetItem.amount - orderedAmount;

		return {
			subtitle: `Ост. зак.: ${Number(remainAmount < 0 ? 0 : remainAmount).toFixed(2)} ${getSuffix(params)}`,
			valueFormatter: value => `${value} ${getSuffix(params)}`,
		};
	};

	const titleRendererParams = params => {
		const item = params.data.consumableSheetItem;

		return {
			subtitle: `${item.partNumber} / ${item.manufacturerName}`
		};
	};

	const withCurrency = (value, currencyId) => {
		const rates = consumableSheet.currencyRates;
		const rate = rates[currencyId];

		return rate && rate * value || value;
	};

	const getPlanCost = params => {
		const item = params.data.consumableSheetItem;

		const { planPrice, retailPrice, retailDiscountPercents } = item;
		const disc = 1 - (retailDiscountPercents / 100);
		const price = planPrice || retailPrice * disc || retailPrice || 0;

		return params.data.amount * price;
	};

	const getActualCost = params => {
		const purchaseOrderItem = params.data.purchaseOrderItems[0];
		const { amountProposed, amount, priceActual } = purchaseOrderItem;
		const price = (amountProposed || amount) * priceActual;

		return price || 0;
	};

	const approveAction = {
		icon: faCheck,
		hoverStyle: { color: 'green' },
		onClick: onApprovePurchaseRequestItem,
		tooltip: 'Утвердить заявку',
	};

	const rejectAction = {
		icon: faTimes,
		hoverStyle: { color: 'red' },
		onClick: onRejectPurchaseRequestItem,
		tooltip: 'Отклонить заявку',
	};

	const approveAmountAction = {
		icon: faCheck,
		hoverStyle: { color: 'green' },
		onClick: onRejectAmount,
		tooltip: 'Утвердить количество',
	};

	const getProductManagerActions = params => {
		const { isApproved, isCanceled } = params.data;

		if (isCanceled) {
			return [];
		}

		const actions = [];

		if (!isApproved) {
			actions.push(approveAction);
		}

		actions.push(rejectAction);

		return actions;
	};

	const getApproveAmountActions = params => {
		const { isAmountApproved } = params.data;
		const actions = [];

		if (!isAmountApproved) {
			actions.push(approveAmountAction);
		}

		return actions;
	};

	const checkboxColumn = {
		checkboxSelection: true,
		maxWidth: 40,
		minWidth: 40,
		width: 40,
	};

	const colDefs = [
		{
			headerName: 'Статус',
			field: 'status',
			suppressSizeToFit: false,
		},
		{
			headerName: 'Наименование',
			field: 'consumableSheetItem.title',
			sort: 'asc',
			suppressSizeToFit: false,
			cellRendererParams: titleRendererParams,
		},
		{
			headerName: 'Заказано',
			field: 'orderedAmount',
			width: 150,
			valueFormatter: numberFormatter,
			cellRendererParams: orderedRendererParams,
		},
		{
			headerName: 'Количество',
			field: 'amount',
			width: 150,
			valueFormatter: params => `${params.value} ${getSuffix(params)}`,
			cellRendererParams: params => {
				return {
					actions: getApproveAmountActions(params),
					title: params.valueFormatted,
				};
			},
			editable: true,
		},
		{
			headerName: 'Плановая стоимость',
			field: 'planCost',
			valueFormatter: numberFormatter,
			valueGetter: params => withCurrency(getPlanCost(params), params.data.consumableSheetItem.priceCurrencyId),
		},
		{
			headerName: 'Фактическая стоимость',
			field: 'actualCost',
			valueFormatter: numberFormatter,
			valueGetter: params => params.data.purchaseOrderItems[0] && withCurrency(getActualCost(params), params.data.consumableSheetItem.priceCurrencyId),
		},
		{
			headerName: 'Примечание',
			field: 'notes',
			editable: true,
			cellEditor: 'agLargeTextCellEditor'
		},
		{
			headerName: '№ Заявки',
			field: 'purchaseRequest.sn',
			valueFormatter: params => `Заявка №${params.value}`,
		},
		{
			headerName: 'Логист',
			field: 'purchaseRequest.assignedUser',
		},
		{
			headerName: '',
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: params => {
				return {
					actions: getProductManagerActions(params)
				};
			},
		},
	];

	if (itemsCheckable) {
		colDefs.unshift(checkboxColumn);
	}

	const renderersMap = {
		'consumableSheetItem.title': isDetail ? null : { component: 'subtitleCellRenderer' },
		'orderedAmount': { component: 'subtitleCellRenderer' },
		'amount': { component: 'actionsCellRenderer' },
		'planCost': { component: 'priceRenderer' },
		'actualCost': { component: 'priceRenderer' },
		'purchaseRequest.assignedUser': { component: 'userCellRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			if (column.field === 'status') {
				column.cellRenderer = 'statusCellRenderer';
			} else {
				column.cellRendererSelector = params => getRendererSelector(params, column);
			}
		}

		return column;
	});
};
