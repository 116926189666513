import NumberCellEditor from 'components/ag-grid-components/editors/NumberCellEditor';
import UnitsCellEditor from 'components/ag-grid-components/editors/UnitsCellEditor';
import RequiredCellRenderer from 'components/ag-grid-components/renderers/RequiredCellRenderer';
import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import CurrencyCellEditor from './components/CurrencyCellEditor';
import SubtitleCellRenderer from 'components/ag-grid-components/renderers/SubtitleCellRenderer';

import { unitsFormatter, numberFormatter } from 'components/ag-grid-components/formatters';

import {
	retailPriceGetter,
	planPriceGetter,
	retailCostGetter,
	planCostGetter,
	customerPriceGetter,
	customerCostGetter,
	margeValueGetter,
	withCurrency,
} from './getters';

import {
	percentCellRenderer,
	priceCellRenderer,
	userCellRenderer,
	defaultCurrencyCellRenderer,
	daysCellRenderer,
} from 'components/ag-grid-components/renderers';

export const getGridOptions = params => {
	const {
		usersMap,
		currenciesMap,
		me,
		consumableSheet,
	} = params;

	const userRenderer = params => userCellRenderer({ params, usersMap });
	const priceRenderer = params => priceCellRenderer({ params, currenciesMap });

	const frameworkComponents = {
		numberCellEditor: NumberCellEditor,
		unitsCellEditor: UnitsCellEditor,
		currencyCellEditor: CurrencyCellEditor,
		requiredCellRenderer: RequiredCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: userRenderer,
		priceCellRenderer: priceRenderer,
		percentCellRenderer: percentCellRenderer,
		defaultCurrencyCellRenderer: defaultCurrencyCellRenderer,
		daysCellRenderer: daysCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
		// selectionFilter: SelectionFilter,
		// jobAssignedFilter: JobAssignedFilter,
	};

	const isEditable = params => params.data.lockedByUserId && params.data.lockedByUserId === me.id;

	const getRowStyle = params => {

		if (isEditable(params) || consumableSheet.isApproved) {
			return null;
		}

		return {
			backgroundColor: 'rgba(0,0,0,0.04)',
			color: 'rgba(0,0,0,0.4)',
		};
	};

	const gridOptions = {
		getRowStyle: getRowStyle,
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			width: 120,
			minWidth: 40,
			resizable: true,
			sortable: true,
		}
	};

	return gridOptions;
};

export const requiredFields = [
	'amount',
	'consumableUnitId',
	'retailPrice',
	'planPrice',
	'planDeliveryDurationDays',
	'supplierTitle',
];

export const getColumnDefs = params => {

	const {
		currencies,
		onCurrencyCellValuesChanged,
		consumableUnits,
		consumableUnitsMap,
		me,
		consumableSheet,
	} = params;

	const { isApproved } = consumableSheet;

	const isEditable = params => params.data.lockedByUserId && params.data.lockedByUserId === me.id;

	const margeCellStyle = (params) => {
		if (Number(params.value) === 0) {
			return null;
		}

		return {color: params.value > 0 ? 'green' : 'red'};
	};

	const titleRendererParams = params => {
		const data = params.data;

		return {
			subtitle: `${data.partNumber} / ${data.manufacturerName}`
		};
	};

	const defs = [
		{
			checkboxSelection: true,
			maxWidth: 40,
			minWidth: 40,
			width: 40,
			pinned: 'left',
			headerCheckboxSelection: true,
		},
		{
			headerName: 'Наименование',
			field: 'title',
			width: 400,
			sort: 'asc',
			pinned: 'left',
			cellRendererParams: titleRendererParams,
		},
		{
			headerName: 'Взял в работу',
			field: 'lockedByUserId',
			width: 120,
			hide: isApproved,
			// filter: 'jobAssignedFilter',
		},
		// {
		// 	headerName: 'Состав ИС',
		// 	field: 'sourceSpecificationsIds',
		// 	// filter: "selectionFilter",
		// },
		{
			headerName: 'Количество',
			field: 'amount',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", step: '0.01' } },
		},
		{
			headerName: 'Ед. изи.',
			field: 'consumableUnitId',
			width: 100,
			editable: isEditable,
			cellEditor: 'unitsCellEditor',
			cellEditorParams: { units: consumableUnits, valueField: 'suffix'},
			valueFormatter: params => unitsFormatter(params, consumableUnitsMap, 'suffix'),
		},
		{
			headerName: 'Розничная цена',
			field: 'retailPrice',
			editable: isEditable,
			valueGetter: retailPriceGetter,
			valueFormatter: numberFormatter,
			cellEditor: 'currencyCellEditor',
			cellEditorParams: { currencies, onCurrencyCellValuesChanged },
		},
		{
			headerName: 'Плановая цена',
			field: 'planPrice',
			editable: isEditable,
			valueGetter: planPriceGetter,
			valueFormatter: numberFormatter,
			cellEditor: 'currencyCellEditor',
			cellEditorParams: { currencies, onCurrencyCellValuesChanged },
		},
		{
			headerName: 'Скидка от розницы',
			field: 'retailDiscountPercents',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", max: "100" } },
		},
		{
			headerName: 'Розничная стоимость',
			field: 'retailCost',
			valueGetter: params => withCurrency(params, retailCostGetter),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Плановая стоимость',
			field: 'planCost',
			valueGetter: params => withCurrency(params, planCostGetter),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Плановый срок поставки',
			field: 'planDeliveryDurationDays',
			editable: isEditable,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", step: "1" } },
		},
		{
			headerName: 'Поставщик',
			field: 'supplierTitle',
			editable: isEditable,
			width: 200,
		},
		{
			headerName: 'Примечание',
			field: 'notes',
			editable: isEditable,
			cellEditor: 'agLargeTextCellEditor',
			width: 200,
		},
		{
			headerName: 'Уменьшение розницы / Увеличение входа (- / +)',
			field: 'priceCorrectionPercents',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "-100" } },
		},
		{
			headerName: 'Цена для заказчика',
			field: 'customerPrice',
			valueGetter: customerPriceGetter,
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Цена в договоре',
			field: 'planPriceOverride',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'currencyCellEditor',
			cellEditorParams: { currencies, onCurrencyCellValuesChanged },
		},
		{
			headerName: 'Стоимость в договоре',
			field: 'customerCost',
			valueGetter: params => withCurrency(params, customerCostGetter),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Маржа',
			field: 'marge',
			valueGetter: margeValueGetter,
			valueFormatter: numberFormatter,
			cellStyle: margeCellStyle,
		},
	];

	const renderersMap = {
		'lockedByUserId': { component: 'userCellRenderer' },
		'retailPrice': { component: 'priceCellRenderer' },
		'planPrice': { component: 'priceCellRenderer' },
		'retailDiscountPercents': { component: 'percentCellRenderer' },
		'retailCost': { component: 'defaultCurrencyCellRenderer' },
		'planCost': { component: 'defaultCurrencyCellRenderer' },
		'priceCorrectionPercents': { component: 'percentCellRenderer' },
		'customerPrice': { component: 'priceCellRenderer' },
		'planPriceOverride': { component: 'priceCellRenderer' },
		'customerCost': { component: 'defaultCurrencyCellRenderer' },
		'marge': { component: 'percentCellRenderer' },
		'planDeliveryDurationDays': { component: 'daysCellRenderer' },
		'title': { component: 'subtitleCellRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;
		const isRequiredColumn = requiredFields.includes(column.field);

		if (isValueUndefined) {
			if (isRequiredColumn && isEditable(params)) {
				return {
					component: 'requiredCellRenderer',
					params: { requiredTitle: '-' },
				};
			} else {
				return { component: 'emptyCellRenderer'};
			}
		}

		return renderersMap[column.field] || null;
	};

	return defs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		if (isApproved) {
			column.editable = false;
		}

		return column;
	});
};
