import DetailCellRenderer from './components/DetailCellRenderer';
import AttachedFilesCellRenderer from 'components/ag-grid-components/renderers/AttachedFilesCellRenderer';
import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import UnitsCellEditor from 'components/ag-grid-components/editors/UnitsCellEditor';
import DateCellEditor from 'components/ag-grid-components/editors/DateCellEditor';
import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

import { faFileExcel, faDollarSign, faShare } from '@fortawesome/free-solid-svg-icons';


export const getGridOptions = params => {

	const { currenciesMap } = params;


	const dateRenderer = params => params.value && moment(params.value).format('L');
	const currencyRenderer = params => {
		const cur = currenciesMap.get(params.value);

		return `${cur.title} (${cur.symbol})`;
	};

	const statusRenderer = params => {

		const { purchaseRequestItems, isPayed } = params.data;

		if (isPayed) {
			return 'В доставке';
		}

		const isApproved = purchaseRequestItems.every(i => i.isApproved && !i.isCanceled);

		if (isApproved) {
			return 'На оплате';
		}

		return 'В работе';
	};

	const frameworkComponents = {
		detailCellRenderer: DetailCellRenderer,
		attachedFilesCellRenderer: AttachedFilesCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		unitsCellEditor: UnitsCellEditor,
		dateCellEditor: DateCellEditor,
		actionsCellRenderer: ActionsCellRenderer,
		dateRenderer,
		currencyRenderer,
		statusRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			width: 120,
			resizable: true,
			sortable: true,
			editable: false,
			suppressSizeToFit: true,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params =>
{
	const { currencies, onPayInvoice, onPlaceInvoice } = params;

	const titleFormatter = params => `Заказ №${params.value}`;

	const invoiceFileRendererParams = params => {
		const attachments = params.value || [];

		return {
			itemsList: attachments.map(attach => {
				return {
					file: attach,
					icon: faFileExcel,
					iconStyle: { color: 'green' },
					noText: true,
				};
			})
		};
	};

	const payAction = {
		icon: faDollarSign,
		hoverStyle: { color: 'green' },
		onClick: onPayInvoice,
	};

	const placeAction = {
		icon: faShare,
		hoverStyle: { color: 'green' },
		onClick: onPlaceInvoice,
	};

	const getPayActions = params => {
		const { isPayed } = params.data;

		if (isPayed) {
			return [];
		}

		return [payAction];
	};


	const getPlaceActions = params => {
		const { isPlaced } = params.data;

		if (isPlaced) {
			return [];
		}

		return [placeAction];
	};

	const colDefs = [
		{
			headerName: 'Номер заказа',
			field: 'sn',
			sort: 'asc',
			suppressSizeToFit: false,
			valueFormatter: titleFormatter,
		},
		{
			headerName: 'Статус',
			field: 'status',
			suppressSizeToFit: false,
		},
		{
			headerName: 'Номер счёта',
			field: 'invoiceNumber',
			editable: true,
		},
		{
			headerName: 'Дата счёта',
			field: 'invoiceDate',
			editable: true,
			cellEditor: 'dateCellEditor',
		},
		{
			headerName: 'Поставщик',
			field: 'supplierTitle',
			editable: true,
		},
		{
			headerName: 'Плановая дата доставки',
			field: 'deliveryDate',
			minWidth: 90,
			editable: true,
			cellEditor: 'dateCellEditor',
		},
		{
			headerName: 'Валюта',
			field: 'currencyId',
			cellEditor: 'unitsCellEditor',
			cellEditorParams: { units: currencies, valueField: 'title'},
			editable: true,
		},
		{
			headerName: 'Условия оплаты',
			field: 'paymentTermsNotes',
			editable: true,
			cellEditor: 'agLargeTextCellEditor',
		},
		{
			headerName: 'Файл счёта',
			field: 'attachments',
			cellRendererParams: invoiceFileRendererParams,
		},
		{
			headerName: 'Оплата',
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: params => {
				return {
					actions: getPayActions(params),
					title: params.data.isPayed ? 'Оплачен' : 'Оплатить',
				};
			}
		},
		{
			headerName: 'Размещение',
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: params => {
				return {
					actions: getPlaceActions(params),
					title: params.data.isPlaced ? 'Размещён' : 'Разместить',
				};
			}
		}
	];

	const renderersMap = {
		'sn': { component: 'agGroupCellRenderer' },
		'attachments': { component: 'attachedFilesCellRenderer' },
		'invoiceDate': { component: 'dateRenderer' },
		'deliveryDate': { component: 'dateRenderer' },
		'currencyId': { component: 'currencyRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (!column.field) {
			return column;
		}

		if (column.field === 'status') {
			column.cellRenderer = 'statusRenderer';
		} else {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
