import EmploeeysAggregator from "./EmploeeysAggregator";
import ReportGenerator from "./ReportGenerator";
import ReportGeneratorLast from "./ReportGeneratorLast";
import ProgressAggregator from "./ProgressAggregator";

class ReportAggregator {

	constructor(params)
	{
		this.params = params;
	}

	emploeeys()
	{
		return new EmploeeysAggregator(new ReportGenerator(this.params));
	}

	progress()
	{
		return {
			last: () => new ProgressAggregator(new ReportGeneratorLast(this.params)),
			delta: () => new ProgressAggregator(new ReportGenerator(this.params)),
		};
	}
}

export default ReportAggregator;
