
import React from 'react';
import PropTypes from 'prop-types';
import { getColumnDefs, getGridOptions } from './defs';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';


class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		agGridProps: PropTypes.object,
		cellStyle: PropTypes.object,
		isDetail: PropTypes.bool,
		currenciesMap: PropTypes.any,
		itemsEditable: PropTypes.bool,
	};

	static defaultProps = {
		items: [],
		agGridProps: {},
		cellStyle: {},
		isDetail: false,
		itemsEditable: true,
	}

	render()
	{
		let columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		if (this.props.isDetail) {
			columnDefs = columnDefs.filter(col => col.field && !col.field.startsWith('purchaseOrder.'));
		}

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				suppressRowClickSelection={true}
				onCellValueChanged={this.props.onCellValueChanged}
				{...this.props.agGridProps}
			/>
		);
	}
}

export default Grid;

