import React from 'react';
import PropTypes from 'prop-types';
import api from 'core/api';
import { toast } from 'react-toastify';

import ButtonToolbar from 'components/ButtonToolbar';
import Grid from './components/Grid';
import { validationFields } from './components/Grid/defs';
import { isValid } from './helpers/validators';

import moment from 'moment';

import style from '../../styles/edit-view.module.sass';


class EditView extends React.PureComponent
{
	static propTypes = {
		projectId: PropTypes.string.isRequired,
		items: PropTypes.arrayOf(PropTypes.object),
		onConfirmImport: PropTypes.func,
	};

	static defaultProps = {
		items: []
	};

	state = {
		selectedNodes: [],
		btnImportDisabled: false,
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onRemoveNodes = (nodes) => {
		this.gridApi.updateRowData({ remove: nodes.map(node => node.data) });
	}

	createJobs = (jobList) => {

		const stageRequest = api.stages().create({
			projectId: this.props.projectId,
			title: 'Новый этап',
		});

		const stageId = stageRequest.props.stageId;

		const jobRequests = jobList.map(job => {
			return api.jobs().create({
				stageId,
				projectId: this.props.projectId,
				...job,
				estimatedStartDate: moment(job.estimatedStartDate).format("YYYY-MM-DD"),
				estimatedEndDate: moment(job.estimatedEndDate).format("YYYY-MM-DD"),
			});
		});

		const transactionBody = [stageRequest, ...jobRequests];

		return this.props.dispatch(api.transaction().execute(transactionBody));
	}

	onConfirmImport = () => {
		if (this.hasUnfilledReqiuredCells()) {
			toast("Необходимо заполнить все поля", {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
			});

			return;
		}

		const model = this.gridApi.getModel();
		const nodes = model.rootNode.allLeafChildren;
		const jobList = nodes.map(node => {
			const data = node.data;
			delete data.index;

			return data;
		});

		this.setState({ btnImportDisabled: true });

		this.gridApi.showLoadingOverlay();
		this.createJobs(jobList)
			.then(() => {
				this.props.onConfirmImport();
			})
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось импортировать список работ", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
					draggable: false,
				});
			})
			.finally(() => {
				this.setState({btnImportDisabled: false});
				this.gridApi.hideOverlay();
			})
		;
	}

	hasUnfilledReqiuredCells = () => {
		if (!this.gridApi) {
			return true;
		}

		const model = this.gridApi.getModel();
		const nodes = model.rootNode.allLeafChildren;

		for (const node of nodes) {
			const data = node.data;
			for (const key of Array.from(Object.keys(data))) {
				if (validationFields.includes(key) && !isValid(data[key], key)) {

					return true;
				}
			}
		}

		return false;
	}

	render()
	{
		return (
			<div className={style.editViewWrapper}>
				<Grid
					{...this.props}
					onGridReady={this.onGridReady}
					onCreateNewStage={this.onCreateNewStage}
					onMoveToStage={this.onMoveToStage}
					onRemoveNodes={this.onRemoveNodes}
				/>
				<ButtonToolbar
					style={{
						marginTop: '10px',
						marginBottom: '10px',
					}}
					btnList={[
						{
							title: 'Импортировать',
							props: {
								onClick: this.onConfirmImport,
								disabled: this.state.btnImportDisabled,
							}
						}
					]}
				/>
			</div>
		);
	}
}

export default EditView;

