import React from 'react';

import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';

import style from 'components/ag-grid-components/styles/custom-cell.module.sass';
import 'react-datepicker/dist/react-datepicker.css';
import ru from "date-fns/locale/ru";

import moment from 'moment';


class DateCellEditor extends React.PureComponent
{

	state = {
		date: undefined,
	}

	componentDidMount()
	{
		this.datePickerRef.setOpen(true);

		const value = this.props.value;

		if (value) {
			this.setState({ date: moment(value).toDate()});
		}
	}

	getValue() {
		return moment(this.state.date).format('YYYY-MM-DD');
	}

	isPopup()
	{
		return false;
	}

	isCancelBeforeStart()
	{
		return false;
	}

	isCancelAfterEnd()
	{
		return !this.state.date;
	}

	render()
	{
		const onChange = date => {
			this.setState({ date }, () => this.props.api.stopEditing());
		};

		const container = ({children}) => {
			return (
				<Portal>
					{children}
				</Portal>
			);
		};

		return (
			<div
				className={style.wrapper}
				style={{padding: 0}}
			>
				<DatePicker
					ref={ref => (this.datePickerRef = ref)}
					style={{
						height: '90%',
						flex: 1,
						margin: '0 2px',
					}}
					popperContainer={container}
					onChange={onChange}
					selected={this.state.date}
					locale={ru}
					dateFormat="P"
				/>
			</div>
		);
	}
}

export default DateCellEditor;
