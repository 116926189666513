import React from 'react';
import moment from 'moment';


class ReportCellRenderer extends React.PureComponent
{
	render()
	{
		const isGroup = this.props.node.group;

		if (!isGroup) {
			return null;
		}

		const children = this.props.node.allLeafChildren;

		if (!children.length) {
			return null;
		}

		const firstChild = children[0];
		const value = firstChild.data.lastReport.date;
		const warningStyle = { color: '#e13200' };
		const okStyle = { color: '#28a745' };

		if (!value) {
			return <div style={warningStyle}>нет</div>;
		}

		const now = moment();
		const lastDateMoment = moment(value);
		const diff = now.diff(lastDateMoment, 'days');

		if (diff) {
			return <div style={warningStyle}>{`${lastDateMoment.from(now)}`}</div>;
		} else {
			return <div style={okStyle}>сегодня</div>;
		}
	}
}

export default ReportCellRenderer;
