import React from 'react';
import { Form, Button } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import api from 'core/api';

import styles from '../styles/signin.module.sass';

class AuthForm extends React.PureComponent
{
	state = {
		phoneNumber: '',
		smsCode: '',
		smsSent: false,
		smsSentCount: 0,
		smsCodeError: false,
		smsTimeoutSeconds: 0,
		loading: false,
		registered: false,
	};

	componentWillUnmount()
	{
		this.clearSmsTimer();
	}

	startSmsTimer = () => {
		const smsTimer = setInterval(() => {
			const tm = this.state.smsTimeoutSeconds;
			if (tm > 0) {
				this.setState({smsTimeoutSeconds: tm - 1});
			} else {
				this.clearSmsTimer();
			}
		}, 1000);

		this.setState({
			smsTimeoutSeconds: 30,
			smsTimer: smsTimer,
		});
	}

	clearSmsTimer = () => {
		clearInterval(this.state.smsTimer);
	}

	resetSms = () => {
		this.setState({
			smsSent: false,
			smsCode: '',
		});
	}

	sendSms = () => {
		if (this.state.smsTimeoutSeconds > 0) {
			return;
		}

		this.setState({smsSentCount: this.state.smsSentCount + 1});

		this.setState({loading: true});
		this.props.dispatch(api.authentication().sendSmsCode({phoneNumber: this.state.phoneNumber}))
			.then(() => {
				this.setState({
					smsSent: true,
				});
			})
			.then(() => this.props.dispatch(api.authentication().isRegistered({phoneNumber: this.state.phoneNumber})))
			.then((res) => {
				const registered = res.registered;
				const policyConfirmed = !registered;
				this.setState({registered, policyConfirmed});
			})
			.then(() => {
				this.refs.smsInput.focus();
			})
			.then(() => {
				if (this.state.smsSentCount > 3) {
					this.startSmsTimer();
				}
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	register = () => {
		const params = {
			phoneNumber: this.state.phoneNumber,
			smsCode: this.state.smsCode,
		};

		const reg = api.authentication().register(params);

		this.setState({loading: true});
		this.props.dispatch(reg).then((r) => {
			this.props.dispatch({
				type: 'LOGIN_WITH_TOKEN',
				token: r.token
			});
		})
			.catch(() => {
				this.setState({
					smsCodeError: true,
				});
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	login = () => {
		const params = {
			phoneNumber: this.state.phoneNumber,
			smsCode: this.state.smsCode,
		};

		const login = api.authentication().login(params);

		this.setState({loading: true});
		this.props.dispatch(login).then((r) => {
			this.props.dispatch({
				type: 'LOGIN_WITH_TOKEN',
				token: r.token
			});
		})
			.catch(() => {
				this.setState({
					smsCodeError: true,
				});
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	onPhoneChange = e => {
		if (this.state.smsSent) {
			this.resetSms();
		}

		this.setState({
			phoneNumber: e.target.value.replace(/[^0-9]/g, ''),
		});
	}

	onSmsCodeChange = e => {
		const value = e.target.value.replace(/[^0-9]/g, '');

		this.setState({
			smsCode: value,
		}, () => {
			if (value.length === 4 && this.state.registered) {
				this.login();
			}
		});
	}

	onPolicyChanged = e => {
		const policyConfirmed = e.target.checked;
		this.setState({policyConfirmed});
	}

	onSubmit = (event) => {
		if (this.state.smsSent) {
			if (this.state.registered) {
				this.login();
			} else {
				this.register();
			}
		} else {
			this.sendSms();
		}

		event.preventDefault();
	}

	isSubmitDisabled = () => {
		const smsCodeValid = this.state.smsCode.length === 4;
		const phoneNumberValid = this.state.phoneNumber.match(/^[0-9]{11}$/);

		if (this.state.loading) {
			return true;
		}

		if (this.state.smsSent) {

			if (!smsCodeValid) {
				return true;
			}
		} else {
			return !phoneNumberValid || this.state.smsTimeoutSeconds;
		}

		return false;
	}

	render()
	{
		const submitText = this.state.smsSent ? 'Войти' : 'Отправить код';
		const submitDisabled = this.isSubmitDisabled();

		const resendSmsLabel = <Form.Text
			style={{
				textDecoration: 'underline',
				cursor: this.state.smsSent ? 'pointer' : 'default',
				opacity: this.state.smsSent ? 1.0 : 0.0,
				textAlign: 'center'
			}}
			onClick={this.sendSms}
			disabled={this.state.loading || !this.state.smsSent}
		>
		Отправить ещё раз
		</Form.Text>;

		const smsTimeoutLabel = <Form.Text
			style={{ textAlign: 'center' }}
		>
			{`Можно отправить серез ${this.state.smsTimeoutSeconds} сек.`}
		</Form.Text>;


		return (
			<Form>
				<Form.Group controlId="phone_number" style={{ width: '100%' }}>
					<Form.Label>Номер телефона</Form.Label>
					<MaskedFormControl
						type="phone"
						placeholder="+7 (xxx) xxx-xx-xx"
						mask="+7 (111) 111-11-11"
						onChange={this.onPhoneChange}
					/>
				</Form.Group>

				<Form.Group controlId="code" style={{width: '100%'}}>
					<Form.Label>Код из SMS</Form.Label>
					<Form.Control
						ref="smsInput"
						disabled={!this.state.smsSent}
						type="text"
						placeholder=""
						onChange={this.onSmsCodeChange}
						value={this.state.smsCode}
						isInvalid={this.state.smsCodeError}
					/>
				</Form.Group>

				<p className="text-thin">Нажимая на кнопку ниже я принимаю условия пользовательского соглашения.</p>

				<Button type="submit" disabled={submitDisabled} onClick={this.onSubmit} className={styles.signin}>
					{submitText}
				</Button>
				{this.state.smsTimeoutSeconds ? smsTimeoutLabel : resendSmsLabel}
			</Form>
		);
	}
}

export default AuthForm;
