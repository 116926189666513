import React from 'react';

class EmploeeysCellRenderer extends React.PureComponent
{
	render()
	{
		const data = this.props.node.data;

		if (!data) {
			return null;
		}

		const emploeeysPlannedCount = Number(data.emploeeysPlannedCount);
		const emploeeysCount = Number(data.emploeeysCount);
		const isLack = emploeeysCount < emploeeysPlannedCount;

		return (
			<div style={{color: isLack ? '#e13200' : '#65b43a'}}>
				{`${emploeeysCount}/${emploeeysPlannedCount}`}
			</div>
		);
	}
}

export default EmploeeysCellRenderer;
