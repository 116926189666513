import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/free-solid-svg-icons';

import style from 'components/ag-grid-components/styles/custom-cell.module.sass';


class EmployeeCellRenderer extends React.PureComponent
{

	getInviteStateComponent = (employee) => {

		if (employee.isActive) {
			return null;
		}

		if (employee.inviteState === 'accepted' && employee.deactivationTime) {
			return (
				<div style={{color: 'rgb(225, 50, 0)'}}>
					Деактивирован
				</div>
			);
		}

		if (employee.inviteState === 'declined') {
			return (
				<div style={{color: 'rgb(225, 50, 0)'}}>
					Приглашение откллонено
				</div>
			);
		}

		if (!employee.inviteState) {
			return (
				<div style={{color: '#ffc728'}}>
					Приглашение отправлено
				</div>
			);
		}

		return null;
	}

	render()
	{
		const userIcon = <FontAwesomeIcon
			fixedWidth
			icon={faUser}
		/>;

		const iconWrapperStyle = {
			height: '28px',
			width: '28px',
			backgroundColor: 'rgba(0,0,0,0.1)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '15px',
			marginRight: '5px',
			marginLeft: '5px',
		};

		const badgeStyle = {
			display: 'flex',
			flexDirection: 'center',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '15px',
			marginLeft: '5px',
			backgroundColor: 'rgba(0,0,0,0.1)',
			padding: '10px',
			height: '70%',
		};

		const employee = this.props.data;
		const user = employee.user;

		const roles = (employee.roles || []).map(role => {
			const closeIcon = <FontAwesomeIcon
				size="sm"
				icon={faTimes}
				onClick={() => this.props.onRemoveRole(employee, role)}
				style={{cursor: 'pointer', marginLeft: '5px'}}
			/>;

			return (
				<div style={badgeStyle} key={role.id}>
					{role.title}
					{closeIcon}
				</div>
			);
		});

		const inviteStateComponent = this.getInviteStateComponent(employee);

		return (
			<div className={style.wrapper}>
				<div style={iconWrapperStyle}>
					{userIcon}
				</div>
				<div style={{marginRight: '5px'}}>{user.fullName || user.phoneNumber}</div>
				{inviteStateComponent || roles}
			</div>
		);
	}
}

export default EmployeeCellRenderer;
