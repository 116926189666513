import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';


class SourceSpecsFilter extends React.PureComponent
{
	static propTypes = {
		sourceSpecs: PropTypes.arrayOf(PropTypes.object),
		onChange: PropTypes.func,
	};

	static defaultProps = {
		sourceSpecs: [],
	};

	state = {
		sourceSpecsIdsSet: new Set(),
	}

	onChange = (checked, spec) => {
		const id = spec.id;
		const sourceSpecsIdsSet = new Set(this.state.sourceSpecsIdsSet);

		if (checked) {
			sourceSpecsIdsSet.add(id);
		} else {
			sourceSpecsIdsSet.delete(id);
		}

		this.setState({sourceSpecsIdsSet});

		this.props.onChange(Array.from(sourceSpecsIdsSet.values()));
	}

	render() {
		return (
			<div
				className={["d-flex flex-row align-items-center mx-3", (this.props.className || '')].join(' ')}
			>
				{this.props.sourceSpecs.map((sourceSpec, index) => {
					return (
						<Form.Group className="mr-2 mb-0" key={index}>
							<Form.Check
								label={sourceSpec.title}
								onChange={ (e) => this.onChange(e.target.checked, sourceSpec) }
							/>
						</Form.Group>
					);
				})}
			</div>
		);
	}
}

export default SourceSpecsFilter;
