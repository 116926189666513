class NotParsedRow
{
	/**
	 * @type {number}
	 */
	index;

	/**
	 * @type {Array}
	 */
	data;

	/**
	 *
	 * @param {number} index
	 * @param {Array} data
	 */
	constructor(index, data)
	{
		this.index = index;
		this.data = data;
	}
}

export default NotParsedRow;
