class RequestItemsAggregator
{
	constructor(params)
	{
		this.params = params;
		this._getItemsMapByConsumableItemId();
		this._getItemsMapByPurchaseRequestId();
		this._getPurchaseOrderItemsMapByRequestItemId();
	}

	_getItemsMapByConsumableItemId = () => {
		const { purchaseRequestItems } = this.params;
		const map = new Map();

		for (const item of purchaseRequestItems) {
			const id = item.consumableSheetItemId;
			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(item);
			} else {
				map.set(id, [item]);
			}
		}

		this.itemsMapByConsumableItemId = map;
	};

	_getItemsMapByPurchaseRequestId = () => {

		const { purchaseRequestItems } = this.params;

		const map = new Map();

		for (const item of purchaseRequestItems) {
			const id = item.purchaseRequestId;
			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(item);
			} else {
				map.set(id, [item]);
			}
		}

		this.itemsMapByPurchaseRequestId = map;
	};

	_getPurchaseOrderItemsMapByRequestItemId = () => {

		const { purchaseOrderItems } = this.params;

		const map = new Map();

		for (const item of purchaseOrderItems) {
			const id = item.purchaseRequestItemId;

			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(item);
			} else {
				map.set(id, [item]);
			}
		}

		this.purchaseOrderItemsMapByRequestItemId = map;
	};

	getGridItems = () => {
		const {
			purchaseRequestItems,
			consumableSheetItemsMap,
			purchaseRequestsMap,
		} = this.params;

		const {
			itemsMapByConsumableItemId,
			itemsMapByPurchaseRequestId,
			purchaseOrderItemsMapByRequestItemId,
		} = this;

		return purchaseRequestItems
			.filter(item => purchaseRequestsMap.get(item.purchaseRequestId))
			.map(item => {

				const ordererdItems = itemsMapByConsumableItemId.get(item.consumableSheetItemId) || [];

				const orderedAmount = ordererdItems
					.filter(i => !i.isCanceled)
					.reduce((acc, item) => {
						return acc + Number(item.amount);
					}, 0)
				;
				const purchaseRequest = purchaseRequestsMap.get(item.purchaseRequestId) || {};
				const purchaseRequestItems = itemsMapByPurchaseRequestId.get(purchaseRequest.id) || [];
				const purchaseOrderItems = purchaseOrderItemsMapByRequestItemId.get(item.id) || [];
				const consumableSheetItem = consumableSheetItemsMap.get(item.consumableSheetItemId) || {};

				return {
					...item,
					purchaseRequest,
					purchaseRequestItems,
					purchaseOrderItems,
					orderedAmount,
					consumableSheetItem,
				};
			})
		;
	};

	getGroupGridItems = () => {
		const {
			purchaseRequests,
			consumableSheetItemsMap,
		} = this.params;

		const {
			itemsMapByPurchaseRequestId,
			itemsMapByConsumableItemId,
			purchaseOrderItemsMapByRequestItemId,
		} = this;

		return purchaseRequests.map(pr => {

			const purchaseRequestItems = (itemsMapByPurchaseRequestId.get(pr.id) || [])
				.map(pri => {

					const ordererdItems = itemsMapByConsumableItemId.get(pri.consumableSheetItemId) || [];
					const purchaseOrderItems = purchaseOrderItemsMapByRequestItemId.get(pri.id) || [];
					const consumableSheetItem = consumableSheetItemsMap.get(pri.consumableSheetItemId) || {};
					const orderedAmount = ordererdItems
						.filter(i => !i.isCanceled)
						.reduce((acc, item) => {
							return acc + Number(item.amount);
						}, 0)
					;

					return {
						...pri,
						purchaseOrderItems,
						consumableSheetItemsMap,
						consumableSheetItem,
						orderedAmount,
					};
				})
			;

			const purchaseOrderItems = purchaseRequestItems.reduce((acc, i) => {
				return acc.concat(i.purchaseOrderItems);
			}, []);

			return {
				...pr,
				purchaseRequestItems,
				purchaseOrderItems,
			};
		});
	};
}

export default RequestItemsAggregator;
