import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';


class TagToolbar extends React.PureComponent
{
	static propTypes = {
		tagList: PropTypes.arrayOf(PropTypes.object),
		style: PropTypes.object,
		className: PropTypes.string
	};

	static defaultProps = {
		tagList: [],
	};


	render() {
		const classNames = ["d-flex flex-row align-items-center", (this.props.className || '')];

		return (
			<div className={classNames.join(' ')}>
				{this.props.tagList.map((tag, index) => {
					return (
						<Badge key={index} pill variant={tag.variant} className="mr-1">
							{`${tag.title}`}
						</Badge>
					);
				})}
			</div>
		);
	}
}

export default TagToolbar;
