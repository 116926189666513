import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid';


class DetailCellRenderer extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onCellValueChanged: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	onFirstDataRendered = params => {
		params.api.sizeColumnsToFit();
	};

	render() {
		const cellStyle = {
			lineHeight: '25px',
			fontSize: '0.9em',
		};

		const agGridProps = {
			headerHeight: 28,
			rowHeight: 25,
			onFirstDataRendered: this.onFirstDataRendered,
			onCellValueChanged: this.props.onCellValueChanged,
		};

		return (
			<div className={"d-flex flex-column flex-grow-1 pr-5 pl-2 py-2"}>
				<Grid
					items={this.props.items}
					agGridProps={agGridProps}
					cellStyle={cellStyle}
					isDetail={true}
				/>
			</div>
		);
	}
}

export default DetailCellRenderer;

