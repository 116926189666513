import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class RequestUserLogo extends React.PureComponent
{
	static propTypes = {
		size: PropTypes.number,
		className: PropTypes.string,
		icon: PropTypes.object,
	}

	static defaultProps = {
		className: '',
		size: 40,
	}

	render()
	{
		const { size, icon } = this.props;
		const className = "rounded-circle bg-light d-flex align-items-center justify-content-center font-weight-bold";

		return (
			<div
				className={[className, this.props.className].join(' ')}
				style={{
					height: size,
					width: size,
				}}
			>
				<span>
					<FontAwesomeIcon
						icon={icon}
						fixedWidth
						size="lg"
						className={"text-muted"}
					/>
				</span>
			</div>
		);
	}
}

export default RequestUserLogo;
