import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

/**
 * Form number input with Formik Field wrapper
 */
class NumberInput extends React.PureComponent {

	renderField = ({field, form: { setFieldValue, values }}) => {
		return (
			<Form.Control
				{...field}
				{...this.props}
				type="number"
				value={values[field.name] || ''}
			/>
		);
	}

	render() {
		return (
			<Field
				{...this.props}
				render={this.renderField}
			/>
		);
	}
}

export default NumberInput;
