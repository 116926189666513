import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import config from 'config';


class CurrencyCellEditor extends React.PureComponent
{
	static propTypes = {
		currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
		onCurrencyCellValuesChanged: PropTypes.func.isRequired,
	}

	static defaultProps = {
		currencies: [],
	}

	state = {}

	componentDidMount()
	{
		setTimeout(() => {
			const priceCurrencyId = this.props.data.priceCurrencyId || config.tbs.priceCurrency.default.id;
			this.select.value = priceCurrencyId;

			const ch = this.props.charPress;
			const re = /^\d$/;
			const isDigit = re.test(ch);

			this.input.focus();

			if (ch && isDigit) {
				this.input.value = ch;
				this.setState({ value: ch, priceCurrencyId});
			} else {
				this.input.value = this.props.value;
				this.input.select();
				this.setState({value: this.props.value, priceCurrencyId});
			}
		});

	}

	componentWillUnmount()
	{
		if (this.input.checkValidity()) {

			const field = this.props.column.colId;
			const { priceCurrencyId, value } = this.state;

			const changes = {};

			if (value) {
				changes[field] = value;
			}

			if (priceCurrencyId) {
				changes.priceCurrencyId = priceCurrencyId;
			}

			const node = this.props.node;

			this.props.onCurrencyCellValuesChanged({ changes, node });
		}
	}

	getValue() {
		return this.state.value;
	}

	isPopup()
	{
		return false;
	}

	isCancelBeforeStart()
	{
		return false;
	}

	isCancelAfterEnd()
	{
		return true;
	}

	render()
	{
		const onChange = (e) => {
			const value = e.target.value;
			this.setState({ value });
		};

		const onSelectChange = (e) => {
			const priceCurrencyId = e.target.value;
			this.setState({ priceCurrencyId });
		};

		const currencies = this.props.currencies;

		return (
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				position: 'absolute',
				width: '100%',
				height: '100%',
			}}
			>
				<input
					style={{
						flex: '0.9 1 auto',
					}}
					className={style.cellNumberInput}
					ref={ref => { this.input = ref; }}
					type="number"
					step="0.01"
					onChange={onChange}
					min="0"
				/>
				<select
					style={{
						flex: '0.1 1 auto',
						minWidth: '30px',
						WebkitAppearance: 'none',
						border: 'none',
						textAlignLast: 'center'
					}}
					ref={ref => { this.select = ref; }}
					onChange={onSelectChange}
				>
					{currencies.map(curr =>
						<option key={curr.id} value={curr.id}>
							{curr.symbol}
						</option>
					)}
				</select>
			</div>
		);
	}
}

export default CurrencyCellEditor;
