class JobUnitMatcher
{
	constructor()
	{
		const delimeterMatch = '\\s*(\\.|\\\\|\\/|\\||\\s+)\\s*';
		const meterMatch = 'м[етр(ы|а|ов)]{0,6}';
		const squareMatch = 'кв[адртны(й|е")]{0,8}?';
		const linearMatch = 'п[огны(й|е)]{0,7}?';
		const cubicMatch = 'к[убически(й|е)]{0,9}?';

		this.matches = [
			{
				patterns: [
					/^к\s*(\\|\/|)?\s*г$/i,
					/^килограмм(ы|ов)?$/i,
				],
				matchId: "C4CADBAF-CFEB-4AC9-8893-3EEED62DF306",
			},
			{
				patterns: [
					/^ч(\.|ас(|ы|ов)?)?$/i,
				],
				matchId: "40A1607D-7B6C-46A3-ADC0-765E4DF09823",
			},
			{
				patterns: [
					/^т(\.|онн(а|ы)?)?$/i,
				],
				matchId: "5DAB2CC4-8834-487D-AFE1-C83B35C93395",
			},
			{
				patterns: [
					/^м(3|³)/i,
					new RegExp(`^(${cubicMatch})(${delimeterMatch})(${meterMatch}\\.?)$`, 'i'),
					new RegExp(`^(${meterMatch})(${delimeterMatch})(${cubicMatch}\\.?)$`, 'i'),
				],
				matchId: "6C83569F-4731-41AC-A182-9F85502C9BE4",
			},
			{
				patterns: [
					/^ш((тук|тука|туки)|((т|тк|ту)\.?)|(\.?))$/i,
				],
				matchId: "796D5CC0-A6F1-4ED2-8D50-E1CA3AA0AB94",
			},
			{
				patterns: [
					new RegExp(`^(${linearMatch})(${delimeterMatch})(${meterMatch}\\.?)$`, 'i'),
					new RegExp(`^(${meterMatch})(${delimeterMatch})(${linearMatch}\\.?)$`, 'i'),
				],
				matchId: "9BB0CA73-D1E0-46E9-A7F6-BE57BDA300BF",
			},
			{
				patterns: [
					/^м(2|²)$/i,
					new RegExp(`^кубо${meterMatch}\\.?$`, 'i'),
					new RegExp(`^(${squareMatch})(${delimeterMatch})(${meterMatch}\\.?)$`, 'i'),
					new RegExp(`^(${meterMatch})(${delimeterMatch})(${squareMatch}\\.?)$`, 'i'),
				],
				matchId: "C4CADBAF-CFEB-4AC9-8893-3EEED62DF306",
			},
		];
	}

	lrTrim(str) {
		if (str === null || str === undefined) {
			return str;
		}

		return str.toString().replace(/^\s+/g, '').replace(/\s+$/g, '');
	}

	match(str) {
		const trimmed = this.lrTrim(str);

		for (const m of this.matches) {
			for (const p of m.patterns) {
				if (p.test(trimmed)) {
					return m.matchId;
				}
			}
		}

		return null;
	}
}

export default JobUnitMatcher;

