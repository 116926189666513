import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Carousel } from 'react-bootstrap';
import PhotoGalleryImage from './components/PhotoGalleryImage';


class PhotoGallery extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultPtops = {
		items: [],
	}

	state = {
		currentImageIndex: 0,
		modalVisible: false,
	}

	openModal = (event, index) => {
		this.setState({
			modalVisible: true,
			currentImageIndex: index,
		});
	}

	closeModal = () => {
		this.setState({
			modalVisible: false,
			currentImageIndex: 0,
		});
	}

	onSelect = (eventKey, direction) => {
		const index = this.state.currentImageIndex;
		const size = this.props.items.length;

		if (direction === 'next') {
			const newIndex = (index + 1) % size;
			this.setState({ currentImageIndex: newIndex });
		}

		if (direction === 'prev') {
			const prev = index - 1;
			const newIndex = prev < 0 ? (size - 1) : prev;
			this.setState({ currentImageIndex: newIndex });
		}
	}

	render()
	{
		const { modalVisible, currentImageIndex } = this.state;
		const { items } = this.props;

		return (
			<div>
				<Modal
					show={modalVisible}
					onHide={this.closeModal}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Carousel
						activeIndex={currentImageIndex}
						slide={false}
						onSelect={this.onSelect}
					>
						{items.map(item => {
							return (
								<Carousel.Item key={item.src}>
									<img
										className="d-block w-100"
										src={item.src}
										alt="First slide"
										style={{
											width: 640,
											height: 480,
										}}
									/>
									<Carousel.Caption>
										<h4 className="text-truncate">
											{item?.job?.title}
										</h4>
									</Carousel.Caption>
								</Carousel.Item>
							);
						})}
					</Carousel>
				</Modal>
				<div className="d-flex flex-wrap justify-content-center m-1">
					{items.map((item, index) => {
						return (
							<PhotoGalleryImage
								key={item.src}
								onClick={this.openModal}
								index={index}
								src={item.src}
								date={item.job?.creationTime}
								jobTitle={item.job?.title}
								assignedUser={item.stage?.assignedUser}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

export default PhotoGallery;
