import React from 'react';
import { connect } from 'react-redux';
import db from 'core/db';
import api from 'core/api';
import { withLoading } from 'components';
import Modal from 'components/Modal';
import { toast } from 'react-toastify';

import Layout from '../shared/Layout';
import OrderTicket from './components/OrderTicket';
import Grid from './components/Grid';
import OrderEditForm from './components/OrderEditForm';

import Aggregator from './helpers/Aggregator';


class OrdersView extends React.PureComponent
{
	state = {
		activeOrderId: null,
		editModalVisible: false,
		editingOrder: null,
	}

	componentDidMount()
	{
		this.setState({ activeOrderId: this.props.groupGridItems[0]?.id });
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.isLoading && !this.props.isLoading) {
			this.setState({ activeOrderId: this.props.groupGridItems[0]?.id });
		}
	}

	openEditModal = () => {
		this.setState({ editModalVisible: true });
	}

	closeEditModal = () => {
		this.setState({ editModalVisible: false });
	}

	onOrderClicked = id => {
		this.setState({ activeOrderId: id });
	}

	onEditOrder = editingOrder => {
		this.setState({ editingOrder });
		this.openEditModal();
	}

	updateOrder = (purchaseOrderId, changes, attach) => {
		if (!purchaseOrderId) {
			return;
		}

		const finallyFunc = () => this.setState({ loading: false });

		const catchFunc = error => {
			console.error(error);
			toast.error("Не удалось сохранить изменения...", {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
				draggable: false,
			});
		};

		this.setState({ loading: true });

		if (attach) {
			const fileCreate = api.attachments().create({
				files: [attach]
			});

			this.props.dispatch(fileCreate)
				.then(res => {
					this.props.dispatch(api.tbsPurchaseOrders().update({
						purchaseOrderId,
						changes: {
							...changes,
							attachments: {
								new: [{ id: res.id }],
							}
						}
					}));
				})
				.then(this.closeEditModal)
				.catch(catchFunc)
				.finally(finallyFunc)
			;
		} else {
			this.props.dispatch(api.tbsPurchaseOrders().update({
				purchaseOrderId,
				changes,
			}))
				.then(this.closeEditModal)
				.catch(catchFunc)
				.finally(finallyFunc)
			;
		}
	}

	updatePurchaseOrderItem = (id, changes) => {
		this.props.dispatch(
			api.tbsPurchaseOrderItems().update({
				purchaseOrderItemId: id,
				changes: changes
			}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	};

	onPayOrder = order => {
		const changes = {
			isPayed: true,
		};

		this.updateOrder(order.id, changes);
	}

	onPlaceOrder = order => {
		this.props.dispatch(api.tbsPurchaseOrders().place({
			purchaseOrderId: order.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onMoveToPayingOrder = order => {
		this.props.dispatch(api.tbsPurchaseOrders().sendToApprove({
			purchaseOrderId: order.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onCellValueChanged = (params, updateCallback) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.updatePurchaseOrderItem(params.node.data.id, changes);
	}

	renderOrders()
	{
		const { groupGridItems } = this.props;

		return groupGridItems.sort((o1, o2) => (o1.sn - o2.sn)).map(order => {
			const isActive = this.state.activeOrderId === order.id;

			return (
				<OrderTicket
					key={order.id}
					order={order}
					isActive={isActive}
					onClicked={this.onOrderClicked}
					onEditOrder={this.onEditOrder}
					onPayOrder={this.onPayOrder}
					onPlaceOrder={this.onPlaceOrder}
					onMoveToPayingOrder={this.onMoveToPayingOrder}
				/>
			);
		});
	}

	renderGrid()
	{
		const { gridItems } = this.props;
		const { activeOrderId } = this.state;
		const items = gridItems.filter(i => i.purchaseOrderId === activeOrderId);

		return (
			<Grid
				items={items}
				currenciesMap={this.props.currenciesMap}
				onCellValueChanged={this.onCellValueChanged}
			/>
		);
	}

	renderEditModal()
	{
		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.editOrderFormProps.handleSubmit(),
					disabled: this.state.loading,
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeEditModal,
				}
			}
		];

		const onFormReady = props => {
			this.editOrderFormProps = props;
		};

		const { editingOrder } = this.state;

		return (
			<Modal
				title={`Заказ №${editingOrder?.sn}`}
				show={this.state.editModalVisible}
				btnList={btnList}
				onHide={this.closeEditModal}
			>
				<OrderEditForm
					onFormReady={onFormReady}
					onSubmit={(changes, attach) => this.updateOrder(this.state.editingOrder?.id, changes, attach)}
					onCancel={this.closeEditModal}
					order={this.state.editingOrder}
					currencies={this.props.currencies}
				/>
			</Modal>
		);
	}

	render()
	{
		const orders = this.renderOrders();
		const grid = this.renderGrid();
		const editModal = this.renderEditModal();

		const list = (
			<div className="flex-grow-1 w-100 overflow-auto">
				{orders}
			</div>
		);

		const btnList = [
			{
				title: ' Заявки',
				props: {
					onClick: this.props.onSwitchRequests,
				}
			},
			{
				title: 'Заказы',
			},
		];

		return (
			<React.Fragment>
				<Layout btnList={btnList}>
					{list}
					{grid}
				</Layout>
				{editModal}
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {
	const { consumableSheetId, projectId } = props;

	if (!consumableSheetId) {
		return { isLoading: true };
	}

	const filter = { filter: { projectId } };
	const filterConsumbaleSheet = { filter: { consumableSheetId } };

	const purchaseOrders = db.tbsPurchaseOrders.list(filter);
	const purchaseOrderItems = db.tbsPurchaseOrderItems.list();
	const purchaseRequests = db.tbsPurchaseRequests.list();
	const purchaseRequestItems = db.tbsPurchaseRequestItems.list();
	const consumableSheetItems = db.tbsConsumableSheetItems.list(filterConsumbaleSheet);
	const currencies = db.currencies.list();

	const purchaseOrdersMap = purchaseOrders.hashById();
	const purchaseRequestItemsMap = purchaseRequestItems.hashById();
	const consumableSheetItemsMap = consumableSheetItems.hashById();
	const purchaseRequestsMap = purchaseRequests.hashById();
	const currenciesMap = currencies.hashById();

	const isLoading = props.isLoading
		|| purchaseOrders.isLoading
		|| purchaseOrderItems.isLoading
		|| purchaseRequests.isLoading
		|| purchaseRequestItems.isLoading
		|| consumableSheetItems.isLoading
		|| currencies.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new Aggregator({
		purchaseOrderItems,
		purchaseRequestItemsMap,
		consumableSheetItemsMap,
		purchaseOrdersMap,
		purchaseRequestsMap,
		purchaseOrders,
	});

	const gridItems = aggregator.getGridItems();
	const groupGridItems = aggregator.getGroupGridItems();

	return {
		gridItems,
		groupGridItems,
		currenciesMap,
		currencies,
	};
};

export default connect(mapToProps)(withLoading(OrdersView));
