import React from 'react';
import db from 'core/db';
import { connect } from 'react-redux';
import { withLoading } from 'components';
import { toast } from 'react-toastify';
import Empty from 'components/Empty';
import Modal from 'components/Modal';
import ConfirmModal from 'components/Modals/Modal';
import moment from 'moment';
import api from 'core/api';

import ReqiestTicket from './components/RequestTicket';
import Grid from './components/Grid';
import OrderToast from './components/OrderToast';
import AttachInvoiceForm from './components/AttachInvoiceForm';
import OrderedItemsList from './components/OrderedItemsList';

import Layout from '../shared/Layout';


import RequestItemsAggregator from './helpers/RequestItemsAggregator';

class RequestsView extends React.Component
{
	state = {
		activeRequestId: null,
		orderingItems: [],
		creationModalVisible: false,
		loading: false,
	}

	componentDidMount()
	{
		this.setState({ activeRequestId: this.props.groupGridItems[0]?.id });
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.isLoading && !this.props.isLoading) {
			this.setState({ activeRequestId: this.props.groupGridItems[0]?.id });
		}
	}

	onRequestClicked = id => {
		this.setState({ activeRequestId: id });
	}

	onAssignToMe = purchaseRequestId => {
		this.props.dispatch(api.tbsPurchaseRequests().assignToMe({
			purchaseRequestId,
		}));
	}

	onAddToOrder = requestItem => {
		const contains = item => {
			for (const oItem of this.state.orderingItems) {
				if (oItem.id === item.id) {
					return true;
				}
			}

			return false;
		};

		if (contains(requestItem)) {
			return;
		}

		const orderingItems = [...this.state.orderingItems];
		orderingItems.push(requestItem);

		this.setState({ orderingItems });
	}

	onApproveAmount = requestItem => {
		// const approve = api.tbsPurchaseRequestItems().approveAmount({
		// 	purchaseRequestItemId: requestItem.id
		// });

		// ConfirmModal.confirm({
		// 	title: 'Утверждени количества',
		// 	text: 'Утвердить количество?',
		// 	onConfirm: () => this.props.dispatch(approve),
		// });
	}

	openCreationOrderModal = () => {
		this.setState({ creationModalVisible: true });
	}

	closeCreationOrderModal = () => {
		this.setState({ creationModalVisible: false });
	}

	clearOrder = () => {
		this.setState({ orderingItems: [] });
	}

	onCreateOrder = data => {
		const { date, file, supplierTitle } = data;
		const { projectId } = this.props;
		const deliveryDate = moment(date).format('YYYY-MM-DD');

		const items = this.state.orderingItems.map(item => {
			return {
				purchaseRequestItemId: item.id,
				amount: item.amount,
			};
		});

		const orderCreate = api.tbsPurchaseOrders().create({
			projectId,
			deliveryDate,
			supplierTitle,
			items,
		});

		const fileCreate = api.attachments().create({
			files: [file]
		});

		this.setState({ loading: true });
		this.props.dispatch(fileCreate)
			.then(res => {

				const { purchaseOrderId } = orderCreate.props;

				const attachUpdate = api.tbsPurchaseOrders().update({
					purchaseOrderId: purchaseOrderId,
					changes: {
						attachments: {
							new: [{ id: res.id }],
						}
					}
				});

				const transactionBody = [orderCreate, attachUpdate];
				const transaction = api.transaction().execute(transactionBody);

				this.props.dispatch(transaction);
			})
			.then(() => {
				this.closeCreationOrderModal();
				this.clearOrder();
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			})
		;
	}

	onRequestCreateOrderClicked = orderingItems => {
		this.setState({ orderingItems });
		this.openCreationOrderModal();
	}

	updateItem = (id, changes) => {
		this.props.dispatch(api.tbsPurchaseRequestItems().update({
			purchaseRequestItemId: id,
			changes,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.updateItem(params.node.data.id, changes);
	}


	renderCreationOrderModal = () => {
		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.billFormProps.handleSubmit(),
					disabled: this.state.loading,
				},
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeCreationOrderModal,
				},
			}
		];

		const onFormReady = props => { this.billFormProps = props; };

		return (
			<Modal
				show={this.state.creationModalVisible}
				onHide={this.closeCreationOrderModal}
				btnList={btnList}
				dialogClassName="w-50"
				title="Прикрепить счёт"
			>
				<OrderedItemsList items={this.state.orderingItems}/>
				<AttachInvoiceForm
					onFormReady={onFormReady}
					onConfirm={this.onCreateOrder}
				/>
			</Modal>
		);
	}

	renderRequests()
	{
		const requests = this.props.groupGridItems.sort((a, b) => (a.sn - b.sn));

		return requests.map(request => {
			const isActive = this.state.activeRequestId === request.id;

			return (
				<ReqiestTicket
					key={request.id}
					request={request}
					isActive={isActive}
					onClicked={this.onRequestClicked}
					onCreateOrderClicked={this.onRequestCreateOrderClicked}
					onAssignToMe={this.onAssignToMe}
				/>
			);
		});
	}

	renderGridContent()
	{
		const gridItems = this.props.gridItems
			.filter(item => item.purchaseRequestId === this.state.activeRequestId);

		return (
			<Grid
				items={gridItems}
				onAddToOrder={this.onAddToOrder}
				consumableSheet={this.props.consumableSheet}
				onCellValueChanged={this.onCellValueChanged}
				onApproveAmount={this.onApproveAmount}
			/>
		);
	}

	renderOrderToast()
	{
		const { orderingItems, creationModalVisible } = this.state;

		if (!orderingItems.length || creationModalVisible) {
			return null;
		}

		return (
			<OrderToast
				items={orderingItems}
				onCreateOrderClicked={this.openCreationOrderModal}
				onClose={this.clearOrder}
			/>
		);
	}

	render()
	{
		if (!this.props.consumableSheet) {
			return <Empty title="Сводная спецификация не утверждена"/>;
		}

		const requests = this.renderRequests();
		const grid = this.renderGridContent();
		const orderToast = this.renderOrderToast();
		const orderModal = this.renderCreationOrderModal();

		const list = (
			<div className="flex-grow-1 w-100 overflow-auto">
				{requests}
			</div>
		);

		const btnList = [
			{
				title: ' Заявки',
			},
			{
				title: 'Заказы',
				props: {
					onClick: this.props.onSwitchOrders,
				},
			},
		];

		return (
			<React.Fragment>
				<Layout btnList={btnList}>
					{list}
					{grid}
				</Layout>
				{orderToast}
				{orderModal}
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {

	if (props.isLoading) {
		return { isLoading: true };
	}

	const consumableSheetId = props.consumableSheetId;
	const filter = { filter: { consumableSheetId } };

	const consumableSheetItems = db.tbsConsumableSheetItems.list(filter);
	const consumableSheetItemsMap = consumableSheetItems.hashById();
	const purchaseRequests = db.tbsPurchaseRequests.list(filter);
	const purchaseRequestsMap = purchaseRequests.hashById();
	const purchaseRequestItems = db.tbsPurchaseRequestItems.list();
	const purchaseOrderItems = db.tbsPurchaseOrderItems.list();
	const purchaseOrders = db.tbsPurchaseOrders.list();
	const purchaseOrdersMap = purchaseOrders.hashById();

	const isLoading = purchaseRequests.isLoading
		|| purchaseRequestItems.isLoading
		|| consumableSheetItems.isLoading
		|| purchaseOrderItems.isLoading
		|| purchaseOrders.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new RequestItemsAggregator({
		purchaseRequestItems,
		purchaseRequests,
		purchaseRequestsMap,
		consumableSheetItemsMap,
		purchaseOrdersMap,
		purchaseOrderItems,
	});

	const gridItems = aggregator.getGridItems();
	const groupGridItems = aggregator.getGroupGridItems();

	return {
		purchaseRequests,
		consumableSheetItemsMap,
		gridItems,
		groupGridItems,
		me: state.me,
	};
};

export default connect(mapToProps)(withLoading(RequestsView));
