import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

/**
 * Form select input with Formik Field wrapper
 */
class Select extends React.PureComponent {

	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		optionField: PropTypes.string,
		idField: PropTypes.string,
		emptyValue: PropTypes.string,
	}

	static defaultProps = {
		optionField: 'title',
		idField: 'id',
		emptyValue: '-',
		items: [],
	}

	renderField = ({field}) => {
		return (
			<Form.Control
				{...field}
				as="select"
				value={undefined}
			>
				<option>{this.props.emptyValue}</option>
				{this.props.items.map(item =>
					<option key={item[this.props.idField]} value={item[this.props.idField]}>
						{item[this.props.optionField]}
					</option>
				)}
			</Form.Control>
		);
	}

	render() {
		return (
			<Field
				{...this.props}
				render={this.renderField}
			/>
		);
	}
}

export default Select;
