import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import style from './overlay.module.sass';


export default class Overlay extends React.PureComponent {
	render() {
		const icon = <FontAwesomeIcon
			className="mr-2"
			fixedWidth
			spin={true}
			icon={faSpinner}
		/>;

		return (
			<div className={style.overlay}>
				{icon}
				Загрузка...
			</div>
		);
	}
}
