import dashboardDataExample from './dashboardDataExample';
import DashboardData from './DashboardData';
import moment from 'moment';
import MiniChart from './MiniChart';

class DashboardCalculator
{
	constructor(periodDays, inactiveDays)
	{
		this.periodDays = periodDays;
		this.inactiveDays = inactiveDays;

		this.inactivityThres = new Date(Date.now() - inactiveDays * (24 * 3600 * 100));
	}

	/**
	 * @private
	 * @param list
	 * @param id
	 * @returns {*}
	 */
	findById(list, id)
	{
		return list.find(item => item.id === id);
	}

	/**
	 * @private
	 */
	calcActivityFeed(projects, stages, jobs, jobsHistory) {
		if (jobs.isLoading || jobsHistory.isLoading) {
			return [];
		}

		let lastEvent = null;

		const jobsFeed = jobsHistory
			.filter(jobHistory => {
				if (!('currentProgress' in jobHistory.changes)) {
					return false;
				}

				const job = this.findById(jobs, jobHistory.jobId);

				if (!job) {
					return false;
				}

				const stage = this.findById(stages, job.stageId);

				if (!stage) {
					return false;
				}

				return true;
			})
			.map(jobHistory => ({
				type: 'jobProgress',
				date: new Date(jobHistory.creationTime),
				user: jobHistory.createdByUser,
				project: this.findById(projects, this.findById(jobs, jobHistory.jobId).projectId),
				count: 1,
			}))
			.sort((a, b) => (a.date.valueOf() > b.date.valueOf() ? -1 : 1))
			.filter(event => {
				if (lastEvent && (event.project && event.user && lastEvent.project && lastEvent.user)) {
					if (
						lastEvent.project.id === event.project.id
						&& lastEvent.user.id === event.user.id
					) {
						lastEvent.count++;

						return false;
					}
				}

				lastEvent = event;

				return true;
			})
		;

		return []
			.concat(jobsFeed)
			.sort((a, b) => {
				return a.date.valueOf() > b.date.valueOf() ? -1 : 1;
			})
			.slice(0, 10)
		;
	}

	/**
	 * @private
	 * @param projects
	 * @param jobs
	 * @returns {object}
	 */
	calcCustomerAndWorkerBudgets(projects, jobs)
	{
		return projects.reduce(
			(acc, project) => {
				const projectJobs = jobs.filter(job => job.projectId === project.id);

				return projectJobs.reduce(
					(acc, job) => ({
						customerBudget: {
							payed: acc.customerBudget.payed + ((job.inActProgress * job.priceClient) || 0),
							notPayed: acc.customerBudget.notPayed + Math.max(0, (((job.currentProgress - job.inActProgress) * job.priceClient) || 0)),
							inFuture: acc.customerBudget.inFuture + Math.max(0, (((job.maxProgress - job.currentProgress) * job.priceClient) || 0)),
						},
						workerBudget: {
							payed: acc.workerBudget.payed + ((job.inActProgress * job.priceWorker) || 0),
							notPayed: acc.workerBudget.notPayed + Math.max(0, (((job.currentProgress - job.inActProgress) * job.priceWorker) || 0)),
							inFuture: acc.workerBudget.inFuture + Math.max(0, (((job.maxProgress - job.currentProgress) * job.priceWorker) || 0)),
						}
					}),
					acc
				);
			},
			{
				customerBudget: {
					payed: 0,
					notPayed: 0,
					inFuture: 0,
				},
				workerBudget: {
					payed: 0,
					notPayed: 0,
					inFuture: 0,
				},
			}
		);
	}

	/**
	 * @private
	 * @param time
	 * @param thres
	 * @returns {boolean}
	 */
	isExpired(time, thres = new Date)
	{
		if (!time) {
			return false;
		}

		const d = Date.parse(time);
		const td = Date.parse(thres);

		return d < td;
	}

	/**
	 * @private
	 */
	calcObjectSummary(projects, stages, jobs)
	{
		return projects.reduce(
			(acc, project) => {
				const expiredJobsCount = jobs
					.filter(job => job.projectId === project.id && this.isExpired(job.estimatedEndDate))
					.length
				;

				const expiredStagesCount = stages
					.filter(stage => stage.projectId === project.id && this.isExpired(stage.estimatedEndDate))
					.length
				;

				return {
					inactive: acc.inactive + (this.isExpired(project.lastActivityTime, this.inactivityThres) ? 1 : 0),
					expiredProjects: acc.expiredProjects + (this.isExpired(project.estimatedEndDate) ? 1 : 0),
					expiredStages: acc.expiredStages + expiredStagesCount,
					expiredJobs: acc.expiredJobs + expiredJobsCount,
				};
			},
			{
				inactive: 0,
				expiredProjects: 0,
				expiredStages: 0,
				expiredJobs: 0,
			}
		);
	}

	/**
	 * @private
	 */
	projectsDynamicSequence(projects)
	{
		return projects
			.map(project => ({type: 'create', date: project.creationTime}))
			.concat(projects
				.filter(project => project.isArchived)
				.map(project => ({type: 'complete', date: new Date(project.archiveTime)}))
			)
			.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
		;
	}

	/**
	 * @private
	 */
	calcProjectsCountChart(projects)
	{
		const timeline = this.projectsDynamicSequence(projects);
		const thres = moment().subtract(6, 'months').startOf('month');

		const byMonths = timeline.reduce(
			(byMonths, item) => {
				if (thres.isAfter(item.date)) {
					return byMonths;
				}

				const created = item.type === 'create' ? 1 : 0;
				const completed = item.type === 'complete' ? 1 : 0;
				const ident = moment(item.date).format('Y-MM');

				return {
					...byMonths,
					[ident]: {
						created: created + ((byMonths[ident] && byMonths[ident].created) || 0),
						completed: completed + ((byMonths[ident] && byMonths[ident].completed) || 0),
					},
				};
			},
			{}
		);

		const withZeros = [];

		let d = thres.clone();
		while (d.isBefore(moment(new Date).subtract(1, 'month'))) {
			d = d.add(1, 'months');
			const ident = d.format('Y-MM');

			withZeros.push({
				month: d.format('MMMM'),
				values: {
					created: byMonths[ident] ? byMonths[ident].created : 0,
					completed: byMonths[ident] ? byMonths[ident].completed : 0,
				},
			});
		}

		return {
			byMonth: withZeros
		};
	}

	/**
	 * @private
	 */
	calcNearestCompletions(projects)
	{
		return projects
			.filter(project => !project.isArchived)
			.map(project => ({
				id: project.id,
				title: project.title,
				date: project.estimatedEndDate && new Date(project.estimatedEndDate),
			}))
			.sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1))
		;
	}

	/**
	 * @private
	 */
	calcMiniChartsProjects(projects)
	{
		const timeline = this.projectsDynamicSequence(projects);
		const creationChart = new MiniChart;
		const completionChart = new MiniChart;
		const inProgressChart = new MiniChart(true);

		for (const entry of timeline) {
			if (entry.type === 'create') {
				creationChart.put(entry.date, 1);
				inProgressChart.put(entry.date, 1);
			}

			if (entry.type === 'complete') {
				completionChart.put(entry.date, 1);
				inProgressChart.put(entry.date, -1);
			}
		}

		const inProgressExported = inProgressChart.export(this.periodDays);
		const prevInProgress = inProgressChart.prevPeriod(this.periodDays);
		const curInProgress = inProgressChart.currentPeriod(this.periodDays);

		return {
			projectsStarted: creationChart.export(this.periodDays),
			projectsCompleted: completionChart.export(this.periodDays),
			projectsInWork: {
				currentPeriod: {
					sum: curInProgress[curInProgress.length - 1].value,
					byDays: inProgressExported.currentPeriod.byDays,
				},
				prevPeriod: {
					sum: prevInProgress[prevInProgress.length - 1].value,
				},
			}
		};
	}

	/**
	 * @private
	 */
	createWorksChart(jobs, jobsHistory)
	{
		const workedChart = new MiniChart;

		for (const h of jobsHistory) {
			if (!h.changes.currentProgress) {
				continue;
			}

			const job = this.findById(jobs, h.jobId);

			if (!job) {
				continue;
			}

			if (!job.priceClient) {
				continue;
			}

			const delta = h.changes.currentProgress - (h.before.currentProgress || 0);

			workedChart.put(h.creationTime, delta * job.priceClient);
		}

		return workedChart;
	}

	/**
	 * @private
	 */
	calcWorksChart(jobs, jobsHistory)
	{
		const worksChart = this.createWorksChart(jobs, jobsHistory);
		const exported = worksChart.export(this.periodDays);

		return {
			byDays: exported.currentPeriod.byDays.map(d => ({date: d.date, values: {completed: d.value}})),
		};
	}

	/**
	 * @private
	 */
	calcManagersInfo(projects, employees)
	{
		const managersMap = {};

		for (const project of projects) {
			if (!project.assignedUserId) {
				continue;
			}

			if (project.isArchived) {
				continue;
			}

			if (!managersMap[project.assignedUserId]) {
				managersMap[project.assignedUserId] = [];
			}

			managersMap[project.assignedUserId].push(project);
		}

		const prod = [];

		for (const managerId in managersMap) {
			const managedProjects = managersMap[managerId];

			const employee = employees.find(e => e.userId === managerId);
			if (!employee) {
				continue;
			}

			const user = employee.user;

			const expired = managedProjects.filter(p => {
				if (!p.estimatedEndDate) {
					return false;
				}

				return this.isExpired(p.estimatedEndDate);
			});

			const inactive = managedProjects.filter(p => {
				if (!p.lastActivityTime) {
					return false;
				}

				return this.isExpired(p.lastActivityTime, this.inactivityThres);
			});

			prod.push({
				user: user,
				projectsCount: managedProjects.length,
				projectsExpired: expired.map(p => ({
					days: moment().diff(p.estimatedEndDate, 'days'),
					project: p,
				})),
				projectsInactive: inactive.map(p => ({
					days: moment().diff(p.estimatedEndDate, 'days'),
					project: p,
				})),
			});
		}

		return prod;
	}

	/**
	 * @public
	 */
	calculate(projects, stages, jobs, jobsHistory, acts, employees, expenses)
	{
		if (!projects.length || !stages.length || !jobs.length || !employees.length) {
			return new DashboardData({});
		}

		const data = {
			...dashboardDataExample,
			activityFeed: this.calcActivityFeed(projects, stages, jobs, jobsHistory),
			objectsSummary: this.calcObjectSummary(projects, stages, jobs),
			projectsCountChart: this.calcProjectsCountChart(projects),
			nearestCompletions: this.calcNearestCompletions(projects),
			values: {
				...dashboardDataExample.values,
				...this.calcMiniChartsProjects(projects),
			},
			worksChart: this.calcWorksChart(jobs, jobsHistory),
			managersInfo: this.calcManagersInfo(projects, employees),
		};

		return new DashboardData(data);
	}
}

export default DashboardCalculator;
