import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

import MenuAction from './components/MenuAction';


class ContextMenu extends React.PureComponent
{
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.object),
		placement: PropTypes.string,
	}

	static defaultProps = {
		actions: [],
		placement: "bottom-start",
	}

	renderPopover()
	{
		const actions = this.props.actions;

		return (
			<Popover>
				<Popover.Content className="px-0">
					{actions.map((act, i) => <MenuAction key={i} action={act} />)}
				</Popover.Content>
			</Popover>
		);
	}

	renderActionsButton()
	{
		return (
			<Button
				className="p-0 d-block"
				variant="light"
				onClick={e => e.stopPropagation()}
			>
				<FontAwesomeIcon
					fixedWidth
					icon={faEllipsisH}
					style={{
						color: 'rgba(0,0,0,0.5',
						width: '20px'
					}}
				/>
			</Button>
		);
	}

	render()
	{
		const content = this.renderActionsButton();
		const overlay = this.renderPopover();

		const popperConfig = {
			modifiers: {
				preventOverflow: { enabled: false },
				hide: { enabled: false },
			}
		};

		return (
			<OverlayTrigger
				overlay={overlay}
				trigger="focus"
				popperConfig={popperConfig}
				placement={this.props.placement || "auto"}
			>
				{content}
			</OverlayTrigger>
		);
	}
}

export default ContextMenu;
