import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.sass';


class StepTail extends React.PureComponent
{
	static propTypes = {
		status: PropTypes.string.isRequired,
	}

	getClassName = () => {
		const st = this.props.status;
		if (st === 'await') {
			return style.stepTailAwait;
		}

		return style.stepTail;
	}

	render()
	{
		return (
			<div className={this.getClassName()}/>
		);
	}
}

export default StepTail;
