import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import NameCellRenderer from 'components/ag-grid-components/renderers/NameCellRenderer';
import StatusCellRenderer from './components/StatusCellRenderer';
import EmploeeysCellRenderer from './components/EmploeeysCellRenderer';
import ReportCellRenderer from './components/ReportCellRenderer';
import EmployeeGroupCellRenderer from './components/EmployeeGroupCellRenderer';


class ChiefsGrid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.shape({
			employeeId: PropTypes.string,
			stage: PropTypes.shape({
				id: PropTypes.string,
				title: PropTypes.string,
			}),
			lastReport: PropTypes.object,
			emploeeysCount: PropTypes.number,
			emploeeysPlannedCount: PropTypes.number,
		})),
		employeesMap: PropTypes.any.isRequired,
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridApi.sizeColumnsToFit();
	}

	render()
	{
		const columnDefs = [
			{
				headerName: 'Ответственный',
				field: 'employeeId',
				rowGroup: true,
				hide: true,
				cellRenderer: 'employeeGroupCellRenderer',
				cellRendererParams: {employeesMap: this.props.employeesMap},
			},
			{
				headerName: 'Люди',
				maxWidth: 120,
				cellRenderer: 'emploeeysCellRenderer',
			},
			{
				headerName: 'Статус',
				maxWidth: 120,
				cellRenderer: 'statusCellRenderer',
			},
			{
				headerName: 'Отчёт',
				field: 'lastReport',
				maxWidth: 120,
				cellRenderer: 'reportCellRenderer',
			}
		];

		const autoGroupColumnDef = {
			headerName: 'Участок',
			field: 'stage.title',
			cellRendererParams: {
				suppressCount: true,
			},
		};

		const frameworkComponents = {
			nameCellRenderer: NameCellRenderer,
			statusCellRenderer: StatusCellRenderer,
			emploeeysCellRenderer: EmploeeysCellRenderer,
			reportCellRenderer: ReportCellRenderer,
			employeeGroupCellRenderer: EmployeeGroupCellRenderer,
		};

		const gridOptions = {
			groupDefaultExpanded: 1,
			loadingOverlayComponent: 'customOverlay',
			frameworkComponents: frameworkComponents,
			autoGroupColumnDef: autoGroupColumnDef,
			defaultColDef: {
				minWidth: 40,
				resizable: false,
				sortable: true,
				editable: false,
			}
		};

		const now = moment().format('YYYY-MM-DD');

		let items = this.props.items || [];

		items = items.map(item => {
			return {
				isReportOk: item.lastReport.date === now,
				isStageOk: item.emploeeysCount >= item.emploeeysPlannedCount,
				...item,
			};
		});

		return (
			<CustomGrid
				wrapperStyle={this.props.style}
				rowData={items}
				headerHeight={43}
				rowHeight={38}
				headerHeight={42}
				rowHeight={38}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				onGridReady={this.onGridReady}
			/>
		);
	}
}

export default ChiefsGrid;
