import React from 'react';
import { connect } from 'react-redux';

import db from 'core/db';
import style from 'styles/page.module.sass';
import TabsSwitch from './components/TabsSwitch';
import Empty from 'components/Empty';
import Loading from 'components/Loading';


class SpecificationsPage extends React.PureComponent
{

	renderComponent()
	{
		if (this.props.consumableSheetIsLoading) {
			return <Loading/>;
		}

		if (!this.props.consumableSheet) {
			return <Empty title="Сводная спецификация не утверждена"/>;
		}

		return <TabsSwitch {...this.props} />;
	}

	render()
	{
		const component = this.renderComponent();

		return (
			<div
				className={style.wrapper}>
				<div className="flex-grow-1 d-flex flex-column justify-content-center" style={{backgroundColor: '#fff'}}>
					{component}
				</div>
			</div>
		);
	}
}


const mapToProps = (state, props) => {

	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId: projectId }};

	const consumableSheets = db.tbsConsumableSheets.list(projectFilter);
	const consumableSheet = consumableSheets[0] || null;
	const consumableSheetIsLoading = consumableSheets.isLoading;

	return {
		consumableSheet: consumableSheet,
		consumableSheetIsLoading: consumableSheetIsLoading,
		projectId: projectId,
	};
};

export default connect(mapToProps)(SpecificationsPage);
