import React from 'react';
import PropTypes from 'prop-types';

import api from 'core/api';
import { connect } from 'react-redux';
import { withLoading } from "components";
import Empty from 'components/Empty';
import Loading from 'components/Loading';
import moment from 'moment';

import ReportAggregator from '../../helpers/aggregator/ReportAggregator/ReportAggregator';
import RequestComponent from '../RequestComponent';


const longDateFormat = moment().localeData().longDateFormat('L');

class ScheduleCard extends RequestComponent
{
	static propTypes = {
		project: PropTypes.object.isRequired,
		jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
		jobsMap: PropTypes.any.isRequired,
	}

	state = {
		estimatedEndDate: null,
	}

	getRequestBody()
	{
		return api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: this.props.projectId,
				isDeleted: false,
			}
		});
	}

	onRequestCallback = (result) => {
		const now = new Date();
		const aggregator = new ReportAggregator({
			startDate: now,
			endDate: now,
			groups: result.groups,
			jobsMap: this.props.jobsMap,
			jobs: this.props.jobs,
			project: this.props.project,
		});

		const progress = aggregator.progress().last();
		const actual = progress.actualPercents();
		const planned = progress.planPercents();

		const gapPercents = actual[0] - planned[0];

		const { estimatedEndDate, estimatedStartDate } = this.props.project;

		if (gapPercents === 0) {
			return estimatedEndDate;
		}

		const projectDuration = moment(estimatedEndDate).diff(estimatedStartDate, 'days');
		const daysGap = Math.floor(Math.abs(gapPercents) * projectDuration / 100);
		let calculatedEndDate = moment(estimatedEndDate);

		if (gapPercents < 0) {
			calculatedEndDate = calculatedEndDate.add(daysGap, 'days');
		} else {
			calculatedEndDate = calculatedEndDate.subtract(daysGap, 'days');
		}

		this.setState({
			estimatedEndDate: calculatedEndDate,
			daysGap: daysGap,
		});
	}

	onUpdateStarted()
	{
		this.setState({ requestInProgress: true });
	}

	onUpdateFinished()
	{
		this.setState({ requestInProgress: false });
	}

	renderRow(textLeft, textRight)
	{
		const style = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			fontSize: '0.8em',
		};

		return (
			<div style={style}>
				<div>{textLeft}</div>
				<div>{textRight}</div>
			</div>
		);
	}

	getPlannedDateRow()
	{
		const plannedEndDate = this.props.project.estimatedEndDate;
		const textLeft = 'Плановая дата завершения';
		const textRight = plannedEndDate && moment(plannedEndDate).format(longDateFormat);

		return this.renderRow(textLeft, textRight);
	}

	getEstimatedDateRow()
	{
		const textLeft = 'Вероятная дата завершения';
		const textRight = this.state.estimatedEndDate.format(longDateFormat);

		return this.renderRow(textLeft, textRight);
	}

	getEstimationRow()
	{
		const plannedEndMoment = moment(this.props.project.estimatedEndDate);
		const estimatedEndMoment = moment(this.state.estimatedEndDate);
		const diff = plannedEndMoment.diff(estimatedEndMoment, 'days');

		const textRightStyle = {};
		let textLeft = 'Укладываемся в срок';

		if (diff < 0) {
			textLeft = 'Отставание от графика';
			textRightStyle.color = '#a42520';
		} else if (diff > 0) {
			textLeft = 'Опережение графика';
			textRightStyle.color = 'green';
		}

		const textRight = diff ? <span style={textRightStyle}>{plannedEndMoment.from(estimatedEndMoment, true)}</span> : '';

		return this.renderRow(textLeft, textRight);
	}

	render()
	{
		const { estimatedEndDate } = this.state;

		if (this.state.requestInProgress) {
			return <Loading />;
		}

		if (!estimatedEndDate) {
			return <Empty title="Нет данных"/>;
		}

		return (
			<div style={{padding: '12px 31px'}}>
				{this.getPlannedDateRow()}
				{this.getEstimatedDateRow()}
				{this.getEstimationRow()}
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const { project, jobs } = props;
	const isLoading = !project || !jobs || jobs.isLoading;

	return { isLoading };
};


export default connect(mapToProps)(withLoading(ScheduleCard));
