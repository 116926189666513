import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';

import projectRoutes from 'domains/projects/routes';
import companyRoutes from 'domains/company/routes';
import userRoutes from 'domains/user/routes';
import { navigation } from 'routes';
import { withRouter, matchPath } from 'react-router-dom';

import logoSrc from '../assets/img/logo-square.png';
import logoTextSrc from '../assets/img/logo-text.png';
import styles from '../style/sidebar.module.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


class SideBar extends React.PureComponent {

	static propTypes = {
		onToggleCollapse: PropTypes.func,
		collapsed: PropTypes.bool,
	}

	static defaultProps = {
		onToggleCollapse: () => {},
		collapsed: true,
	}

	renderSeparartor(key)
	{
		return (
			<div
				key={key}
				className={styles.separatorWrapper}
			>
				<div
					className={styles.separator}
				/>
			</div>
		);
	}

	renderMainMenu = () => {

		const collapsed = this.props.collapsed;

		return (
			<nav className={styles.mainMenu}>
				{navigation.filter(route => route.path !== '/')
					.map((route, key) => {

						const match = matchPath(this.props.location.pathname, {
							path: route.path,
							strict: false,
						});

						const routeTitle = !collapsed && <div className="ml-2">
							{route.title}
						</div>;

						const routeIcon = <FontAwesomeIcon icon={route.faIcon} fixedWidth size="lg"/>;

						return (
							<LinkContainer key={key} to={route.path} exact={route.exact}>
								<div className={[
									collapsed ? styles.linkCollapsed : styles.link,
									match ? styles.linkActive : '',
								].join(' ')}
								>
									{routeIcon}
									{routeTitle}
								</div>
							</LinkContainer>
						);
					})}
			</nav>
		);
	}

	renderContextMenu = () => {

		const pathname = this.props.location.pathname;

		const matchProjectPath = matchPath(pathname, {
			path: '/projects/:projectId',
			strict: false
		});

		const matchCompanyPath = matchPath(pathname, {
			path: '/company',
			strict: false
		});

		const matchUserPath = matchPath(pathname, {
			path: '/user',
			strict: false
		});

		const contextMatch = matchProjectPath || matchCompanyPath || matchUserPath;

		if (!contextMatch) {
			return null;
		}

		let contextRoutes = null;

		if (matchProjectPath) {
			contextRoutes = projectRoutes;
		} else if (matchCompanyPath) {
			contextRoutes = companyRoutes;
		} else if (matchUserPath) {
			contextRoutes = userRoutes;
		}

		if (!contextRoutes) {
			return null;
		}

		const collapsed = this.props.collapsed;

		const menuItems = [];

		for (let i = 0; i < contextRoutes.length; ++i) {
			const route = contextRoutes[i];
			const path = `${contextMatch.path}${route.path}`;

			const match = matchPath(this.props.location.pathname, {
				path,
				strict: false,
			});

			const routeTitle = !collapsed && <div className="ml-2">
				{route.title}
			</div>;

			const routeIcon = <FontAwesomeIcon icon={route.faIcon} fixedWidth size="lg"/>;

			const link = <LinkContainer key={i} to={`${contextMatch.url}${route.path}`} exact={route.exact}>
				<div className={[
					styles.linkContext,
					collapsed ? styles.linkCollapsed : styles.link,
					match ? styles.linkActive : '',
				].join(' ')}
				>
					{routeIcon}
					{routeTitle}
				</div>
			</LinkContainer>;

			const separator = route.separator && this.renderSeparartor(`sep_${i}`);

			menuItems.push(link);

			if (separator) {
				menuItems.push(separator);
			}
		}

		return (
			<nav className={styles.contextMenu}>
				{menuItems}
			</nav>
		);
	}

	render() {

		const collapsed = this.props.collapsed;
		const mainMenu = this.renderMainMenu(navigation);
		const contextMenu = this.renderContextMenu();

		const logoTextImg = !collapsed && <img
			src={logoTextSrc}
			height={24}
			className="ml-3"
			style={{
				marginTop: '5px'
			}}
		/>;

		const logoClassName = collapsed ? "w-100 d-flex flex-row align-items-center justify-content-left p-2" : "w-100 d-flex flex-row align-items-center justify-content-left p-3";

		const logoImg = <img
			src={logoSrc}
			className={collapsed ? styles.logoImgCollapsed : styles.logoImg}
		/>;

		const collapseIcon = <FontAwesomeIcon
			fixedWidth
			icon={collapsed ? faChevronRight : faChevronLeft}
			onClick={this.props.onToggleCollapse}
			className="mt-2 mr-2"
			style={{
				color: '#ccc',
				cursor: 'pointer',
				opacity: 0.7,
			}}
		/>;

		return (
			<div className="flex-grow-1  w-100 d-flex flex-column justify-content-between">
				<div className={"d-flex flex-column"}>
					<LinkContainer
						to={'/'}
						exact={true}
						style={{cursor: 'pointer'}}
					>
						<div className={logoClassName}>
							{logoImg}
							{logoTextImg}
						</div>
					</LinkContainer>
					{contextMenu}
				</div>
				<div className="w-100 text-right">
					{mainMenu}
					{collapseIcon}
				</div>
			</div>
		);
	}
}

export default withRouter(SideBar);
