import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import {types} from '../helpers/activityTypes';
import moment from 'moment';

import styles from 'layouts/Default/components/ActivityDrawer/style/activity-drawer.module.sass';


class ActivityItem extends React.PureComponent
{
	render()
	{
		const data = this.props.data;
		const type = data.type;
		const activityType = types[type] || {
			color: '#fff',
			icon: faBell,
			title: 'Событие'
		};

		let activityText = 'Событие';

		if (type === 'jobProgress') {
			activityText = 'Обновлен прогресс по работе';
		} else if (type === 'act') {
			activityText = `Добавлен новый Акт на сумму ${data.sum}р.`;
		} else if (type === 'expense') {
			activityText = `Добавлен новый Расход на сумму ${data.sum}р.`;
		}

		return (
			<div className={styles.activityItem}>
				<div className={styles.activityItemHeader}>
					<div
						className={styles.iconWrapper}
						style={{backgroundColor: activityType.color}}
					>
						<FontAwesomeIcon icon={activityType.icon} fixedWidth />
					</div>
					<div className={styles.activityItemHeaderText}>
						<div>
							{activityType.title}
						</div>
						<div>
							{moment(data.date).format('HH:mm')}
						</div>
					</div>
				</div>
				<div className={styles.activityItemContentWrapper}>
					<div className={styles.activityItemContent}>
						<div>
							{activityText}
						</div>
						<div className={styles.activityItemContentSecondary}>
							<span>{`${data.user.fullName}`}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ActivityItem.propTypes = {
	data: PropTypes.object
};

ActivityItem.defaultProps = {
	data: {}
};

export default ActivityItem;
