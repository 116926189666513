import React from 'react';
import ReactDOM from 'react-dom';
import store from 'core/store';
import App from './App';

const agGridKeyTrial = 'Evaluation_License-_Not_For_Production_Valid_Until_15_September_2019__MTU2ODUwMjAwMDAwMA==01b715f5df1a6e83be62c2a2e4c20de9';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey(agGridKeyTrial);

const container = document.getElementById('root');

ReactDOM.render(
	<App store={store} />,
	container
);
