import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireAlt, faArchive } from '@fortawesome/free-solid-svg-icons';


class StatusCellRenderer extends React.Component
{
	render()
	{
		const data = this.props.data;
		const { estimatedEndDate, isArchived } = data;

		if (isArchived) {
			return (
				<div>
					<FontAwesomeIcon
						className="text-secondary"
						icon={faArchive}
						fixedWidth
					/>
					<span className="pl-1">
						В архиве
					</span>
				</div>
			);
		}

		const now = moment().format('YYYY-MM-DD');

		if (!estimatedEndDate || estimatedEndDate > now) {
			return null;
		}

		const icon = <FontAwesomeIcon
			icon={faFireAlt}
			fixedWidth
			color={'rgb(225, 50, 0)'}
		/>;

		const text = moment(estimatedEndDate).startOf('day').fromNow(true);

		return (
			<div>
				{icon}
				<span className="pl-1">
					{text}
				</span>
			</div>
		);
	}
}

export default StatusCellRenderer;
