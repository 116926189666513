import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import db from 'core/db';
import { Row, Col, Container } from 'react-bootstrap';
import ProjectAggregator from '../helpers/aggregator/ProjectAggregator';
import StagesAggregator from '../helpers/aggregator/StagesAggregator';

import Card from '../components/Card';
import ScheduleCard from '../components/cards/ScheduleCard';
import EmploeeysCard from '../components/cards/EmploeeysCard';
import WeatherCard from '../components/cards/WeatherCard';
import MainProgressCard from '../components/cards/MainProgressCard';
import JobProgressCard from '../components/cards/JobProgressCard';
import EmptyCard from '../components/cards/EmptyCard';

import PlanChart from '../components/charts/PlanChart';
import PerfomanceChart from '../components/charts/PerfomanceChart';
import EmploeeysChart from '../components/charts/EmploeeysChart';
import GapChart from '../components/charts/GapChart';

import ChiefsGrid from '../components/ChiefsGrid';
import Panel from '../components/Panel';
import PhotoGallery from '../components/PhotoGallery';

import styles from '../styles/report.module.sass';

import {REPORT_DAYS_INTERVAL} from '../helpers/constants';
import CardCompact from '../components/CardCompact';


class ReportView extends React.PureComponent
{
	state = {
		reportStartDate: new Date(),
		reportEndDate: new Date(),
		photoCarouselVisible: false,
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.project) {
			return;
		}

		if (prevProps.isLoading && !this.props.isLoading) {
			this.updateProjectData();
			this.updateStagesData();
		}
	}

	componentDidMount()
	{
		const now = new Date();
		this.updateReportDates(now);

		if (!this.props.isLoading) {
			this.updateStagesData();
			this.updateProjectData();
		}
	}

	updateStagesData = () => {
		const aggregator = new StagesAggregator({
			stages: this.props.stages,
			periodicReports: this.props.periodicReports,
			employees: this.props.employees,
			jobs: this.props.jobs,
		});

		const chiefsGridData = aggregator.chiefsGridData();
		this.setState({chiefsGridData});
	}

	updateProjectData = () => {
		const aggregator = new ProjectAggregator({
			jobs: this.props.jobs,
			project: this.props.project,
		});

		const projectEstimatedEndDate = aggregator.estimatedEndDate();
		const projectCurrentPerfomancePercent = aggregator.perfomancePercents();

		this.setState({projectEstimatedEndDate, projectCurrentPerfomancePercent});
	}

	updateReportDates = (date) => {
		const endDateMoment = moment(date);
		const startDateMoment = moment(date);
		startDateMoment.subtract(REPORT_DAYS_INTERVAL - 1, 'days');

		this.setState({
			reportStartDate: startDateMoment.toDate(),
			reportEndDate: endDateMoment.toDate(),
		});
	}

	onDateChanged = date => {
		this.updateReportDates(date);
	}

	renderGalleryCard()
	{
		const { stagesMap } = this.props;

		const images = this.props.jobs.reduce((acc, job) => {
			const { attachments, stageId } = job;
			const stage = stagesMap.get(stageId);

			if (!attachments.length) {
				return acc;
			}

			for (const attachment of attachments) {

				if (!attachment.url) {
					continue;
				}

				acc.push({
					src: attachment.url,
					job,
					stage,
				});
			}

			return acc;

		}, []);

		return (
			<Card
				title="Фото с объекта"
				className={images.length ? styles.cardPhotos : ''}
			>
				{images.length ? <PhotoGallery items={images} /> : <EmptyCard/>}
			</Card>
		);
	}

	renderChiefsGridCard()
	{
		const chiefsGridItems = this.state.chiefsGridData;

		const chiefsGrid = chiefsGridItems && chiefsGridItems.length
			? <ChiefsGrid
				style={{height: '270px'}}
				items={this.state.chiefsGridData}
				employeesMap={this.props.employeesMap}
			/>
			: <EmptyCard/>
		;

		return (
			<Card title="Ситуация на участках">
				{chiefsGrid}
			</Card>
		);
	}

	render()
	{
		const chartCardStyle = {
			minHeight: '380px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		};

		const chiefsCard = this.renderChiefsGridCard();
		const photosCard = this.renderGalleryCard();

		return (
			<Container
				fluid style={{padding: '20px'}}
			>
				<Row className="mt-1">
					<Col className="px-1">
						<Card style={{ minHeight: '48px', paddingLeft: 8}} >
							<Panel onDateChanged={this.onDateChanged}/>
						</Card>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col className="px-1">
						<Row className="mx-1">
							<Col sm={5} md={5} className="px-0">
								<Card title="Успеваемость по графику" style={{minHeight: '125px'}}>
									<ScheduleCard
										project={this.props.project}
										jobs={this.props.jobs}
										jobsMap={this.props.jobsMap}
									/>
								</Card>
							</Col>
							<Col sm={2} md={2} className="px-2">
								<Card title="Людей на объекте"
									style={{minHeight: '125px'}}
								>
									<EmploeeysCard
										project={this.props.project}
										jobs={this.props.jobs}
										jobsMap={this.props.jobsMap}
									/>
								</Card>
							</Col>
							<Col sm={2} md={2} className="px-0">
								<Card title="Погода" style={{minHeight: '125px'}}>
									<WeatherCard/>
								</Card>
							</Col>
							<Col sm={3} md={3} className="px-2">
								<Card style={{minHeight: '125px'}} title={'Общая готовность'}>
									<MainProgressCard progressPercent={this.state.projectCurrentPerfomancePercent}/>
								</Card>
							</Col>
						</Row>

						<Row className="mt-3 mx-1">
							<Col sm={6} className="pr-1 pl-0">
								<Card
									title={"План"}
									style={chartCardStyle}
								>
									<PlanChart
										project={this.props.project}
										jobs={this.props.jobs}
										jobsMap={this.props.jobsMap}
										startDate={this.state.reportStartDate}
										endDate={this.state.reportEndDate}
									/>
								</Card>
							</Col>
							<Col sm={6} className="pl-1 pr-0">
								<Card
									title={"Производительность"}
									style={chartCardStyle}
								>
									<PerfomanceChart
										project={this.props.project}
										jobs={this.props.jobs}
										jobsMap={this.props.jobsMap}
										startDate={this.state.reportStartDate}
										endDate={this.state.reportEndDate}
									/>
								</Card>
							</Col>
						</Row>

						<Row className="mt-3 mx-1">
							<Col sm={6} className="pr-1 pl-0">
								<Card
									title={"Сотрудники"}
									style={chartCardStyle}
								>
									<EmploeeysChart
										project={this.props.project}
										jobs={this.props.jobs}
										jobsMap={this.props.jobsMap}
										startDate={this.state.reportStartDate}
										endDate={this.state.reportEndDate}
									/>
								</Card>
							</Col>
							<Col sm={6} className="pl-1 pr-0">
								<Card
									title={"Отставание"}
									style={chartCardStyle}
								>
									<GapChart
										project={this.props.project}
										jobs={this.props.jobs}
										jobsMap={this.props.jobsMap}
										startDate={this.state.reportStartDate}
										endDate={this.state.reportEndDate}
									/>
								</Card>
							</Col>
						</Row>

						<Row className="mt-3">
							<Col className="d-flex justify-content-left flex-wrap">
								{this.props.actualJobs.map((job, index) =>
									<CardCompact
										key={index}
										title={job.title}
										style={{
											minHeight: '50px',
											width: '260px',
											margin: '5px',
										}}
										className="d-flex flex-column"
									>
										<JobProgressCard job={job}/>
									</CardCompact>
								)}
							</Col>
						</Row>

						<Row className="mt-3 mx-1">
							<Col className="p-0">
								{chiefsCard}
							</Col>
						</Row>
						<Row className="mt-3 mx-1">
							<Col className="p-0">
								{photosCard}
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = {filter: {projectId}};

	const projects = db.projects.list({ filter: { id: projectId } });
	const stages = db.stages.listNotDeleted(projectIdFilter);
	const jobs = db.jobs.listNotDeleted(projectIdFilter);
	const employees = db.employees.list();
	const periodicReports = db.periodicReports.list();

	const jobsMap = jobs.hashById();
	const employeesMap = employees.hashById();
	const stagesMap = stages.hashById();
	const project = projects[0];

	const isLoading = stages.isLoading
		|| periodicReports.isLoading
		|| jobs.isLoading
		|| employees.isLoading
		|| projects.isLoading
	;

	const actualJobs = jobs.filter(job => (job.currentProgress > 0 && job.currentProgress < job.maxProgress) || moment(job.estimatedStartDate).isBefore(new Date()));

	return {
		actualJobs,
		jobs,
		jobsMap,
		projectId,
		project,
		isLoading,
		stages,
		employees,
		employeesMap,
		periodicReports,
		stagesMap,
	};
};

export default connect(mapToProps)(ReportView);
