import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import style from './loading.module.sass';


export default class Overlay extends React.PureComponent {

	static propTypes = {
		title: PropTypes.string,
	}

	static defaultProps = {
		title: null,
	}

	render() {
		const icon = <FontAwesomeIcon
			className={style.icon}
			fixedWidth
			spin={true}
			icon={faSpinner}
		/>;

		return (
			<div className={["d-flex flex-column align-items-center justify-content-center text-muted", this.props.className].join(' ')}>
				{icon}
				{this.props.title}
			</div>
		);
	}
}
