import React from 'react';
import PropTypes from 'prop-types';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';
import EmployeeCellRenderer from './EmployeeCellRenderer';
import { faCog, faTimes, faRedoAlt } from '@fortawesome/free-solid-svg-icons';


class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onAddEmploeeyRole: PropTypes.func,
		onRemoveEmploeey: PropTypes.func,
		onRemoveEmploeeyRole: PropTypes.func,
		onInviteEmploeey: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	render()
	{
		const getItemActions = params => {
			const emp = params.data;

			const removeAction = {
				icon: faTimes,
				hoverStyle: {color: 'rgb(225, 50, 0)'},
				onClick: this.props.onRemoveEmploeey,
			};

			const addRoleAction = {
				icon: faCog,
				onClick: this.props.onAddEmploeeyRole,
			};

			const reinviteAction = {
				icon: faRedoAlt,
				onClick: (e) => { this.props.onInviteEmploeey(e); },
			};

			if (emp.isActive) {
				return [addRoleAction, removeAction];
			}

			if (!emp.inviteState) {
				return [removeAction];
			}

			if (emp.inviteState === 'declined') {
				return [reinviteAction, removeAction];
			}

			if (emp.inviteState === 'accepted' && emp.deactivationTime) {
				return [reinviteAction];
			}

			return [];
		};

		const columnDefs = [
			{
				headerName: 'Сотрудник',
				field: 'user',
				suppressSizeToFit: false,
				cellRenderer: 'employeeCellRenderer',
				cellRendererParams: {onRemoveRole: this.props.onRemoveEmploeeyRole}
			},
			{
				headerName: 'Редактирование',
				cellRenderer: 'actionsCellRenderer',
				cellRendererParams: params => {
					const actions = getItemActions(params);

					return {actions: actions};
				}

			},
		];

		const frameworkComponents = {
			actionsCellRenderer: ActionsCellRenderer,
			employeeCellRenderer: EmployeeCellRenderer,
		};

		const gridOptions = {
			frameworkComponents: frameworkComponents,
			defaultColDef: {
				minWidth: 40,
				width: 120,
				resizable: true,
				sortable: true,
				editable: false,
				suppressSizeToFit: true,
			}
		};

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				onFirstDataRendered={this.onFirstDataRendered}
				onGridReady={this.onGridReady}
				rowData={this.props.items}
				suppressCellSelection={true}
			/>
		);
	}
}

export default Grid;
