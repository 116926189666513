import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Form, Col} from 'react-bootstrap';


export default class InputModal extends React.Component
{
	static propTypes = {
		onConfirm: PropTypes.func,
		onHide: PropTypes.func,
		title: PropTypes.string,
		text: PropTypes.string,
		placeholder: PropTypes.string,
	}

	static defaultProps = {
		title: 'Введите значение',
		placeholder: 'Ведите значение',
	}

	state = {
		show: true,
	}

	componentDidMount()
	{
		document.addEventListener('keydown', this.hadleKeyDown, false);
	}

	componentWillUnmount()
	{
		document.removeEventListener('keydown', this.hadleKeyDown, false);
	}

	hadleKeyDown = (e) => {
		if (e.key === 'Enter' && this.state.text && this.state.text.length) {
			e.preventDefault();
			this.props.onConfirm(this.state.text);
		}
	}

	onHide = () => {
		this.setState({show: false});
		this.props.onHide();
	}

	onConfirm = () => {
		if (!this.state.text) {
			this.onHide();

			return;
		}

		this.props.onConfirm(this.state.text);
	}

	onTextChanged = (e) => {
		const text = e.target.value;
		this.setState({text});
	}

	render = () => {

		return (
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.state.show}
				onHide={this.onHide}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Row>
							<Col>
								<Form.Group>
									<Form.Label>
										{this.props.text}
									</Form.Label>
									<Form.Control
										ref={(ref) => { this.input = ref; }}
										onChange={this.onTextChanged}
										placeholder={this.props.placeholder}
									/>
								</Form.Group>
							</Col>
						</Form.Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.onConfirm}>Ок</Button>
					<Button onClick={this.onHide}>Отмена</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
