
import { faBuilding, faUsers, faSuitcase, faUser } from '@fortawesome/free-solid-svg-icons';

import {getRoutes, getRoutesMap} from './helpers';
import Projects from 'domains/projects/components/ListPage';
import EmployeesList from 'domains/employees';

import projectRoutes from 'domains/projects/routes';
import companyRoutes from 'domains/company/routes';
import userRoutes from 'domains/user/routes';


export const navigation = [
	{
		path: '/',
		to: '/projects',
		exact: true,
		redirect: true,
	},
	{
		path: '/projects',
		component: Projects,
		title: 'Проекты',
		exact: true,
		faIcon: faSuitcase,
		children: [
			{
				path: '/:projectId',
				exact: true,
				redirect: true,
				to: '/projects/:projectId/report',
				children: projectRoutes,
			}
		],
	},
	{
		path: '/employees',
		title: 'Сотрудники',
		faIcon: faUsers,
		component: EmployeesList,
	},
	{
		path: '/company',
		title: 'Компания',
		faIcon: faBuilding,
		children: companyRoutes,
		redirect: true,
		to: '/company/roles',
		exact: true,
	},
	{
		path: '/user',
		title: 'Личный кабинет',
		exact: true,
		faIcon: faUser,
		children: userRoutes,
		redirect: true,
		to: '/user/profile'
	}
];


export const routes = getRoutes(navigation);
export const routesMap = getRoutesMap(navigation);
