import React from 'react';
import { Modal } from 'react-bootstrap';
import style from './styles/modal.module.sass';
import ButtonToolbar from 'components/ButtonToolbar';

class BasicModal extends React.Component {

	render()
	{
		const {title, btnList, ...props} = this.props;

		return (
			<Modal
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				{...props}
			>
				<Modal.Header closeButton className={style.modalHeader}>
					<Modal.Title className={style.modalTitle}>
						{title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.children}
				</Modal.Body>
				<Modal.Footer>
					<ButtonToolbar btnList={btnList}/>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default BasicModal;
