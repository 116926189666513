import { faSitemap, faChartPie, faProjectDiagram, faFileUpload, faFileExcel, faLayerGroup, faFileInvoiceDollar, faFileImport } from '@fortawesome/free-solid-svg-icons';
import GanttControl from 'domains/projects/components/GanttControl';
import Scope from 'domains/projects/components/Scope';
import Report from 'domains/projects/components/Report';
import DataImportView from 'domains/data-import';
import InvoicesPage from 'domains/tbs-supplies/pages/InvoicesPage';
import RequestsPage from 'domains/tbs-supplies/pages/RequestsPage';
import SpecificationsPage from 'domains/tbs-supplies/pages/SpecificationsPage';
import ImportPage from 'domains/tbs-supplies/pages/ImportPage';

const tbsRoutes = [
	{
		path: "/tbs-specifications",
		title: 'Спецификации',
		faIcon: faFileExcel,
		component: SpecificationsPage,
	},
	{
		path: "/tbs-requests",
		title: 'Заказы и заявки',
		faIcon: faLayerGroup,
		component: RequestsPage,
	},
	{
		path: "/tbs-orders",
		title: 'Счета',
		faIcon: faFileInvoiceDollar,
		component: InvoicesPage,
	},
	{
		path: "/tbs-import",
		title: 'Импорт спецификаций',
		faIcon: faFileImport,
		component: ImportPage,
	},
];

const routes = [
	{
		path: "/report",
		title: 'Ежедневный отчёт',
		faIcon: faChartPie,
		component: Report,
	},
	{
		path: "/gantt",
		title: 'График Гантта',
		faIcon: faProjectDiagram,
		component: GanttControl,
	},
	{
		path: "/scope",
		title: 'План работ',
		faIcon: faSitemap,
		component: Scope,
	},
	{
		path: "/import",
		title: 'Импорт данных',
		faIcon: faFileUpload,
		component: DataImportView,
		separator: true,
	},
	...tbsRoutes,
];

export default routes;
