import React from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { withFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faAt } from '@fortawesome/free-solid-svg-icons';

import api from 'core/api';

import UserAvatar from 'components/UserAvatar';
import TextInput from 'components/Form/TextInput';

class Edit extends React.PureComponent {

	state = {
		readonly: true,
	}

	switchEditor = () => {
		this.setState({
			readonly: !this.state.readonly
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.readOnly !== prevProps.readOnly) {
			this.setState({
				readonly: this.props.readOnly
			});
		}
	}

	render () {
		return (
			<Col>

				<Form onSubmit={this.props.handleSubmit}>
					<Form.Row className="justify-content-center mb-3">
						<Col><UserAvatar user={this.props} size={64} style={{margin: "auto"}} /></Col>
					</Form.Row>
					<Form.Group as={Row} controlId="fullName" className="justify-content-center">
						<Form.Label column sm={2}>Имя и фамилия</Form.Label>
						<Col sm={5}>
							<TextInput name="fullName" readOnly={this.state.readonly} plaintext={this.state.readonly} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} controlId="companyTitle" className="justify-content-center">
						<Form.Label column sm={2}>Компания</Form.Label>
						<Col sm={5}>
							<TextInput name="companyTitle" readOnly={this.state.readonly} plaintext={this.state.readonly} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} controlId="position" className="justify-content-center">
						<Form.Label column sm={2}>Должность</Form.Label>
						<Col sm={5}>
							<TextInput name="position" readOnly={this.state.readonly} plaintext={this.state.readonly} />
						</Col>
					</Form.Group>
					<Row className="justify-content-center mt-3 mb-1">
						<Col sm={7}>
							<h5>Контактная информация</h5>
						</Col>
					</Row>

					<Form.Group as={Row} controlId="email" className="justify-content-center">
						<Form.Label column sm={2}>E-mail</Form.Label>
						<Col sm={5}>
							<InputGroup>
								<InputGroup.Prepend style={{ display: this.state.readonly ? 'none' : 'block' }}>
									<InputGroup.Text id="inputGroupPrepend" style={{height: '38px'}}>
										<FontAwesomeIcon icon={faAt} fixedWidth />
									</InputGroup.Text>
								</InputGroup.Prepend>
								<TextInput name="email" readOnly={this.state.readonly} plaintext={this.state.readonly} />
							</InputGroup>
						</Col>
					</Form.Group>

					<Form.Group as={Row} controlId="phoneNumber" className="justify-content-center">
						<Form.Label column sm={2}>Телефон</Form.Label>
						<Col sm={5}>
							<InputGroup>
								<InputGroup.Prepend style={{ display: this.state.readonly ? 'none' : 'block' }}>
									<InputGroup.Text id="inputGroupPrepend2" style={{height: '38px'}}>
										<FontAwesomeIcon icon={faPhoneAlt} fixedWidth />
									</InputGroup.Text>
								</InputGroup.Prepend>
								<TextInput name="phoneNumber" readOnly={this.state.readonly} plaintext={this.state.readonly} />
							</InputGroup>
						</Col>
					</Form.Group>

					<Form.Row className="justify-content-end">
						<Col md="auto">
							<Button onClick={this.switchEditor}>{this.state.readonly ? 'Изменить' : 'Отменить'}</Button>
						</Col>
						<Col md="auto">
							<Button type="submit">Сохранить</Button>
						</Col>
					</Form.Row>
				</Form>
			</Col>
		);
	}
}

export default connect()(withFormik({
	mapPropsToValues: (props) => {
		return {
			fullName: props.fullName || '',
			companyTitle: props.companyTitle || '',
			position: props.position || '',
			email: props.email || '',
			phoneNumber: props.phoneNumber || '',
		};
	},
	handleSubmit: (values, { props, setSubmitting }, v) => {
		props.dispatch(api.me().updateSelf({
			...values,
			userId: props.userId,
		})).then(() => { props.switchEditor(); });

		setSubmitting(false);
	}
})(Edit));
