import React from 'react';
import CustomGrid from 'components/ag-grid-components/components/CustomGrid';
import SourceSpecInfoCellRenderer from './SourceSpecInfoCellRenderer';

class DetailCellRenderer extends React.PureComponent
{
	render() {

		const {data, sourceSpecItemsMap, sourceSpecsMapByItemId } = this.props;

		const sourceItems = data.sourceSpecificationItemIds.map(id => {
			return {
				...sourceSpecItemsMap.get(id),
				isSource: true,
				sourceSpecs: sourceSpecsMapByItemId.get(id) || [],
			};
		});

		const columnDefs = [
			{
				headerName: 'Наименование',
				field: 'title',
				width: 400
			},
			{
				headerName: 'Тип',
				field: 'partNumber',
			},
			{
				headerName: 'Производитель',
				field: 'manufacturerName',
			},
			{
				headerName: 'Исходные спецификации',
				width: 80,
				field: 'sourceSpecs',
				cellRenderer: 'sourceSpecInfoCellRenderer',
				cellRendererParams: { sourceSpecsMapByItemId },
			},
			{
				headerName: 'Количество',
				field: 'amount',
				width: 80
			},
			{
				headerName: 'Ед. изи.',
				field: 'consumableUnit.suffix',
				width: 60
			},
		];

		const onFirstDataRendered = (params) => {
			params.api.sizeColumnsToFit();
		};

		const frameworkComponents = {
			sourceSpecInfoCellRenderer: SourceSpecInfoCellRenderer,
		};

		const gridOptions = {
			frameworkComponents: frameworkComponents,
			defaultColDef: {
				cellStyle: {
					lineHeight: '25px',
					fontSize: '0.9em',
				},
			},
		};

		const wrapStyle = {
			background: '#EDF6FF',
		};

		return (
			<div style={wrapStyle} className="d-flex position-absolute w-100 h-100 p-1">
				<CustomGrid
					gridOptions={gridOptions}
					headerHeight={28}
					rowHeight={25}
					columnDefs={columnDefs}
					rowData={sourceItems}
					onFirstDataRendered={onFirstDataRendered}
				/>
			</div>
		);
	}
}

export default DetailCellRenderer;

