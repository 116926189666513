class ColumnSpec
{
	aliases;
	isRequired;

	/**
	 *
	 * @param {RegExp[]} aliases
	 * @param {boolean} isRequired
	 */
	constructor(aliases, isRequired)
	{
		this.aliases = aliases;
		this.isRequired = isRequired;
	}
}

export default ColumnSpec;
