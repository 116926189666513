import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';
import StepTail from './StepTail';
import style from './styles.module.sass';


class Steps extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		currentStep: PropTypes.number.isRequired,
	}

	getStatus = (index) => {
		const cs = this.props.currentStep;
		if (this.props.currentStep === this.props.items.length - 1) {
			return 'done';
		}

		if (index < cs) {
			return 'done';
		} else if (index > cs) {
			return 'await';
		} else {
			return 'active';
		}
	}

	render()
	{
		return (
			<div className={style.stepsWrapper}>
				{this.props.items.map((item, index) => {

					const isLast = index === this.props.items.length - 1;
					const tail = isLast ? null : <StepTail status={this.getStatus(index + 1)}/>;

					return (
						<React.Fragment key={index} >
							<Step
								number={index}
								status={this.getStatus(index, isLast)}
								{...item}
							/>
							{tail}
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}

export default Steps;
