import React from 'react';
import PropTypes from 'prop-types';

import style from './styles/ticket.module.sass';
import actionsStyle from './styles/actions-toolbar.module.sass';
import ContextMenu from './components/ContextMenu';


class TicketLayout extends React.PureComponent
{
	static propTypes = {
		isActive: PropTypes.bool,
		actions: PropTypes.arrayOf(PropTypes.object),
		onClick: PropTypes.func,
		className: PropTypes.string,
	}

	static defaultProps = {
		isActive: false,
		actions: [],
		className: '',
	}

	state = {
		hovered: false,
	}

	onMouseEnterHandler = () => {
		this.setState({ hovered: true });
	}

	onMouseLeaveHandler = () => {
		this.setState({ hovered: false });
	}

	render()
	{
		const { isActive, onClick, children, className, actions } = this.props;
		const { hovered } = this.state;

		const classNames = [isActive ? style.ticketActive : style.ticket, className];

		const contextMenu = (
			<div className={hovered || isActive ? actionsStyle.actionsActive : actionsStyle.actions}>
				<ContextMenu
					actions={actions}
				/>
			</div>
		);

		return (
			<div
				className={classNames.join(' ')}
				onClick={onClick}
				onMouseEnter={this.onMouseEnterHandler}
				onMouseLeave={this.onMouseLeaveHandler}
			>
				<div className="mr-3">
					{children[0] || children}
				</div>
				<div className="flex-grow-1 d-flex flex-column justify-content-between">
					{children[1]}
				</div>
				{contextMenu}
			</div>
		);
	}
}

export default TicketLayout;
