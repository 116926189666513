import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

/**
 * Form text input with Formik Field wrapper
 */
class TextInput extends React.PureComponent {
	render() {
		return (
			<Field
				{...this.props}
				render={({field}) => (
					<Form.Control
						{...field}
						{...this.props}
						type="text"
					/>
				)}
			/>
		);
	}
}

export default TextInput;
