import ParsedRow from './ParsedRow';

class RowParser
{
	/**
	 * @type {object<ColumnSpec>}
	 */
	columns;

	/**
	 *
	 * @param {object<ColumnSpec>} columns
	 */
	constructor(columns)
	{
		this.columns = columns;
	}

	/**
	 * @public
	 * @param {HeaderRow} header
	 * @param {number} rowIndex
	 * @param {Array} rowData
	 * @return {?ParsedRow}
	 */
	parse(header, rowIndex, rowData)
	{
		const rowObject = {};

		for (const name in header.columnIndexes) {
			const column = this.columns[name];

			const index = header.columnIndexes[name];

			const v = rowData[index] !== undefined ? rowData[index] : null;

			if (v !== null) {
				rowObject[name] = v;
			} else if (column.isRequired) {
				return null;
			}
		}

		return new ParsedRow(rowIndex, rowObject);
	}
}

export default RowParser;
