import React from 'react';
import PropTypes from 'prop-types';

import api from 'core/api';
import ParsedRow from 'core/xlsx-parser/src/ParsedRow';

import ButtonToolbar from 'components/ButtonToolbar/';
import Uploader from 'components/Uploader';
import JobSheetParser from '../helpers/JobSheetParser/JobSheetParser';

import style from '../styles/upload-view.module.sass';


class UploadView extends React.Component
{
	state = {
		fileList: []
	}

	parse = (file) => {
		const result = file.parseResult;
		const parser = new JobSheetParser();
		const parsedSheet = parser.parse(result.body);
		const worksheet = parsedSheet.worksheets && parsedSheet.worksheets[0];
		if (worksheet) {
			file.worksheet = worksheet;
			const parsedRows = worksheet.rootRowGroup.rows.filter(row => row instanceof ParsedRow);
			if (parsedRows.length) {
				file.parsedRows = parsedRows;
			} else {
				file.warning = 'Не удалось распознать строки таблицы';
			}
		} else {
			file.warning = 'Не удалось распознать таблицу';
		}
	}

	render()
	{
		const fl = this.state.fileList;
		const bynUploadDisabled = fl.some(f => f.status === 'error' || f.status === 'loading') || !fl.length;

		return (
			<React.Fragment>
				<div className={style.view}>
					<Uploader
						onFileListChanged={fileList => this.setState({fileList})}
						uploadProps={{
							customRequest: uploadApi => {
								this.props.dispatch(api.parsers().parseXlsx(uploadApi.file))
									.then(r => {
										uploadApi.file.parseResult = r;
										this.parse(uploadApi.file);
										uploadApi.onSuccess();
									})
									.catch(err => {
										console.error(err);
										uploadApi.onError();
									})
								;
							},
							multiple: true,
							accept: '.xlsx',
						}}
					/>
				</div>
				<ButtonToolbar
					style={{
						marginTop: '10px',
						marginBottom: '10px',
					}}
					btnList={[
						{
							title: 'Загрузить',
							props: {
								disabled: bynUploadDisabled,
								onClick: () => this.props.onConfirmUpload(this.state.fileList),
							}
						}
					]}
				/>
			</React.Fragment>
		);
	}
}

UploadView.propTypes = {
	onConfirmUpload: PropTypes.func,
};

export default UploadView;
