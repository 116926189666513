
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';


class NotificationItem extends React.PureComponent
{
	render()
	{
		const data = this.props.data;

		return (
			<Card bg="primary" className="mb-2">
				<Card.Header>{data.title}</Card.Header>
				<Card.Body className="pt-1">
					<Card.Text>
						<span>{data.time}</span><br/>
						{data.text}
					</Card.Text>
				</Card.Body>
				<Card.Footer>
					<Button>Прочитано</Button>
				</Card.Footer>
			</Card>
		);
	}
}

NotificationItem.propTypes = {
	data: PropTypes.object
};

NotificationItem.defaultProps = {
	data: {}
};

export default NotificationItem;
