import React from 'react';
import PropTypes from 'prop-types';
import style from '../../styles/custom-cell.module.sass';
import {Badge} from 'react-bootstrap';


class RadioGroupCellRenderer extends React.PureComponent
{
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.object)
	}

	static defaultProps = {
		actions: []
	}

	onItemClick = (key) => {
		const v = this.props.value;
		const keys = v && [...v] || [];
		const index = keys.indexOf(key);

		if (index === -1) {
			keys.push(key);
		} else {
			keys.splice(index, 1);
		}

		this.props.setValue(keys);
	}

	render()
	{
		const actions = this.props.actions;

		const dividerStyle = {
			display: 'flex',
			alignItems: 'center',
			padding: '0 5px',
		};

		const badgeStyle = {
			minWidth: '80px',
			maxWidth: '80px',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			fontWeight: 500,
			color: 'rgba(0,0,0,0.7)',
			cursor: 'pointer',
			padding: '5px 0',
		};

		const keys = this.props.value || [];

		return (
			<div
				className={style.wrapper}
				style={{justifyContent: 'center'}}
			>
				{actions.map((action, index) => {
					const devider = index === actions.length - 1 ? null : <div style={dividerStyle}/>;
					const hasKey = keys.includes(action.key);

					return (
						<div key={index} style={{display: 'flex', flexDirection: 'row'}}>
							<Badge
								pill
								style={{
									...badgeStyle,
									backgroundColor: hasKey ? 'rgba(40, 167, 69, 0.5)' : null,
									opacity: hasKey ? 1.0 : 0.4,
								}}
								onClick={() => this.onItemClick(action.key)}
							>
								{action.title}
							</Badge>
							{devider}
						</div>
					);
				})}
			</div>
		);
	}
}

export default RadioGroupCellRenderer;
