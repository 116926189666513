import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Nav } from 'react-bootstrap';

import ConsumableSheetView from './ConsumableSheetView';
import RequestsView from './RequestsView';
import OrdersView from './OrdersView';


class TabsSwitch extends React.PureComponent
{
	static propTypes = {
		consumableSheet: PropTypes.object.isRequired,
	}

	render()
	{
		const consumableSheet = this.props.consumableSheet;
		const consumableSheetApproved = consumableSheet && consumableSheet.isApproved;

		return (
			<Tab.Container defaultActiveKey="first">
				<Nav variant="tabs" className="w-100" justify>
					<Nav.Item>
						<Nav.Link eventKey="first">
								Смета
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="second" disabled={!consumableSheet}>
								Заказанные
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="third" disabled={!consumableSheetApproved}>
								Отслеживание
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<Tab.Content className="flex-grow-1 d-flex p-2 w-100">
					<Tab.Pane
						eventKey="first"
						className="flex-grow-1 d-flex"
						unmountOnExit
					>
						<ConsumableSheetView
							{...this.props}
							consumableSheet={this.props.consumableSheet}
							consumableSheetId={this.props.consumableSheet.id}
							isLoading={this.props.consumableSheetIsLoading}
						/>
					</Tab.Pane>
					<Tab.Pane
						eventKey="second"
						className="flex-grow-1 d-flex"
						unmountOnExit
					>
						<RequestsView
							consumableSheet={this.props.consumableSheet}
							consumableSheetId={this.props.consumableSheet.id}
							projectId={this.props.projectId}
							showTopToolbar={false}
							itemsCheckable={false}
						/>
					</Tab.Pane>
					<Tab.Pane
						eventKey="third"
						className="flex-grow-1 d-flex"
						unmountOnExit
					>
						<OrdersView
							consumableSheet={this.props.consumableSheet}
							consumableSheetId={this.props.consumableSheet.id}
							projectId={this.props.projectId}
							showTopToolbar={false}
							itemsEditable={false}
							payedOnly={true}
						/>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		);
	}
}

export default TabsSwitch;
