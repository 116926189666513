export default class Aggregator
{
	constructor(params)
	{
		this.params = params;
		this._getPurchaseRequestItemsByPurchaseOrderIdMap(params);
	}

	_getPurchaseRequestItemsByPurchaseOrderIdMap = params => {
		const { purchaseOrderItems, purchaseRequestItemsMap } = params;
		const map = new Map();

		for (const item of purchaseOrderItems) {
			const purchaseRequestItem = purchaseRequestItemsMap.get(item.purchaseRequestItemId);

			if (!purchaseRequestItem) {
				continue;
			}

			const mapItems = map.get(item.purchaseOrderId);

			if (mapItems) {
				mapItems.push(purchaseRequestItem);
			} else {
				map.set(item.purchaseOrderId, [purchaseRequestItem]);
			}
		}

		this._purchaseRequestItemsByPurchaseOrderIdMap = map;
	};

	getGridItems = () => {
		const {
			purchaseOrderItems,
			purchaseRequestItemsMap,
			consumableSheetItemsMap,
			purchaseOrdersMap,
			purchaseRequestsMap,
		} = this.params;

		return purchaseOrderItems.reduce((acc, item) => {
			const purchaseOrder = purchaseOrdersMap.get(item.purchaseOrderId);
			const purchaseRequestItem = purchaseRequestItemsMap.get(item.purchaseRequestItemId);
			const purchaseRequest = purchaseRequestsMap.get(purchaseRequestItem.purchaseRequestId);
			const consumableSheetItem = consumableSheetItemsMap.get(purchaseRequestItem.consumableSheetItemId);

			if (consumableSheetItem) {
				acc.push({
					...item,
					purchaseRequestItem,
					consumableSheetItem,
					purchaseOrder,
					purchaseRequest,
				});
			}

			return acc;

		}, []);
	};

	getGroupGridItems = () => {
		const { purchaseOrders } = this.params;
		const { _purchaseRequestItemsByPurchaseOrderIdMap } = this;

		return purchaseOrders.map(order => {
			const purchaseRequestItems = _purchaseRequestItemsByPurchaseOrderIdMap.get(order.id) || [];

			return {
				...order,
				purchaseRequestItems,
			};
		});
	};
}
