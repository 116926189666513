import React from 'react';
import PropTypes from 'prop-types';
import Icon from './components/icon';


class AttachedFilesToolbar extends React.PureComponent
{
	static propTypes = {
		iconsList: PropTypes.arrayOf(PropTypes.shape({
			file: PropTypes.object.isRequired,
			icon: PropTypes.object.isRequired,
			iconStyle: PropTypes.object,
		})),
		style: PropTypes.object,
		onRemoveClick: PropTypes.func,
	};

	static defaultProps = {
		iconsList: [],
	};

	render() {

		return (
			<div
				className={["d-flex flex-row align-items-center", (this.props.className || '')].join(' ')}
			>
				{this.props.iconsList.map((icon, index) => {
					return (
						<Icon
							key={index}
							onRemoveClick={this.props.onRemoveClick}
							{...icon}
						/>
					);
				})}
			</div>
		);
	}
}

export default AttachedFilesToolbar;
