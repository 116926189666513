
import EmptyCellRenderer from 'components/ag-grid-components/renderers/EmptyCellRenderer';
import UserCellRenderer from 'components/ag-grid-components/renderers/UserCellRenderer';
import DetailCellRenderer from './components/DetailCellRenderer';
import ActionsCellRenderer from 'components/ag-grid-components/renderers/ActionsCellRenderer';
import moment from 'moment';

import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';


export const getGridOptions = params => {

	const statusCellRenderer = params => {
		const { assignedUserId, purchaseRequestItems } = params.data;

		if (assignedUserId) {
			return 'В работе';
		}

		if (purchaseRequestItems.some(i => !i.isAmountApproved)) {
			return 'Утверждение количества';
		}

		if (purchaseRequestItems.every(i => i.isApproved)) {
			return 'Обработана';
		}

		return 'Оформлена';
	};

	const frameworkComponents = {
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: UserCellRenderer,
		statusCellRenderer: statusCellRenderer,
		detailCellRenderer: DetailCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			width: 120,
			resizable: true,
			sortable: true,
			editable: false,
			suppressSizeToFit: true,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params =>
{
	const { assignToMe, unassignFromMe, me } = params;

	const dateFormatter = params => moment(params.value).format('L');
	const requestTitleFormatter = params => `Заявка №${params.value}`;

	const assignAction = {
		icon: faLink,
		onClick: item => assignToMe({
			purchaseRequestId: item.id,
		}),
	};

	const unassignAction = {
		icon: faUnlink,
		onClick: item => unassignFromMe({
			purchaseRequestId: item.id,
		}),
	};

	const actionsRendererParams = params => {
		const { data } = params;

		const actions = [];

		if (data.assignedUserId) {
			if (data.assignedUserId === me.id) {
				actions.push(unassignAction);
			}
		} else {
			actions.push(assignAction);
		}

		return { actions };
	};

	const colDefs = [
		{
			headerName: 'Название',
			field: 'sn',
			suppressSizeToFit: false,
			sort: 'asc',
			valueFormatter: requestTitleFormatter,
			cellRenderer: "agGroupCellRenderer",
		},
		{
			headerName: 'Дата создания',
			field: 'creationTime',
			valueFormatter: dateFormatter,
		},
		{
			headerName: 'Статус',
			cellRenderer: 'statusCellRenderer',
		},
		{
			headerName: 'Логист',
			field: 'assignedUser',
			cellRendererSelector: params => (params.value ? { component: 'userCellRenderer' } : { component: 'emptyCellRenderer' }),
		},
		{
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: actionsRendererParams,
		}
	];

	return colDefs;
};
