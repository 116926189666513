import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import db from 'core/db';
import api from 'core/api';

import { withLoading } from 'components';
import Grid from './components/Grid';
import GroupGrid from './components/GroupGrid';
import Toolbar from 'domains/tbs-supplies/components/Toolbar';

import Aggregator from './helpers/Aggregator';


class OrdersView extends React.Component
{
	static propTypes = {
		consumableSheet: PropTypes.object,
		consumableSheetId: PropTypes.string,
		showTopToolbar: PropTypes.bool,
		itemsEditable: PropTypes.bool,
	}

	static defaultProps = {
		showTopToolbar: true,
		itemsEditable: true,
	}

	state = {
		groupByOrder: false,
	}

	renderTopToolbar()
	{
		const onChange = e => this.setState({ groupByOrder: e.target.checked });

		return (
			<Toolbar>
				<Form.Check
					label={'Группировка по номеру заказа'}
					onChange={onChange}
				/>
			</Toolbar>
		);
	}

	onPayInvoice = params => {
		this.props.dispatch(api.tbsPurchaseOrders().update({
			purchaseOrderId: params.id,
			changes: {
				isPayed: true,
			}
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onPlaceInvoice = params => {
		this.props.dispatch(api.tbsPurchaseOrders().place({
			purchaseOrderId: params.id,
		}))
			.catch(error => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	renderGrid()
	{
		if (this.state.groupByOrder) {
			return (
				<GroupGrid
					items={this.props.groupGridItems}
					detailItems={this.props.gridItems}
					onDetailCellValueChanged={params => this.onCellValueChanged(params, this.updatePurchaseOrderItem)}
					onCellValueChanged={params => this.onCellValueChanged(params, this.updatePurchaseOrder)}
					currenciesMap={this.props.currenciesMap}
					currencies={this.props.currencies}
					onPayInvoice={this.onPayInvoice}
					onPlaceInvoice={this.onPlaceInvoice}
				/>
			);
		}

		return <Grid
			items={this.props.gridItems}
			onCellValueChanged={params => this.onCellValueChanged(params, this.updatePurchaseOrderItem)}
			currenciesMap={this.props.currenciesMap}
			itemsEditable={this.props.itemsEditable}
		/>;
	}

	updatePurchaseOrderItem = (id, changes) => {
		this.props.dispatch(
			api.tbsPurchaseOrderItems().update({
				purchaseOrderItemId: id,
				changes: changes
			}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось внести изменения", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	};

	updatePurchaseOrder = (id, changes) => {
		this.props.dispatch(
			api.tbsPurchaseOrders().update({
				purchaseOrderId: id,
				changes: changes
			}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось внести изменения", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	onCellValueChanged = (params, updateCallback) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		updateCallback(params.node.data.id, changes);
	}

	render()
	{
		const topToolbar = this.props.showTopToolbar && this.renderTopToolbar();
		const grid = this.renderGrid();

		return (
			<div className="flex-grow-1 d-flex flex-column">
				{topToolbar}
				{grid}
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const { consumableSheetId, projectId } = props;

	if (!consumableSheetId) {
		return { isLoading: true };
	}

	const filter = { filter: { projectId } };
	const filterConsumbaleSheet = { filter: { consumableSheetId } };

	const purchaseOrders = db.tbsPurchaseOrders.list(filter);
	const purchaseOrderItems = db.tbsPurchaseOrderItems.list();
	const purchaseRequests = db.tbsPurchaseRequests.list();
	const purchaseRequestItems = db.tbsPurchaseRequestItems.list();
	const consumableSheetItems = db.tbsConsumableSheetItems.list(filterConsumbaleSheet);
	const currencies = db.currencies.list();

	const purchaseOrdersMap = purchaseOrders.hashById();
	const purchaseRequestItemsMap = purchaseRequestItems.hashById();
	const consumableSheetItemsMap = consumableSheetItems.hashById();
	const purchaseRequestsMap = purchaseRequests.hashById();
	const currenciesMap = currencies.hashById();

	const isLoading = props.isLoading
		|| purchaseOrders.isLoading
		|| purchaseOrderItems.isLoading
		|| purchaseRequests.isLoading
		|| purchaseRequestItems.isLoading
		|| consumableSheetItems.isLoading
		|| currencies.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new Aggregator({
		purchaseOrderItems,
		purchaseRequestItemsMap,
		consumableSheetItemsMap,
		purchaseOrdersMap,
		purchaseRequestsMap,
		purchaseOrders,
	});

	const gridItems = aggregator.getGridItems();
	const groupGridItems = aggregator.getGroupGridItems();

	return {
		gridItems,
		groupGridItems,
		currenciesMap,
		currencies,
	};
};

export default connect(mapToProps)(withLoading(OrdersView));
