class ClientStorage
{
	getAuthToken()
	{
		return localStorage.getItem('token');
	}

	setAuthToken(token)
	{
		localStorage.setItem('token', token);
	}

	deleteAuthToken()
	{
		localStorage.removeItem('token');
	}


	getDeviceId()
	{
		return localStorage.getItem('deviceId');
	}

	setDeviceId(deviceId)
	{
		localStorage.setItem('deviceId', deviceId);
	}

	deleteDeviceId()
	{
		localStorage.removeItem('deviceId');
	}


	getWebsocketToken()
	{
		return localStorage.getItem('webSocketToken');
	}

	setWebsocketToken(token)
	{
		localStorage.setItem('webSocketToken', token);
	}

	deleteWebsocketToken()
	{
		localStorage.removeItem('webSocketToken');
	}

	clear()
	{
		localStorage.clear();
	}
}

export default new ClientStorage();
