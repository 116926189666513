import React from 'react';
import api from 'core/api';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withLoading } from "components";
import Empty from 'components/Empty';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReportAggregator from '../../helpers/aggregator/ReportAggregator/ReportAggregator';
import RequestComponent from '../RequestComponent';


class PlanChart extends RequestComponent
{
	static propTypes = {
		project: PropTypes.object.isRequired,
		jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
		jobsMap: PropTypes.any.isRequired,
	}

	state = {
		dataFact: [],
		dataPlan: [],
		labels: [],
	}

	getRequestBody()
	{
		return api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: this.props.projectId,
				isDeleted: false,
			}
		});
	}

	onRequestCallback = (result) => {
		const aggregator = new ReportAggregator({
			startDate: this.props.startDate,
			endDate: this.props.endDate,
			groups: result.groups,
			jobsMap: this.props.jobsMap,
			jobs: this.props.jobs,
			project: this.props.project,
		});

		const progress = aggregator.progress().last();
		const dataFact = progress.actualPercents();
		const dataPlan = progress.planPercents();
		const labels = progress.labels().map(date => moment(date).format('DD.MM'));

		this.setState({ dataFact, dataPlan, labels });
	}

	onUpdateStarted()
	{
		this.setState({ requestInProgress: true });
	}

	onUpdateFinished()
	{
		this.setState({ requestInProgress: false });
	}

	render()
	{
		const { dataPlan, dataFact, labels, requestInProgress } = this.state;

		const setProps = {
			fill: true,
			lineTension: 0.1,
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			pointBackgroundColor: '#fff',
			pointBorderWidth: 6,
			pointHoverRadius: 8,
			pointHoverBorderWidth: 2,
			pointRadius: 1,
			pointHitRadius: 10,
			pointHoverBorderColor: 'rgba(220,220,220,1)',
		};

		const data = {
			labels: labels,
			datasets: [
				{
					...setProps,
					label: 'Факт %',
					backgroundColor: 'rgba(75,192,192,0.4)',
					borderColor: 'rgba(75,192,192,1)',
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointBorderColor: 'rgba(75,192,192,1)',
					data: dataFact,
				},
				{
					...setProps,
					label: 'План %',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					pointHoverBackgroundColor: 'rgba(255,99,132,1)',
					pointBorderColor: 'rgba(255,99,132,1)',
					data: dataPlan,
				}
			]
		};

		const options = {
			scales: {
				yAxes: [{
					ticks: {
						suggestedMin: 0,
						suggestedMax: 100,
					}
				}]
			}
		};

		const emptyData = dataFact.every(v => v === 0) && dataPlan.every(v => v === 0);
		const noData = dataFact.length === 0 && dataPlan.length === 0;

		if (requestInProgress) {
			return <Loading />;
		}

		if (noData || emptyData) {
			return <Empty title="Нет данных"/>;
		}

		return (
			<Line
				data={data}
				options={options}
			/>
		);
	}
}

const mapToProps = (state, props) => {
	const { project, jobs } = props;
	const isLoading = !project || !jobs || jobs.isLoading;

	return { isLoading };
};


export default connect(mapToProps)(withLoading(PlanChart));
