import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';


class ButtonToolbar extends React.PureComponent
{
	static propTypes = {
		btnList: PropTypes.arrayOf(PropTypes.object),
		style: PropTypes.object,
		align: PropTypes.string,
	};

	static defaultProps = {
		btnList: [],
		align: 'right',
	};


	render() {

		const getJustify = (align) => {

			if (align === 'left') {
				return 'flex-begin';
			} else if (align === 'right') {
				return 'flex-end';
			} else if (align === 'center') {
				return 'center';
			}

			return undefined;
		};

		const align = this.props.align;

		const wrapperStyle = {
			...this.props.style,
			justifyContent: getJustify(align),
		};

		const btnStyle = { minWidth: '150px'};
		if (align === 'right') {
			btnStyle.marginLeft = '8px';
		} else {
			btnStyle.marginRight = '8px';
		}

		return (
			<div
				className={["d-flex flex-row align-items-center", (this.props.className || '')].join(' ')}
				style={wrapperStyle}
			>
				{this.props.btnList.map((btn, index) => {
					return (
						<Button
							key={index}
							style={btnStyle}
							{...btn.props}
						>
							{btn.title}
						</Button>
					);
				})}
			</div>
		);
	}
}

export default ButtonToolbar;
