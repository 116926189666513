import React from 'react';
import {connect} from 'react-redux';
import { Row } from 'react-bootstrap';

import EditForm from './Edit';

class Profile extends React.PureComponent {

	state = {
		isEdit: false,
	}

	render()
	{
		return (
			<Row className="basic-section m-3 p-3 justify-content-center">
				<EditForm
					fullName={this.props.me.fullName}
					companyTitle={this.props.me.companyTitle}
					position={this.props.me.position}
					email={this.props.me.email}
					phoneNumber={this.props.me.phoneNumber}

					userId={this.props.me.id}
					readOnly={this.state.isEdit}
					switchEditor={ () => {
						this.setState({
							isEdit: !this.state.isEdit
						});
					}}
				/>
			</Row>
		);
	}
}

export default connect((state, props) => {
	return {
		me: state.me,
		company: state.me.defaultEmployee.company
	};
})(Profile);
