import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import { withFormik } from 'formik';
import DateForm from 'components/Form/Date';
import FileInput from 'components/Form/FileInput';
import TextInput from 'components/Form/TextInput';


class AttachInvoiceForm extends React.Component
{
	static propTypes = {
		onFormReady: PropTypes.func,
		onConfirm: PropTypes.func,
	}

	componentDidMount()
	{
		this.props.onFormReady(this.props);
	}

	render()
	{
		return (
			<Form>
				<Form.Group className="d-flex flex-column">
					<Form.Label>Поставщик</Form.Label>
					<TextInput name="supplierTitle"/>
				</Form.Group>
				<Form.Group as={Row}>
					<Col>
						<Form.Label>Файл счёта</Form.Label>
						<FileInput name="file"/>
					</Col>
					<Col>
						<Form.Label>Предполагаемая дата поставки</Form.Label>
						<DateForm name="date"/>
					</Col>
				</Form.Group>
			</Form>
		);
	}
}

const mapPropsToValues = props => {
	return {
		date: new Date(),
	};
};

const handleSubmit = (values, { props, setSubmitting }) => {

	const { date, file, supplierTitle } = values;

	if (date && file && supplierTitle) {
		props.onConfirm({ date, file, supplierTitle });
	}

	setSubmitting(false);
};

export default withFormik({ handleSubmit, mapPropsToValues })(AttachInvoiceForm);
