class Aggregator
{
	//! TODO надо бы нормально тут всё сделать
	constructor(params)
	{
		this.params = params;
		this._sourceSpecsMapByItemId = this.getSourceSpecsMapByItemId();
	}

	getSourceSpecsMapByItemId = () => {
		const { sourceSpecsMap, sourceSpecItems } = this.params;

		const map = new Map();

		for (const item of sourceSpecItems) {
			const itemId = item.id;
			const sourceSpecId = item.sourceSpecificationId;
			const sourceSpec = sourceSpecsMap.get(sourceSpecId);

			if (!sourceSpec) {
				continue;
			}

			const mapItems = map.get(itemId);

			if (mapItems) {
				mapItems.push(sourceSpec);
			} else {
				map.set(itemId, [sourceSpec]);
			}
		}

		return map;
	};


	getGridItems = () => {
		const { sourceSpecItems, consSpecItems, allowedSourceSpecIds, sourceSpecItemsMap } = this.params;
		const { _sourceSpecsMapByItemId } = this;

		const gridSourceItems = sourceSpecItems
			.filter(item => (allowedSourceSpecIds.length === 0 || allowedSourceSpecIds.includes(item.sourceSpecificationId)))
			.filter(item => item.consolidatedSpecificationItemId === null)
			.reduce((acc, item) => {
				const sourceSpecs = _sourceSpecsMapByItemId.get(item.id);

				if (!sourceSpecs) {
					return acc;
				}

				acc.push({
					...item,
					sourceSpecs,
					isSource: true,
				});

				return acc;
			}, [])
		;

		const gridConsItems = consSpecItems
			.filter(item => item.sourceSpecificationItemIds.length)
			.reduce((acc, item) => {

				const sourceSpecificationItemIds = item.sourceSpecificationItemIds.filter(sourceItemId => {
					const sourceItem = sourceSpecItemsMap.get(sourceItemId);

					return sourceItem && (allowedSourceSpecIds.length === 0 || allowedSourceSpecIds.includes(sourceItem.sourceSpecificationId));
				});

				const amount = sourceSpecificationItemIds.reduce((acc, sourceItemId) => {
					const sourceItem = sourceSpecItemsMap.get(sourceItemId);

					if (sourceItem) {
						return acc + Number(sourceItem.amount);
					}

					return acc;
				}, 0);

				const sourceSpecsSet = new Set();

				for (const sourceItemId of sourceSpecificationItemIds) {
					const specs = _sourceSpecsMapByItemId.get(sourceItemId);

					if (!specs) {
						continue;
					}

					for (const spec of specs) {
						sourceSpecsSet.add(spec);
					}
				}

				const sourceSpecs = Array.from(sourceSpecsSet.values());

				if (!sourceSpecs.length) {
					return acc;
				}

				acc.push({
					...item,
					sourceSpecificationItemIds,
					sourceSpecs,
					amount,
					isSource: false,
				});

				return acc;
			}, [])
		;

		return [...gridSourceItems, ...gridConsItems];
	};
}

export default Aggregator;
