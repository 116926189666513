import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { withFormik } from 'formik';
import moment from 'moment';
import { forIn, isNil, isArray, isObject, isEmpty } from 'lodash';

import TextInput from 'components/Form/TextInput';
import Select from 'components/Form/Select';
import Date from 'components/Form/Date';


class ProjectEditForm extends React.PureComponent {

	static propTypes = {
		onFormCreated: PropTypes.func,
		onSubmit: PropTypes.func,
		onCancel: PropTypes.func,
		employees: PropTypes.arrayOf(PropTypes.object),
		// project: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
	}

	componentDidMount()
	{
		this.props.onFormCreated(this.props);
	}

	render() {
		return (
			<Form>
				<Form.Group>
					<Form.Label>Название проекта</Form.Label>
					<TextInput name="title" />
				</Form.Group>
				<Form.Group>
					<Form.Label>Адрес объекта</Form.Label>
					<TextInput name="address" />
				</Form.Group>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label>Дата начала работ</Form.Label>
							<Date name="estimatedStartDate" locale="ru" />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>Дата завершения работ</Form.Label>
							<Date name="estimatedEndDate" locale="ru" />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>Руководитель проекта</Form.Label>
							<Select
								name="assignedUserId"
								items={this.props.employees}
								optionField="fullName"
								emptyValue="Не назначен"
							/>
						</Form.Group>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

const mapPropsToValues = (props) => {

	const project = props.project || {};

	return { ...project };
};

const handleSubmit = (values, { props, setSubmitting }) => {

	const intialValues = props.project || {};
	const formValues = { ...values };
	formValues.estimatedStartDate = moment(formValues.estimatedStartDate).format('YYYY-MM-DD');
	formValues.estimatedEndDate = moment(formValues.estimatedEndDate).format('YYYY-MM-DD');

	const changes = {};

	//!! Функция проверят изменения сделанные в форме
	//!! Без вложенности, исключая массивы и объекты
	forIn(formValues, (value, key) => {
		const isPlain = !(isNil(value) || isArray(value) || isObject(value));

		if (isPlain) {
			if (intialValues[key] !== value) {
				changes[key] = value;
			}
		}
	});

	if (isEmpty(changes)) {
		props.onCancel();
	} else {
		props.onSubmit(changes);
	}

	setSubmitting(false);
};

const formik = withFormik({ mapPropsToValues, handleSubmit })(ProjectEditForm);

export default connect()(formik);
