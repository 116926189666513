import React from 'react';
import PropTypes from 'prop-types';

class Price extends React.Component
{
	static formatNumber(num, withPlus = false)
	{
		const plus = withPlus && num > 0 ? '+' : '';

		return plus + ((num - 0) || 0).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1\u00A0');
	}

	render()
	{
		if (this.props.value === null && this.props.placeholder !== null) {
			return <span>{this.props.placeholder}</span>;
		}

		return (
			<span>{Price.formatNumber(this.props.value, this.props.withPlus)}&nbsp;₽</span>
		);
	}
}

Price.propTypes = {
	value: PropTypes.any,
	withPlus: PropTypes.bool,
	placeholder: PropTypes.string,
};

export default Price;
