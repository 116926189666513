import React from 'react';
import { Badge } from 'react-bootstrap';


class UserCellRenderer extends React.PureComponent
{
	render() {

		const user = this.props.value;

		if (!user) {
			return null;
		}

		return (
			<div>
				<Badge pill variant="light">
					{(user && (user.fullName || user.phoneNumber || user.email)) || 'noname'}
				</Badge>
			</div>
		);
	}
}

export default UserCellRenderer;

