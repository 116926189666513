import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFire } from '@fortawesome/free-solid-svg-icons';


class StatusCellRenderer extends React.PureComponent
{
	render()
	{
		const isGroup = this.props.node.group;
		let isOk = false;

		if (isGroup) {
			const children = this.props.node.allLeafChildren;

			if (!children.length) {
				return null;
			}

			const firstChild = children[0];
			isOk = firstChild.data.isReportOk;
		} else {
			const data = this.props.node.data;
			isOk = data.isStageOk;
		}

		const icon = <FontAwesomeIcon
			fixedWidth
			icon={isOk ? faCheck : faFire}
			style={{color: isOk ? '#65b43a' : '#e13200'}}
		/>;

		return (
			<div>
				{icon}
			</div>
		);
	}
}

export default StatusCellRenderer;
