function numerize(number, one, two, five)
{
	// eslint-disable-next-line no-bitwise
	const n = number | 0;

	const dec = n % 10;
	const hun = n % 100;

	if (dec === 0 || (hun > 10 && hun < 20)) {
		return five;
	}

	if (dec === 1) {
		return one;
	}

	if (dec < 5) {
		return two;
	}

	return five;
}

export default numerize;
