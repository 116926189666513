export const getRoutes = (navigation) => {

	let r = [];


	const getChildrenPaths = (item, parentPath = '') => {
		const currentPath = parentPath + item.path;
		let res = [{...item, path: currentPath}];

		if (item.children && item.children.length) {
			for (const child of item.children) {
				res = res.concat(getChildrenPaths(child, currentPath));
			}
		}

		return res;
	};

	for (const item of navigation) {
		r = r.concat(getChildrenPaths(item));
	}

	return r;
};

export const getRoutesMap = (navigation) => {
	let r = {};

	const getChildrenPaths = (item, parentPath = '') => {

		const currentPath = parentPath + item.path;
		let res = {};
		res[currentPath] = {...item};

		if (item.children && item.children.length) {
			for (const child of item.children) {
				res = Object.assign(res, getChildrenPaths(child, currentPath));
			}
		}

		return res;
	};

	for (const item of navigation) {
		r = Object.assign(r, getChildrenPaths(item));
	}

	return r;
};
