import moment from 'moment';
import { DATE_FORMAT } from '../../constants';

export default class ReportGenerator
{
	constructor(params)
	{
		this.params = params;
		const { startDate, endDate, groups } = params;

		const reportInterval = this._getReportInterval(startDate, endDate);
		const dates = this._genDates(startDate, reportInterval);
		const jobSetsMap = this._genJobSetsMap(dates, groups);

		this.dates = dates;
		this.jobSetsMap = jobSetsMap;
		this.reportInterval = reportInterval;
	}

	getJobSetsIterator(modificator = null)
	{
		const { jobSetsMap, mapper } = this;

		return {
			*[Symbol.iterator]() {
				for (const jobId of jobSetsMap.keys()) {

					let values = jobSetsMap.get(jobId);

					if (mapper) {
						values = mapper(values);
					}

					if (modificator) {
						values = values.map((value, index) => modificator(value, jobId, index));
					}

					yield values;
				}
			}
		};
	}

	getDatesIterator()
	{
		const { dates } = this;

		return {
			*[Symbol.iterator]() {
				for (const date of dates) {
					yield date;
				}
			}
		};
	}

	getDateByIndex(index)
	{
		const { dates } = this;

		if (index < 0 || index > dates.length) {
			return null;
		}

		return dates[index];
	}

	_getReportInterval(startDate, endDate, gap = 'days')
	{
		if (!startDate || !endDate) {
			return 0;
		}

		const interval = (moment(endDate).diff(moment(startDate), gap) || 0) + 1;

		return interval < 0 ? 0 : interval;
	}

	_genJobSetsMap(dates, groups)
	{
		if (!groups) {
			return new Map();
		}

		const jobGroupsMap = new Map(Object.entries(groups));
		const jobSetsMap = new Map();

		for (const jobId of jobGroupsMap.keys()) {
			const jobGroupValues = jobGroupsMap.get(jobId);
			const values = [];
			for (const date of dates) {
				values.push(jobGroupValues[date] || 0);
			}
			jobSetsMap.set(jobId, values);
		}

		return jobSetsMap;
	}

	_genDates(startDate, reportInterval)
	{
		if (!startDate) {
			return [];
		}

		const dateMoments = [];
		for (let i = 0; i < reportInterval; ++i) {
			dateMoments.push(moment(startDate).add(i, 'days'));
		}

		return dateMoments.map(dm => dm.format(DATE_FORMAT));
	}
}
