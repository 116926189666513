export const withCurrency = (params, getter) => {
	const { currencyRates } = params.data.consumableSheet;
	const value = getter(params);
	const currId = params.data.priceCurrencyId;
	const currValue = currId && currencyRates[currId];

	if (currValue && value) {
		return value * currValue;
	}

	return value;
};

export const retailPriceGetter = (params) => {
	const data = params.data;
	const rp = data.retailPrice;
	const pp = data.planPrice;
	const disc = 1 - (data.retailDiscountPercents / 100);

	if (rp) {
		return rp;
	} else if (pp) {
		return disc === 0 ? pp : pp / disc;
	}

	return null;
};

export const planPriceGetter = (params) => {
	const data = params.data;
	const rp = data.retailPrice;
	const pp = data.planPrice;
	const disc = 1 - (data.retailDiscountPercents / 100);

	if (pp) {
		return pp;
	} else if (rp) {
		return rp * disc;
	}

	return null;
};

export const retailCostGetter = (params) => {
	const rp = retailPriceGetter(params);
	const amount = params.data.amount;

	if (!(rp && amount)) {
		return null;
	}

	return rp * amount;
};

export const planCostGetter = (params) => {
	const pp = planPriceGetter(params);
	const amount = params.data.amount;

	if (!(pp && amount)) {
		return null;
	}

	return pp * amount;
};

export const customerPriceGetter = (params) => {
	const pp = planPriceGetter(params);
	const rp = retailPriceGetter(params);

	if (!(pp && rp)) {
		return null;
	}

	let pc = params.data.priceCorrectionPercents;
	pc = (pc && pc / 100) || 0;

	return (pc > 0 ? pp : rp) * (pc + 1);
};

export const customerCostGetter = (params) => {
	const cp = customerPriceGetter(params);
	const ppo = params.data.planPriceOverride || 0;
	const amount = params.data.amount;

	if (!(cp && amount)) {
		return null;
	}

	return ppo && Number(ppo) !== 0 ? ppo * amount : cp * amount;
};

export const margeValueGetter = (params) => {
	const cc = customerCostGetter(params);
	const pc = planCostGetter(params);

	if (!(cc && pc)) {
		return null;
	}

	return cc === 0 ? 0 : (cc - pc) / cc * 100;
};
