import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import { withRouter, matchPath } from 'react-router-dom';
import { routesMap } from '../../../../../routes';
import db from 'core/db';

import styles from 'layouts/Default/style/statusbar.module.sass';

class Breadcrumbs extends React.PureComponent {
	render() {
		if (db.projects.isLoading) {
			return null;
		}

		const locationDetails = this.props.locationDetails;
		const header = locationDetails.pop();

		return	(
			<div className={styles.header}>
				<span className={styles.screenTitle}>{header.title}</span>
				<Breadcrumb className={styles.path}>
					{locationDetails.map((item, index) => {
						let title = "";

						if (item.title || (item.entity && item.entity.title)) {
							title = item.title ? item.title : item.entity.title;
						}

						if (!title) {
							return null;
						}

						return (
							<Breadcrumb.Item key={index} href={"/#" + item.url} className={styles.item}>{title}</Breadcrumb.Item>
						);
					}
					)}
				</Breadcrumb>
			</div>
		);
	}
}

export default withRouter(connect((state, props) => {
	const locationDetails = [];
	let entity = null;

	/**
	 * @todo Навеное это стоит вынести куда-то в другое место
	 */
	const entityMap = {
		"/:projectId": {
			name: 'projects',
			idField: 'projectId'
		},
		"/:employeeId": {
			name: 'employees',
			idField: 'employeeId'
		}
	};

	for (const mask in routesMap) {
		const match = matchPath(props.location.pathname, {
			path: mask
		});
		const e = entityMap[routesMap[mask].path] ? entityMap[routesMap[mask].path] : null;

		if (!match) {
			continue;
		}

		if (e) {
			entity = db[e.name].find(match.params[e.idField]);
		}

		locationDetails.push({...match, ...routesMap[mask], entity: entity});
	}

	return {
		locationDetails: locationDetails
	};
})(Breadcrumbs));
