import React from 'react';
import PropTypes from 'prop-types';

import style from 'components/ag-grid-components/styles/custom-cell.module.sass';


class EmptyCellRenderer extends React.PureComponent
{

	static propTypes = {
		emptyTitle: PropTypes.string,
	}

	static defaultProps = {
		emptyTitle: '-',
	}

	render()
	{
		return (
			<div className={[style.wrapper, 'justify-content-center'].join(' ')}>
				{this.props.emptyTitle}
			</div>
		);
	}
}

export default EmptyCellRenderer;
