import React from 'react';
import { connect } from 'react-redux';

import ButtonToolbar from 'components/ButtonToolbar';
import Grid from './components/Grid';
import ProjectEditForm from './components/ProjectEditForm';
import { withLoading } from 'components';
import Empty from 'components/Empty';
import { withRouter } from 'react-router-dom';
import Modal from 'components/Modal';
import { Modal as ModalStatic } from 'components/Modals';
import { toast } from 'react-toastify';

import db from 'core/db';
import api from 'core/api';


class List extends React.PureComponent
{
	state = {
		editingProject: null,
		modalVisible: false,
		onlyArchivedVsisble: false,
	}

	showArchived = () => {
		this.setState({ onlyArchivedVsisble: true });
	}

	showActive = () => {
		this.setState({ onlyArchivedVsisble: false });
	}

	onTitleCellClicked = params => {
		const id = params?.data?.id;

		if (!id) {
			return;
		}

		this.props.history.push({
			pathname: `/projects/${id}`,
		});
	}

	onUnarchiveProjectClicked = params => {
		this.updateProject(params.id, { isArchived: false });
	}

	onArchiveProjectClicked = params => {
		ModalStatic.confirm({
			onConfirm: () => this.updateProject(params.id, { isArchived: true }),
			title: 'В архив',
			text: `Перенести "${params.title}" в архив?`,
		});
	}

	onEditProjectClicked = params => {
		this.setState({ editingProject: params }, () => {
			this.openModal();
		});
	}

	onCreateProjectClicked = () => {
		this.setState({ editingProject: null }, () => {
			this.openModal();
		});
	}

	closeModal= () => {
		this.setState({ modalVisible: false });
	}

	openModal = () => {
		this.setState({ modalVisible: true });
	}

	onFormCreated = formProps => {
		this.formProps = formProps;
	}

	onFormSubmit = formChanges => {
		if (this.state.editingProject) {
			this.updateProject(this.state.editingProject.id, formChanges);
		} else {
			this.createProject(formChanges);
		}
	}

	updateProject = (projectId, changes) => {
		this.props.dispatch(
			api.projects().update({
				projectId,
				changes,
			}))
			.then(() => {
				this.setState({ modalVisible: false, editingProject: null});
			})
			.catch(error => {
				toast.error("Не удалось обновить данные...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	createProject = projectParams => {
		this.props.dispatch(
			api.projects().create({
				...projectParams
			}))
			.then(() => {
				this.setState({ modalVisible: false, editingProject: null});
			})
			.catch(error => {
				toast.error("Не удалось создать проект...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	renderContent = () => {
		const onlyArchived = this.state.onlyArchivedVsisble;
		const { activeProjects, archivedProjects } = this.props;
		const items = onlyArchived ? archivedProjects : activeProjects;

		if (items.length === 0) {
			return (
				<div className="flex-grow-1 d-flex flex-column justify-content-center">
					<Empty title="Список проектов пуст."/>
				</div>
			);
		} else {
			return (
				<Grid
					className="flex-grow-1"
					items={items}
					onTitleCellClicked={this.onTitleCellClicked}
					onEditProjectClicked={this.onEditProjectClicked}
					onArchiveProjectClicked={this.onArchiveProjectClicked}
					onRowDoubleClicked={this.onEditProjectClicked}
					onUnarchiveProjectClicked={this.onUnarchiveProjectClicked}
				/>
			);
		}
	}

	render()
	{
		const btnList = [
			{
				title: 'Новый проект',
				props: {
					onClick: this.onCreateProjectClicked
				},
			}
		];

		const filterBtnList = [
			{
				title: 'Активные',
				props: {
					onClick: this.showActive,
				},
			},
			{
				title: 'Архив',
				props: {
					onClick: this.showArchived,
				},
			},
		];

		const modalBtnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.formProps?.handleSubmit(),
				},
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeModal
				},
			}
		];

		const content = this.renderContent();
		const editingProject = this.state.editingProject;

		return (
			<React.Fragment>
				<div className="flex-grow-1 d-flex flex-column">
					<div className ="d-flex flex-row justify-content-between pb-2">
						<ButtonToolbar
							btnList={btnList}
							align={'left'}
						/>
						<ButtonToolbar
							btnList={filterBtnList}
							align={'right'}
						/>
					</div>
					{content}
				</div>
				<Modal
					title={ editingProject ? 'Редактирование проекта' : 'Новый проект'}
					show={this.state.modalVisible}
					onHide={this.closeModal}
					btnList={modalBtnList}
				>
					<ProjectEditForm
						onFormCreated={this.onFormCreated}
						project={this.state.editingProject}
						onSubmit={this.onFormSubmit}
						onCancel={this.closeModal}
						employees={this.props.employees.map(e => e.user)}
					/>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {

	const activeProjects = db.projects.listNotArchived({
		filter: {
			companyId: state.company.id,
		}
	});

	const archivedProjects = db.projects.list({
		filter: {
			companyId: state.company.id,
			isArchived: true,
		}
	});

	const employees = db.employees.list();

	const isLoading = activeProjects.isLoading || archivedProjects.isLoading || employees.isLoading;
	const loadingTitle = 'Загружкаем список проектов...';

	return {
		isLoading,
		loadingTitle,
		activeProjects,
		archivedProjects,
		employees,
		company: state.company,
	};
};

export default connect(mapToProps)(withRouter(withLoading(List)));
