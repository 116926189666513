import React from 'react';
import Loading from './Loading';


const withLoading = (Component) => {

	class LoadingComponent extends React.PureComponent
	{
		render()
		{
			const { isLoading, loadingTitle } = this.props;

			if (isLoading) {
				return <Loading
					className="w-100"
					title={loadingTitle}
				/>;
			}

			return <Component {...this.props}/>;
		}
	}

	return LoadingComponent;
};

export default withLoading;
