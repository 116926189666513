import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

import { faUser, faUserEdit } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

import TicketIcon from '../../shared/TicketIcon';
import TicketLayout from '../../shared/TicketLayout';


class RequestTicket extends React.PureComponent
{
	static propTypes = {
		className: PropTypes.string,
		request: PropTypes.object.isRequired,
		isActive: PropTypes.bool,
		onClicked: PropTypes.func,
		onCreateOrderClicked: PropTypes.func,
		onAssignToMe: PropTypes.func,
	}

	static defaultProps = {
		className: '',
		request: {},
		isActive: false,
	}

	getStatus()
	{
		const { assignedUserId, purchaseRequestItems } = this.props.request;

		if (purchaseRequestItems.some(i => !i.isAmountApproved)) {
			return 'Утверждение количества';
		}

		if (purchaseRequestItems.every(i => i.isApproved)) {
			return 'Обработана';
		}

		if (assignedUserId) {
			return 'В работе';
		}

		return 'Оформлена';
	}

	getActions()
	{
		const { request } = this.props;
		const { purchaseRequestItems, assignedUser } = request;

		const actions = [
			{
				title: 'Создать заказ',
				onClick: () => this.props.onCreateOrderClicked(purchaseRequestItems),
			},
			{
				title: 'Завершить обработку',
			},
			{
				title: 'Удалить',
			}
		];

		const assignAction = {
			title: 'Взять в работу',
			onClick: () => this.props.onAssignToMe(request.id),
		};

		if (!assignedUser) {
			actions.unshift(assignAction);
		}

		return actions;
	}

	render()
	{
		const { request, isActive, className, onClicked } = this.props;
		const { purchaseRequestItems, assignedUser } = request;

		const isDone = purchaseRequestItems.every(i => i.isApproved && i.isAmountApproved);
		const date = request.creationTime && moment(request.creationTime).format('L');
		const title = `Заявка №${request.sn}`;
		const userName = assignedUser?.fullName || assignedUser?.phoneNumber || 'Не назначен';
		const status = this.getStatus();
		const actions = this.getActions();

		const icon = <TicketIcon icon={assignedUser ? faUserEdit : faUser}/>;

		const content = (
			<React.Fragment>
				<div className="w-100">
					<div className="w-100 d-flex flex-row justify-content-between align-items-center text-truncate">
						{title}
						<span style={{fontSize: '0.85em'}}>{date}</span>
					</div>
					<span
						className={["font-weight-bold", assignedUser ? '' : 'text-muted'].join(' ')}
						style={{fontSize: '0.75em'}}
					>
						{userName}
					</span>
				</div>
				<div>
					<Badge variant={ isDone ? "success" : "secondary"}>
						{status}
					</Badge>
				</div>
			</React.Fragment>
		);

		return (
			<TicketLayout
				className={className}
				actions={actions}
				onClick={() => onClicked(request.id)}
				isActive={isActive}
			>
				{icon}
				{content}
			</TicketLayout>
		);
	}
}

export default RequestTicket;
