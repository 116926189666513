import React from 'react';
import PropTypes from 'prop-types';


class PerfomanceCellRenderer extends React.PureComponent {

	static propTypes = {
		jobUnitsMap: PropTypes.object.isRequired,
	}

	render()
	{
		const number = this.props.valueFormatted;
		const unit = this.props.jobUnitsMap.get(this.props.data.unitId);

		if (!unit) {
			return (
				<div className="text-truncate">
					{number}
				</div>
			);
		}

		return (
			<div className="text-truncate">
				{`${number} (${unit.suffix})/смена`}
			</div>
		);
	}
}

export default PerfomanceCellRenderer;
