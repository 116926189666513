class DBResource
{
	constructor(store, apiResourceName, apiResource)
	{
		this.store = store;
		this.apiResourceName = apiResourceName;
		this.apiResource = apiResource;
	}

	list({filter = {}, refresh = false} = {})
	{
		const action = this.apiResource.list(() => {});
		const queryParameters = action.requestClass.QUERY_PARAMETERS;

		const serverFilters = {};

		for (const pp of action.requestClass.PATH_PARAMETERS) {
			serverFilters[pp] = filter[pp];
		}

		for (const qp of queryParameters) {
			const m = qp.match(/^filter\.(.*)$/);

			if (!m) {
				continue;
			}

			if (m[1] in filter) {
				serverFilters[m[1]] = filter[m[1]];
			}
		}

		let cacheState = this._getCache('list', serverFilters);
		if (!cacheState) {
			cacheState = this._getCache('list', {});
		}

		let p;

		if (cacheState && (cacheState.value === 'done' || cacheState.value === 'refreshInProgress')) {
			if (refresh && cacheState.value === 'done') {
				p = this.updateList(serverFilters, refresh);
			}

			return this.wrapArray(
				this._state().entities[this.apiResourceName].items
					.filter(this._makeClientFilter(filter))
					.filter(item => !item.isDeleted),
				p
			);
		}

		if (!cacheState) {
			p = this.updateList(serverFilters);
		}

		const r = [];

		r.isLoading = true;

		return this.wrapArray(r, p);
	}

	wrapArray(array, promise = null)
	{
		const wrap = this.wrapArray.bind(this);

		for (const method of ['filter', 'sort', 'map']) {
			array[method] = function(...args) {
				const a = Array.prototype[method].call(this, ...args);

				a.isLoading = this.isLoading;

				return wrap(a);
			};
		}

		array.concat = function (...args) {
			const a = Array.prototype.concat.call(this, ...args);

			if (this.isLoading) {
				a.isLoading = true;
			}

			for (const arg of args) {
				if (arg.isLoading) {
					a.isLoading = true;
				}
			}

			return wrap(a);
		};

		array.hashById = function () {
			const hash = new Map;

			for (const item of this) {
				hash.set(item.id, item);
			}

			hash.isLoading = this.isLoading;

			return hash;
		};

		if (promise) {
			array.then = promise.then.bind(promise);
			array.catch = promise.catch.bind(promise);
		} else {
			array.then = () => array;
			array.catch = () => {};
		}

		return array;
	}

	listNotDeleted(query = {})
	{
		return this.list({
			...query,
			filter: {
				...query.filter,
				isDeleted: false
			}
		});
	}

	listNotArchived(query = {})
	{
		return this.list({
			...query,
			filter: {
				...query.filter,
				isArchived: false
			}
		});
	}

	listArchived(query = {})
	{
		return this.list({
			...query,
			filter: {
				...query.filter,
				isArchived: true
			}
		});
	}

	async updateList(serverFilters, refresh = false)
	{
		this._updateCache('list', serverFilters, refresh ? 'refreshInProgress' : 'inProgress');

		const body = Object.keys(serverFilters).length ? {filter: serverFilters} : {};

		return this.store
			.dispatch(this.apiResource.list(body).withoutCache())
			.then(() => this._updateCache('list', serverFilters, 'done'))
		;
	}

	find(id)
	{
		const entity = this._state().entities[this.apiResourceName].items.find(item => {
			return item.id === id;
		});

		if (entity) {
			return entity;
		}

		return this._callOnce('find', id, () => {
			const action = this.apiResource.get(() => {});
			const idParameter = action.requestClass.PATH_PARAMETERS[0];

			return this.store
				.dispatch(this.apiResource.get(() => ({
					[idParameter]: id
				})))
			;
		});
	}

	get()
	{
		const entity = this._state().entities[this.apiResourceName];

		if (entity) {
			return entity;
		}

		this._callOnce('get', 'self', () => {
			const action = this.apiResource.get(() => {});

			this.store.dispatch(action);
		});

		return undefined;
	}

	_state()
	{
		return this.store.getState();
	}

	_makeClientFilter(filters)
	{
		return obj => {
			for (const field in filters) {
				if (obj[field] !== filters[field]) {
					return false;
				}
			}

			return true;
		};
	}

	_getCacheKey(method, args)
	{
		return this.apiResourceName + '::' + method + '::' + JSON.stringify(args);
	}

	_getCache(method, args)
	{
		const key = this._getCacheKey(method, args);

		return this._state().dbResourceCache[key];
	}

	_updateCache(method, args, value)
	{
		const key = this._getCacheKey(method, args);

		this.store.dispatch({
			type: 'DB_RESOURCE_CACHE',
			key: key,
			value: value,
		});
	}

	_callOnce(method, args, cb)
	{
		const cached = this._getCache(method, args);

		if (cached) {
			return cached.value;
		}

		const updateCache = (value) => {
			this._updateCache(method, args, value);
		};

		const value = cb();

		if (value instanceof Promise) {
			this._updateCache(method, args, null);

			value.then(updateCache);

			return null;
		} else {
			updateCache(value);
		}

		return value;
	}

	access(permission, params)
	{
		const cached = this._getCache('access', {permission, params});
		if (cached) {
			return cached.value;
		}

		this._updateCache('access', {permission, params}, undefined);

		this.apiResource.access()[permission](params).then(r => {
			this._updateCache('access', {permission, params}, r.permitted);
		});

		return null;
	}
}

export default DBResource;
