import React from 'react';
import styles from 'layouts/Default/style/statusbar.module.sass';
import userIcon from 'layouts/Default/assets/img/icons/worker-001.png';


class CustomToggle extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		e.preventDefault();
		this.props.onClick(e);
	}

	render() {

		return (
			<div className={styles.user} onClick={this.handleClick}>
				<span className={styles.name}>{this.props.userName}</span>
				<div className={styles.icon}>
					<img src={userIcon}></img>
				</div>
			</div>
		);
	}
}

export default CustomToggle;
