import clientStorage from './clientStorage';
import uuidv4 from 'uuid/v4';

let deviceId = clientStorage.getDeviceId();

if (!deviceId) {
	deviceId = uuidv4();
	clientStorage.setDeviceId(deviceId);
}

export default deviceId;
