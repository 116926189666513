
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

import api from "core/api";
import db from "core/db";

import ButtonToolbar from 'components/ButtonToolbar';
import TagToolbar from 'components/TagToolbar';
import AttachedFilesToolbar from 'components/AttachedFilesToolbar';
import { Modal } from 'components/Modals';
import { withLoading } from 'components';

import Grid from './components/Grid';
import MergeDialog from './components/MergeDialog';
import SourceSpecsFilter from './components/SourceSpecsFilter';
import Toolbar from 'domains/tbs-supplies/components/Toolbar';

import Merger from './helpers/Merger';
import Aggregator from './helpers/Aggregator';


//! TODO Надо бы декомпозировать немного
class ConsolidatedSpecView extends React.PureComponent
{
	static propTypes = {
		consSpec: PropTypes.object.isRequired,
		consSpecId: PropTypes.string.isRequired,
	};

	state = {
		selectedNodes: [],
		mergeDialogOpen: false,
		approving: false,
		uploading: false,
		filterModalVisible: false,
	}

	componentDidMount()
	{
		if (!this.props.isLoading) {
			this.tryMerge();
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.isLoading && !this.props.isLoading) {
			this.tryMerge();
		}
	}

	tryMerge()
	{
		const { consSpec, consSpecId, sourceSpecItems, consSpecItems } = this.props;

		if (!consSpec || consSpec.isApproved) {
			return;
		}

		const needMerge = Merger.mergeAll(
			consSpecId,
			sourceSpecItems,
			consSpecItems,
		);

		if (needMerge.length) {
			Modal.confirm({
				title: 'Объеденине позиций',
				text: `Найдены одинаковые позиции(${needMerge.length}), объеденить?`,
				onConfirm: () => this.merge(needMerge),
			});
		}
	}

	merge(merges)
	{
		this.props.dispatch(api.transaction().execute(merges))
			.then(() => {
				toast.success('Позиции объеденины!', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				this.gridApi.redrawRows();
			})
			.catch(err => {
				console.error(err);
				toast.error('Не удалось объеденить позиции...', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	closeMergeDialog = () => {
		this.setState({mergeDialogOpen: false});
	}

	openMergeDialog = () => {
		this.setState({mergeDialogOpen: true});
	}

	onSelectionChanged = (params) => {
		const selectedNodes = params.api.getSelectedNodes();
		this.setState({selectedNodes});
	}

	approveSpecification = () => {
		const sourceItems = this.props.sourceSpecItems.filter(item => item.consolidatedSpecificationItemId === null);

		this.setState({ approving: true });

		const constItemsCreation = sourceItems.map(item => api.tbsConsolidatedSpecificationItems().create({
			consolidatedSpecificationId: this.props.consSpecId,
			title: item.title,
			partNumber: item.partNumber,
			manufacturerName: item.manufacturerName,
			consumableUnitId: item.consumableUnitId,
			sourceSpecificationItemIds: [item.id],
		}));

		const consSheetCreation = api.tbsConsumableSheets().create({
			consolidatedSpecificationId: this.props.consSpecId
		});

		const transactionBody = [...constItemsCreation, consSheetCreation];

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => db.tbsConsolidatedSpecifications.list({ refresh: true }))
			.then(() => this.setState({ approving: false }))
			.catch(err => {
				console.error(err);
				toast.error('Ошибка утверждения сводной спецификации', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				this.setState({ approving: false });
			})
		;
	};

	onApproveClick = () => {
		const model = this.gridApi.getModel();
		const nodes = model.rootNode.allLeafChildren;

		const hasUnfilled = nodes.some(node => !node.data.consumableUnitId);

		if (hasUnfilled) {
			toast.error('Необходимо заполнить все поля', {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
			});
		} else {
			this.approveSpecification();
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onFilterToolbarReady = api => {
		this.filterApi = api;
	}

	onMergeSuccess = () => this.setState({selectedNodes: []});

	removeSourceSpec = (spec) => {
		this.props.dispatch(api.tbsSourceSpecifications().update({
			sourceSpecificationId: spec.id,
			changes: {
				isDeleted: true
			}
		}))
			.then(() => this.props.dispatch(api.tbsConsolidatedSpecificationItems().list().withoutCache()))
			.then(() => this.props.dispatch(api.tbsSourceSpecificationItems().list().withoutCache()))
			.catch(err => {
				console.error(err);
			})
		;
	};

	onRemoveSourceSpecClicked = (attachedFile) => {
		Modal.confirm({
			title: 'Удаление ИС',
			text: `Удалить ${attachedFile.originalName}?`,
			onConfirm: () => this.removeSourceSpec(attachedFile),
		});
	}

	onSourceSpecFilterChange = sourceSpecIds => {
		this.props.onSourceSpecFilterChange(sourceSpecIds);
	}

	onUploadSourceSpec = file => {
		this.setState({uploading: true});
		this.props.dispatch(api.tbsSourceSpecifications().uploadFile(file))

			.then((result) => {
				return this.props.dispatch(api.tbsSourceSpecifications().create({
					consolidatedSpecificationId: this.props.consSpecId,
					attachmentId: result.attachment.id,
				}));
			})
			.then(() => {
				db.tbsConsolidatedSpecifications.list({refresh: true});
				db.tbsConsolidatedSpecificationItems.list({refresh: true});
				db.tbsSourceSpecificationItems.list({refresh: true});
				db.tbsSourceSpecifications.list({refresh: true});
				if (!this.props.isLoading) {
					this.tryMerge();
				}
			})
			.then(() => {
				toast.success('Файл загружен!', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.catch((error) => {
				console.error(error);
				toast.error('Не удалось загрузить файл...', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				this.setState({uploading: false});
			})
		;
	}

	renderTopToolbar = () => {
		const isApproved = this.props.consSpec.isApproved;

		const tagList = [
			{
				title: 'Утверждена',
				variant: 'success',
			},
		];

		const tagToolbar = isApproved && <TagToolbar
			tagList={tagList}
			className="p-2"
		/>;

		const sourceSpecsAttachedXlsxList = this.props.sourceSpecs.map(sourceSpec => {
			return {
				file: {
					id: sourceSpec.id,
					originalName: sourceSpec.attachment.originalName,
					url: sourceSpec.attachment.url,
				},
				icon: faFileExcel,
				iconStyle: {color: 'green'},
			};
		});

		const consSpecAttachedXlsxList = this.props.consSpec.artifacts.map(art => {
			return {
				file: {
					id: art.downloads.xlsx.url,
					originalName: art.title,
					url: art.downloads.xlsx.url,
				},
				icon: faFileExcel,
				iconStyle: {color: 'green'},
				withAuth: true,
			};
		});

		const attachedIconsList = [...sourceSpecsAttachedXlsxList, ...consSpecAttachedXlsxList];

		const uploadIcon = !isApproved &&
		<React.Fragment>
			<Button
				disabled={this.state.uploading}
				className="px-1 mx-2"
				onClick={() => this.uploadInputRef.click()}
			>
				<FontAwesomeIcon
					icon={faFileUpload}
					fixedWidth
					size="lg"
				/>
			</Button>
			<input
				ref={ref => (this.uploadInputRef = ref)}
				type="file"
				style={{display: 'none'}}
				onChange={() => this.onUploadSourceSpec(this.uploadInputRef.files[0])}
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			/>
		</React.Fragment>;

		const attachedFilesToobar =
			<AttachedFilesToolbar
				iconsList={attachedIconsList}
				onRemoveClick={isApproved ? null : this.onRemoveSourceSpecClicked}
				isRemovable={true}
			/>
		;

		const filterToolbar = !isApproved && <SourceSpecsFilter
			sourceSpecs={this.props.sourceSpecs}
			onChange={this.onSourceSpecFilterChange}
		/>;

		return (
			<Toolbar className="justify-content-between w-100">
				<div className="d-flex flex-row align-items-center">
					{filterToolbar}
					{tagToolbar}
				</div>
				<div className="d-flex flex-row align-items-center">
					{attachedFilesToobar}
					{uploadIcon}
				</div>
			</Toolbar>
		);
	}

	renderBtnTooolbar = () => {
		const selectedCount = this.state.selectedNodes.length;

		const btnListRight = [
			{
				title: 'Утвердить',
				props: {
					onClick: this.onApproveClick,
					disabled: this.state.approving,
				}
			},
		];

		const btnMerge = {
			title: `Объеденить (${selectedCount})`,
			props: {
				onClick: this.openMergeDialog,
			}
		};

		const btnListLeft = [];

		if (selectedCount > 1) {
			btnListLeft.push(btnMerge);
		}

		return (
			<div className="d-flex flex-row justify-content-between m-2">
				<ButtonToolbar btnList={btnListLeft} align="left"/>
				<ButtonToolbar btnList={btnListRight}/>
			</div>
		);
	}

	render() {
		const isApproved = this.props.consSpec.isApproved;
		const topToolbar = this.renderTopToolbar();
		const btnToolbar = !isApproved && this.renderBtnTooolbar();
		const mergeItems = this.state.selectedNodes.map(node => node.data);

		return (
			<React.Fragment>
				<div className={"d-flex flex-column flex-grow-1 mw-100"}>
					{topToolbar}
					<Grid
						items={this.props.gridItems}
						sourceSpecsMap={this.props.sourceSpecsMap}
						sourceSpecsMapByItemId={this.props.sourceSpecsMapByItemId}
						consumableUnitsMap={this.props.consumableUnitsMap}
						consumableUnits={this.props.consumableUnits}
						onSelectionChanged={this.onSelectionChanged}
						sourceSpecItemsMap={this.props.sourceSpecItemsMap}
						consSpecId={this.props.consSpecId}
						onGridReady={this.onGridReady}
						isApproved={isApproved}
					/>
					{btnToolbar}
				</div>
				<MergeDialog
					items={mergeItems}
					isOpen={this.state.mergeDialogOpen}
					onClose={this.closeMergeDialog}
					consumableUnitsMap={this.props.consumableUnitsMap}
					consumableUnits={this.props.consumableUnits}
					consSpecId={this.props.consSpecId}
					onSuccess={this.onMergeSuccess}
				/>
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {

	const { consSpecId, allowedSourceSpecIds } = props;
	const consSpecIdFilter = { filter: { consolidatedSpecificationId: consSpecId }};

	const sourceSpecItems = db.tbsSourceSpecificationItems.list(consSpecIdFilter);
	const consSpecItems = db.tbsConsolidatedSpecificationItems.list(consSpecIdFilter);
	const sourceSpecs = db.tbsSourceSpecifications.list(consSpecIdFilter);
	const consumableUnits = db.consumableUnits.list();

	const sourceSpecItemsMap = sourceSpecItems.hashById();
	const sourceSpecsMap = sourceSpecs.hashById();
	const consumableUnitsMap = consumableUnits.hashById();

	const isLoading = props.isLoading
		|| sourceSpecItems.isLoading
		|| consSpecItems.isLoading
		|| sourceSpecs.isLoading
		|| consumableUnits.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new Aggregator({
		sourceSpecsMap,
		sourceSpecItems,
		consSpecItems,
		allowedSourceSpecIds,
		sourceSpecItemsMap,
	});

	const gridItems = aggregator.getGridItems();
	const sourceSpecsMapByItemId = aggregator.getSourceSpecsMapByItemId();

	return {
		gridItems,
		sourceSpecsMapByItemId,
		sourceSpecsMap,
		sourceSpecs,
		consumableUnitsMap,
		consumableUnits,
		sourceSpecItems,
		consSpecItems,
		sourceSpecItemsMap,
	};
};

export default connect(mapToProps)(withLoading(ConsolidatedSpecView));

