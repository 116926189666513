class ParsedWorksheet
{
	/**
	 * @type {string}
	 */
	title;

	/**
	 * @type {ParsedRowGroup}
	 */
	rootRowGroup;

	/**
	 * @param {string} title
	 * @param {ParsedRowGroup} rootRowGroup
	 */
	constructor(title, rootRowGroup)
	{
		this.title = title;
		this.rootRowGroup = rootRowGroup;
	}
}

export default ParsedWorksheet;
