import React from 'react';
import PropTypes from 'prop-types';
import style from '../styles/upload-list.module.sass';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faSpinner, faCheck, faExclamationCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';


class UploadListItem	extends React.Component
{
	state = {
		iconHover: false
	}

	onMouseEnterHandler = () => {
		this.setState({
			iconHover: true
		});
	}

	onMouseLeaveHandler = () => {
		this.setState({
			iconHover: false
		});
	}

	getIcon = item => {
		const status = item.status;
		const warning = item.warning;

		if (this.state.iconHover) {
			return faTimes;
		}
		if (status === 'done') {
			return warning ? faExclamationTriangle : faCheck;
		} else if (status === 'loading') {
			return faSpinner;
		} else if (status === 'error') {
			return faExclamationCircle;
		}

		return undefined;
	}

	getIconClass = item => {
		const status = item.status;
		const warning = item.warning;

		if (this.state.iconHover) {
			return style.itemIconRemove;
		}
		if (status === 'done') {
			return warning ? style.itemIconWarning : style.itemIconDone;
		} else if (status === 'error') {
			return style.itemIconError;
		}

		return style.itemIcon;
	}

	render()
	{
		const item = this.props.data;
		const status = item.status;
		const icon = this.getIcon(item);
		const iconClassName = this.getIconClass(item);
		const spin = status === 'loading' && !this.state.iconHover;
		const warning = item.warning;
		const error = item.status === 'error';

		const tooltipVisible = error || warning;
		const tooltipText = error ? item.errorText : warning;

		const tooltip =
			<Tooltip
				id={"tooltip_" + item.uid}
				style={{visibility: tooltipVisible ? 'visible' : 'hidden'}}
			>
				{tooltipText}
			</Tooltip>
		;


		const popperConfig = {
			modifiers: {
				preventOverflow: { enabled: false },
				hide: { enabled: false },
			}
		};

		return (
			<OverlayTrigger overlay={tooltip} trigger="hover" popperConfig={popperConfig}>
				<div
					className={error ? style.uploadListItemError : style.uploadListItem}
					onMouseEnter={this.onMouseEnterHandler}
					onMouseLeave={this.onMouseLeaveHandler}
				>
					<div> {item.name} </div>
					<div className={style.itemIconsWrapper}>
						<FontAwesomeIcon
							fixedWidth
							className={iconClassName}
							spin={spin}
							icon={icon}
							onClick={() => this.props.onRemove(item)}
						/>
					</div>
				</div>
			</OverlayTrigger>
		);
	}
}

UploadListItem.propTypes = {
	data: PropTypes.object.isRequired,
	onRemove: PropTypes.func,
};

UploadListItem.defaultProps = {
	data: {}
};

export default UploadListItem;
