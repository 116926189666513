class Estimator
{
	/**
	 *
	 * @type {TasksGraph}
	 */
	tasksGraph = null;
	nowMs = 0;

	constructor(now, tasksGraph)
	{
		this.nowMs = now.getTime();
		this.tasksGraph = tasksGraph;
	}

	taskIsCompleted(task)
	{
		return task.completed;
	}

	reestimateInplace()
	{
		for (const task of this.tasksGraph.tasks) {
			task.estimatedEndMs = null;
		}

		for (const task of this.tasksGraph.tasks) {
			this.estimateEndMsInplace(task, 0);
		}
	}

	taskDurationMs(task)
	{
		return task.end_date.getTime() - task.start_date.getTime();
	}

	estimateEndMsInplace(task, level)
	{
		if (task.estimatedEndMs) {
			return task.estimatedEndMs;
		}

		if (task.completed) {
			task.estimatedEndMs = task.end_date.getTime();

			return task.estimatedEndMs;
		}

		if (level > 10000) {
			throw new Error('Recursion detected');
		}

		if (task.taskType === 'stage') {
			return this.stageEstimateEndMsInplace(task, level);
		}

		let estimatedStartMs = task.start_date.getTime();

		for (const dep of this.tasksGraph.taskIncomingMap[task.id]) {
			const sd = this.estimateEndMsInplace(dep, level + 1);

			if (sd > estimatedStartMs) {
				estimatedStartMs = sd;
			}
		}

		const durationMs = this.taskDurationMs(task);
		const remainingDuration = durationMs * (1 - Math.min(1, task.progress || 0));

		const estimatedEndMs = Math.max(this.nowMs + remainingDuration, estimatedStartMs + remainingDuration);

		task.estimatedEndMs = estimatedEndMs;

		return estimatedEndMs;
	}

	stageEstimateEndMsInplace(stageTask, level)
	{
		let estimatedEndMs = stageTask.end_date.getTime();

		for (const dep of this.tasksGraph.taskIncomingMap[stageTask.id]) {
			const end = this.estimateEndMsInplace(dep, level + 1);

			if (end > estimatedEndMs) {
				estimatedEndMs = end;
			}
		}

		for (const task of this.tasksGraph.stageChildsMap[stageTask.id]) {
			const end = this.estimateEndMsInplace(task, level + 1);

			if (end > estimatedEndMs) {
				estimatedEndMs = end;
			}
		}

		stageTask.estimatedEndMs = estimatedEndMs;

		return estimatedEndMs;
	}
}

export default Estimator;
