import React from 'react';
import PropTypes from 'prop-types';
import style from '../styles/container.module.sass';


class Card extends React.PureComponent
{
	static propTypes = {
		title: PropTypes.string,
		style: PropTypes.object,
		contentStyle: PropTypes.object,
	}

	render()
	{
		return (
			<div className={"basic-section " + style.card + " " + this.props.className} style={this.props.style}>
				<h3>{this.props.title}</h3>
				<div className={style.container} style={this.props.contentStyle}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default Card;
