import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';

class AuthDownloadLink extends React.Component
{
	extractFilename(contentDisposition)
	{
		const ascii = contentDisposition.match(/filename="(.*?)"/);
		if (ascii) {
			return ascii[1];
		}

		const utf = contentDisposition.match(/filename\*=UTF-8''([^,]*)/);
		if (utf) {
			return decodeURIComponent(utf[1]);
		}

		return null;
	}

	onClick(event)
	{
		event.preventDefault();
		event.stopPropagation();

		let response;

		fetch(
			this.props.href,
			{
				method: 'GET',
				headers: {
					Authorization: this.props.token,
				},
			}
		).then(r => {
			response = r;

			return r.blob();
		}).then(blob => {
			saveAs(blob, this.extractFilename(response.headers.get('content-disposition')));
		}).catch(console.error);
	}

	render()
	{
		return <a href={this.props.href} onClick={this.onClick.bind(this)}>{this.props.children}</a>;
	}
}

AuthDownloadLink.propTypes = {
	children: PropTypes.node.isRequired,
	href: PropTypes.string.isRequired,
};

export default connect((state, props) => ({
	token: state.token,
}))(AuthDownloadLink);
