import React from 'react';
import PropTypes from 'prop-types';
import AttachedFilesToolbar from 'components/AttachedFilesToolbar';
import TagToolbar from 'components/TagToolbar';

import TicketLayout from '../../shared/TicketLayout';
import TicketIcon from '../../shared/TicketIcon';

import { faFileInvoice, faFileExcel } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');


class OrderTicket extends React.PureComponent
{
	static propTypes = {
		className: PropTypes.string,
		order: PropTypes.object.isRequired,
		isActive: PropTypes.bool,
		onClicked: PropTypes.func,
	}

	static defaultProps = {
		className: '',
		order: {},
		isActive: false,
	}

	getStatus() {

		const { purchaseRequestItems, isPayed, isPlaced, isWatingForApprove } = this.props.order;

		const isApproved = purchaseRequestItems.every(i => i.isApproved && !i.isCanceled);

		if (isPayed) {
			return 'Оплачен';
		}

		if (isPlaced) {
			return 'Размещён';
		}

		if (isWatingForApprove && isApproved) {
			return 'На оплате';
		}

		if (isApproved) {
			return "Утверждён РП";
		}

		return 'Новый';
	}

	getActions()
	{
		const { order } = this.props;

		const { isPayed, isPlaced, isWaitingForApprove } = order;

		const actions = [
			{
				title: 'Редактировать',
				onClick: () => this.props.onEditOrder(order),
			}
		];

		if (!isWaitingForApprove) {
			actions.push({
				title: 'Отправить на оплату',
				onClick: () => this.props.onMoveToPayingOrder(order),
			});
		}

		if (!isPlaced) {
			actions.push({
				title: 'Разместить',
				onClick: () => this.props.onPlaceOrder(order),
			});
		}

		if (!isPayed) {
			actions.push({
				title: 'Оплатить',
				onClick: () => this.props.onPayOrder(order),
			});
		}

		actions.push({
			title: 'Завершить обработку',
		});

		actions.push({
			title: 'Удалить',
		});

		return actions;
	}

	getTagList() {
		const { order } = this.props;
		const { isPayed } = order;

		const status = this.getStatus();

		const tagList = [
			{
				title: status,
				variant: isPayed ? "success" : "secondary",
			},
		];

		return tagList;
	}

	render()
	{
		const { order, isActive, className, onClicked } = this.props;
		const { sn, invoiceNumber, invoiceDate, supplierTitle, attachments } = order;

		const icon = <TicketIcon icon={faFileInvoice}/>;
		const actions = this.getActions();
		const tagList = this.getTagList();

		const attachedInvoices = attachments.map(attach => {
			return {
				file: attach,
				icon: faFileExcel,
				iconStyle: { color: 'green' },
			};
		});

		const invoice = invoiceNumber && <div style={{fontSize: '0.85em'}}>
			{`Счёт "${invoiceNumber}"`}
		</div>;

		const supplier = supplierTitle && <div style={{fontSize: '0.85em'}}>
			{`Поставщик "${supplierTitle}"`}
		</div>;

		const date = invoiceDate && <div style={{fontSize: '0.85em'}}>
			{`Дата ${moment(invoiceDate).format('L')}`}
		</div>;

		const content = (
			<React.Fragment>
				<div className="w-100">
					{`Заказ №${sn}`}
				</div>
				<div className="d-flex flex-row justify-content-between align-items-center w-100">
					<div>
						{invoice}
						{supplier}
						{date}
					</div>
					<AttachedFilesToolbar iconsList={attachedInvoices}/>
				</div>
				<div>
					<TagToolbar className="mt-2" tagList={tagList}/>
				</div>
			</React.Fragment>
		);

		return (
			<TicketLayout
				className={className}
				actions={actions}
				onClick={() => onClicked(order.id)}
				isActive={isActive}
			>
				{icon}
				{content}
			</TicketLayout>
		);
	}
}

export default OrderTicket;
